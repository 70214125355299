import {
  COURSE_ALL_FAILURE,
  COURSE_ALL_REQUEST,
  COURSE_ALL_SUCCESS,
  UPDATE_COURSE_ORDER_INDEX_FAILURE,
  UPDATE_COURSE_ORDER_INDEX_REQUEST,
  UPDATE_COURSE_ORDER_INDEX_SUCCESS
} from '../Action/types';

const GET_ALL_INITIAL_STATE = { loading: false, data: {}, error: {} };
const UPDATE_COURSE__ORDER_INDEX_INITIAL_STATE = { loading: false, data: {}, error: {} };

export const allCourse = (state = GET_ALL_INITIAL_STATE, action) => {
  switch (action.type) {
    case COURSE_ALL_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case COURSE_ALL_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case COURSE_ALL_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const updateCourseOrderIndex = (state = UPDATE_COURSE__ORDER_INDEX_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COURSE_ORDER_INDEX_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case UPDATE_COURSE_ORDER_INDEX_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case UPDATE_COURSE_ORDER_INDEX_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

import { Alert } from '../alert';

export const AlertTrialEnded = () => {
  return (
    <Alert
      type="error"
      title="Your free trial has ended"
      text="We hope you enjoyed your TeachTappy 14-day free trial. Unfortunately, your trial period has now expired, please upgrade to continue using our platform."
    />
  );
};

import React, { useState } from 'react';
import { Steps } from 'antd';
import GetStarted from '../GetStarted/Container';
import ClassDetail from '../ClassDetail/Container';
import Schedule from '../Schedule/Container';
import SchoolCalendar from '../SchoolCalendar/Container';
import CourseStandards from '../CourseStandards/Container';
import PaceStandards from '../PaceStandards/Container';
import Assessments from '../Assessments/Container';
import CheckMyPlan from '../CheckMyPlan/Container';
import Done from '../Done/Container';

const PageContext = React.createContext();
const { Step } = Steps;

export default function Courses(props) {
  const [state, setState] = useState({ errors: {} });
  const [loader, setLoader] = useState(false);
  return (
    <PageContext.Provider value={{ state, setState, loader, setLoader, ...props }}>
      <COURSES />
    </PageContext.Provider>
  );
}

function COURSES() {
  return (
    <>
      <Steps current={3}>
        <Step title="GetStarted" className={'steps-content'} description={<GetStarted />} />
        <Step title="ClassDetail" className={'steps-content'} description={<ClassDetail />} />
        <Step title="Schedule" className={'steps-content'} description={<Schedule />} />
        <Step title="SchoolCalendar" className={'steps-content'} description={<SchoolCalendar />} />
        <Step title="CourseStandards" className={'steps-content'} description={<CourseStandards />} />
        <Step title="PaceStandards" className={'steps-content'} description={<PaceStandards />} />
        <Step title="Assessments" className={'steps-content'} description={<Assessments />} />
        <Step title="CheckMyPlan" className={'steps-content'} description={<CheckMyPlan />} />
        <Step title="Done" className={'steps-content'} description={<Done />} />
      </Steps>
    </>
  );
}

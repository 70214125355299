import PropTypes from 'prop-types';
import { HorizontalCard } from '../../atom';

import styles from './current-plan-card.module.scss';

export const CurrentPlanCard = ({ currentPlanDescription, content }) => {
  return (
    <HorizontalCard>
      <div className={styles.cardContainer}>
        <div className={styles.currentPlan}>
          <label className={`body-large ${styles.currentPlanLabel}`}>Current Plan</label>
          <h4 className={`heading4 ${styles.currentPlanValue}`}>{currentPlanDescription}</h4>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </HorizontalCard>
  );
};

CurrentPlanCard.propTypes = {
  currentPlanDescription: PropTypes.string.isRequired
};

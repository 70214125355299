/* AUTH */
import Login from './Pages/Auth/Login/Container/index';
import ResetPassword from './Pages/Auth/ResetPassword/Container/index';
import CreateNewPassword from './Pages/Auth/CreateNewPassword/Container/index';
import CreateAccount from './Pages/Auth/CreateAccount/Container/index';
import EmailVerification from './Pages/Auth/EmailVerification/Container/index';
import TellUsAboutYou from './Pages/Auth/TellUsAboutYou/Container/index';
import ResetPasswordEmailVerification from './Pages/Auth/ResetPasswordEmailVerification/Container/index';
import PasswordChangedSuccess from './Pages/Auth/PasswordChangedSuccess/Container';

/* TEACHER */
import Notifications from './Pages/Notifications/Container/index';
import Dashboard from './Pages/Dashboard/Container/index';
import Courses from './Pages/Courses/Container/index';
import IndividualCourse from './Pages/IndividualCourse/Container/index';
import ProfileAndSettings from './Pages/ProfileAndSettings/Container/index';
import Typography from './Pages/Typography/Container/index';
import Calendar from './Pages/Calendar/Component/Calendar';
import Timeline from './Pages/Timeline/Component/Timeline';
import CourseView from './Pages/CourseView/Component/CourseView';
import UpgradePlan from './Pages/UpgradePlan/Component/UpgradePlan';
import PaperGrader from './Pages/PaperGrader/Component/PaperGrader';

/* TEACHER ADMIN */
import TeacherAdminLogin from './Pages/TeacherAdmin/Login/Container/index';
import TeacherAdminResetPassword from './Pages/TeacherAdmin/ResetPassword/Container/index';
import TeacherAdminResetPasswordEmailVerification from './Pages/TeacherAdmin/ResetPasswordEmailVerification/Container/index';
import TeacherAdminPasswordChangedSuccess from './Pages/TeacherAdmin/PasswordChangedSuccess/Container';
import TeacherAdminCreateNewPassword from './Pages/TeacherAdmin/CreateNewPassword/Container/index';
import TeacherAdminDashboard from './Pages/TeacherAdmin/Dashboard/Container/index';

/* SUBSCRIPTION */
import ConfirmedPage from './Pages/ConfirmedPage/Component/confirmed-page';
import ContactUsPage from './Pages/ContactUs/Component/ContactUs';
import ContactUsGratitude from './Pages/ContactUsGratitude/Component/contact-us-gratitude';
import EnterprisePaymentConfirm from './Pages/EnterprisePaymentConfirm/Component/EnterprisePaymentConfirm';
import SponsoredSubscriptionPage from './Pages/SponsoredSubscription/Component/SponsoredSubscription';

const routes = [
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: null,
    component: Login
  },
  {
    name: 'Reset Password',
    layout: '/auth',
    path: '/reset-password',
    icon: null,
    component: ResetPassword
  },
  {
    name: 'Reset Password Email Verification',
    layout: '/auth',
    path: '/reset-password-email-verification',
    icon: null,
    component: ResetPasswordEmailVerification
  },
  {
    name: 'Password Changed Success',
    layout: '/auth',
    path: '/password-changed-success',
    icon: null,
    component: PasswordChangedSuccess
  },
  {
    name: 'Create new password',
    layout: '/auth',
    path: '/create-new-password',
    icon: null,
    component: CreateNewPassword
  },
  {
    name: 'Create an Account',
    layout: '/auth',
    path: '/create-account',
    icon: null,
    component: CreateAccount
  },
  {
    name: 'Email Verification',
    layout: '/auth',
    path: '/email-verification',
    icon: null,
    component: EmailVerification
  },
  {
    name: 'Tell Us About You',
    layout: '/auth',
    path: '/tell-us-about-you',
    icon: null,
    component: TellUsAboutYou
  },
  {
    name: 'Notifications',
    layout: '/teacher',
    display: false,
    path: '/notifications',
    icon: 'icon icon-notifications',
    component: Notifications
  },
  {
    name: 'Dashboard',
    layout: '/teacher',
    display: true,
    path: '/dashboard',
    icon: 'icon icon-dashboard',
    component: Dashboard
  },
  {
    name: 'Courses',
    layout: '/teacher',
    display: true,
    path: '/courses',
    icon: 'icon icon-courses',
    component: Courses
  },
  {
    name: 'Calendar',
    layout: '/teacher',
    display: true,
    path: '/calendar/',
    icon: 'fa-regular fa-calendar',
    component: Calendar
  },
  {
    name: 'Timeline',
    layout: '/teacher',
    display: true,
    path: '/timeline/',
    icon: 'fa-regular fa-clock',
    component: Timeline
  },
  {
    name: 'Paper Grader',
    layout: '/teacher',
    display: true,
    path: '/paper-grader/',
    icon: 'fa-regular fa-pencil',
    component: PaperGrader
  },
  {
    name: 'Individual Course',
    layout: '/teacher',
    display: false,
    path: '/individual-course/:id',
    icon: 'icon icon-today',
    component: IndividualCourse
  },
  {
    name: 'Course View',
    layout: '/teacher',
    display: false,
    path: '/course-view/:id',
    icon: 'icon icon-today',
    component: CourseView
  },
  {
    name: 'Profile & Settings',
    layout: '/teacher',
    display: false,
    path: '/profile-and-settings',
    icon: 'icon icon-dashboard',
    component: ProfileAndSettings
  },
  {
    name: 'typograhpy',
    layout: '/teacher',
    display: false,
    path: '/typography',
    icon: 'icon icon-dashboard',
    component: Typography
  },
  {
    name: 'typograhpy',
    layout: '/teacher-admin',
    display: false,
    path: '/sign-in',
    icon: null,
    component: TeacherAdminLogin
  },
  {
    name: 'typograhpy',
    layout: '/teacher-admin',
    display: false,
    path: '/reset-password',
    icon: null,
    component: TeacherAdminResetPassword
  },
  {
    name: 'typograhpy',
    layout: '/teacher-admin',
    display: false,
    path: '/reset-password-email-verification',
    icon: null,
    component: TeacherAdminResetPasswordEmailVerification
  },
  {
    name: 'Create new password',
    layout: '/teacher-admin',
    path: '/create-new-password',
    display: true,
    icon: null,
    component: TeacherAdminCreateNewPassword
  },
  {
    name: 'typograhpy',
    layout: '/teacher-admin',
    display: false,
    path: '/password-changed-success',
    icon: null,
    component: TeacherAdminPasswordChangedSuccess
  },
  {
    name: 'typograhpy',
    layout: '/teacher-admin',
    display: false,
    path: '/dashboard',
    icon: null,
    component: TeacherAdminDashboard
  },
  {
    name: 'upgrade-plan',
    layout: '/teacher',
    display: false,
    path: '/upgrade-plan',
    icon: null,
    component: UpgradePlan
  },
  {
    name: 'contact-us-gratitude',
    layout: '/teacher',
    display: false,
    path: '/contact-us-gratitude',
    icon: null,
    component: ContactUsGratitude
  }
];

export default routes;

export const subscriptionRoutes = [
  {
    name: 'confirmed-payment',
    layout: '/subscription',
    display: false,
    path: '/confirmed-payment',
    icon: null,
    component: ConfirmedPage
  },
  {
    name: 'confirmed-sponsored-payment',
    layout: '/subscription',
    display: false,
    path: '/confirmed-sponsored-payment/:email',
    icon: null,
    component: ConfirmedPage
  },
  {
    name: 'contact-us',
    layout: '/subscription',
    display: false,
    path: '/contact-us',
    icon: null,
    component: ContactUsPage
  },
  {
    name: 'enterprise-payment-confirm',
    layout: '/subscription',
    display: false,
    path: '/enterprise-payment-confirm',
    icon: null,
    component: EnterprisePaymentConfirm
  },
  {
    name: 'sponsored-subscription',
    layout: '/subscription',
    display: false,
    path: '/sponsored-subscription',
    icon: null,
    component: SponsoredSubscriptionPage
  }
];

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './UpgradePlan.module.scss';
import { EnterpriseSubscriptionCard, FreeTrialCard, IndividualSubscriptionCard, RadioGroup } from '../../../Components';
import { subscriptionService } from '../../../Services/subscriptions.service';
import Loading from '../../../Layout/loading/Component/Loading';
import { SubscriptionStatusMessage } from '../../../Components/molecules';

const PageContext = React.createContext();

export default function UpgradePlan() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <UpdatePlan_ />
    </PageContext.Provider>
  );
}

function UpdatePlan_() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const subscription = JSON.parse(localStorage.getItem('subscription'));
  const prodId = subscription.prodId;
  const subscriptionStatus = subscription.status;
  const subscriptionTrialStart = subscription.trialStart;
  const subscriptionName = subscription.name;
  const cancelAt = subscription.cancelAt;
  const interval = subscription.interval;
  const [subscriptionInterval, setSubscriptionInterval] = useState(
    subscriptionStatus === 'trialing' ? '1' : interval === 'month' ? '1' : '2'
  );
  const [freeTrialProduct, setFreeTrialProduct] = useState([]);
  const [individualProduct, setIndividualProduct] = useState([]);
  const [enterpriseProduct, setEnterpriseProduct] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const { data } = await subscriptionService.getProducts(subscriptionInterval);
        setFreeTrialProduct(data.find((product) => product.type === 'trial'));
        setIndividualProduct(data.find((product) => product.type === 'individual'));
        setEnterpriseProduct(data.find((product) => product.type === 'enterprise'));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        error?.response?.data?.error && toast.error(error.response.data.error);
      }
    };

    getProducts();
  }, [subscriptionInterval]);

  const createSubscription = async (product) => {
    setLoading(true);
    try {
      const { data } = await subscriptionService.createSubscription(product.priceId, false);
      window.location.href = data.url;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  const handleSponsor = async (product) => {
    setLoading(true);
    try {
      const { data } = await subscriptionService.createSubscription(product.priceId, true);
      history.push({ pathname: '/subscription/sponsored-subscription', state: { url: data.url } });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  const cancelSubscription = async () => {
    setLoading(true);
    try {
      const { data } = await subscriptionService.cancelSubscription();
      setLoading(false);
      subscription.cancelAt = data.cancel_at;
      localStorage.setItem('subscription', JSON.stringify(subscription));
      toast.success('Subscription cancelled');
      history.goBack();
    } catch (error) {
      setLoading(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  return (
    <div id="content-area">
      <div className={styles.header}>
        <div className="container xl-container">
          <h6 className="lbl-txt" onClick={() => history.goBack()}>
            <i className="fa-solid fa-arrow-left"></i> Back To Profile
          </h6>
          <h1>Upgrade Subscription</h1>
        </div>
      </div>
      <div className="container xl-container">
        <div className={styles.subscriptionPlanContainer}>
          <div className={styles.subscriptionPlanTitle}>
            <h1>Subscription Plans</h1>
          </div>
          <div className={styles.subscriptionPlanButtons}>
            <RadioGroup
              options={[
                { value: '1', label: 'Monthly' },
                { value: '2', label: 'Annual' }
              ]}
              selectValue={(value) => setSubscriptionInterval(value)}
              selectedValue={subscriptionInterval}
            />
          </div>
        </div>
        <SubscriptionStatusMessage
          status={subscriptionStatus}
          trialStart={subscriptionTrialStart}
          name={subscriptionName}
          cancelAt={cancelAt}
        />
        <div className={styles.planListContainer}>
          {loading ? (
            <Loading />
          ) : (
            <>
              <FreeTrialCard
                product={freeTrialProduct}
                isActive={prodId === freeTrialProduct.prodId || subscriptionStatus === 'canceled'}
              />
              <IndividualSubscriptionCard
                product={individualProduct}
                isActive={prodId === individualProduct.prodId && subscriptionStatus !== 'canceled'}
                cancelAt={cancelAt}
                onClick={() =>
                  prodId === individualProduct.prodId ? cancelSubscription() : createSubscription(individualProduct)
                }
                handleSponsor={() => handleSponsor(individualProduct)}
              />
              <EnterpriseSubscriptionCard
                product={enterpriseProduct}
                isActive={prodId === enterpriseProduct.prodId && subscriptionStatus !== 'canceled'}
                onClick={() => history.push('/subscription/contact-us')}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function ClassDetail() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <ClassDetail_ />
    </PageContext.Provider>
  );
}

function ClassDetail_() {
  return <div>this is vashrambhai </div>;
}

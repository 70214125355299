import {
  GET_ALL_USERS_SUCCESSFULLY,
  GET_ALL_USERS_FAILED,
  SUSPENSE_USERS_SUCCESSFULLY,
  SUSPENSE_USERS_FAILED
} from '../types';
import teacherAdminApi from '../../Services/teacherAdminApi';

export const getAllUsers = (params) => {
  return (dispatch) => {
    return teacherAdminApi
      .get('/account/users/', { params })
      .then((response) => {
        dispatch({
          type: GET_ALL_USERS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: GET_ALL_USERS_FAILED,
            data: error.response.data
          });
        }
      });
  };
};

export const suspenseUser = (params) => {
  return (dispatch) => {
    return teacherAdminApi
      .post('/account/users/accountAction/', params)
      .then((response) => {
        dispatch({
          type: SUSPENSE_USERS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: SUSPENSE_USERS_FAILED,
            data: error.response.data
          });
        }
      });
  };
};

import React from 'react';
import { Modal, Button } from 'antd';

export const ErrorModal = ({ errorModalData, showErrorModal, setShowErrorModal, handleCloseErrorModal }) => {
  return (
    <Modal
      className="calendar-error-modal"
      visible={errorModalData.visible && showErrorModal}
      closable={false}
      footer={[
        <Button key="close" onClick={handleCloseErrorModal}>
          Confirm
        </Button>
      ]}>
      <div className="error-wrap">
        <div className="modal-head">
          <h1>{errorModalData.errorMessage}</h1>
          {errorModalData.subMessage && <h4 className="subhead">{errorModalData.subMessage}</h4>}
        </div>
        <div className="fitem-check">
          <input
            id="showmsgs"
            className="error-checkbox"
            type="checkbox"
            onChange={(e) => {
              setShowErrorModal(!e.target.checked);
            }}
          />
          <label className="error-checkbox-label" htmlFor="showmsgs">
            Do not show this message again
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;

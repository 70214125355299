const setLocalAccessToken = (token) => {
  localStorage.setItem('authToken', JSON.stringify(token));
};

const getLocalAccessToken = () => {
  return JSON.parse(localStorage.getItem('authToken'));
};

const removeLocalAccessToken = () => {
  localStorage.removeItem('authToken');
};

const setLocalAccessTokenAdmin = (token) => {
  localStorage.setItem('authTokenTeacherAdmin', JSON.stringify(token));
};

const getLocalAccessTokenAdmin = () => {
  return JSON.parse(localStorage.getItem('authTokenTeacherAdmin'));
};

const removeLocalAccessTokenAdmin = () => {
  localStorage.removeItem('authTokenTeacherAdmin');
};

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user');
};

// Create Account Temp
const setEmail = (email) => {
  localStorage.setItem('email', JSON.stringify(email));
};

const getEmail = () => {
  return JSON.parse(localStorage.getItem('email'));
};

const removeEmail = () => {
  localStorage.removeItem('email');
};

const setNewPasswordInfo = (info) => {
  localStorage.setItem('setNewPasswordInfo', JSON.stringify(info));
};

const getNewPasswordInfo = () => {
  return JSON.parse(localStorage.getItem('setNewPasswordInfo'));
};

const removeNewPasswordInfo = () => {
  localStorage.removeItem('setNewPasswordInfo');
};

const clearTeacherStorage = () => {
  tokenService.removeUser();
  tokenService.removeEmail();
  tokenService.removeNewPasswordInfo();
  tokenService.removeLocalAccessToken();
  removeSubscription();
};

const removeSubscription = () => {
  localStorage.removeItem('subscription');
};

const setSubscription = (subscription) => {
  localStorage.setItem('subscription', JSON.stringify(subscription));
};

const getSubscription = () => {
  const subscription = JSON.parse(localStorage.getItem('subscription'));
  return {
    due_date: subscription.due_date,
    interval: subscription.interval,
    price: subscription.price,
    status: subscription.status,
    trialStart: subscription.trialStart,
    type: subscription.type,
    quantity: subscription.quantity
  };
};

export const tokenService = {
  clearTeacherStorage,
  getEmail,
  getLocalAccessToken,
  getLocalAccessTokenAdmin,
  getNewPasswordInfo,
  getSubscription,
  getUser,
  removeEmail,
  removeLocalAccessToken,
  removeLocalAccessTokenAdmin,
  removeNewPasswordInfo,
  removeSubscription,
  removeUser,
  setEmail,
  setLocalAccessToken,
  setLocalAccessTokenAdmin,
  setNewPasswordInfo,
  setSubscription,
  setUser
};

import { TableHeader } from '../../atom';
import styles from './table-header-row.module.scss';

export const TableHeaderRow = ({ columns }) => (
  <tr className={styles.headerRow}>
    {columns.map((col) => (
      <TableHeader key={col.key} title={col.title} />
    ))}
  </tr>
);

import { roundNumber } from '../../../Utils';

export const useSummaryPriceContent = (products) => {
  const subtotal = roundNumber(products?.reduce((acc, product) => (acc = acc + product.price), 0));

  const total = parseFloat(subtotal);

  return {
    subtotal,
    total
  };
};

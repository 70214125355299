const INITIAL_STATE = { data: {}, errors: {} };

const updateInstructionalEvent = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_INSTRUCTIONAL_EVENT_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'UPDATE_INSTRUCTIONAL_EVENT_FAILED':
      return { ...INITIAL_STATE, errors: action?.error?.detail };
    default:
      return { ...state };
  }
};
export default updateInstructionalEvent;

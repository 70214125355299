const INITIAL_STATE = { data: {}, errors: {} };

const getInstructionalEventCategories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_INSTRUCTIONAL_EVENT_CATEGORIES_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'GET_INSTRUCTIONAL_EVENT_CATEGORIES_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default getInstructionalEventCategories;

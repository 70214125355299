import {
  TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE,
  TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS,
  TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST,
  TEACHER_ADMIN_RESEND_CODE_FAILURE,
  TEACHER_ADMIN_RESEND_CODE_REQUEST,
  TEACHER_ADMIN_RESEND_CODE_SUCCESS,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS,
  TEACHER_ADMIN_RESET_PASSWORD_FAILURE,
  TEACHER_ADMIN_RESET_PASSWORD_REQUEST,
  TEACHER_ADMIN_RESET_PASSWORD_SUCCESS,
  TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE,
  TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST,
  TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS
} from '../types';
import teacherAdminApi from '../../Services/teacherAdminApi';
import { tokenService } from '../../Services';

export const teacherAdminResetPassword = (payload) => {
  return (dispatch) => {
    dispatch(request());
    return teacherAdminApi.post('/account/users/resetPassword/', payload).then(
      (response) => {
        tokenService.setEmail(payload.email);
        dispatch(success(response.data));
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_RESET_PASSWORD_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_RESET_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_RESET_PASSWORD_FAILURE, error };
  }
};

export const teacherAdminEmailVerification = (payload) => {
  return (dispatch) => {
    dispatch(request());
    const clonedPayload = {
      reset_password_code: payload.code,
      email: tokenService.getEmail()
    };
    return teacherAdminApi.post('/account/users/verifyCode/', clonedPayload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.setNewPasswordInfo({
          reset_password_code: payload.code,
          email: tokenService.getEmail()
        });
        tokenService.removeEmail();
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE, error };
  }
};

export const teacherAdminResendCode = () => {
  return (dispatch) => {
    dispatch(request());
    const payload = {
      email: tokenService.getEmail()
    };
    return teacherAdminApi.post('/account/users/resetPassword/', payload).then(
      (response) => {
        dispatch(success(response.data));
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_RESEND_CODE_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_RESEND_CODE_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_RESEND_CODE_FAILURE, error };
  }
};

export const teacherAdminSetNewPassword = (payload) => {
  return (dispatch) => {
    dispatch(request());
    const clonedPayload = {
      ...payload,
      reset_password_code: tokenService.getNewPasswordInfo().reset_password_code,
      email: tokenService.getNewPasswordInfo().email
    };
    return teacherAdminApi.post('/account/users/changePassword/', clonedPayload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.removeNewPasswordInfo();
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE, error };
  }
};

export const teacherAdminChangePassword = (payload) => {
  return (dispatch) => {
    dispatch(request());
    return teacherAdminApi.post('account/users/changePassword/', payload).then(
      (response) => {
        dispatch(success(response.data));
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE, error };
  }
};

import { TableCell } from '../../atom';
import styles from './table-row.module.scss';

export const TableRow = ({ rowData, columns }) => (
  <tr className={styles.row}>
    {columns.map((col) => {
      const content = col.render ? col.render(rowData) : rowData[col.key];
      return <TableCell key={col.key} content={content} />;
    })}
  </tr>
);

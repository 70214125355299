import React, { useEffect, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import _ from 'lodash';

import moment from 'moment';
import ToolbarWoLegend from './ToolbarWoLegend';
import ReusableCalendar from '../../../Components/ReusableCalendar';
import { useCalendarAPI } from '../../../Services/calendar.service';
import store from '../../../store';
import momentTimeZone from 'moment-timezone';
import { Link, Link as ReactRouterLink, useParams } from 'react-router-dom';
import { Button, Modal, Drawer } from 'antd';
import { map } from 'lodash';
import { connect } from 'react-redux';
import {
  getSchoolCourseAssessments,
  addSchoolCourse,
  getCourseById,
  getCourseSchedule,
  getCourseLessons,
  deleteCourseById,
  updateSchoolCourse,
  addInstructionalEvent,
  updateInstructionalEvent,
  deleteInstructionalEvent,
  getInstructionalEventById,
  updateLesson,
  confirm,
  confirmInstructionalEvent
} from '../../../Action';
import ErrorModal from '../../Calendar/Component/ErrorModal';
import Loading from '../../../Layout/loading/Container';
import addedicon from '../../../assets/images/added-icon.png';
import overtime from '../../../assets/images/overtime.svg';
import loaderImg from '../../../assets/images/loader.png';
import { colors } from '../../../Services/courseColors';
import { Editor } from '@tinymce/tinymce-react';

const localizer = momentLocalizer(moment);

const parseMetadataToCalendarEvents = (metadata) => {
  const parsedEvents = [];

  // for each course metadata, create a new parsed event and push it to the parsed events array
  metadata.forEach((apiMetadata, index) => {
    // api metadata has date, start time, end time. Set the start and end time to datetimes so the day and time can be compared
    apiMetadata.scheduleDays.forEach((scheduleDay) => {
      const [year, month, day] = scheduleDay.date.split('T')[0].split('-');
      const startDate = new Date(
        year,
        month - 1,
        day,
        scheduleDay.startTime.split(':')[0],
        scheduleDay.startTime.split(':')[1],
        scheduleDay.startTime.split(':')[2]
      );
      const endDate = new Date(
        year,
        month - 1,
        day,
        scheduleDay.endTime.split(':')[0],
        scheduleDay.endTime.split(':')[1],
        scheduleDay.endTime.split(':')[2]
      );

      const parsedEvent = {
        id: apiMetadata.courseId, // course id
        title: apiMetadata.courseName,
        start: startDate,
        end: endDate,
        backgroundColor: colors[index % colors.length]
      };
      parsedEvents.push(parsedEvent);
    });
  });
  return parsedEvents;
};

const parseEventToCalendarEvent = (apiEventData) =>
  apiEventData.map((event, index) => {
    const courseId = event.metadata.find((metadata) => metadata.key === 'course:id').value;
    return {
      id: event.id,
      courseId: courseId,
      title: event.title,
      start: new Date(event.day),
      end: new Date(event.day),
      backgroundColor: colors[index % colors.length]
    };
  });

const parseEvents = ({ courseDayEvents, courseDayEventsWithLessonPlans, holidays }) => {
  // for each course day. find the corresponding course day with lesson plan id and date and add the lesson plan title to the course day
  courseDayEvents.forEach((courseDayEvent) => {
    const courseDayEventWithLessonPlan = courseDayEventsWithLessonPlans.find(
      (courseDayEventWithLessonPlan) =>
        courseDayEventWithLessonPlan.courseId === courseDayEvent.id &&
        // check if the course day event is on the same day as the course day event with lesson plan. Just the day, not the time
        courseDayEventWithLessonPlan.start.getFullYear() === courseDayEvent.start.getFullYear() &&
        courseDayEventWithLessonPlan.start.getMonth() === courseDayEvent.start.getMonth() &&
        courseDayEventWithLessonPlan.start.getDate() === courseDayEvent.start.getDate()
    );

    // holidays have start_date and end_date
    const courseDayEventHoliday = holidays.find(
      (holiday) =>
        //check if the course day in within the holiday range
        new Date(holiday.start_date) <= courseDayEvent.start && new Date(holiday.end_date) >= courseDayEvent.start
    );

    courseDayEvent.courseId = courseDayEvent.id;
    if (courseDayEventWithLessonPlan) {
      courseDayEvent.id = courseDayEventWithLessonPlan.id;
      courseDayEvent.title = `${courseDayEventWithLessonPlan.title}`;
      courseDayEvent.courseId = courseDayEventWithLessonPlan.courseId;
    } else {
      courseDayEvent.title = 'No Lesson Planned';
      courseDayEvent.disabled = true;
    }
    if (courseDayEventHoliday && courseDayEventHoliday.course_id === courseDayEvent.courseId) {
      courseDayEvent.title = `${courseDayEventHoliday.event_title} - ${courseDayEvent.title}`;
      courseDayEvent.disabled = true;
    }
  });
  // now we will add holiday events separately
  // const holidayEvents = holidays.map((holiday, index) => {
  //   const start = new Date(holiday.start_date);
  //   const end = new Date(holiday.end_date);

  //   return {
  //     id: holiday.id,
  //     title: holiday.event_title,
  //     start,
  //     end,
  //     backgroundColor: colors[index % colors.length],
  //     disabled: true // set to true if holiday events should be disabled
  //   };
  // });

  return [...courseDayEvents];
};

const ByCourseCalendar = ({ courseIdParam, onChildLessons, onNewCourseAdded, ...props }) => {
  const { getCourseById, getCourseSchedule, getCourseLessons } = props;
  const { useGetEventsQuery, useMutateReschedule, useGetHolidaysByCourseIdQuery, errorModalData, setErrorModalData } =
    useCalendarAPI();
  const { mutate: mutateReschedule } = useMutateReschedule();
  const holidayQuery = useGetHolidaysByCourseIdQuery();
  const [eventData, setEventData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(true);
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());
  const [visibleLessonDrawer, setVisibleLessonDrawer] = useState(false);
  const [lesson, setLesson] = useState({});
  const [visibleEventDrawer, setVisibleEventDrawer] = useState(false);
  const [isLessonEdit, setIsLessonEdit] = useState(false);
  const [page_number, setPage] = useState(0);
  const [lessonCount, SetLessonCount] = useState(0);
  const [dayChoice, setDayChoice] = useState('');
  const [displayDate, SetDisplayDate] = useState(null);
  const [indexNumber, setIndexNumber] = useState(0);
  const params = useParams();
  const course_id = +params?.id || 0;
  const timezone = localStorage.getItem('user')?.time_zone || momentTimeZone.tz.guess(true);

  const [state, setState] = useState({
    errors: {},
    data: { days: [], isSame: true },
    selectedTimeZone: timezone
  });

  const getDetails = () => {
    // setLoader(true);
    const params = {
      course_id: course_id,
      page_size: 1000
    };
    Promise.allSettled([getCourseById(params.course_id), getCourseSchedule(params), getCourseLessons(params)])
      .then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { CourseDetails, CourseSchedule, CourseLessons } = store.getState();
          let objCourseDetails = CourseDetails?.data;
          objCourseDetails['course_schedule'] = CourseSchedule?.data?.results;
          let draft_events = [];
          if (CourseLessons?.data?.results && CourseLessons?.data?.results?.length > 0) {
            draft_events = CourseLessons?.data?.results.filter((x) => x.is_draft === false);
          }
          const timeZoneForCourse =
            objCourseDetails?.time_zone || localStorage.getItem('user')?.time_zone || momentTimeZone.tz.guess(true);
          SetLessonCount(CourseSchedule?.data?.count);
          myAsyncLoopFunction(objCourseDetails?.schedule_time).then((schedule_time) => {
            objCourseDetails['schedule_time'] = schedule_time;
            setState((s) => ({
              ...s,
              CourseDetails: objCourseDetails,
              id: objCourseDetails?.id,
              courseName: objCourseDetails?.name,
              grade: objCourseDetails?.grade,
              level: objCourseDetails?.level,
              is_display_create_lesson_plan_suggestion_popup:
                objCourseDetails?.is_display_create_lesson_plan_suggestion_popup,
              is_display_date_not_planned_instruction_day_popup:
                objCourseDetails?.is_display_date_not_planned_instruction_day_popup,
              selectedTimeZone: timeZoneForCourse,
              start_date: moment.tz(objCourseDetails?.start_date, 'YYYY-MM-DD', timeZoneForCourse),
              end_date: moment.tz(objCourseDetails?.end_date, 'YYYY-MM-DD', timeZoneForCourse),
              run_time: objCourseDetails?.run_time,
              total_course_schedule_count: objCourseDetails?.total_course_schedule_count,
              total_lesson_planned_count: objCourseDetails?.total_lesson_planned_count,
              schedule_time: schedule_time,
              CourseLessons: draft_events,
              data: { days: schedule_time, isSame: objCourseDetails?.time_choice === 'same_time' ? true : false }
            }));
            onNewCourseAdded();
          });
        }
        // setLoader(false);
      })
      .catch(() => {
        // setLoader(false);
      });
  };

  const myAsyncLoopFunction = async (array) => {
    const allAsyncResults = [];
    for (const item of array) {
      const startTime = moment(item.start_time, ['HH:mm']).format('hh:mm');
      const startampm = moment(item.start_time, ['HH:mm']).format('A');
      const endTime = moment(item.end_time, ['HH:mm']).format('hh:mm');
      const endampm = moment(item.end_time, ['HH:mm']).format('A');
      const data = {
        ...item,
        ['start_time']: startTime,
        ['start_time_t']: startTime,
        ['startAMPM']: startampm,
        ['end_time']: endTime,
        ['end_time_t']: endTime,
        ['endAMPM']: endampm
      };
      allAsyncResults.push(data);
    }
    return allAsyncResults;
  };

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { allDay: initialAllDay } = event;

    const allDay =
      (!initialAllDay && droppedOnAllDaySlot) || (initialAllDay && !droppedOnAllDaySlot)
        ? !initialAllDay
        : initialAllDay;

    const updatedEvent = { ...event, start, end, allDay };

    // Check if there is already an event on the same day
    const eventOnSameDay = eventData.some(
      (event) =>
        event.start.getFullYear() === updatedEvent.start.getFullYear() &&
        event.start.getMonth() === updatedEvent.start.getMonth() &&
        event.start.getDate() === updatedEvent.start.getDate() &&
        !event.disabled &&
        event.courseId == updatedEvent.courseId
    );

    if (eventOnSameDay) {
      alert('There is already an event on this day');
      return;
    }

    if (event.disabled) return;

    mutateReschedule({
      lessonPlanId: updatedEvent.id,
      newDate: updatedEvent.start
    });
  };

  const eventsToDisplay = courseIdParam ? eventData.filter((event) => event.courseId === courseIdParam) : eventData;

  const CustomEvent = ({ event }) => (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <i className="fa-regular fa-grip-dots-vertical" style={{ paddingRight: '8px' }} /> {event.title}
    </div>
  );

  const { data: calendarApiData } = useGetEventsQuery();

  useEffect(() => {
    if (calendarApiData && holidayQuery.isFetched) {
      const courseDayEvents = parseMetadataToCalendarEvents(calendarApiData.courseAndTheirScheduleDays);
      const courseDayEventsWithLessonPlans = parseEventToCalendarEvent(calendarApiData.events);
      setEventData(
        parseEvents(
          structuredClone({ courseDayEvents, courseDayEventsWithLessonPlans, holidays: holidayQuery.data.holidays })
        )
      );
      onChildLessons(_.filter(courseDayEventsWithLessonPlans, { courseId: courseIdParam }).length);
      // add a unique courseDayEvent object to courses and determine unique courses by title
      const uniqueCourses = new Map();
      courseDayEvents.forEach((courseDayEvent) => {
        uniqueCourses.set(courseDayEvent.title, courseDayEvent);
      });
      setCourses([...uniqueCourses.values()]);
      getDetails();
    }
  }, [calendarApiData, holidayQuery.isFetched, courseIdParam, holidayQuery.data, holidayQuery.isFetching]);

  /** Start of Holiday Modal */
  // const holidayEventQueryId = selectedCourse === 'All Courses' ? 0 : selectedCourse;
  // const { useEditHolidayMutation } = useCalendarAPI();
  // const courseHolidayQuery = useGetHolidaysByCourseIdQuery(holidayEventQueryId);
  // const { mutate: editHoliday } = useEditHolidayMutation();

  // const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  // const [isAddHolidayVisible, setIsAddHolidayVisible] = useState(false);
  // const [holidayName, setHolidayName] = useState('');
  // const [holidayStartDate, setHolidayStartDate] = useState('');
  // const [holidayEndDate, setHolidayEndDate] = useState('');
  // const [holidayList, setHolidayList] = useState([]);

  // useEffect(() => {
  //   if (courseHolidayQuery.isLoading || selectedCourse === 'All Courses') return;
  //   setHolidayList(
  //     courseHolidayQuery.data.holidays.map((holiday) => ({
  //       id: holiday.id || undefined,
  //       name: holiday.event_title,
  //       start: moment(holiday.start_date).startOf('day').toDate(),
  //       end: moment(holiday.end_date).startOf('day').toDate(),
  //       is_draft: !holiday.is_draft
  //     }))
  //   );
  // }, [courseHolidayQuery.isFetching]);

  // const showHolidayModal = () => {
  //   setIsAddHolidayVisible(false);
  //   setIsHolidayModalVisible(true);
  // };

  // const handleCloseHolidayModal = () => {
  //   editHoliday({
  //     courseId: selectedCourse,
  //     holidays: holidayList.map((holiday) => ({
  //       id: holiday.id || undefined,
  //       event_title: holiday.name,
  //       start_date: moment(holiday.start).format('YYYY-MM-DD'),
  //       end_date: moment(holiday.end).format('YYYY-MM-DD'),
  //       is_draft: !holiday.is_draft
  //     }))
  //   });
  //   setIsHolidayModalVisible(false);
  // };

  // const handleCancelHolidayModal = () => {
  //   setIsHolidayModalVisible(false);
  // };

  // const handleAddHolidayOpen = () => {
  //   setHolidayName('');
  //   setHolidayStartDate(null);
  //   setHolidayEndDate(null);
  //   setIsAddHolidayVisible(true);
  // };

  // const handleAddHolidayClose = () => {
  //   setHolidayName('');
  //   setHolidayStartDate('');
  //   setHolidayEndDate('');
  //   setIsAddHolidayVisible(false);
  // };

  // const handleHolidayCheckbox = (item, index) => {
  //   const holiday = holidayList.splice(index, 1)[0];
  //   holiday.is_draft = !holiday.is_draft;
  //   holidayList.splice(index, 0, holiday);
  //   setHolidayList([...holidayList]);
  // };

  // const handleAddHoliday = () => {
  //   if (holidayName !== '' && holidayStartDate !== '' && holidayEndDate !== '') {
  //     const holiday = {
  //       name: holidayName,
  //       start: moment(holidayStartDate).startOf('day').toDate(),
  //       end: moment(holidayEndDate).startOf('day').toDate(),
  //       is_draft: false
  //     };
  //     setHolidayList([...holidayList, holiday]);
  //     handleAddHolidayClose();
  //   }
  // };
  /** End of Holiday Modal */

  const handleCloseErrorModal = () => {
    setErrorModalData({ visible: false, errorMessage: '', subMessage: '' });
  };

  const onSelectDay = (event) => {
    const newValue = moment(event.start);
    const { CourseDetails, is_display_date_not_planned_instruction_day_popup, selectedTimeZone } = state;
    const arrCourseSchedule = CourseDetails?.course_schedule
      ? CourseDetails.course_schedule.filter(
          (x) =>
            moment.tz(x.schedule_date, 'YYYY-MM-DD', selectedTimeZone).format('YYYY-MM-DD') ===
            newValue.format('YYYY-MM-DD')
        )
      : [];
    const idx = CourseDetails?.course_schedule?.findIndex(
      (x) =>
        moment.tz(x.schedule_date, 'YYYY-MM-DD', selectedTimeZone).format('YYYY-MM-DD') ===
        newValue.format('YYYY-MM-DD')
    );
    setIndexNumber(idx);
    if (arrCourseSchedule && arrCourseSchedule.length > 0) {
      setValue(moment(event.start));
      setSelectedValue(moment(event.start));
      SetDisplayDate(moment(event.start));
      setDayChoice(arrCourseSchedule[0]?.day_choice);
      setState((s) => ({
        ...s,
        is_display_over_class_time_popup: true
      }));
      state?.CourseLessons?.forEach((item) => {
        if (
          item?.course_schedule_fk === arrCourseSchedule[0]?.id &&
          item?.is_draft === false &&
          moment(item?.date).format('YYYY-MM-DD') === moment(newValue).format('YYYY-MM-DD')
        ) {
          arrCourseSchedule[0].course_lessons = [item];
        }
      });
      if (arrCourseSchedule[0].course_lessons && arrCourseSchedule[0].course_lessons.length > 0) {
        if (arrCourseSchedule[0].course_lessons?.some((item) => item.is_draft === false)) {
          const LessonItemChanged = arrCourseSchedule[0].course_lessons?.find((item) => item.is_draft === false);
          setLesson(LessonItemChanged);
          setVisibleEventDrawer(true);
          setPage(indexNumber + 1);
          SetLessonCount(arrCourseSchedule[0].page_number);
          setState((s) => ({
            ...s,
            is_display_over_class_time_popup: LessonItemChanged?.is_display_lesson_over_class_time_popup
          }));
          const isDraft = LessonItemChanged?.is_draft;
          setIsLessonEdit(isDraft);
        } else {
          setLesson({});
          setVisibleLessonDrawer(true);
          setPage(arrCourseSchedule[0].page_number);
          SetLessonCount(arrCourseSchedule[0].page_number);
          setIsLessonEdit(true);
        }
      } else {
        setLesson({});
        setState((s) => ({
          ...s,
          title: '',
          lesson_objective: '',
          lesson_title: ''
        }));
        setPage(arrCourseSchedule[0].page_number);
        SetLessonCount(arrCourseSchedule[0].page_number);
        setVisibleLessonDrawer(true);
        setIsLessonEdit(true);
      }
    } else {
      if (is_display_date_not_planned_instruction_day_popup) {
        // setIsEmptyData(true);
      }
    }
  };

  const onClose = () => {
    setVisibleLessonDrawer(false);
  };

  // delay render till courses are repopulated.
  // ReusableCalendar will not render if courses is empty on first render

  if (!courses.length) return <div>Loading...</div>;
  return (
    <div className="calendar-container">
      <div className="container-lg">
        <ReusableCalendar
          selectable
          events={eventsToDisplay}
          localizer={localizer}
          onEventDrop={moveEvent}
          defaultView="month"
          defaultDate={new Date()}
          isResizable={false}
          onSelectEvent={(event) => {
            onSelectDay(event);
          }}
          components={{
            toolbar: (props) => <ToolbarWoLegend {...props} />,
            event: CustomEvent
          }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
              color: '#102328',
              opacity: event.disabled ? 0.5 : 1
            }
          })}
        />
        <Drawer
          className={'lesson-plan-drawer'}
          title={value.format('dddd, MMM DD, YYYY')}
          width={'710px'}
          placement="right"
          visible={visibleLessonDrawer === true ? visibleLessonDrawer : visibleEventDrawer}
          onClose={onClose}>
          <InstructionalEvent
            setVisibleLessonDrawer={setVisibleLessonDrawer}
            value={value}
            getDetails={getDetails}
            lesson={lesson}
            setLesson={setLesson}
            visibleEventDrawer={visibleEventDrawer}
            setVisibleEventDrawer={setVisibleEventDrawer}
            isLessonEdit={isLessonEdit}
            setIsLessonEdit={setIsLessonEdit}
            getCourseLessons={getCourseLessons}
            page_number={page_number}
            setPage={setPage}
            lessonCount={lessonCount}
            SetLessonCount={SetLessonCount}
            id={course_id}
            dayChoice={dayChoice}
            setDayChoice={setDayChoice}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setValue={setValue}
            displayDate={displayDate}
            SetDisplayDate={SetDisplayDate}
            indexNumber={indexNumber}
            setIndexNumber={setIndexNumber}
            details={store.getState().CourseDetails}
            {...props}
          />
        </Drawer>
      </div>
      {/* Holiday Modal */}
      {/* <Modal
        className="model-footer-right"
        closeIcon={<div className="icon icon-add"></div>}
        visible={isHolidayModalVisible}
        closable={false}
        keyboard={false}
        centered={true}
        width={770}
        maskClosable={false}
        footer={[
          <div key="holiday_footer" className="add-holiday-footer">
            <Button
              key="holiday_back"
              onClick={handleCancelHolidayModal}
              className="btn btn-focus-color-bg holiday-cancel-btn">
              Cancel
            </Button>
            <Button
              key="holiday_success_back"
              onClick={handleCloseHolidayModal}
              className="btn btn-brand holiday-save-btn">
              Save Changes
            </Button>
          </div>
        ]}>
        <div className="holiday-wrap">
          <div className="modal-head">
            <h1>Manage Holidays</h1>
            <p className="font-weight-400 font-sans">
              Add your holidays dates, breaks or school events below to organize your plan around them.
            </p>
            <div className="holiday-form-wrap">
              <form>
                <div className="holiday-header">
                  <div className="holiday-header-item">
                    <label>Event</label>
                  </div>
                  <div className="holiday-header-item">
                    <label>Dates</label>
                  </div>
                </div>
                <div className="holiday-list">
                  {holidayList?.map((item, index) => (
                    <div className="holiday-item" key={index}>
                      <input
                        type="checkbox"
                        name={'holiday-checkbox-' + index}
                        checked={item?.is_draft}
                        onChange={() => handleHolidayCheckbox(item, index)}
                      />
                      <div className="holiday-item-name">
                        <label>{item?.name}</label>
                      </div>
                      <div className="holiday-item-date">
                        <label>
                          {moment(item?.start).format('dddd')}
                          {', '}
                          {moment(item?.start).format('MMM DD')}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) && ' - '}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) &&
                            moment(item?.end).format('MMM DD')}
                          {', '}
                          {moment(item?.start).format('YYYY')}
                          {!(moment(item?.start).format('YYYY') === moment(item?.end).format('YYYY')) &&
                            ' - ' + moment(item?.end).format('YYYY')}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-option display-false' : 'add-holiday-option display-true'
                    }
                    onClick={handleAddHolidayOpen}>
                    <label>+ Add Another Date</label>
                  </div>
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-item display-true' : 'add-holiday-item display-false'
                    }>
                    <div className="add-holiday-header">Add Date</div>
                    <div className="add-holiday-body">
                      <div className="add-holiday-body-items">
                        <div className="inner-items-wrap">
                          <label>Holiday Name</label>
                          <input
                            value={holidayName}
                            type="text"
                            placeholder="Spring Break"
                            onChange={(event) => {
                              setHolidayName(event?.target?.value);
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap start-date">
                          <label className="holiday-start-date">Start Date</label>
                          <DatePicker
                            value={holidayStartDate ? moment(holidayStartDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayStartDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayStartDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap end-date">
                          <label className="holiday-end-date">End Date</label>
                          <DatePicker
                            value={holidayEndDate ? moment(holidayEndDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayEndDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayEndDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap add-holiday-btn">
                          <Button key="add-holiday" onClick={handleAddHoliday} className="btn btn-focus-color-bg">
                            + Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal> */}
      {/* Holiday Modal Ends */}
      {/* Error Modal Starts */}
      <ErrorModal
        errorModalData={errorModalData}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        handleCloseErrorModal={handleCloseErrorModal}
      />
      {/* Error Modal Ends */}
    </div>
  );
};

function InstructionalEvent(props) {
  const {
    setVisibleLessonDrawer,
    value,
    getDetails,
    lesson,
    visibleEventDrawer,
    setVisibleEventDrawer,
    setLesson,
    isLessonEdit,
    setIsLessonEdit,
    page_number,
    setPage,
    lessonCount,
    SetLessonCount,
    id,
    dayChoice,
    setDayChoice,
    selectedValue,
    setSelectedValue,
    setValue,
    displayDate,
    SetDisplayDate,
    indexNumber,
    setIndexNumber,
    CourseDetails
    // details
  } = props;
  const [state, setState] = useState({
    errors: {},
    data: { days: [], isSame: true },
    selectedTimeZone: 'UTC',
    category: '',
    event_duration: '',
    eventTitle: '',
    description: '',
    teacher_doing: '',
    student_doing: '',
    formative_assessment: '',
    material_list: '',
    lesson_prep_notes: '',
    formValid: false
  });
  const [loader, setLoader] = useState(false);

  const [isLessonConfirmeModalVisible, setIsLessonConfirmeModalVisible] = useState(false);
  const [totalEventDuration, setTotalEventDuration] = useState(0);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const { addLesson } = useCalendarAPI();

  const cancelConfirmCreateEvent = () => {
    setIsLessonConfirmeModalVisible(false);
  };

  useEffect(() => {
    if (isEmptyData) {
      console.log('isEmptyData: ', isEmptyData);
    }
  }, []);

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-76>eachers to see each of their courses in a calendar</TARA-76>
   */
  const confirmLesson = () => {
    /**
     * AUTHOR: RITESH SOLANKI
     * <TARA-661>Individual course page | When user has not selected "DO not show this message again" and user click on
     * "confirm" button and again click on non-instructional day then it is not showing the modal</TARA-661>
     * Modify code to call api conditionally
     */
    const { is_display_create_lesson_plan_suggestion_popup } = state;
    if (!is_display_create_lesson_plan_suggestion_popup) {
      const params = {
        id: id,
        is_display_create_lesson_plan_suggestion_popup: is_display_create_lesson_plan_suggestion_popup
      };
      Promise.allSettled([confirm(params)])
        .then(() => {
          const { ConfirmCourse } = store.getState();
          if (ConfirmCourse?.data && Object.keys(ConfirmCourse?.data).length > 0) {
            createNewLesson();
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      createNewLesson();
    }
    // createNewLesson();
  };

  const confirm = () => {
    /**
     * AUTHOR: RITESH SOLANKI
     * <TARA-661>Individual course page | When user has not selected "DO not show this message again" and user click on
     * "confirm" button and again click on non-instructional day then it is not showing the modal</TARA-661>
     * Modify code to call api conditionally
     */
    const { is_display_date_not_planned_instruction_day_popup } = state;
    if (!is_display_date_not_planned_instruction_day_popup) {
      const params = {
        id: id,
        is_display_date_not_planned_instruction_day_popup: is_display_date_not_planned_instruction_day_popup
      };
      Promise.allSettled([confirm(params)])
        .then(() => {
          const { ConfirmCourse } = store.getState();
          if (ConfirmCourse?.data && Object.keys(ConfirmCourse?.data).length > 0) {
            getDetails();
            setIsEmptyData(false);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setIsEmptyData(false);
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const createNewLesson = () => {
    setLoader(true);
    const arrCourseSchedule = CourseDetails?.data?.course_schedule
      ? CourseDetails.data.course_schedule.filter(
          (x) =>
            moment.tz(x.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('YYYY-MM-DD') ===
            value.format('YYYY-MM-DD')
        )
      : [];
    const userObj = JSON.parse(localStorage.getItem('user'));
    if (arrCourseSchedule && arrCourseSchedule.length > 0) {
      const params = {
        user_fk: userObj['id'],
        course_schedule_fk: arrCourseSchedule[0].id,
        title: 'Lesson Plan Title',
        date: value.utc().format(),
        lesson_objective: '',
        is_draft: true,
        categories: [
          {
            category: 'bell_work',
            event_duration: 0,
            title: 'Bell Work',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'lecture',
            event_duration: 0,
            title: 'Lecture',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'student_discussion_groups',
            event_duration: 0,
            title: 'Student Discussion Groups',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'closing_activity',
            event_duration: 0,
            title: 'Closing Activity',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          }
        ]
      };
      Promise.allSettled([addLesson(params)])
        .then((result) => {
          const AddLesson = result[0];
          if (AddLesson?.value && Object.keys(AddLesson?.value).length > 0) {
            getDetails();
            setLesson(AddLesson?.value);
            setIsLessonEdit(true);
            setVisibleLessonDrawer(false);
            cancelConfirmCreateEvent();
            setVisibleEventDrawer(true);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  return (
    <>
      {loader && <Loading />}
      <div>
        <div className={'title-cap'}>
          <h2>{lesson?.title || 'Lesson Plan Title'}</h2>
          <p>{`Instruction Day ${indexNumber + 1} of ${CourseDetails?.data?.total_course_schedule_count}`}</p>
        </div>
        <div className={'lesson-detail'}>
          <h5>Lesson Plan</h5>
          <p>{lesson?.lesson_objective || 'You haven’t planned this lesson yet.'}.</p>
          <Button
            className={'btn-brand'}
            onClick={() => {
              if (lesson?.id > 0) {
                setVisibleEventDrawer(true);
              } else {
                if (state?.is_display_create_lesson_plan_suggestion_popup) {
                  setIsLessonConfirmeModalVisible(true);
                } else {
                  createNewLesson();
                }
              }
            }}>
            Create a New Lesson
          </Button>
        </div>
      </div>

      {/* Lets Get Started Modal  */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isLessonConfirmeModalVisible}
        onCancel={() => {
          setState((s) => ({
            ...s,
            is_display_create_lesson_plan_suggestion_popup: true
          }));
          cancelConfirmCreateEvent();
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="event_delete_back"
            onClick={() => {
              setState((s) => ({
                ...s,
                is_display_create_lesson_plan_suggestion_popup: true
              }));
              cancelConfirmCreateEvent();
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="event_delete_delete"
            onClick={() => {
              confirmLesson();
            }}
            className="btn btn-brand">
            Let&apos;s Get Started
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Create a Lesson Plan</h1>
            <p className="lg-txt">
              Depending on your experience with the course content, set aside &nbsp;
              <i className="icon text-danger fa-solid fa-clock"></i>
              &nbsp; 15 - 60 minutes to plan each lesson.
            </p>
            <label>To speed up the process, have the following information nearby:</label>
          </div>
          <ul className="list-item warn-list">
            <li>
              <i data-icon="k" className="icon"></i>
              Course curriculum or link to publisher site
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              Physical or digital resources you plan to use
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              School calendar to reference days you are/are not teaching
            </li>
          </ul>
          <div className="fitem-check">
            <input
              className="fitem-ck-input"
              id="showmsg"
              type="checkbox"
              checked={!state?.is_display_create_lesson_plan_suggestion_popup}
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  is_display_create_lesson_plan_suggestion_popup: !e.target.checked
                }));
              }}
            />
            <label className="fitem-ck-txt" htmlFor="showmsg">
              Do not show this message again.
            </label>
          </div>
        </div>
      </Modal>
      {/* Lets Get Started Modal ends  */}

      {/* Instructonal Event Drawer  */}
      <Drawer
        className={'full-drawer ' + (!isLessonEdit ? 'fl-lesson-drawer' : '')}
        title={''}
        visible={visibleEventDrawer}
        width={'100%'}
        closable={false}
        onClose={() => {
          setVisibleEventDrawer(false);
          SetDisplayDate(null);
        }}>
        <DisplayInstructionalEvent
          {...props}
          getDetails={getDetails}
          setVisibleEventDrawer={setVisibleEventDrawer}
          value={value}
          lesson={lesson}
          setLesson={setLesson}
          totalEventDuration={totalEventDuration}
          setTotalEventDuration={setTotalEventDuration}
          isLessonEdit={isLessonEdit}
          setIsLessonEdit={setIsLessonEdit}
          page_number={page_number}
          setPage={setPage}
          lessonCount={lessonCount}
          SetLessonCount={SetLessonCount}
          id={id}
          dayChoice={dayChoice}
          setDayChoice={setDayChoice}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setValue={setValue}
          displayDate={displayDate}
          SetDisplayDate={SetDisplayDate}
          setVisibleLessonDrawer={setVisibleLessonDrawer}
          indexNumber={indexNumber}
          setIndexNumber={setIndexNumber}
          state={state}
          setState={setState}
          loader={loader}
          setLoader={setLoader}
        />
      </Drawer>
    </>
  );
}

function DisplayInstructionalEvent(props) {
  const {
    state,
    setState,
    loader,
    setLoader,
    addInstructionalEvent,
    updateInstructionalEvent,
    deleteInstructionalEvent,
    getInstructionalEventById,
    updateLesson,
    setVisibleEventDrawer,
    value,
    lesson,
    setLesson,
    totalEventDuration,
    setTotalEventDuration,
    isLessonEdit,
    setIsLessonEdit,
    getDetails,
    page_number,
    setPage,
    SetLessonCount,
    dayChoice,
    displayDate,
    SetDisplayDate,
    setVisibleLessonDrawer,
    indexNumber,
    setIndexNumber,
    setValue,
    selectedValue,
    setSelectedValue
  } = props;
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isEventDeleteModalVisible, setIsEventDeleteModalVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState(0);
  const [eventHeading, setEventHeading] = useState(0);
  const [isOvertimeModalVisible, setIsOvertimeModalVisible] = useState(false);
  const [isAutoSaving, SetIsAutoSaving] = useState(false);
  const [isLessonSaved, SetIsLessonSaved] = useState(false);
  const { getInstructionalEvents, getCourseLessonById, getInstructionalEventCategories } = useCalendarAPI();
  const [lessonLimit, setLessonLimit] = useState(0);

  const {
    category = '',
    event_duration = '',
    eventTitle = '',
    description = '',
    teacher_doing = '',
    student_doing = '',
    formative_assessment = '',
    material_list = '',
    lesson_prep_notes = '',
    formValid = false,
    errors
  } = state;

  /**
   * AUTHOR: RITESH SOLANKI
   */
  useEffect(() => {
    const { CourseDetails } = store.getState();
    if (CourseDetails?.data && Object.keys(CourseDetails?.data).length > 0) {
      setLessonLimit(CourseDetails?.data?.total_course_schedule_count);
    }
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getInstructionalEventCategories()]).then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const InstructionalEventCategories = result[0];
          if (InstructionalEventCategories?.value && Object.keys(InstructionalEventCategories?.value).length > 0) {
            setEvents(InstructionalEventCategories?.value?.categories);
          }
        }
      });
    }
  }, []);

  /**
   * AUTHOR: RITESH SOLANKI
   */
  useEffect(() => {
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getAllEvents()]).then(() => {});
    }
  }, [lesson]);

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  useEffect(() => {
    getTotalTime();
  }, [dayChoice]);

  useEffect(() => {
    getTotalTime();
  }, [dayChoice]);

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const getAllEvents = (isDisplayedCongratsOrOverTime = false) => {
    Promise.allSettled([getInstructionalEvents({ lesson_fk: lesson?.id }), getCourseLessonById(lesson?.id)]).then(
      (result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const GetEvents = result[0];
          const Lesson = result[1];
          let dataIdx = [];
          dataIdx = GetEvents?.value?.results.filter((d) => d.is_draft === false);
          let totalEventDuration = 0;
          if (GetEvents?.value && Object.keys(GetEvents?.value).length > 0) {
            let allEvent = [];
            GetEvents?.value?.results.map((item) => {
              totalEventDuration += +item?.event_duration;
              item['is_expand'] = dataIdx?.length > 0 && item?.id === dataIdx?.[0]?.id ? true : false;
              item['expand_menu'] = false;
              allEvent.push(item);
            });
            setTotalEventDuration(totalEventDuration);
            if (totalEventDuration === state.hours && isDisplayedCongratsOrOverTime) {
              SetIsLessonSaved(true);
              const displayTimeOut = setTimeout(() => {
                SetIsLessonSaved(false);
                clearTimeout(displayTimeOut);
              }, 2000);
            } else {
              if (totalEventDuration > state.hours && isDisplayedCongratsOrOverTime) {
                if (Lesson?.data?.is_display_lesson_over_class_time_popup) {
                  setIsOvertimeModalVisible(true);
                }
              }
            }
            //Set All Event in State
            setState((s) => ({
              ...s,
              events: allEvent
            }));
          }
        }
      }
    );
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const confirmEvent = () => {
    const { is_display_over_class_time_popup } = state;
    if (!is_display_over_class_time_popup) {
      setLoader(true);
      const params = {
        id: lesson?.id,
        is_display_lesson_over_class_time_popup: is_display_over_class_time_popup
      };
      Promise.allSettled([updateLesson(params)])
        .then(() => {
          setIsOvertimeModalVisible(false);
          setLoader(false);
        })
        .catch(() => {
          setIsOvertimeModalVisible(false);
          setLoader(false);
        });
    } else {
      setIsOvertimeModalVisible(false);
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * @param {*} id Instructional Event Id
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const getEventById = (id) => {
    setEventId(id);
    // setConfirmEventId(id);
    setLoader(true);
    Promise.allSettled([getInstructionalEventById(id)]).then((result) => {
      const isFulfilled = result.filter((x) => x.status === 'fulfilled');
      if (isFulfilled?.length > 0) {
        const { GetEvent } = store.getState();
        if (GetEvent?.data && Object.keys(GetEvent?.data).length > 0) {
          setState((s) => ({
            ...s,
            category: GetEvent?.data?.category,
            event_duration: GetEvent?.data?.event_duration,
            eventTitle: GetEvent?.data?.title,
            description: GetEvent?.data?.description,
            teacher_doing: GetEvent?.data?.teacher_doing,
            student_doing: GetEvent?.data?.student_doing,
            formative_assessment: GetEvent?.data?.formative_assessment,
            material_list: GetEvent?.data?.material_list,
            lesson_prep_notes: GetEvent?.data?.lesson_prep_notes,
            formValid:
              GetEvent?.data?.event_duration !== '' &&
              GetEvent?.data?.teacher_doing !== '' &&
              GetEvent?.data?.student_doing !== ''
                ? true
                : false,
            is_display_over_class_time_popup: GetEvent?.data?.is_display_over_class_time_popup,
            errors: {}
          }));
        }
        setLoader(false);
        setIsEventModalVisible(true);
      }
    });
  };

  const isValidInteger = (value) => {
    if (+value === 0 || value === '') {
      return 'Duration is mandatory';
    } else if (
      value.toString().indexOf('-') !== -1 ||
      value.toString().indexOf('+') !== -1 ||
      value.toString().indexOf('.') !== -1 ||
      value >= 1 === false
    ) {
      return 'A valid integer is required';
    } else {
      return '';
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const validate = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case 'category':
        errors.category = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
      case 'event_duration':
        errors.event_duration = isValidInteger(value);
        break;
      case 'teacher_doing':
        errors.teacher_doing = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
      case 'student_doing':
        errors.student_doing = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
    }
    setState((s) => ({
      ...s,
      formValid:
        category && event_duration && isValidInteger(event_duration) === '' && teacher_doing && student_doing
          ? true
          : false
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const clear = () => {
    setState((s) => ({
      ...s,
      category: '',
      event_duration: '',
      eventTitle: '',
      description: '',
      teacher_doing: '',
      student_doing: '',
      formative_assessment: '',
      material_list: '',
      lesson_prep_notes: '',
      errors: {},
      formValid: false
    }));
    setEventId(0);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const createEvent = () => {
    if (isValidInteger(event_duration) !== '') {
      setState((s) => ({
        ...s,
        errors: isValidInteger(event_duration)
      }));
    } else {
      setState((s) => ({
        ...s,
        errors: {}
      }));
      setLoader(true);
      const params = {
        category: category,
        event_duration: event_duration,
        title: eventTitle,
        description: description,
        teacher_doing: teacher_doing,
        student_doing: student_doing,
        formative_assessment: formative_assessment,
        material_list: material_list,
        lesson_prep_notes: lesson_prep_notes,
        lesson_fk: lesson?.id,
        is_draft: false
      };
      Promise.allSettled([addInstructionalEvent(params)])
        .then(() => {
          const { AddInstructionalEvent } = store.getState();
          if (AddInstructionalEvent?.data && Object.keys(AddInstructionalEvent?.data).length > 0) {
            clear();
            getAllEvents(true);
            setIsEventModalVisible(false);
          } else {
            clear();
            getAllEvents(true);
            setIsEventModalVisible(false);
          }
          setEventId(0);
          setLoader(false);
        })
        .catch(() => {
          setIsEventModalVisible(false);
          setEventId(0);
          setLoader(false);
        });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-113>AAbility for teachers to add/remove instructional event cards</TARA-113>
   */
  const updateEvent = () => {
    if (isValidInteger(event_duration) !== '') {
      setState((s) => ({
        ...s,
        errors: isValidInteger(event_duration)
      }));
    } else {
      setState((s) => ({
        ...s,
        errors: {}
      }));
      setIsEventModalVisible(false);
      SetIsAutoSaving(true);
      const params = {
        id: eventId,
        category: category,
        event_duration: event_duration,
        title: eventTitle,
        description: description,
        teacher_doing: teacher_doing,
        student_doing: student_doing,
        formative_assessment: formative_assessment,
        material_list: material_list,
        lesson_prep_notes: lesson_prep_notes,
        is_draft: false
        // lesson_fk: Lesson?.data?.id
      };
      Promise.allSettled([updateInstructionalEvent(params)])
        .then(() => {
          const { UpdateInstructionalEvent } = store.getState();
          if (UpdateInstructionalEvent?.data && Object.keys(UpdateInstructionalEvent?.data).length > 0) {
            clear();
            setIsEventModalVisible(false);
            const autoSavedTimeOut = setTimeout(() => {
              SetIsAutoSaving(false);
              getAllEvents(true);
              clearTimeout(autoSavedTimeOut);
            }, 1500);
          } else {
            clear();
            setIsEventModalVisible(false);
            const autoSavedTimeOut = setTimeout(() => {
              SetIsAutoSaving(false);
              getAllEvents(true);
              clearTimeout(autoSavedTimeOut);
            }, 1500);
          }
          setEventId(0);
          setLoader(false);
        })
        .catch(() => {
          setIsEventModalVisible(false);
          setEventId(0);
          setLoader(false);
          const autoSavedTimeOut = setTimeout(() => {
            SetIsAutoSaving(false);
            getAllEvents(true);
            clearTimeout(autoSavedTimeOut);
          }, 1500);
        });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-113>AAbility for teachers to add/remove instructional event cards</TARA-113>
   */
  const deleteEvent = () => {
    setLoader(true);
    Promise.allSettled([deleteInstructionalEvent(eventId)])
      .then(() => {
        const { DeleteInstructionalEvent } = store.getState();
        if (DeleteInstructionalEvent?.data && Object.keys(DeleteInstructionalEvent?.data).length > 0) {
          getAllEvents();
          setIsEventDeleteModalVisible(false);
        }
        setEventId(0);
        setLoader(false);
      })
      .catch(() => {
        setIsEventDeleteModalVisible(false);
        setEventId(0);
        setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} item Item Object
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const expandCollapseEvent = (item, isExpand) => {
    const { events } = state;
    events.map((obj) => {
      if (obj.id === item.id) {
        obj['is_expand'] = isExpand;
      }
    });

    //Set All Event in State
    setState((s) => ({
      ...s,
      events: events
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} item Item Object
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const expandMenu = (item, isExpand) => {
    const { events } = state;
    events.map((obj) => {
      if (obj.id === item.id) {
        obj['expand_menu'] = !isExpand;
      }
    });

    //Set All Event in State
    setState((s) => ({
      ...s,
      events: events
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  // const onChange = (e) => {
  //   setState((s) => ({ ...s, description: e.target.getContent() }));
  // };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const getTotalTime = () => {
    const { CourseDetails, CourseSchedule } = store.getState();
    if (
      CourseDetails &&
      Object.keys(CourseDetails.data).length > 0 &&
      CourseDetails?.data?.course_schedule &&
      CourseDetails?.data?.course_schedule.length > 0
    ) {
      map(CourseDetails.data.course_schedule, (obj) => {
        /**
         * AUTHOR: Ritesh Solanki
         * Added If block to Fix <TARA-644></TARA-644>
         */
        if (dayChoice === obj.day_choice) {
          const startTime = moment(obj.start_time, 'HH:mm:ss a');
          const endTime = moment(obj.end_time, 'HH:mm:ss a');
          const duration = moment.duration(endTime.diff(startTime));
          const hours = parseInt(duration.asMinutes());
          setState((s) => ({
            ...s,
            hours: hours
          }));
        }
      });
    } else if (CourseSchedule && Object.keys(CourseSchedule.data?.results).length > 0) {
      map(CourseSchedule.data?.results, (obj) => {
        if (dayChoice === obj.day_choice) {
          const startTime = moment(obj.start_time, 'HH:mm:ss a');
          const endTime = moment(obj.end_time, 'HH:mm:ss a');
          const duration = moment.duration(endTime.diff(startTime));
          const hours = parseInt(duration.asMinutes());
          setState((s) => ({
            ...s,
            hours: hours
          }));
        }
      });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @returns
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const showProgressbar = () => {
    const percentage = (totalEventDuration * 100) / state?.hours;
    return percentage;
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-619>New Instruction Event | When user create one instructional event and try to
   * create another then user has not added any value but still "Add to my lesson" button enabled
   * </TARA-619>
   */
  const handleCategoryChange = (event) => {
    const title = events.filter((x) => x.key === event?.target?.value);
    setState((s) => ({
      ...s,
      category: event?.target?.value,
      eventTitle: title && title.length > 0 ? title[0]?.value : '',
      formValid:
        category && event_duration && +event_duration >= 1 && Number.isInteger(+value) && teacher_doing && student_doing
          ? true
          : false
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   * Functoin used to load last lesson details
   */
  const lastLesson = () => {
    const { CourseSchedule, CourseLessons } = store.getState();
    let idx = +indexNumber - 1;
    if (idx >= 0) {
      let lesson = CourseSchedule?.data?.results[idx];
      let nextMonth = moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMYYYY');
      let currentMonth = moment(selectedValue).format('MMYYYY');
      lesson.page_number = indexNumber.toString();
      const findLesson = CourseLessons?.data?.results.find((item) => item?.course_schedule_fk === lesson?.id);
      if (findLesson?.id) {
        lesson.course_lessons = [findLesson];
      }
      if (nextMonth < currentMonth) {
        setSelectedValue(moment(selectedValue).add(-1, 'M'));
        setValue(moment(selectedValue).add(-1, 'M'));
      }
      if (lesson?.course_lessons?.some((item) => item.is_draft === false)) {
        const LessonItemChanged = lesson?.course_lessons?.find((item) => item.is_draft === false);
        setPage(lesson?.page_number);
        setIndexNumber(idx);
        SetLessonCount(CourseLessons?.data?.count);
        setValue(moment.tz(LessonItemChanged?.date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(LessonItemChanged?.date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson(LessonItemChanged);
      } else {
        setPage(idx.toString());
        setIndexNumber(idx);
        setValue(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson({});
        setTimeout(() => {}, 500);
        setState((s) => ({
          ...s,
          title: '',
          lesson_objective: '',
          lesson_title: ''
        }));
        setVisibleEventDrawer(false);
        setVisibleLessonDrawer(true);
      }
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   * Function used to load next lesson details
   */
  const nextLesson = () => {
    const { CourseSchedule, CourseLessons } = store.getState();
    let idx = indexNumber + 1;
    if (parseInt(page_number) < parseInt(CourseSchedule?.data?.count)) {
      let lesson = CourseSchedule?.data?.results[idx];
      lesson.page_number = indexNumber.toString();
      const findLesson = CourseLessons?.data?.results.find((item) => item?.course_schedule_fk === lesson?.id);
      if (findLesson?.id) {
        lesson.course_lessons = [findLesson];
      }
      let nextMonth = moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMYYYY');
      let currentMonth = moment(selectedValue).format('MMYYYY');
      if (nextMonth > currentMonth) {
        setSelectedValue(moment(selectedValue).add(1, 'M'));
        setValue(moment(selectedValue).add(1, 'M'));
      }
      // console.log('lesson', lesson);
      if (lesson?.course_lessons?.some((item) => item.is_draft === false)) {
        const LessonItemChanged = lesson?.course_lessons?.find((item) => item.is_draft === false);
        setPage(lesson?.page_number);
        setIndexNumber(idx);
        SetLessonCount(CourseLessons?.data?.count);
        setValue(moment.tz(LessonItemChanged?.date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(LessonItemChanged?.date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson(LessonItemChanged);
      } else {
        setPage(lesson?.page_number);
        setValue(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson({});
        setTimeout(() => {
          setState((s) => ({
            ...s,
            title: '',
            lesson_objective: '',
            lesson_title: ''
          }));
        }, 500);

        setVisibleEventDrawer(false);
        setVisibleLessonDrawer(true);
      }
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-701>Add/Edit instructional event | When user has added any link in fields
   * then after saving event it should work and behave like a link</TARA-701>
   */
  // const isValidUrl = (url) => {
  //   const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  //   return regex.test(url);
  // };
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>';
    });
  };

  return (
    <>
      {loader && <Loading />}
      <div className="fd-header flex">
        {isLessonEdit && (
          <React.Fragment>
            <div className="left-caption">
              <Link
                to={'#'}
                as={ReactRouterLink}
                className="btn-txt back-btn"
                onClick={() => {
                  getDetails();
                  setVisibleEventDrawer(false);
                  setIsLessonEdit(false);
                  setLesson({});
                }}>
                <i className="icon icon-prev"></i>
                Back to Course View
              </Link>
            </div>
            <h1>Create Lesson Plan</h1>
            <div className="right-caption">
              <p className="date-block">
                {displayDate ? displayDate.format('dddd, MMM DD, YYYY') : value?.format('dddd, MMM DD, YYYY')}
              </p>
            </div>
          </React.Fragment>
        )}
        {!isLessonEdit && (
          <React.Fragment>
            <div className="left-caption">
              <p className="date-title">
                {displayDate ? displayDate.format('dddd, MMM DD, YYYY') : value?.format('dddd, MMM DD, YYYY')}
              </p>
            </div>
            <div className="right-caption">
              <div className="btn-group">
                <a
                  // href="javascript:void(0)"
                  className={'btn btn-bdr ' + (indexNumber === 0 ? 'disabled' : '')}
                  onClick={() => {
                    lastLesson();
                  }}>
                  <i className="icon icon-prev"></i>
                  <span>Last Lesson</span>
                </a>
                <a
                  // href="javascript:void(0)"
                  className={'btn btn-bdr ' + (parseInt(indexNumber + 1) === parseInt(lessonLimit) ? 'disabled' : '')}
                  onClick={() => {
                    nextLesson();
                  }}>
                  <span>Next Lesson</span>
                  <i className="icon icon-next"></i>
                </a>
              </div>
              <span className="ant-closedr">
                <i
                  className="icon icon-add"
                  aria-hidden="true"
                  onClick={() => {
                    setState((s) => ({ ...s, page: 1 }));
                    setVisibleEventDrawer(false);
                  }}></i>
              </span>
              {/* <div className="icon icon-add"></div> */}
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="fd-body">
        <LessonDetails
          {...props}
          lesson={lesson}
          totalEventDuration={totalEventDuration}
          isLessonEdit={isLessonEdit}
          page_number={page_number}
          setPage={setPage}
          state={state}
          setState={setState}
        />
        <div className="fd-right-wrap">
          <div className="title-row flex">
            <div className="left-cap">
              <h4> {!isLessonEdit ? 'Lesson Plan' : 'Instructional Events'}</h4>
              {isLessonEdit && (
                <button
                  type="button"
                  className="btn btn-brand"
                  disabled={totalEventDuration >= state?.hours}
                  onClick={() => {
                    clear();
                    setIsEventModalVisible(true);
                  }}>
                  <i className={'icon fa-solid fa-circle-plus'}></i>
                  Add Instructional Event
                </button>
              )}
            </div>
            {!isLessonEdit && (
              <div className="right-btn">
                <Link
                  to={'#'}
                  as={ReactRouterLink}
                  className="btn btn-bdr"
                  onClick={() => {
                    setIsLessonEdit(true);
                  }}>
                  <i className="icon icon-edits font-size-20 mr-8"></i>
                  Edit Lesson Details
                </Link>
              </div>
            )}
            {isLessonEdit && (
              <React.Fragment>
                {totalEventDuration <= state?.hours && (
                  <div className="right-cap">
                    <div className="progress-wrap">
                      <p className="progress-plan">
                        <b>{totalEventDuration}</b> minutes planned
                      </p>
                      <div className="progress-bar">
                        <span style={{ width: `${showProgressbar()}%` }}></span>
                      </div>
                      <p className="progress-time">
                        <span>Total Time</span>
                        <strong>{state?.hours} min</strong>
                      </p>
                    </div>
                  </div>
                )}
                {totalEventDuration > state?.hours && (
                  <div className="right-cap warn-card">
                    <div className="progress-wrap">
                      <p className="progress-plan">
                        <img src={overtime} />
                        Over Time Limit
                      </p>
                      <div className="progress-bar">
                        <span style={{ width: `${showProgressbar()}%` }}></span>
                      </div>
                      <p className="progress-time">
                        <span>Time Exceeded by </span>
                        <strong>{totalEventDuration - state?.hours}min</strong>
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <p className="suggetion-out">
            <strong>Suggested outline for standard courses.</strong> Edit these instructional event placeholders or add
            your own.
          </p>
          <div className="event-list">
            {state?.events &&
              state?.events.map((item) => {
                if (!item.is_draft || isLessonEdit) {
                  return (
                    <div
                      key={item?.id}
                      className={!item.is_draft ? 'event-card event-word-card' : 'event-card light-card'}>
                      <div className="event-title">
                        <h6>{item?.title}</h6>
                        <div className="right-cap flex align-items-center">
                          <label className={'time ' + (isLessonEdit && item.event_duration > 0 ? 'right-bar' : '')}>
                            <i
                              className={
                                'icon fa-solid fa-clock ' + (item?.is_expand ? 'text-danger' : 'text-secondary')
                              }></i>
                            {item?.event_duration} min
                          </label>
                          {!item.is_draft && isLessonEdit && (
                            <div className="event-optn custom-dropdown">
                              {isLessonEdit && (
                                <Link
                                  to={'#'}
                                  as={ReactRouterLink}
                                  className={item.expand_menu ? 'ellipsis-btn show' : 'ellipsis-btn'}>
                                  <i
                                    className="fa-solid fa-ellipsis"
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                    }}></i>
                                </Link>
                              )}
                              <ul
                                className="cdropdown-menu custom-dropdown"
                                style={{ display: item.expand_menu ? 'block' : 'none' }}>
                                <li>
                                  <Link
                                    to={'#'}
                                    as={ReactRouterLink}
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                      getEventById(item.id);
                                    }}>
                                    <i className="icon icon-edits icons-lg"></i>
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={'#'}
                                    as={ReactRouterLink}
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                      setEventId(item.id);
                                      setEventHeading(item.title);
                                      setIsEventDeleteModalVisible(true);
                                    }}>
                                    <i className="icon fa-solid fa-trash"></i>
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="event-body">
                        {item.is_draft && (
                          <React.Fragment>
                            <div className="caption-card">
                              <p>
                                This is a placeholder instructional activity. Edit this card or add details to
                                customize.
                                <Link
                                  to={'#'}
                                  as={ReactRouterLink}
                                  className=" btn-txt"
                                  onClick={() => {
                                    expandCollapseEvent(item, true);
                                  }}>
                                  Links you add will show in the lesson outline
                                </Link>
                              </p>
                            </div>

                            <div className="btn-group">
                              <button
                                type="button"
                                className=" btn-txt"
                                onClick={() => {
                                  setEventId(item.id);
                                  setEventHeading(item.title);
                                  setIsEventDeleteModalVisible(true);
                                }}>
                                <i className="icon fa-solid fa-trash"></i>
                                Delete Placeholder Event
                              </button>
                              <button
                                type="button"
                                className=" btn-txt"
                                onClick={() => {
                                  getEventById(item.id);
                                }}>
                                <i className="icon icon-edits icons-lg"></i>
                                Edit Event Details
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        {!item.is_draft && (
                          <React.Fragment>
                            <div className="yellow-block">
                              <p>
                                <strong>Teacher</strong>
                                <span>{item?.teacher_doing}</span>
                              </p>
                              <p>
                                <strong>Students</strong>
                                <span>{item?.student_doing}</span>
                              </p>
                            </div>
                            <div className="work-detail">
                              <div className="work-d-list flex justify-content-start">
                                <label className="mx-0">Details:</label>
                                <div
                                  className="mx-0 flex-fill"
                                  dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                              </div>
                              {item?.is_expand && (
                                <React.Fragment>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Formative Assessment:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.formative_assessment) }}
                                    />
                                    {/* {isValidUrl(item?.formative_assessment) && (
                                      <a target="_blank" rel="noreferrer" href={item?.formative_assessment}>
                                        {item?.formative_assessment}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.formative_assessment) && (
                                      <div className="mx-0 flex-fill">{item?.formative_assessment}</div>
                                    )} */}
                                  </div>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Materials:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.material_list) }}
                                    />
                                    {/* {isValidUrl(item?.material_list) && (
                                      <a target="_blank" rel="noreferrer" href={item?.material_list}>
                                        {item?.material_list}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.material_list) && (
                                      <div className="mx-0 flex-fill">{item?.material_list}</div>
                                    )} */}
                                  </div>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Prep Notes:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.lesson_prep_notes) }}
                                    />
                                    {/* {isValidUrl(item?.lesson_prep_notes) && (
                                      <a target="_blank" rel="noreferrer" href={item?.lesson_prep_notes}>
                                        {item?.lesson_prep_notes}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.lesson_prep_notes) && (
                                      <div className="mx-0 flex-fill">{item?.lesson_prep_notes}</div>
                                    )} */}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      {item?.is_expand && !item.is_draft && (
                        <div className="event-footer">
                          <span
                            className="showmore-less open"
                            onClick={() => {
                              expandCollapseEvent(item, false);
                            }}>
                            View Less
                            <i className="fa-solid fa-angle-down"></i>
                          </span>
                        </div>
                      )}
                      {!item?.is_expand && !item.is_draft && (
                        <div className="event-footer">
                          <span
                            className="showmore-less open"
                            onClick={() => {
                              expandCollapseEvent(item, true);
                            }}>
                            View More
                            <i className="fa-solid fa-angle-up"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        className="instructional-popup"
        visible={isEventModalVisible}
        onCancel={() => {
          clear();
          setIsEventModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="event_back"
            onClick={() => {
              clear();
              setIsEventModalVisible(false);
            }}
            className="btn btn-gray btn-bdr">
            Cancel
          </Button>,
          <Button
            key="event_delete"
            className="btn btn-brand"
            onClick={eventId > 0 ? updateEvent : createEvent}
            disabled={!formValid}>
            {eventId > 0 ? 'Save Changes' : 'Add To My Lesson'}
          </Button>
        ]}>
        <div className="modal-head">
          <h1>{eventId > 0 ? 'Edit ' : 'New '}Instructional Event</h1>
          <p className="h4">Add details for this instructional event. You can edit this later in your lesson plan.</p>
          <div className="title-row">
            <form action="" className="form-group">
              <div className="half-fitem fitem mb-8">
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    Category
                  </label>
                  <select
                    name="category"
                    value={category}
                    onBlur={validate}
                    onChange={(event) => {
                      handleCategoryChange(event);
                    }}>
                    <option value="" disabled>
                      Select Category
                    </option>
                    {events.map((item, index) => (
                      <option key={index} value={item.key}>
                        {item?.value}
                      </option>
                    ))}
                  </select>
                  {errors['category'] ? <label className="error">{errors['category']}</label> : ''}
                </div>
                <div className={errors['event_duration'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label className="fw-normal required" htmlFor="">
                    Event Duration
                  </label>
                  <div className="flex minute-dura">
                    <input
                      name="event_duration"
                      type="text"
                      placeholder="00"
                      value={event_duration}
                      onBlur={validate}
                      onChange={(event) => {
                        if (event?.target?.value !== '') {
                          validate(event);
                        }
                        setState((s) => ({ ...s, event_duration: event?.target?.value }));
                      }}
                    />
                    <span>minutes</span>
                  </div>
                  {errors['event_duration'] ? <label className="error">{errors['event_duration']}</label> : ''}
                </div>
              </div>
              <i className="mb-24 d-block">Selecting custom will allow you to add a custom title below.</i>

              <div className="fitem">
                <label htmlFor="">Title (optional - default to category name)</label>
                <input
                  type="text"
                  placeholder="Enter a custom title"
                  value={eventTitle}
                  onKeyUp={() => {
                    setState((s) => ({ ...s, category: 'other' }));
                  }}
                  onChange={(event) => {
                    setState((s) => ({ ...s, eventTitle: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem">
                <label className="fw-normal" htmlFor="">
                  Description (optional)
                </label>
                <Editor
                  // onInit={(evt, editor) => {
                  //   editorRef.current = editor;
                  //   setState((s) => ({ ...s, description: editor }));
                  // }}
                  // content={props.section.contents || props.placeholder}
                  // onChange={onChange}
                  // initialValue={description}
                  apiKey="n1kconw5gil983ockkdvjt9a2bjubkcxgeurngipvvge021j"
                  onEditorChange={(newValue) => {
                    setState((s) => ({ ...s, description: newValue }));
                  }}
                  value={description}
                  init={{
                    height: 215,
                    menubar: false,
                    placeholder:
                      'Information about the instructional event. Includes links to youtube videos, documentation, your notes, etc.',
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'help',
                      'wordcount'
                    ],
                    toolbar:
                      'superscript subscript text bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | '
                  }}
                />
                {/* <textarea
                  placeholder="Information about the instructional event. Includes links to youtube videos, documentation, your notes, etc."
                  value={description}
                  onChange={(event) => {
                    setState((s) => ({ ...s, description: event?.target?.value }));
                  }}></textarea> */}
              </div>

              <div className="half-fitem fitem">
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    What is the teacher doing during?
                  </label>
                  <input
                    name="teacher_doing"
                    type="text"
                    value={teacher_doing}
                    onBlur={validate}
                    onChange={(event) => {
                      if (event?.target?.value !== '') {
                        validate(event);
                      }
                      setState((s) => ({ ...s, teacher_doing: event?.target?.value }));
                    }}
                  />
                  {errors['teacher_doing'] ? <label className="error">{errors['teacher_doing']}</label> : ''}
                </div>
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    What are the students doing?
                  </label>
                  <input
                    name="student_doing"
                    type="text"
                    value={student_doing}
                    onBlur={validate}
                    onChange={(event) => {
                      if (event?.target?.value !== '') {
                        validate(event);
                      }
                      setState((s) => ({ ...s, student_doing: event?.target?.value }));
                    }}
                  />
                  {errors['student_doing'] ? <label className="error">{errors['student_doing']}</label> : ''}
                </div>
              </div>
              <div className="fitem">
                <label htmlFor="">Formative Assessment (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="Add description of formative assessment that is part of this instructional event."
                  value={formative_assessment}
                  onChange={(event) => {
                    setState((s) => ({ ...s, formative_assessment: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem">
                <label htmlFor="">Materials List (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="List physical or digital materials used in the lesson"
                  value={material_list}
                  onChange={(event) => {
                    setState((s) => ({ ...s, material_list: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem mb-0">
                <label htmlFor="">Lesson Prep Notes (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="Things to prepare before the lesson and time needed."
                  value={lesson_prep_notes}
                  onChange={(event) => {
                    setState((s) => ({ ...s, lesson_prep_notes: event?.target?.value }));
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Overtime Modal Popup */}

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isOvertimeModalVisible}
        onOk={() => {
          setIsOvertimeModalVisible(false);
        }}
        onCancel={() => {
          setIsOvertimeModalVisible(false);
        }}
        width={705}
        maskClosable={false}
        footer={[
          <Button
            key="course_delete_back"
            onClick={() => {
              confirmEvent(false);
            }}
            className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Over class time</h1>
            <h6 className="subhead pt-19 mb-12">
              <b>The total time for this lesson’s instructional events exceeds the total scheduled class time.</b>
            </h6>
            <h6 className="subhead">
              Your lesson will be saved, but you will not be able to add more instructional events. Reduce the time for
              one or more instructional events or edit your class schedule.
            </h6>
          </div>
          <div className="fitem-check">
            <input
              id="dshowmsg"
              className="fitem-ck-input"
              type="checkbox"
              checked={!state?.is_display_over_class_time_popup}
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  is_display_over_class_time_popup: !e.target.checked
                }));
              }}
            />
            <label className="fitem-ck-txt" htmlFor="dshowmsg">
              Do not show this message again
            </label>
          </div>
        </div>
      </Modal>

      {/* Overtime Modal Popup Ends */}

      {/* Delete Modal Popup */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isEventDeleteModalVisible}
        onCancel={() => {
          setIsEventDeleteModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="lesson_plan_back"
            onClick={() => {
              setIsEventDeleteModalVisible(false);
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="lesson_plan_delete"
            onClick={() => {
              deleteEvent();
            }}
            className="btn btn-brand">
            Confirm Deletion
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Delete Instructional Event</h1>
            <h4 className="h4">Are you sure you want to delete {eventHeading}? This action cannot be undone.</h4>
          </div>
        </div>
      </Modal>
      {/* Delete Modal Popup Ends */}

      {/* Loading Popup Starts */}
      <Modal closable={false} keyboard={false} centered={true} width={705} visible={isAutoSaving} footer={false}>
        <div className="popup-loader">
          <figure>
            <img src={loaderImg} alt="" />
          </figure>
          <p>Autosaving your progress</p>
        </div>
      </Modal>
      {/* Loading Popup Ends */}

      {/* <TARA-629> Congrats popup </TARA-629> */}
      <Modal
        closable={false}
        keyboard={false}
        centered={true}
        width={705}
        visible={isLessonSaved}
        footer={false}
        className="cource-created-wrapper">
        <div className="modal-caption cource-added-wrap min-caption-modal">
          <div className="modal-head">
            <figure>
              <img src={addedicon} alt="" />
            </figure>
            <h1>Congrats! You planned your lesson! </h1>
          </div>
        </div>
      </Modal>
      {/* <TARA-629> Congrats popup </TARA-629> */}
    </>
  );
}

function LessonDetails(props) {
  const { lesson, isLessonEdit, state, setState, loader, setLoader, updateLesson, CourseDetails, indexNumber } = props;
  const [isLessonModalVisible, setIsLessonModalVisible] = useState(false);
  const { title, lesson_objective } = state;
  const { getCourseLessonById } = useCalendarAPI();

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  useEffect(() => {
    getLessonDetails();
  }, [lesson]);

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const getLessonDetails = () => {
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getCourseLessonById(lesson?.id)]).then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const Lesson = result[0];
          if (Lesson?.value && Object.keys(Lesson?.value).length > 0) {
            setState((s) => ({
              ...s,
              title: Lesson?.value?.title,
              lesson_objective: Lesson?.value?.lesson_objective,
              lesson_title: Lesson?.value?.title
            }));
          }
        }
      });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const uodateLessonDetails = () => {
    setLoader(true);
    const params = {
      id: lesson?.id,
      title: title,
      lesson_objective: lesson_objective
    };
    Promise.allSettled([updateLesson(params)])
      .then(() => {
        const { UpdateLesson } = store.getState();
        if (UpdateLesson?.data && Object.keys(UpdateLesson?.data).length > 0) {
          getLessonDetails();
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <>
      {loader && <Loading />}
      <div className="fd-left-wrap">
        <div className="title-row flex mb-48">
          <div className="title-cap">
            {isLessonEdit && <h2>{state?.lesson_title || 'Lesson Plan Title'}</h2>}
            {isLessonEdit && (
              <p>{`Instruction Day ${indexNumber + 1} of ${CourseDetails?.data?.total_course_schedule_count || 0}`}</p>
            )}
            {!isLessonEdit && <h2>{state?.lesson_title || 'Lesson Plan Title'}</h2>}
            {!isLessonEdit && (
              <p>{`Instruction Day ${indexNumber + 1} of ${CourseDetails?.data?.total_course_schedule_count || 0}`}</p>
            )}
          </div>
          {isLessonEdit && (
            <div className="right-btn">
              <Link
                to={'#'}
                as={ReactRouterLink}
                className="btn-txt"
                onClick={() => {
                  setIsLessonModalVisible(true);
                }}>
                <i className="icon icon-edits icons-lg"></i>
                Edit Lesson Details
              </Link>
            </div>
          )}
        </div>
        <label htmlFor="">Lesson Objective </label>
        <label className="lbl-txt">{state?.lesson_objective || 'Add a lesson objective'}</label>
      </div>

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isLessonModalVisible}
        onCancel={() => {
          setIsLessonModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="lesson_plan_back"
            onClick={() => {
              setIsLessonModalVisible(false);
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="lesson_plan_delete"
            onClick={() => {
              setIsLessonModalVisible(false);
              uodateLessonDetails();
            }}
            className="btn btn-brand">
            Save Lesson Details
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Lesson Plan Details</h1>
            <h4 className="h4">
              Add or edit the title and objective for this lesson. Writing an objective can help you build your teaching
              strategy from one lesson to another.
            </h4>
            <div className="title-row">
              <form action="" className="form-group">
                <div className="fitem">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    placeholder="Give your lesson a title  (i.e. What is science?)"
                    value={title}
                    onChange={(event) => {
                      setState((s) => ({ ...s, title: event?.target?.value }));
                    }}
                  />
                </div>
                <div className="fitem">
                  <label htmlFor="">Objective (Recommended) </label>
                  <textarea
                    placeholder="What is the goal of the lesson? What should students know by the end of the lesson? "
                    value={lesson_objective}
                    onChange={(event) => {
                      setState((s) => ({ ...s, lesson_objective: event?.target?.value }));
                    }}></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    IndividualCourse,
    SchoolCourseAssessments,
    SchoolCourse,
    CourseDetails,
    CourseSchedule,
    CourseLessons,
    Lesson,
    UpdateLesson,
    DeleteCourse,
    UpdateSchoolCourse,
    AddEvent,
    UpdateInstructionalEvent,
    DeleteInstructionalEvent,
    GetEvent,
    GetEvents,
    InstructionalEventCategories,
    ConfirmCourse,
    ConfirmInstructionalEvent
  } = state;
  return {
    IndividualCourse,
    SchoolCourseAssessments,
    SchoolCourse,
    CourseDetails,
    CourseSchedule,
    CourseLessons,
    Lesson,
    UpdateLesson,
    DeleteCourse,
    UpdateSchoolCourse,
    AddEvent,
    UpdateInstructionalEvent,
    DeleteInstructionalEvent,
    GetEvent,
    GetEvents,
    InstructionalEventCategories,
    ConfirmCourse,
    ConfirmInstructionalEvent
  };
};

const actionCreators = {
  getSchoolCourseAssessments,
  addSchoolCourse,
  getCourseById,
  getCourseSchedule,
  getCourseLessons,
  deleteCourseById,
  updateSchoolCourse,
  addInstructionalEvent,
  updateInstructionalEvent,
  deleteInstructionalEvent,
  getInstructionalEventById,
  updateLesson,
  confirm,
  confirmInstructionalEvent
};

export default connect(mapStateToProps, actionCreators)(ByCourseCalendar);

import api from '../Services/api';
import {
  COURSE_ALL_FAILURE,
  COURSE_ALL_REQUEST,
  COURSE_ALL_SUCCESS,
  GET_COURSE_DETAILS_REQUEST,
  GET_COURSE_DETAILS_SUCCESSFULLY,
  GET_COURSE_DETAILS_FAILED,
  GET_COURSE_SCHEDULE_SUCCESSFULLY,
  GET_COURSE_SCHEDULE_FAILED,
  UPDATE_COURSE_ORDER_INDEX_REQUEST,
  UPDATE_COURSE_ORDER_INDEX_SUCCESS,
  UPDATE_COURSE_ORDER_INDEX_FAILURE
} from './types';

export const allCourse = (params) => {
  return (dispatch) => {
    dispatch({
      type: COURSE_ALL_REQUEST
    });
    return api
      .get('/school-course/course/', { params })
      .then((response) => {
        dispatch({
          type: COURSE_ALL_SUCCESS,
          data: response.data
        });
        return response.data;
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: COURSE_ALL_FAILURE,
            error: error?.response?.data
          });
          throw error?.response?.data;
        }
      });
  };
};

export const getCourseById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_COURSE_DETAILS_REQUEST
    });
    return api
      .get(`/school-course/course/${id}/`)
      .then((response) => {
        dispatch({
          type: GET_COURSE_DETAILS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_COURSE_DETAILS_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const getCourseSchedule = (params) => {
  return (dispatch) => {
    return api
      .get('/school-course/course-schedule/', { params })
      .then((response) => {
        dispatch({
          type: GET_COURSE_SCHEDULE_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_COURSE_SCHEDULE_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const updateCourseOrderIndex = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COURSE_ORDER_INDEX_REQUEST
    });
    return api
      .post('/school-course/course/updateOrderIndex/', payload)
      .then((response) => {
        dispatch({
          type: UPDATE_COURSE_ORDER_INDEX_SUCCESS,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_COURSE_ORDER_INDEX_FAILURE,
            error: error?.response?.data
          });
        }
      });
  };
};

import React from 'react';
import cloudUploadIcon from '../../../../assets/images/cloud-upload.svg';
import fileDownloadIcon from '../../../../assets/images/file-download.svg';
import styles from './AssignSeatsModalContent.module.scss';
import Papa from 'papaparse';

export default function AssignSeatsModalContent({ onDropped }) {
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length) {
      const file = files[0];
      parseCSV(file);
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        onDropped(result.data);
      },
      header: true,
      skipEmptyLines: true
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.assignSeatsModal}>
      <h1>Assign Seats</h1>
      <h2>Upload a .CSV file below to invite users to be part of your enterprise subscription</h2>
      <div className={styles.dragableArea} onDrop={handleDrop} onDragOver={handleDragOver}>
        <p>Drag and drop to upload files</p>
        <img src={cloudUploadIcon} />
        <p>Click here to search in your computer files</p>
      </div>
      <div className={styles.downloadLink}>
        <img src={fileDownloadIcon} />
        <a href="!#">Download CSV Template</a>
      </div>
    </div>
  );
}

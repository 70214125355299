import React, { useState, useEffect } from 'react';
import Loading from '../../../Layout/loading/Container';
import ByCourseCalendar from './ByCourseCalendar';
import ByCourseTimeline from './ByCourseTimeline';
import CourseSchedule from './CourseSchedule';
import { useParams } from 'react-router-dom';
import { Tabs, Select } from 'antd';
import { useCalendarAPI } from '../../../Services/calendar.service';
import _ from 'lodash';
import moment from 'moment';
import AddNewCourse from '../../Calendar/Component/AddNewCourse';
import DeleteCourse from '../../Calendar/Component/DeleteCourse';
import EditDetails from '../../Calendar/Component/EditDetails';
import ExportModal from '../../Calendar/Component/ExportModal';

const { TabPane } = Tabs;

const CourseView = () => {
  const params = useParams();
  const id = +params?.id || 0;
  const [view, setView] = useState('1');
  const [courseData, setCourseData] = useState({ scheduleDays: [] });
  const [formattedDate, setFormattedDate] = useState('');
  const [lessonsFromChild, setLessonsFromChild] = useState();

  const [loading, setLoading] = useState(false);
  const [isNewCourseModalVisible, setIsNewCourseModalVisible] = useState(false);
  const [isDeleteCourseModalVisible, setIsDeleteCourseModalVisible] = useState(false);
  const [isEditDetailsModalVisible, setIsEditDetailsModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [newCourseAdded, setNewCourseAdded] = useState(false);

  const handleNewCourseAdded = () => {
    setNewCourseAdded(true);
  };

  const options = [
    {
      value: 'Create New Course',
      label: (
        <div>
          <i className="fa-regular fa-plus" style={{ paddingRight: '14px' }}></i>
          Create New Course
        </div>
      )
    },
    {
      value: 'Edit Details',
      label: (
        <div>
          <i className="fa-solid fa-pencil" style={{ paddingRight: '14px' }}></i>
          Edit Details
        </div>
      )
    },
    {
      value: 'Delete Course',
      label: (
        <div>
          <i className="fa-solid fa-trash" style={{ paddingRight: '14px' }}></i>
          Delete Course
        </div>
      )
    },
    {
      value: 'Export',
      label: (
        <div>
          <i className="fa-solid fa-cloud-arrow-down" style={{ paddingRight: '14px' }}></i>
          Export
        </div>
      )
    }
  ];
  const { useGetEventsQuery } = useCalendarAPI();
  const { data: calendarApiData, isLoading: calendarLoading, refetch } = useGetEventsQuery();

  useEffect(() => {
    setLoading(calendarLoading);
    if (calendarApiData) {
      const { courseAndTheirScheduleDays, courseMetadatas } = calendarApiData;
      const courseScheduleData = _.find(courseAndTheirScheduleDays, { courseId: id });
      setCourseData(courseScheduleData || { scheduleDays: [] });
      const courseMeta = _.find(courseMetadatas, { id: id });
      if (courseMeta) {
        let formattedStart = moment(courseMeta.start).format('MMMM, DD');
        let formattedEnd = moment(courseMeta.end).format('MMMM, DD, YYYY');
        let formattedCourseDate = `${formattedStart} - ${formattedEnd}`;
        setFormattedDate(formattedCourseDate);
      }
    }
  }, [calendarApiData, id, calendarLoading]);

  useEffect(() => {
    if (newCourseAdded) {
      refetch();
      setNewCourseAdded(false);
    }
  }, [newCourseAdded]);

  const changeView = (index) => {
    setView(index);
  };
  const onViewTabChange = (key) => {
    changeView(key);
  };
  const handleSelectOption = (option) => {
    const { value } = option;

    if (value === 'Create New Course') {
      handleCreateNewCourse();
    } else if (value === 'Export') {
      handleExportCourse();
    } else if (value === 'Delete Course') {
      handleDeleteCourse();
    } else if (value === 'Edit Details') {
      handleEditDetails();
    }
    // setSelectedOption(option);
  };

  const handleCreateNewCourse = () => {
    setIsNewCourseModalVisible(true);
  };

  const handleCloseCreateNewCourse = () => {
    setIsNewCourseModalVisible(false);
  };

  const handleDeleteCourse = () => {
    setIsDeleteCourseModalVisible(true);
  };

  const handleCloseDeleteCourse = () => {
    setIsDeleteCourseModalVisible(false);
  };

  const handleEditDetails = () => {
    setIsEditDetailsModalVisible(true);
  };

  const handleCloseEditDetails = () => {
    setIsEditDetailsModalVisible(false);
  };

  const handleCloseExportCourse = () => {
    setIsExportModalVisible(false);
  };

  const handleExportCourse = () => {
    setIsExportModalVisible(true);
  };

  // Display a loading spinner if data is loading
  if (loading || calendarLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }
  return (
    <div className="course-view">
      <div className="course-view__header">
        <div className="course-view__header__top">
          <h1>{courseData.courseName}</h1>
          <div className="grade-level">
            <div className="grade">{courseData.courseGrade}</div>
            {courseData.courseLevel && <div className="level">, {courseData.courseLevel}</div>}
          </div>
          <Select
            labelInValue
            className="option-select"
            dropdownClassName="option-select-dropdown"
            style={{ width: 139 }}
            dropdownMatchSelectWidth={false}
            placement={'bottomRight'}
            value={{ value: 'Options', label: 'Options' }}
            onChange={handleSelectOption}
            options={options}
          />
        </div>
        <div className="course-view__header__bottom">
          <div className="course-view__header__bottom__left">
            <i className="fa-regular fa-calendar"></i>
            <span>{formattedDate}</span>
            <CourseSchedule courseData={courseData} />
          </div>
        </div>
      </div>
      <div className="tab-wrap">
        <Tabs defaultActiveKey="1" onChange={onViewTabChange}>
          <TabPane tab="Calendar" key="1"></TabPane>
          <TabPane tab="Timeline" key="2"></TabPane>
        </Tabs>
        <div className="instruction-days">
          Instruction Days <span>{courseData.scheduleDays.length}</span>
        </div>
        <div className="lessons-planned">
          Lessons Planned <span>{lessonsFromChild}</span>
        </div>
      </div>
      {view === '1' && (
        <div className="course-view__calendar">
          <ByCourseCalendar
            courseIdParam={id}
            onChildLessons={setLessonsFromChild}
            onNewCourseAdded={handleNewCourseAdded}
          />
        </div>
      )}
      {view === '2' && (
        <div className="course-view__timeline">
          <ByCourseTimeline courseIdParam={id} onChildLessons={setLessonsFromChild} />
        </div>
      )}
      <AddNewCourse
        visible={isNewCourseModalVisible}
        onClose={handleCloseCreateNewCourse}
        onNewCourseAdded={handleNewCourseAdded}
      />
      <DeleteCourse
        courseId={id}
        courseName={courseData.courseName}
        isVisible={isDeleteCourseModalVisible}
        onClose={handleCloseDeleteCourse}
      />
      <EditDetails
        courseId={id}
        courseName={courseData.courseName}
        isVisible={isEditDetailsModalVisible}
        onClose={handleCloseEditDetails}
        onCourseEdit={handleNewCourseAdded}
      />
      <ExportModal isVisible={isExportModalVisible} onClose={handleCloseExportCourse} data={calendarApiData} />
    </div>
  );
};

export default CourseView;

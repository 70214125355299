import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { Link, Link as ReactRouterLink, useHistory } from 'react-router-dom';
import store from '../../../store';
import { deleteCourseById } from '../../../Action';
import { connect } from 'react-redux';

function DeleteCourse(props) {
  const { isVisible, onClose, courseId, courseName, deleteCourseById } = props;
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const history = useHistory();

  const handleOk = () => {
    setIsDeleteModalVisible(false);
  };

  const handleClick = () => {
    setIsConfirmDelete(!isConfirmDelete);
  };

  const handleDelete = () => {
    handleCancel();
    Promise.allSettled([deleteCourseById(courseId)])
      .then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { DeleteCourse } = store.getState();
          const { data } = DeleteCourse;
          if (Object.keys(data).length > 0) {
            sessionStorage.setItem('DeletedCourse', courseName);
            sessionStorage.setItem('DeletedCourse', courseName);
            history.push('/teacher/dashboard');
          }
          // setLoader(false);
        }
      })
      .catch(() => {
        // setLoader(false);
      });
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
    onClose();
  };

  useEffect(() => {
    setIsDeleteModalVisible(isVisible);
  }, [isVisible]);

  return (
    <Modal
      visible={isDeleteModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={770}
      maskClosable={false}
      closeIcon={<div className="icon icon-add"></div>}
      keyboard={false}
      centered={true}
      footer={[
        <Button key="course_delete_back" onClick={handleCancel} className="btn btn-primary btn-bdr">
          Cancel
        </Button>,
        <Button
          key="course_delete_delete"
          onClick={handleDelete}
          className="btn btn-brand"
          disabled={isConfirmDelete === false}>
          Delete Course
        </Button>
      ]}>
      <div className="delete-wrap">
        <div className="modal-head">
          <h1>
            Are you sure you want to delete <br />
            {courseName}?
          </h1>
          <h4 className="h4">Deleting this course will delete all of its data, including:</h4>
        </div>
        <ul className="list-item danger-list">
          <li>
            <i data-icon="k" className="icon"></i>
            Course details
          </li>
          <li>
            <i data-icon="k" className="icon"></i>
            Course schedule
          </li>
          <li>
            <i data-icon="k" className="icon"></i>
            All planned lessons
          </li>
        </ul>
        <h4 className="subhead font-weight-400 font-sans">
          Please confirm you want to delete
          <strong> {courseName} </strong>
          by checking the box below.&nbsp;
          <strong>
            <Link to={'#'} as={ReactRouterLink} className="clr-required" htmlFor={'#'}>
              This action cannot be undone.
            </Link>
          </strong>
        </h4>
        <div className="fitem-check">
          <input id="deletecource" className="fitem-ck-input" type="checkbox" onClick={handleClick} />
          <label className="fitem-ck-txt" htmlFor="deletecource">
            Yes, I want to delete this course.
          </label>
        </div>
      </div>
    </Modal>
  );
}

// export default DeleteCourse;

const mapStateToProps = (state) => {
  const { DeleteCourse } = state;
  return {
    DeleteCourse
  };
};

const actionCreators = {
  deleteCourseById
};

export default connect(mapStateToProps, actionCreators)(DeleteCourse);

import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import store from './store';
import setupInterceptors from './Services/setupInterceptors';
import setupInterceptorsTeacherAdminApi from './Services/setupInterceptorsTeacherAdminApi';
setupInterceptors(store);
setupInterceptorsTeacherAdminApi(store);
import { history } from './Lib/history';
import AuthLayout from './Layout/Auth';
import TeacherLayout from './Layout/Teacher';
import TeacherAdminLayout from './Layout/TeacherAdmin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { subscriptionRoutes } from './routes';
import { Toast } from './Components';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider rovider store={store}>
        <Router history={history}>
          <Switch>
            <Route path="/auth" component={AuthLayout} />
            <Route path="/teacher" component={TeacherLayout} />
            <Route path="/teacher-admin" component={TeacherAdminLayout} />
            {subscriptionRoutes.map((route, index) => (
              <Route key={index} path={`${route.layout}${route.path}`} component={route.component} />
            ))}
            <Redirect from="/" to="/auth" />
          </Switch>
          <Toast />
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}
export default App;

import React, { useState, useEffect, useContext } from 'react';
import { Alert, Button, Calendar, DatePicker, Drawer, Modal, Tabs } from 'antd';
import * as yup from 'yup';
import { Link, useParams, useHistory, Link as ReactRouterLink } from 'react-router-dom';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { map } from 'lodash';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import config from '../../../Lib/config';
import store from '../../../store';
import Loading from '../../../Layout/loading/Container';
import addedicon from '../../../assets/images/added-icon.png';
import overtime from '../../../assets/images/overtime.svg';
import loaderImg from '../../../assets/images/loader.png';
import { useCalendarAPI } from '../../../Services/calendar.service';

// import LoadingPopup from '../../../Layout/loading-popup/Container';

// import { INITIAL_EVENTS } from './event-utils';
const PageContext = React.createContext();

const { TabPane } = Tabs;

export default function IndividualCourse(props) {
  const history = useHistory();
  const params = useParams();
  const id = +params?.id || 0;
  const [state, setState] = useState({
    errors: {},
    data: { days: [], isSame: true },
    selectedTimeZone: ''
  });
  const [loader, setLoader] = useState(false);
  return (
    <PageContext.Provider value={{ state, setState, loader, setLoader, history, id, ...props }}>
      <IndividualCourse_ courseId={id} />
    </PageContext.Provider>
  );
}

function IndividualCourse_(props) {
  const { useGetHolidaysByCourseIdQuery, useEditHolidayMutation } = useCalendarAPI();
  const courseHolidayQuery = useGetHolidaysByCourseIdQuery(props.courseId);
  const { mutate: editHoliday, isLoading: isEditHolidayLoading } = useEditHolidayMutation();

  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());
  const [visibleLessonDrawer, setVisibleLessonDrawer] = useState(false);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [lesson, setLesson] = useState({});
  const [visibleEventDrawer, setVisibleEventDrawer] = useState(false);
  const [years, setYears] = useState([]);
  const [isLessonEdit, setIsLessonEdit] = useState(false);
  const [page_number, setPage] = useState(0);
  const [lessonCount, SetLessonCount] = useState(0);
  const [dayChoice, setDayChoice] = useState('');
  const [isCourseAddedVisible, setIsCourseAddedVisible] = useState(false);
  const [displayDate, SetDisplayDate] = useState(null);
  const [indexNumber, setIndexNumber] = useState(0);
  const [view, setView] = useState('1');

  const ctx = useContext(PageContext);
  const {
    state,
    setState,
    loader,
    setLoader,
    // history,
    id,
    getCourseById,
    getCourseSchedule,
    getCourseLessons
    // deleteCourseById,
    // updateSchoolCourse
  } = ctx;

  /**
   * AUTHOR: RITESH SOLANKI
   * Function will execute when values changed in selectedValue variable
   */
  useEffect(() => {
    getDetails();
  }, [id]);

  useEffect(() => {
    let arryear = [];
    const yyyy = +selectedValue?.format('YYYY') + 3;
    for (var i = 2010; i <= yyyy; i++) {
      arryear.push(i);
    }

    /* START DATE / END DATE YEAR Includes on year selection START */
    if (!arryear.includes(+moment(state?.CourseDetails?.start_date).format('YYYY'))) {
      if (2010 > +moment(state?.CourseDetails?.start_date).format('YYYY')) {
        for (var j = 2009; j >= +moment(state?.CourseDetails?.start_date).format('YYYY'); j--) {
          if (!arryear.includes(+j)) {
            arryear.unshift(+j);
          }
        }
      }
    }

    if (!arryear.includes(+moment(state?.CourseDetails?.end_date).format('YYYY'))) {
      if (selectedValue?.format('YYYY') < +moment(state?.CourseDetails?.end_date).format('YYYY')) {
        for (var k = selectedValue?.format('YYYY'); k <= +moment(state?.CourseDetails?.end_date).format('YYYY'); k++) {
          if (!arryear.includes(+j)) {
            arryear.push(+k);
          }
        }
      }
    }
    /* START DATE / END DATE YEAR Includes on year selection END */
    setYears(arryear);
    const addedCourse = sessionStorage.getItem('addedCourse');
    if (addedCourse) {
      setIsCourseAddedVisible(true);
    }
  }, [state?.CourseDetails?.start_date, state?.CourseDetails?.end_date]);

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const getDetails = () => {
    // setLoader(true);
    const params = {
      course_id: id,
      page_size: 1000
      // month: moment(selectedValue).format('MM'),
      // year: moment(selectedValue).format('YYYY')
    };
    Promise.allSettled([getCourseById(id), getCourseSchedule(params), getCourseLessons(params)])
      .then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { CourseDetails, CourseSchedule, CourseLessons } = store.getState();
          let objCourseDetails = CourseDetails?.data;
          objCourseDetails['course_schedule'] = CourseSchedule?.data?.results;
          let draft_events = [];
          if (CourseLessons?.data?.results && CourseLessons?.data?.results?.length > 0) {
            draft_events = CourseLessons?.data?.results.filter((x) => x.is_draft === false);
            // SetLessonCount(draft_events.length);
          }
          const timeZoneForCourse =
            objCourseDetails?.time_zone || localStorage.getItem('user')?.time_zone || momentTimeZone.tz.guess(true);
          SetLessonCount(CourseSchedule?.data?.count);
          myAsyncLoopFunction(objCourseDetails?.schedule_time).then((schedule_time) => {
            objCourseDetails['schedule_time'] = schedule_time;
            setState((s) => ({
              ...s,
              CourseDetails: objCourseDetails,
              id: objCourseDetails?.id,
              courseName: objCourseDetails?.name,
              grade: objCourseDetails?.grade,
              level: objCourseDetails?.level,
              is_display_create_lesson_plan_suggestion_popup:
                objCourseDetails?.is_display_create_lesson_plan_suggestion_popup,
              is_display_date_not_planned_instruction_day_popup:
                objCourseDetails?.is_display_date_not_planned_instruction_day_popup,
              selectedTimeZone: timeZoneForCourse,
              start_date: moment.tz(objCourseDetails?.start_date, 'YYYY-MM-DD', timeZoneForCourse),
              end_date: moment.tz(objCourseDetails?.end_date, 'YYYY-MM-DD', timeZoneForCourse),
              run_time: objCourseDetails?.run_time,
              total_course_schedule_count: objCourseDetails?.total_course_schedule_count,
              total_lesson_planned_count: objCourseDetails?.total_lesson_planned_count,
              schedule_time: schedule_time,
              // CourseLessons: CourseLessons?.data?.results,
              CourseLessons: draft_events,
              data: { days: schedule_time, isSame: objCourseDetails?.time_choice === 'same_time' ? true : false }
            }));
          });
        }
        // setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const myAsyncLoopFunction = async (array) => {
    const allAsyncResults = [];
    for (const item of array) {
      const startTime = moment(item.start_time, ['HH:mm']).format('hh:mm');
      const startampm = moment(item.start_time, ['HH:mm']).format('A');
      const endTime = moment(item.end_time, ['HH:mm']).format('hh:mm');
      const endampm = moment(item.end_time, ['HH:mm']).format('A');
      const data = {
        ...item,
        ['start_time']: startTime,
        ['start_time_t']: startTime,
        ['startAMPM']: startampm,
        ['end_time']: endTime,
        ['end_time_t']: endTime,
        ['endAMPM']: endampm
      };
      allAsyncResults.push(data);
    }
    return allAsyncResults;
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const onSelect = (newValue) => {
    const { CourseDetails, is_display_date_not_planned_instruction_day_popup, selectedTimeZone } = state;
    const arrCourseSchedule = CourseDetails?.course_schedule
      ? CourseDetails.course_schedule.filter(
          (x) =>
            moment.tz(x.schedule_date, 'YYYY-MM-DD', selectedTimeZone).format('YYYY-MM-DD') ===
            newValue.format('YYYY-MM-DD')
        )
      : [];
    const idx = CourseDetails?.course_schedule?.findIndex(
      (x) =>
        moment.tz(x.schedule_date, 'YYYY-MM-DD', selectedTimeZone).format('YYYY-MM-DD') ===
        newValue.format('YYYY-MM-DD')
    );
    setIndexNumber(idx);
    if (arrCourseSchedule && arrCourseSchedule.length > 0) {
      setValue(newValue);
      setSelectedValue(newValue);
      SetDisplayDate(newValue);
      setDayChoice(arrCourseSchedule[0]?.day_choice);
      setState((s) => ({
        ...s,
        is_display_over_class_time_popup: true
      }));
      if (arrCourseSchedule[0].course_lessons && arrCourseSchedule[0].course_lessons.length > 0) {
        if (arrCourseSchedule[0].course_lessons?.some((item) => item.is_draft === false)) {
          const LessonItemChanged = arrCourseSchedule[0].course_lessons?.find((item) => item.is_draft === false);
          setLesson(LessonItemChanged);
          setVisibleEventDrawer(true);
          setPage(arrCourseSchedule[0].page_number);
          SetLessonCount(arrCourseSchedule[0].page_number);
          setState((s) => ({
            ...s,
            is_display_over_class_time_popup: LessonItemChanged?.is_display_lesson_over_class_time_popup
          }));
          const isDraft = LessonItemChanged?.is_draft;
          setIsLessonEdit(isDraft);
        } else {
          setLesson({});
          setVisibleLessonDrawer(true);
          setPage(arrCourseSchedule[0].page_number);
          SetLessonCount(arrCourseSchedule[0].page_number);
          setIsLessonEdit(true);
        }
      } else {
        setLesson({});
        setVisibleLessonDrawer(true);
        setPage(arrCourseSchedule[0].page_number);
        SetLessonCount(arrCourseSchedule[0].page_number);
        setState((s) => ({
          ...s,
          title: '',
          lesson_objective: '',
          lesson_title: ''
        }));
        setIsLessonEdit(true);
      }
    } else {
      if (is_display_date_not_planned_instruction_day_popup) {
        setIsEmptyData(true);
      }
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const onPanelChange = (newValue) => {
    setValue(newValue);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const dateCellRender = (value) => {
    const listData = getListData(value);
    const day = value.format('DD');
    return (
      <div
        className={
          listData && listData?.length > 0
            ? 'ant-picker-cell-inner ant-picker-calendar-date ant-picker-calendar-date-today blue-highlight'
            : 'ant-picker-cell-inner ant-picker-calendar-date ant-picker-calendar-date-today '
        }>
        <div className="ant-picker-calendar-date-value">{day}</div>
        <div className="ant-picker-calendar-date-content">
          {listData && listData?.length > 0 && (
            <ul className="events">
              {listData.map((item, index) => {
                if (
                  moment.tz(item?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MM-YYYY') ===
                  moment(selectedValue).format('MM-YYYY')
                ) {
                  return (
                    <li key={index} style={{ listStyle: 'none' }} className={'dot-active'}>
                      <span>{item?.day}</span>
                      {item?.course_lessons &&
                        item?.course_lessons.map((objLesson, index) => {
                          if (!objLesson.is_draft) {
                            return (
                              <Button
                                type="primary"
                                key={index}
                                style={{ width: '150px', height: '30px', backgroundColor: '#257A8E' }}>
                                {objLesson?.title}
                              </Button>
                            );
                          }
                        })}
                    </li>
                  );
                }
              })}
            </ul>
          )}
        </div>
      </div>
    );
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const getListData = (value) => {
    const { CourseDetails, CourseLessons, selectedTimeZone } = state;
    let dateValue = value.format('yyyy/MM/DD'); // you can parse value in every format you want
    let listData = [];
    let listLessons = [];
    let previous_month_count = 0;
    if (
      CourseDetails &&
      CourseDetails !== undefined &&
      Object.keys(CourseDetails).length > 0 &&
      CourseDetails.course_schedule &&
      CourseDetails.course_schedule.length > 0
    ) {
      map(CourseDetails?.course_schedule, (obj, index) => {
        listLessons = [];
        previous_month_count = obj.previous_month_count;
        if (dateValue === moment.tz(obj?.schedule_date, 'YYYY-MM-DD', selectedTimeZone).format('yyyy/MM/DD')) {
          map(CourseLessons, (objLesson, idx) => {
            let lesson_page = idx + 1;
            objLesson['lesson_page'] = lesson_page;
            if (+obj.id === +objLesson?.course_schedule_fk) {
              listLessons.push(objLesson);
            }
          });
          if (listLessons && listLessons.length > 0) {
            obj['course_lessons'] = listLessons;
          }
          let count = previous_month_count + (index + 1);
          obj['day'] = `Instruction Day ${count}`;
          obj['page_number'] = `${count}`;
          listData.push(obj);
        }
      });
    }
    return listData || [];
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const onClose = () => {
    setVisibleLessonDrawer(false);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const handleMonthChange = (event) => {
    let month = moment(selectedValue).set({ month: +event.target.value - 1 });
    setSelectedValue(month);
    setValue(month);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const handleYearChange = (event) => {
    let year = moment(selectedValue).set({ year: +event.target.value });
    setSelectedValue(year);
    setValue(year);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-76>eachers to see each of their courses in a calendar</TARA-76>
   */
  const previousMonth = () => {
    setSelectedValue(moment(selectedValue).add(-1, 'M'));
    setValue(moment(selectedValue).add(-1, 'M'));
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-76>eachers to see each of their courses in a calendar</TARA-76>
   */
  const nextMonth = () => {
    setSelectedValue(moment(selectedValue).add(1, 'M'));
    setValue(moment(selectedValue).add(1, 'M'));
  };

  const changeView = (index) => {
    setView(index);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-76>eachers to see each of their courses in a calendar</TARA-76>
   */
  const confirm = () => {
    /**
     * AUTHOR: RITESH SOLANKI
     * <TARA-661>Individual course page | When user has not selected "DO not show this message again" and user click on
     * "confirm" button and again click on non-instructional day then it is not showing the modal</TARA-661>
     * Modify code to call api conditionally
     */
    const { is_display_date_not_planned_instruction_day_popup } = state;
    if (!is_display_date_not_planned_instruction_day_popup) {
      const params = {
        id: id,
        is_display_date_not_planned_instruction_day_popup: is_display_date_not_planned_instruction_day_popup
      };
      Promise.allSettled([ctx.confirm(params)])
        .then(() => {
          const { ConfirmCourse } = store.getState();
          if (ConfirmCourse?.data && Object.keys(ConfirmCourse?.data).length > 0) {
            getDetails();
            setIsEmptyData(false);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setIsEmptyData(false);
    }
  };

  React.useEffect(() => {
    /* Display full month name format on calendar */
    moment.updateLocale('en', {
      // weekdaysMin: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      weekdaysMin: ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
    });
    return () => {
      /* Display short month name format on other places */
      moment.updateLocale('en', {
        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      });
    };
  }, []);

  /** Start of Holiday Modal */
  const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  const [isAddHolidayVisible, setIsAddHolidayVisible] = useState(false);
  const [holidayName, setHolidayName] = useState('');
  const [holidayStartDate, setHolidayStartDate] = useState('');
  const [holidayEndDate, setHolidayEndDate] = useState('');
  const [holidayList, setHolidayList] = useState([]);

  useEffect(() => {
    if (courseHolidayQuery.isLoading) return;
    setHolidayList(
      courseHolidayQuery.data.holidays.map((holiday) => ({
        id: holiday.id || undefined,
        name: holiday.event_title,
        // start: moment.utc(holiday.start_date).add(1, 'day').toDate(),
        // end: moment.utc(holiday.end_date).add(1, 'day').toDate(),
        start: moment(holiday.start_date).startOf('day').toDate(),
        end: moment(holiday.end_date).startOf('day').toDate(),
        is_draft: !holiday.is_draft
      }))
    );
    // console.log('Setting holidayList', holidayList);
  }, [courseHolidayQuery.isFetching]);

  const showHolidayModal = () => {
    // setHolidayName('');
    // setHolidayStartDate('');
    // setHolidayEndDate('');
    setIsAddHolidayVisible(false);
    setIsHolidayModalVisible(true);
    // console.log('holidayList', holidayList);
  };

  const handleCloseHolidayModal = () => {
    editHoliday({
      courseId: props.courseId,
      holidays: holidayList.map((holiday) => ({
        id: holiday.id || undefined,
        event_title: holiday.name,
        start_date: moment(holiday.start).format('YYYY-MM-DD'),
        end_date: moment(holiday.end).format('YYYY-MM-DD'),
        is_draft: !holiday.is_draft
      }))
    });
    console.log('isEditHolidayLoading', isEditHolidayLoading);
    setIsHolidayModalVisible(false);
  };

  const handleCancelHolidayModal = () => {
    setIsHolidayModalVisible(false);
  };

  const handleAddHolidayOpen = () => {
    setHolidayName('');
    setHolidayStartDate(null);
    setHolidayEndDate(null);
    setIsAddHolidayVisible(true);
  };

  const handleAddHolidayClose = () => {
    setHolidayName('');
    setHolidayStartDate('');
    setHolidayEndDate('');
    setIsAddHolidayVisible(false);
  };

  const handleHolidayCheckbox = (item, index) => {
    // if (item.is_draft == false) {
    //   holidayList[index].is_draft = true;
    // } else {
    //   holidayList[index].is_draft = false;
    // }
    // get holiday out of array
    const holiday = holidayList.splice(index, 1)[0];
    // toggle is_draft
    holiday.is_draft = !holiday.is_draft;
    // put holiday back in array
    holidayList.splice(index, 0, holiday);
    setHolidayList([...holidayList]);
  };

  const handleAddHoliday = () => {
    if (holidayName !== '' && holidayStartDate !== '' && holidayEndDate !== '') {
      const holiday = {
        name: holidayName,
        start: moment(holidayStartDate).startOf('day').toDate(),
        end: moment(holidayEndDate).startOf('day').toDate(),
        is_draft: false
      };
      setHolidayList([...holidayList, holiday]);
      handleAddHolidayClose();
    }
  };
  /** End of Holiday Modal */

  return (
    <>
      {loader && <Loading />}
      <div className="page-head">
        <Header getDetails={getDetails} handleOpenHolidayModal={showHolidayModal} changeView={changeView} />
      </div>
      <div className="block-row">
        <div className={'container-lg'}>
          <div className={'fc-cal-wrap cust-antd'}>
            <Alert />
            {view === '1' && (
              <Calendar
                value={value}
                onSelect={onSelect}
                dateFullCellRender={dateCellRender}
                // dateCellRender={dateCellRender}
                onPanelChange={onPanelChange}
                // locale={{
                //   lang: {
                //     locale: 'en',
                //     dayFormat: moment.updateLocale('en', {
                //       weekdaysMin: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                //     })
                //   }
                // }}
                fullscreen={true}
                headerRender={() => (
                  <div className="cal-header">
                    <div className="calh-left">
                      <button className="btn-icon" type="button" onClick={previousMonth}>
                        <i className="icon icon-prev"></i>
                      </button>
                      <button className="btn-icon" type="button" onClick={nextMonth}>
                        <i className="icon icon-next"></i>
                      </button>
                      <h2>{`${selectedValue?.format('MMMM')} ${selectedValue?.format('YYYY')}`}</h2>
                    </div>
                    <div className="calh-right">
                      <select value={selectedValue?.format('M')} style={{ width: 120 }} onChange={handleMonthChange}>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <select value={selectedValue?.format('YYYY')} style={{ width: 120 }} onChange={handleYearChange}>
                        {map(years, (item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
              />
            )}
            {view === '2' && <h1>GANTT</h1>}
            <Drawer
              className={'lesson-plan-drawer'}
              title={value.format('dddd, MMM DD, YYYY')}
              width={'710px'}
              placement="right"
              visible={visibleLessonDrawer === true ? visibleLessonDrawer : visibleEventDrawer}
              onClose={onClose}>
              <InstructionalEvent
                setVisibleLessonDrawer={setVisibleLessonDrawer}
                value={value}
                getDetails={getDetails}
                lesson={lesson}
                setLesson={setLesson}
                visibleEventDrawer={visibleEventDrawer}
                setVisibleEventDrawer={setVisibleEventDrawer}
                isLessonEdit={isLessonEdit}
                setIsLessonEdit={setIsLessonEdit}
                getCourseLessons={getCourseLessons}
                page_number={page_number}
                setPage={setPage}
                lessonCount={lessonCount}
                SetLessonCount={SetLessonCount}
                id={id}
                dayChoice={dayChoice}
                setDayChoice={setDayChoice}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                setValue={setValue}
                displayDate={displayDate}
                SetDisplayDate={SetDisplayDate}
                indexNumber={indexNumber}
                setIndexNumber={setIndexNumber}
              />
            </Drawer>

            {/* Delete Modal  */}
            <Modal
              closeIcon={<div className="icon icon-add"></div>}
              keyboard={false}
              centered={true}
              visible={isEmptyData}
              onOk={() => {
                setIsEmptyData(false);
              }}
              onCancel={() => {
                setIsEmptyData(false);
              }}
              width={705}
              maskClosable={false}
              footer={[
                <Button
                  key="course_delete_back"
                  onClick={() => {
                    confirm();
                  }}
                  className="btn btn-brand">
                  Confirm
                </Button>
              ]}>
              <div className="delete-wrap">
                <div className="modal-head">
                  <h1>
                    The date selected is not a planned
                    <br />
                    instruction day for this course.
                  </h1>
                  <h4 className="subhead pt-15">
                    Lesson plans cannot be added to days your course is not scehduled to be taught.
                  </h4>
                  <h4 className="subhead">
                    To ammend your course schedule, click on the “Edit Course Details” button in the page header.
                  </h4>
                </div>
                <div className="fitem-check">
                  <input
                    id="showmsgs"
                    className="fitem-ck-input"
                    type="checkbox"
                    value={!state?.is_display_date_not_planned_instruction_day_popup}
                    onChange={(e) => {
                      setState((s) => ({
                        ...s,
                        is_display_date_not_planned_instruction_day_popup: !e.target.checked
                      }));
                    }}
                  />
                  <label className="fitem-ck-txt" htmlFor="showmsgs">
                    Do not show this message again
                  </label>
                </div>
              </div>
            </Modal>

            {/* Course added */}
            <Modal
              visible={isCourseAddedVisible}
              width={770}
              closeIcon={<div className="icon icon-add"></div>}
              keyboard={false}
              centered={true}
              className="cource-created-wrapper"
              closable={false}
              maskClosable={false}
              footer={[
                <Button
                  className="btn btn-brand"
                  key="edit_link"
                  type="button"
                  onClick={() => {
                    sessionStorage.clear('addedCourse');
                    setIsCourseAddedVisible(false);
                  }}>
                  Start Planning
                </Button>
              ]}>
              <div className="modal-head">
                <div className="modal-caption cource-added-wrap">
                  <div className="modal-head">
                    <figure>
                      <img src={addedicon} alt="added-course-icon" />
                    </figure>
                    <h1>
                      You’ve added {sessionStorage.getItem('addedCourse')} <br />
                    </h1>
                    <h4 className="subhead">
                      Welcome to your new course page. Click on a date to add or edit a lesson plan.
                    </h4>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>

      {/* Holiday Modal */}
      <Modal
        className="model-footer-right"
        closeIcon={<div className="icon icon-add"></div>}
        visible={isHolidayModalVisible}
        closable={false}
        keyboard={false}
        centered={true}
        width={770}
        maskClosable={false}
        footer={[
          <div key="holiday_footer" className="add-holiday-footer">
            <Button
              key="holiday_back"
              onClick={handleCancelHolidayModal}
              className="btn btn-focus-color-bg holiday-cancel-btn">
              Cancel
            </Button>
            <Button
              key="holiday_success_back"
              onClick={handleCloseHolidayModal}
              className="btn btn-brand holiday-save-btn">
              Save Changes
            </Button>
          </div>
        ]}>
        <div className="holiday-wrap">
          <div className="modal-head">
            <h1>Manage Holidays</h1>
            <p className="font-weight-400 font-sans">
              Add your holidays dates, breaks or school events below to organize your plan around them.
            </p>
            <div className="holiday-form-wrap">
              <form>
                <div className="holiday-header">
                  <div className="holiday-header-item">
                    <label>Event</label>
                  </div>
                  <div className="holiday-header-item">
                    <label>Dates</label>
                  </div>
                </div>
                <div className="holiday-list">
                  {holidayList?.map((item, index) => (
                    <div className="holiday-item" key={index}>
                      <input
                        type="checkbox"
                        name={'holiday-checkbox-' + index}
                        checked={item?.is_draft}
                        onChange={() => handleHolidayCheckbox(item, index)}
                      />
                      <div className="holiday-item-name">
                        <label>{item?.name}</label>
                      </div>
                      <div className="holiday-item-date">
                        <label>
                          {moment(item?.start).format('dddd')}
                          {', '}
                          {moment(item?.start).format('MMM DD')}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) && ' - '}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) &&
                            moment(item?.end).format('MMM DD')}
                          {', '}
                          {moment(item?.start).format('YYYY')}
                          {!(moment(item?.start).format('YYYY') === moment(item?.end).format('YYYY')) &&
                            ' - ' + moment(item?.end).format('YYYY')}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-option display-false' : 'add-holiday-option display-true'
                    }
                    onClick={handleAddHolidayOpen}>
                    <label>+ Add Another Date</label>
                  </div>
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-item display-true' : 'add-holiday-item display-false'
                    }>
                    <div className="add-holiday-header">Add Date</div>
                    <div className="add-holiday-body">
                      <div className="add-holiday-body-items">
                        <div className="inner-items-wrap">
                          <label>Holiday Name</label>
                          <input
                            value={holidayName}
                            type="text"
                            placeholder="Spring Break"
                            onChange={(event) => {
                              setHolidayName(event?.target?.value);
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap start-date">
                          <label className="holiday-start-date">Start Date</label>
                          <DatePicker
                            value={holidayStartDate ? moment(holidayStartDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayStartDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayStartDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap end-date">
                          <label className="holiday-end-date">End Date</label>
                          <DatePicker
                            value={holidayEndDate ? moment(holidayEndDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayEndDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayEndDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap add-holiday-btn">
                          <Button key="add-holiday" onClick={handleAddHoliday} className="btn btn-focus-color-bg">
                            + Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      {/* Holiday Modal Ends */}
    </>
  );
}

function Header(props) {
  const { getDetails, changeView } = props;
  const { state, setState, setLoader, history, deleteCourseById, updateSchoolCourse } = useContext(PageContext);
  const [step, setStep] = useState('COURSE_DETAILS');
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  // const [coursemDeleteMessage, setCoursemDeleteMessage] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  // const [isOpenEditPopup, setOpenEditPopup] = useState(false);
  const [daysError, setDaysError] = useState([
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    }
  ]);
  let arrDaysError = [];
  const { id, courseName, start_date, end_date, errors } = state;
  const [menuOpen, setMenuOpen] = useState(false);
  const [noDaysFall, setNoDaysFall] = useState('');
  /**
   * AUTHOR: RITESH SOLANKI
   */
  const handleOk = () => {
    setIsDeleteModalVisible(false);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const handleCancel = () => {
    setIsDeleteModalVisible(false);
    setIsEditModalVisible(false);
    setStep('COURSE_DETAILS');
    setState((s) => ({
      ...s,
      selectedOption: ''
    }));
    moment.updateLocale('en', {
      weekdaysMin: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-75>Ability for teachers to deleted a course</TARA-75>
   */
  const handleClick = () => {
    setIsConfirmDelete(!isConfirmDelete);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * @param {*} event
   * <TARA-75>Ability for teachers to deleted a course</TARA-75>
   */
  const handleOnChange = (value) => {
    if (value === 'delete') {
      setIsDeleteModalVisible(true);
    } else if (value === 'edit') {
      setNoDaysFall('');
      setStep('COURSE_DETAILS');
      // getDetails();
      setIsEditModalVisible(true);
      /* Display short month name format on other places */
      moment.updateLocale('en', {
        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
      });
    }
    setDaysError([]);
    setState((s) => ({
      ...s,
      selectedOption: value,
      errors: {}
    }));
    setMenuOpen(false);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const setDay = (day_choice, idx) => {
    const { data } = state;
    let { days, isSame } = data;
    // if (isSame) {
    //   days = [{ day_choice: day_choice, start_time: '09:00', startAMPM: 'AM', end_time: '09:00', endAMPM: 'AM' }];
    // } else {
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      days.splice(index, 1);
      if (days && days.length === 0) {
        days.push({
          day_choice: '',
          start_time: '',
          start_time_t: '',
          startAMPM: '',
          end_time: '',
          end_time_t: '',
          endAMPM: '',
          index: 0
        });
      }
    } else {
      if (days && days.length === 1 && days[0]['day_choice'] === '') {
        days[0]['day_choice'] = day_choice;
        days[0]['index'] = 0;
      } else {
        if (isSame) {
          days.push({
            day_choice: day_choice,
            start_time: days[0]?.start_time,
            start_time_t: days[0]?.start_time_t,
            startAMPM: days[0]?.startAMPM,
            end_time: days[0]?.end_time,
            end_time_t: days[0]?.end_time_t,
            endAMPM: days[0]?.endAMPM,
            index: idx
          });
        } else {
          days.push({
            day_choice: day_choice,
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            end_time_t: '',
            endAMPM: '',
            index: idx
          });
        }
      }
    }
    days = days.filter((el) => {
      return el.day_choice !== '';
    });
    // }
    errors['days'] = '';
    days.sort(config.sortDay);
    setState((s) => ({
      ...s,
      errors: errors,
      data: { ...s.data, days: days, ...(!isSame ? { isSame: days.length <= 1 } : '') }
    }));
    setDaysError([]);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const validateStepOne = () => {
    const schema = yup.object().shape({
      courseName: yup.string().required('Course name is required.'),
      start_date: yup.string().typeError('Start date is required.').required('Start date is required.'),
      end_date: yup
        .date()
        .typeError('End date is required.')
        .required('End date is required.')
        .test('Is date greater', 'End date has to be more than start date', (value) => {
          if (!value) return true;
          return !moment(start_date).isSameOrAfter(moment(value).format('YYYY-MM-DD'));
        })
        .test('Is date greater', 'End date cannot occur in the past', (value) => {
          if (!value) return true;
          return !moment(moment().format('YYYY-MM-DD')).isAfter(moment(value).format('YYYY-MM-DD'));
        })
    });
    const data = { courseName, start_date, end_date };
    // let temp = [];
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        // map(state?.CourseDetails.schedule_time, (obj) => {
        //   // let params = { start_time: '', end_time: '' };
        //   // temp.push(params);
        //   const startTime = moment(obj.start_time, ['HH:mm']).format('hh:mm');
        //   const startampm = moment(obj.start_time, ['HH:mm']).format('A');
        //   const endTime = moment(obj.end_time, ['HH:mm']).format('hh:mm');
        //   const endampm = moment(obj.end_time, ['HH:mm']).format('A');
        //   loadSchedule(obj.day_choice, startTime, startampm, endTime, endampm);
        // });
        setStep('COURSE_SCHEDULE');
        // setDaysError(temp);
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const validateStepTwo = () => {
    const { data } = state;
    let { isSame, days } = data;
    const isDaySelected = days.some((item) => item.day_choice !== '');
    if (isDaySelected) {
      if (isSame) {
        map(days, (item, index) => {
          let validationError = {
            start_time: '',
            startAMPM: '',
            end_time: '',
            endAMPM: ''
          };

          if (index === 0) {
            if (item.start_time === '') {
              validationError['start_time'] = 'Select start time.';
            }

            if (item.startAMPM === '') {
              validationError['startAMPM'] = 'Select start am/pm.';
            }

            if (item.end_time === '') {
              validationError['end_time'] = 'Select end time.';
            }

            if (item.endAMPM === '') {
              validationError['endAMPM'] = 'Select end am/pm.';
            }

            if (item.start_time && item.startAMPM && item.end_time && item.endAMPM) {
              const end = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm:ss');
              const endTimeShouldBe = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A')
                .add('15', 'minutes')
                .format('HH:mm:ss');
              if (end >= endTimeShouldBe) {
                validationError['end_time'] = '';
              } else {
                validationError['end_time'] = 'End must occur at least 15min after start time.';
              }
            }
            arrDaysError.push(validationError);
          }
        });
      } else {
        map(days, (item) => {
          let validationError = {
            start_time: '',
            startAMPM: '',
            end_time: '',
            endAMPM: ''
          };
          if (item.start_time === '') {
            validationError['start_time'] = 'Select start time.';
          }
          if (item.startAMPM === '') {
            validationError['startAMPM'] = 'Select start am/pm.';
          }

          if (item.end_time === '') {
            validationError['end_time'] = 'Select end time.';
          }

          if (item.endAMPM === '') {
            validationError['endAMPM'] = 'Select end am/pm.';
          }

          if (item.start_time && item.startAMPM && item.end_time && item.endAMPM) {
            const end = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HHmmss');
            const endTimeShouldBe = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A')
              .add('15', 'minutes')
              .format('HHmmss');
            if (end >= endTimeShouldBe) {
              validationError['end_time'] = '';
            } else {
              validationError['end_time'] = 'End must occur at least 15min after start time.';
            }
          }
          arrDaysError.push(validationError);
        });
      }
      setDaysError(arrDaysError);
      const isFulfilled = arrDaysError.filter(
        (x) => x.start_time !== '' || x.startAMPM !== '' || x.end_time !== '' || x.endAMPM !== ''
      );
      if (isFulfilled?.length === 0) {
        const params = {
          id: id,
          name: state?.courseName ? state?.courseName : '',
          grade: state?.grade ? state?.grade : '',
          level: state?.level ? state?.level : '',
          run_time: state?.run_time ? state?.run_time : '',
          start_date: moment
            .tz(moment(state?.start_date).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .startOf('day')
            .format(),
          end_date: moment
            .tz(moment(state?.end_date).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .endOf('day')
            .format(),
          time_choice: isSame ? 'same_time' : 'custom_time',
          course_schedule: days
        };
        updateCourse(params);
      }
    } else {
      errors['days'] = 'Please select at least one day.';
      setState((s) => ({ ...s, errors: errors }));
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const updateCourse = (params) => {
    setLoader(true);
    if (state?.data?.isSame) {
      /**
       * AUTHOR: Ritesh Solanki
       * <TARA-438>Create course | Add course schedule | When user has selected multiple days in "Is your class schedule the same every day you teach? "
       * selected "Custom" and then selects "its same for all day" then it is showing multiple "Start/End time" row</TARA-438>
       */
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('HH:mm:ss');
        item['start_time_t'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        item['end_time'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('HH:mm:ss');
        item['end_time_t'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        delete item.start_time_t;
        delete item.startAMPM;
        delete item.end_time_t;
        delete item.endAMPM;
      });
    } else {
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('HH:mm');
        item['start_time_t'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('hh:mm');
        item['end_time'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm');
        item['end_time_t'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm');
        delete item.start_time_t;
        delete item.startAMPM;
        delete item.end_time_t;
        delete item.endAMPM;
      });
    }

    Promise.allSettled([updateSchoolCourse(params)])
      .then(() => {
        const { UpdateSchoolCourse } = store.getState();
        if (UpdateSchoolCourse?.data && Object.keys(UpdateSchoolCourse?.data).length > 0) {
          setState((s) => ({
            ...s,
            selectedOption: ''
          }));
          getDetails();
          finilzeCourse();
        } else {
          /**
           * AUTHOR: Ritesh Solanki
           * <TARA-552>Individual course page | Edit course schedule modal | In "Select all that apply"
           * if the user selects days which are not fall under selected dates then it should show a validation message
           * </TARA-552>
           */
          setNoDaysFall(UpdateSchoolCourse?.errors);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const finilzeCourse = () => {
    setStep('COURSE_DETAILS');
    setIsEditModalVisible(false);
    moment.updateLocale('en', {
      weekdaysMin: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    });
  };

  /**
   * AUTHOR: Ritesh Solanki
   */
  // const loadSchedule = (day_choice, start_time, start_ampm, end_time, end_ampm) => {
  //   const { data } = state;
  //   let { days } = data;
  //   // if (isSame) {
  //   //   days = [
  //   //     { day_choice: day_choice, start_time: start_time, startAMPM: start_ampm, end_time: end_time, endAMPM: end_ampm }
  //   //   ];
  //   // } else {
  //   //   const index = _.findIndex(days, (item) => item.day_choice === day_choice);
  //   //   if (index > -1) {
  //   //     days.splice(index, 1);
  //   //   } else {
  //   //     days.push({
  //   //       day_choice: day_choice,
  //   //       start_time: start_time,
  //   //       startAMPM: start_ampm,
  //   //       end_time: end_time,
  //   //       endAMPM: end_ampm
  //   //     });
  //   //   }
  //   // }
  //   const index = _.findIndex(days, (item) => item.day_choice === day_choice);
  //   if (index > -1) {
  //     // days.splice(index, 1);
  //     console.log('loadSchedule', index);
  //   } else {
  //     days.push({
  //       day_choice: day_choice,
  //       start_time: start_time,
  //       start_time_t: start_time,
  //       startAMPM: start_ampm,
  //       end_time: end_time,
  //       end_time_t: end_time,
  //       endAMPM: end_ampm
  //     });
  //   }

  //   setState((s) => ({
  //     ...s,
  //     data: { ...s.data, days: days }
  //   }));
  // };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const isSelected = (day_choice) => {
    const { data } = state;
    const { days } = data;
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const changedTime = (type, index, value) => {
    const { data } = state;
    const { days, isSame } = data;
    if (isSame) {
      days.map((item) => {
        if (type === 'start_time') {
          item['start_time'] = value;
          item['start_time_t'] = value;
        } else if (type === 'startAMPM') {
          item['startAMPM'] = value;
        } else if (type === 'end_time') {
          item['end_time'] = value;
          item['end_time_t'] = value;
        } else if (type === 'endAMPM') {
          item['endAMPM'] = value;
        }
      });
    } else {
      days.map((item, idx) => {
        if (index === idx) {
          if (type === 'start_time') {
            item['start_time'] = value;
            item['start_time_t'] = value;
          } else if (type === 'startAMPM') {
            item['startAMPM'] = value;
          } else if (type === 'end_time') {
            item['end_time'] = value;
            item['end_time_t'] = value;
          } else if (type === 'endAMPM') {
            item['endAMPM'] = value;
          }
        }
      });
    }

    setState((s) => ({
      ...s,
      data: { ...s.data, days: days }
    }));
    // if (type === 'start_time') {
    //   days[index].start_time = value;
    // } else if (type === 'startAMPM') {
    //   days[index].startAMPM = value;
    // } else if (type === 'end_time') {
    //   days[index].end_time = value;
    // } else {
    //   days[index].endAMPM = value;
    // }
    // setState((s) => ({
    //   ...s,
    //   data: { ...s.data, days: days }
    // }));
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-75>Ability for teachers to deleted a course</TARA-75>
   */
  const handleDelete = () => {
    setLoader(true);
    handleCancel();
    Promise.allSettled([deleteCourseById(id)])
      .then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { DeleteCourse } = store.getState();
          const { data } = DeleteCourse;
          if (Object.keys(data).length > 0) {
            sessionStorage.setItem('DeletedCourse', state?.CourseDetails?.name);
            sessionStorage.setItem('DeletedCourse', state?.CourseDetails?.name);
            history.push('/teacher/dashboard');
          }
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const onViewTabChange = (key) => {
    changeView(key);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   */
  const objDays = {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun'
  };

  return (
    <React.Fragment>
      {/* {loader && <Loading />} */}
      <div className="container-lg">
        <div className="head-inner flex">
          <div className="head-left">
            <h1>{state?.CourseDetails?.name}</h1>
            {(state?.CourseDetails?.grade || state?.CourseDetails?.level || state?.CourseDetails?.run_time) && (
              <div className="course-year">
                {(state?.CourseDetails?.grade || state?.CourseDetails?.level) && (
                  <p>
                    {state?.CourseDetails?.grade ? `${state?.CourseDetails?.grade},` : state?.CourseDetails?.grade}{' '}
                    {state?.CourseDetails?.level}
                  </p>
                )}
                {state?.CourseDetails?.run_time && <p>{state?.CourseDetails?.run_time}</p>}
              </div>
            )}
          </div>
          <div className="head-right">
            <div className={menuOpen ? 'mydropdowns open' : 'mydropdowns'} onClick={() => setMenuOpen(!menuOpen)}>
              <span className="btn"> Course Options </span>
              <ul className="mydropdowns-menu">
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      // setState((s) => ({ ...s, selectedOption: 'edit' }));
                      handleOnChange('edit');
                    }}>
                    <span>
                      <i className="icon icon-edits"></i>
                    </span>{' '}
                    Edit Details
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      // setState((s) => ({ ...s, selectedOption: 'delete' }));
                      handleOnChange('delete');
                    }}>
                    <span>
                      <i className="icon icon-delete"></i>
                    </span>{' '}
                    Delete Course
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      props.handleOpenHolidayModal();
                    }}>
                    <span>
                      <i className="fa-regular fa-calendar"></i>
                    </span>{' '}
                    Edit holidays
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="course-time">
          <p>
            <i className="clr-secondaryone icon icon-calendar-default-state"></i>
            {state?.start_date && state?.end_date && state?.selectedTimeZone && (
              <span>
                Dates: {moment.tz(state?.start_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMMM, DD, YYYY')} -{' '}
                {moment.tz(state?.end_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMMM, DD, YYYY')}
              </span>
            )}
          </p>
          <p>
            <i className="clr-secondaryone icon icon-clock-desk"></i>
            <span>
              {' '}
              Schedule {''}
              {state?.CourseDetails?.schedule_time?.length > 0 &&
                state?.CourseDetails?.schedule_time?.map((item, index) => {
                  return (
                    <span key={index}>
                      {state?.CourseDetails?.time_choice === 'same_time' ? (
                        <>
                          <span style={{ textTransform: 'capitalize' }}>
                            {item?.day_choice?.substring(0, 3) || ''}{' '}
                            {index === state?.CourseDetails?.schedule_time?.length - 1 ? ' ' : ' / '}
                          </span>
                          <span style={{ textTransform: 'lowercase' }}>
                            {index === state?.CourseDetails?.schedule_time?.length - 1 &&
                            item?.start_time &&
                            item?.startAMPM &&
                            item?.end_time &&
                            item?.endAMPM
                              ? `${item?.start_time} ${item?.startAMPM} - ${item?.end_time} ${item?.endAMPM}`
                              : ''}
                          </span>
                        </>
                      ) : (
                        <>
                          <span style={{ textTransform: 'capitalize' }}>
                            {item?.day_choice?.substring(0, 3) || ''}{' '}
                          </span>
                          <span style={{ textTransform: 'lowercase' }}>
                            {item?.start_time &&
                              item?.startAMPM &&
                              item?.end_time &&
                              item?.endAMPM &&
                              `${item?.start_time} ${item?.startAMPM} - ${item?.end_time} ${item?.endAMPM} ${
                                index === state?.CourseDetails?.schedule_time?.length - 1 ? '' : ' / '
                              }`}
                          </span>
                        </>
                      )}
                    </span>
                  );
                })}{' '}
              {moment()?.tz(state?.selectedTimeZone)?.format('z')}
            </span>
          </p>
        </div>
        <div className="course-details-row">
          <div className="course-view-tab">
            <Tabs defaultActiveKey="1" onChange={onViewTabChange}>
              <TabPane tab="Calendar" key="1"></TabPane>
              <TabPane tab="Timeline" key="2"></TabPane>
            </Tabs>
          </div>
          <div className="course-details">
            <p>
              <strong>Instruction Days</strong>
              <span>{state?.CourseDetails?.total_course_schedule_count || 0}</span>
            </p>
            <p>
              <strong>Lessons Planned</strong>
              <span>{state?.CourseDetails?.total_lesson_planned_count || 0}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Delete Modal  */}
      <Modal
        visible={isDeleteModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={770}
        maskClosable={false}
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        footer={[
          <Button key="course_delete_back" onClick={handleCancel} className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="course_delete_delete"
            onClick={handleDelete}
            className="btn btn-brand"
            disabled={isConfirmDelete === false}>
            Delete Course
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>
              Are you sure you want to delete <br />
              {state?.CourseDetails?.name}?
            </h1>
            <h4 className="h4">Deleting this course will delete all of its data, including:</h4>
          </div>
          <ul className="list-item danger-list">
            <li>
              <i data-icon="k" className="icon"></i>
              Course details
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              Course schedule
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              All planned lessons
            </li>
          </ul>
          <h4 className="subhead font-weight-400 font-sans">
            Please confirm you want to delete
            <strong> {state?.CourseDetails?.name} </strong>
            by checking the box below.&nbsp;
            <strong>
              <Link to={'#'} as={ReactRouterLink} className="clr-required" htmlFor={'#'}>
                This action cannot be undone.
              </Link>
            </strong>
          </h4>
          <div className="fitem-check">
            <input id="deletecource" className="fitem-ck-input" type="checkbox" onClick={handleClick} />
            <label className="fitem-ck-txt" htmlFor="deletecource">
              Yes, I want to delete this course.
            </label>
          </div>
        </div>
      </Modal>

      {/* edit Modal  */}
      <Modal
        visible={isEditModalVisible}
        width={704}
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        closable={true}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button key="edit_back" onClick={handleCancel} className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            className="btn btn-brand"
            key="edit_link"
            type="button"
            onClick={
              step === 'COURSE_DETAILS' ? validateStepOne : step === 'COURSE_SCHEDULE' ? validateStepTwo : finilzeCourse
            }>
            {step === 'COURSE_DETAILS' ? 'Next Step' : step === 'COURSE_SCHEDULE' ? 'Save Changes' : 'Start Planning'}
          </Button>
        ]}>
        <div className="modal-head">
          {step === 'COURSE_DETAILS' && (
            <React.Fragment>
              <h1>Edit Course Details</h1>
              <form action="" className="form-group">
                <div className={errors['courseName'] ? 'fitem has-error' : 'fitem'}>
                  <label htmlFor="" className="required">
                    Course Name
                  </label>
                  <input
                    type="text"
                    placeholder="Freshman Biology - Period 1"
                    value={state?.courseName}
                    onChange={(event) => {
                      setState((s) => ({ ...s, courseName: event?.target?.value }));
                    }}
                  />
                  {errors['courseName'] ? <label className="error">{errors['courseName']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <div className="fitem half-fitem">
                    <label htmlFor="">Grade </label>
                    <input
                      type="text"
                      placeholder="9th"
                      value={state?.grade}
                      onChange={(event) => {
                        setState((s) => ({ ...s, grade: event?.target?.value }));
                      }}
                    />
                  </div>
                  <div className="fitem half-fitem">
                    <label htmlFor="">Level</label>
                    <input
                      type="text"
                      placeholder="Intermediate"
                      value={state?.level}
                      onChange={(event) => {
                        setState((s) => ({ ...s, level: event?.target?.value }));
                      }}
                    />
                  </div>
                </div>
                <div className="fitem"></div>
                <div className="fitem">
                  <label htmlFor="">Enter the dates your course begins and ends</label>
                </div>
                <div className="half-fitem fitem">
                  <div className={errors['start_date'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                    <label htmlFor="" className="required">
                      Start Date
                    </label>
                    <DatePicker
                      suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                      value={state?.start_date ? moment(state?.start_date) : ''}
                      placeholder={'MM/DD/YYYY'}
                      format={'MM/DD/YYYY'}
                      onChange={(event) => {
                        setState((s) => ({ ...s, start_date: event }));
                      }}
                      onBlur={(event) => {
                        if (moment(event.target.value).isValid()) {
                          setState((s) => ({ ...s, start_date: moment(event.target.value) }));
                        }
                      }}
                    />
                    {errors['start_date'] ? <label className="error">{errors['start_date']}</label> : ''}
                    {moment(state?.start_date).isBefore(moment().format('YYYY-MM-DD')) && (
                      <label className="lbl-txt">This start date occurs in the past</label>
                    )}
                  </div>
                  <div className={errors['end_date'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                    <label htmlFor="" className="required">
                      End Date
                    </label>
                    <DatePicker
                      suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                      value={state?.end_date ? moment(state?.end_date) : ''}
                      placeholder={'MM/DD/YYYY'}
                      format={'MM/DD/YYYY'}
                      onChange={(event) => {
                        setState((s) => ({ ...s, end_date: event }));
                      }}
                      onBlur={(event) => {
                        if (moment(event.target.value).isValid()) {
                          setState((s) => ({ ...s, end_date: moment(event.target.value) }));
                        }
                      }}
                    />
                    {errors['end_date'] ? <label className="error">{errors['end_date']}</label> : ''}
                  </div>
                </div>
                <div className="fitem mb-0">
                  <label htmlFor="">Label this School Year, Semester, or Quarter</label>
                  <input
                    type="text"
                    placeholder="Spring Semester 2023"
                    value={state?.run_time}
                    onChange={(event) => {
                      setState((s) => ({ ...s, run_time: event?.target?.value }));
                    }}
                  />
                </div>
              </form>
            </React.Fragment>
          )}
          {step === 'COURSE_SCHEDULE' && (
            <React.Fragment>
              <h1 className="mb-24">Edit Course Schedule</h1>
              <form className="form-group hide weekly-wrap">
                <div className="fitem">
                  <label className="required lbl-lg mt-0">
                    <i>Select all that apply:</i>
                  </label>
                  <ul className="weekday-wrap flex">
                    <li onClick={() => setDay('monday', 0)} className={isSelected('monday') ? 'active' : ''}>
                      Monday
                    </li>
                    <li onClick={() => setDay('tuesday', 1)} className={isSelected('tuesday') ? 'active' : ''}>
                      Tuesday
                    </li>
                    <li onClick={() => setDay('wednesday', 2)} className={isSelected('wednesday') ? 'active' : ''}>
                      Wednesday
                    </li>
                    <li onClick={() => setDay('thursday', 3)} className={isSelected('thursday') ? 'active' : ''}>
                      Thursday
                    </li>
                    <li onClick={() => setDay('friday', 4)} className={isSelected('friday') ? 'active' : ''}>
                      Friday
                    </li>
                    <li onClick={() => setDay('saturday', 5)} className={isSelected('saturday') ? 'active' : ''}>
                      Saturday
                    </li>
                    <li onClick={() => setDay('sunday', 6)} className={isSelected('sunday') ? 'active' : ''}>
                      Sunday
                    </li>
                  </ul>
                  {errors['days'] ? <label className="error">{errors['days']}</label> : ''}
                </div>
                <div className="fitem">
                  <i style={{ color: '#B91C1C' }}>{noDaysFall}</i>
                </div>
                <div className="fitem mb-24">
                  <label className="lg-txt">Is your class schedule the same every day you teach?</label>
                </div>
                <div className="fitem half-fitem mb-24">
                  <div className="fitem half-fitem">
                    <div className="fitem-check">
                      <input
                        className="fitem-ck-input"
                        id="sameEveryDay"
                        name="isSame"
                        type="radio"
                        checked={state?.data?.isSame}
                        onChange={() => {
                          setState((s) => ({
                            ...s,
                            data: { ...s.data, isSame: true }
                          }));
                        }}
                      />
                      <label className="fitem-ck-txt fw-semibold" htmlFor="sameEveryDay">
                        Same for all the days above
                      </label>
                    </div>
                  </div>
                  <div className={state?.data?.isSame ? 'fitem half-fitem maxw-328' : 'fitem half-fitem maxw-276'}>
                    <div className="fitem-check">
                      <input
                        className="fitem-ck-input"
                        id="custom"
                        name="isSame"
                        type="radio"
                        disabled={state?.data?.days.length === 1 ? true : false}
                        checked={!state?.data?.isSame}
                        onChange={() => {
                          setState((s) => ({
                            ...s,
                            data: { ...s.data, isSame: false }
                          }));
                        }}
                      />
                      <label className="fitem-ck-txt fw-semibold" htmlFor="custom">
                        Custom (different every day)
                      </label>
                    </div>
                  </div>
                </div>
                {!state?.data?.isSame && state?.data?.days.length > 0 && (
                  <>
                    <div className="fitem half-fitem fitem-mb-0">
                      {!state?.data?.isSame && (
                        <div className="days-item">
                          <p>Day</p>
                        </div>
                      )}
                      <div className="fitem half-fitem maxw-276">
                        <label className="required">Select Start Time</label>
                      </div>
                      <div className="fitem half-fitem maxw-276">
                        <label className="required">Select End Time</label>
                      </div>
                    </div>
                    {state?.data?.days.map((item, index) => (
                      <div className="fitem half-fitem" key={item.day_choice}>
                        {!state?.data?.isSame && (
                          <div className="days-item">
                            <label>{objDays[item.day_choice]}</label>
                          </div>
                        )}
                        <div
                          className={
                            daysError[index]?.start_time
                              ? 'fitem half-fitem has-error maxw-276 d-block'
                              : 'fitem half-fitem maxw-276 d-block'
                          }>
                          <div className="flex">
                            <div className="half-half-fitem half-fitem">
                              <input
                                type="text"
                                placeholder={'Add Time'}
                                value={item?.start_time_t}
                                onKeyUp={(event) => {
                                  if (event.code === 'Backspace') {
                                    changedTime('start_time', index, '');
                                    changedTime('start_time_t', index, '');
                                    // setState((s) => ({ ...s, testStartDate: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  // const { testStartDate } = state;
                                  const testStartDate = item?.start_time_t;
                                  // var hh = testStartDate.substring(0, 2);
                                  // var mm = testStartDate.toString().slice(-2);
                                  // let maintime = hh + ':' + mm;
                                  let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                  let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                  if (!mainTimeIsValid) {
                                    // const errorArr = {
                                    //   start_time: 'Please Enter a valid time'
                                    // };
                                    // if (daysError && daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[index]['start_time'] = 'Please Enter a valid time';
                                    // }
                                    const error = [...daysError];
                                    error[index] = { start_time: 'Please Enter a valid time' };
                                    setDaysError(error);
                                    // maintime = hh && mm ? `${hh}:${mm}` : '';
                                    let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                    changedTime('start_time', index, tempDate);
                                    changedTime('start_time_t', index, tempDate);
                                  } else {
                                    // const errorArr = {
                                    //   start_time: ''
                                    // };
                                    // if (daysError && daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[index]['start_time'] = '';
                                    // }
                                    const error = [...daysError];
                                    error[index] = { start_time: '' };
                                    setDaysError(error);
                                    changedTime('start_time', index, maintime);
                                    changedTime('start_time_t', index, maintime);
                                  }
                                }}
                                onChange={(event) => {
                                  // setDaysError([]);
                                  // const re = /^[0-9\b]+$/;
                                  // if (re.test(event.target.value)) {
                                  //   setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                  //   changedTime('start_time', index, event?.target?.value);
                                  //   changedTime('start_time_t', index, event?.target?.value);
                                  // }
                                  if (event.target.value !== '') {
                                    setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                    changedTime('start_time', index, event?.target?.value);
                                    changedTime('start_time_t', index, event?.target?.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="half-half-fitem half-fitem last">
                              <select
                                value={item?.startAMPM}
                                onChange={(e) => changedTime('startAMPM', index, e.target.value)}>
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value={'AM'}>AM</option>
                                <option value={'PM'}>PM</option>
                              </select>
                            </div>
                          </div>
                          {daysError[index]?.start_time ? (
                            <label className="error">{daysError[index]?.start_time}</label>
                          ) : (
                            ''
                          )}
                          {daysError[index]?.startAMPM ? (
                            <label className="error">{daysError[index]?.startAMPM}</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div
                          className={
                            daysError[index]?.end_time
                              ? 'fitem half-fitem has-error maxw-276 d-block'
                              : 'fitem half-fitem maxw-276 d-block'
                          }>
                          <div className="flex">
                            <div className="half-half-fitem half-fitem">
                              <input
                                type="text"
                                placeholder={'Add Time'}
                                value={item?.end_time_t}
                                onKeyUp={(event) => {
                                  if (event.code === 'Backspace') {
                                    changedTime('end_time', index, '');
                                    changedTime('end_time_t', index, '');
                                    // setState((s) => ({ ...s, testStartDate: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  const testStartDate = item?.end_time_t;
                                  if (testStartDate) {
                                    // var hh = testStartDate.substring(0, 2);
                                    // var mm = testStartDate.toString().slice(-2);
                                    // let maintime = hh + ':' + mm;
                                    let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                    let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                    if (!mainTimeIsValid) {
                                      // const errorArr = {
                                      //   end_time: 'Please Enter a valid time'
                                      // };
                                      // if (daysError && daysError.length === 0) {
                                      //   daysError.push(errorArr);
                                      // } else {
                                      //   daysError[index]['end_time'] = 'Please Enter a valid time';
                                      // }
                                      const error = [...daysError];
                                      error[index] = { end_time: 'Please Enter a valid time' };
                                      setDaysError(error);

                                      // maintime = hh && mm ? `${hh}:${mm}` : '';
                                      let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                      changedTime('end_time', index, tempDate);
                                      changedTime('end_time_t', index, tempDate);
                                    } else {
                                      // const errorArr = {
                                      //   end_time: ''
                                      // };
                                      // if (daysError && daysError.length === 0) {
                                      //   daysError.push(errorArr);
                                      // } else {
                                      //   daysError[index]['end_time'] = '';
                                      // }
                                      const error = [...daysError];
                                      error[index] = { end_time: '' };
                                      setDaysError(error);
                                      changedTime('end_time', index, maintime);
                                      changedTime('end_time_t', index, maintime);
                                    }
                                  }
                                }}
                                onChange={(event) => {
                                  // setDaysError([]);
                                  const re = /^[0-9\b]+$/;
                                  if (re.test(event.target.value)) {
                                    setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                    changedTime('end_time', index, event?.target?.value);
                                    changedTime('end_time_t', index, event?.target?.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="half-half-fitem half-fitem last">
                              <select
                                value={item?.endAMPM}
                                onChange={(e) => changedTime('endAMPM', index, e.target.value)}>
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value={'AM'}>AM</option>
                                <option value={'PM'}>PM</option>
                              </select>
                            </div>
                          </div>
                          {daysError[index]?.end_time ? (
                            <label className="error">{daysError[index]?.end_time}</label>
                          ) : (
                            ''
                          )}
                          {daysError[index]?.endAMPM ? (
                            <label className="error">{daysError[index]?.endAMPM}</label>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {state?.data?.isSame && (
                  <>
                    <div className="fitem half-fitem fitem-mb-0">
                      <div className="fitem half-fitem">
                        <label className="required">Select Start Time</label>
                      </div>
                      <div className="fitem half-fitem maxw-328">
                        <label className="required">Select End Time</label>
                      </div>
                    </div>
                    <div className="fitem half-fitem ">
                      <div
                        className={
                          daysError[0]?.start_time
                            ? 'fitem half-fitem has-error maxw-328 ml-0 d-block'
                            : 'fitem half-fitem maxw-328 ml-0 d-block'
                        }>
                        <div className="flex">
                          <div className="half-half-fitem half-fitem">
                            <input
                              type="text"
                              placeholder={'Add Time'}
                              value={state?.data?.days[0]?.start_time_t || ''}
                              onKeyUp={(event) => {
                                if (event.code === 'Backspace') {
                                  changedTime('start_time', 0, '');
                                  changedTime('start_time_t', 0, '');
                                  setState((s) => ({ ...s, testStartDate: '' }));
                                }
                              }}
                              onBlur={() => {
                                const testStartDate = state?.data?.days[0]?.start_time_t || '';
                                // var hh = testStartDate.substring(0, 2);
                                // var mm = testStartDate.toString().slice(-2);
                                // let maintime = hh + ':' + mm;
                                let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                // maintime = maintime === 'Invalid date' ? '' : maintime;
                                if (!mainTimeIsValid) {
                                  // const errorArr = {
                                  //   start_time: 'Please Enter a valid time'
                                  // };
                                  // if (daysError.length === 0) {
                                  //   daysError.push(errorArr);
                                  // } else {
                                  //   daysError[0]['start_time'] = 'Please Enter a valid time';
                                  // }
                                  // setDaysError(daysError);
                                  const error = [...daysError];
                                  error[0] = { start_time: 'Please Enter a valid time' };
                                  setDaysError(error);
                                  // maintime = hh && mm ? `${hh}:${mm}` : '';
                                  let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                  changedTime('start_time', 0, tempDate);
                                  changedTime('start_time_t', 0, tempDate);
                                } else {
                                  // const errorArr = {
                                  //   start_time: ''
                                  // };
                                  // if (daysError && daysError.length === 0) {
                                  //   daysError.push(errorArr);
                                  // } else {
                                  //   daysError[0]['start_time'] = '';
                                  // }
                                  // setDaysError(daysError);
                                  const error = [...daysError];
                                  error[0] = { start_time: '' };
                                  setDaysError(error);
                                  changedTime('start_time', 0, maintime);
                                  changedTime('start_time_t', 0, maintime);
                                }
                              }}
                              onChange={(event) => {
                                // const re = /^[0-9\b]+$/;
                                // if (re.test(event.target.value)) {
                                //   setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                //   changedTime('start_time', 0, event?.target?.value);
                                //   changedTime('start_time_t', 0, event?.target?.value);
                                // }
                                if (event.target.value !== '') {
                                  setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                  changedTime('start_time', 0, event?.target?.value);
                                  changedTime('start_time_t', 0, event?.target?.value);
                                }
                              }}
                            />
                          </div>
                          <div className="half-half-fitem half-fitem ml-10">
                            <select
                              value={state?.data?.days[0]?.startAMPM}
                              onChange={(e) => changedTime('startAMPM', 0, e.target.value)}>
                              <option value="" disabled>
                                Select
                              </option>
                              <option value={'AM'}>AM</option>
                              <option value={'PM'}>PM</option>
                            </select>
                          </div>
                        </div>
                        {daysError[0]?.start_time ? <label className="error">{daysError[0]?.start_time}</label> : ''}
                        {daysError[0]?.startAMPM ? <label className="error">{daysError[0]?.startAMPM}</label> : ''}
                      </div>
                      <div
                        className={
                          daysError[0]?.end_time
                            ? 'fitem half-fitem has-error maxw-328 ml-auto d-block'
                            : 'fitem half-fitem maxw-328 ml-auto d-block'
                        }>
                        <div className="flex">
                          <div className="half-half-fitem half-fitem">
                            <input
                              type="text"
                              placeholder={'Add Time'}
                              value={state?.data?.days[0]?.end_time_t || ''}
                              onKeyUp={(event) => {
                                if (event.code === 'Backspace') {
                                  changedTime('end_time', 0, '');
                                  changedTime('end_time_t', 0, '');
                                  setState((s) => ({ ...s, testEndDate: '' }));
                                }
                              }}
                              onBlur={() => {
                                const testEndDate = state?.data?.days[0]?.end_time_t || '';
                                // var hh = testEndDate.substring(0, 2);
                                // var mm = testEndDate.toString().slice(-2);
                                // let maintime = hh + ':' + mm;
                                let maintime = moment(testEndDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                if (!mainTimeIsValid) {
                                  // const errorArr = {
                                  //   end_time: 'Please Enter a valid time'
                                  // };
                                  // if (daysError.length === 0) {
                                  //   daysError.push(errorArr);
                                  // } else {
                                  //   daysError[0]['end_time'] = 'Please Enter a valid time';
                                  // }
                                  // setDaysError(daysError);
                                  const error = [...daysError];
                                  error[0] = { end_time: 'Please Enter a valid time' };
                                  setDaysError(error);
                                  // maintime = hh && mm ? `${hh}:${mm}` : '';
                                  let tempDate = !mainTimeIsValid ? testEndDate : maintime;
                                  changedTime('end_time', 0, tempDate);
                                  changedTime('end_time_t', 0, tempDate);
                                } else {
                                  // const errorArr = {
                                  //   start_time: ''
                                  // };
                                  // if (daysError && daysError.length === 0) {
                                  //   daysError.push(errorArr);
                                  // } else {
                                  //   daysError[0]['start_time'] = '';
                                  // }
                                  // setDaysError(daysError);
                                  const error = [...daysError];
                                  error[0] = { end_time: '' };
                                  setDaysError(error);
                                  changedTime('end_time', 0, maintime);
                                  changedTime('end_time_t', 0, maintime);
                                }
                              }}
                              onChange={(event) => {
                                // const re = /^[0-9\b]+$/;
                                // if (re.test(event.target.value)) {
                                //   setState((s) => ({ ...s, testEndDate: event?.target?.value }));
                                //   changedTime('end_time', 0, event?.target?.value);
                                //   changedTime('end_time_t', 0, event?.target?.value);
                                // }
                                if (event.target.value !== '') {
                                  setState((s) => ({ ...s, testEndDate: event?.target?.value }));
                                  changedTime('end_time', 0, event?.target?.value);
                                  changedTime('end_time_t', 0, event?.target?.value);
                                }
                              }}
                            />
                          </div>
                          <div className="half-half-fitem half-fitem ml-10">
                            <select
                              value={state?.data?.days[0]?.endAMPM}
                              onChange={(e) => changedTime('endAMPM', 0, e.target.value)}>
                              <option value="" disabled>
                                Select
                              </option>
                              <option value={'AM'}>AM</option>
                              <option value={'PM'}>PM</option>
                            </select>
                          </div>
                        </div>
                        {daysError[0]?.end_time ? <label className="error">{daysError[0]?.end_time}</label> : ''}
                        {daysError[0]?.endAMPM ? <label className="error">{daysError[0]?.endAMPM}</label> : ''}
                      </div>
                    </div>
                  </>
                )}
              </form>
            </React.Fragment>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

function InstructionalEvent(props) {
  const {
    setVisibleLessonDrawer,
    value,
    getDetails,
    lesson,
    visibleEventDrawer,
    setVisibleEventDrawer,
    setLesson,
    isLessonEdit,
    setIsLessonEdit,
    page_number,
    setPage,
    lessonCount,
    SetLessonCount,
    id,
    dayChoice,
    setDayChoice,
    selectedValue,
    setSelectedValue,
    setValue,
    displayDate,
    SetDisplayDate,
    indexNumber,
    setIndexNumber
  } = props;
  const { state, setState, addLesson, loader, setLoader, confirm } = useContext(PageContext);
  const { CourseDetails } = state;
  const [isLessonConfirmeModalVisible, setIsLessonConfirmeModalVisible] = useState(false);
  const [totalEventDuration, setTotalEventDuration] = useState(0);
  // const [visibleEventDrawer, setVisibleEventDrawer] = useState(false);

  const cancelConfirmCreateEvent = () => {
    setIsLessonConfirmeModalVisible(false);
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-76>eachers to see each of their courses in a calendar</TARA-76>
   */
  const confirmLesson = () => {
    /**
     * AUTHOR: RITESH SOLANKI
     * <TARA-661>Individual course page | When user has not selected "DO not show this message again" and user click on
     * "confirm" button and again click on non-instructional day then it is not showing the modal</TARA-661>
     * Modify code to call api conditionally
     */
    const { is_display_create_lesson_plan_suggestion_popup } = state;
    if (!is_display_create_lesson_plan_suggestion_popup) {
      const params = {
        id: id,
        is_display_create_lesson_plan_suggestion_popup: is_display_create_lesson_plan_suggestion_popup
      };
      Promise.allSettled([confirm(params)])
        .then(() => {
          const { ConfirmCourse } = store.getState();
          if (ConfirmCourse?.data && Object.keys(ConfirmCourse?.data).length > 0) {
            createNewLesson();
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      createNewLesson();
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const createNewLesson = () => {
    setLoader(true);
    const arrCourseSchedule = CourseDetails?.course_schedule
      ? CourseDetails.course_schedule.filter(
          (x) =>
            moment.tz(x.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('YYYY-MM-DD') ===
            value.format('YYYY-MM-DD')
        )
      : [];
    const userObj = JSON.parse(localStorage.getItem('user'));
    if (arrCourseSchedule && arrCourseSchedule.length > 0) {
      const params = {
        user_fk: userObj['id'],
        course_schedule_fk: arrCourseSchedule[0].id,
        title: 'Lesson Plan Title',
        date: value.utc().format(),
        lesson_objective: '',
        is_draft: true,
        categories: [
          {
            category: 'bell_work',
            event_duration: 0,
            title: 'Bell Work',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'lecture',
            event_duration: 0,
            title: 'Lecture',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'student_discussion_groups',
            event_duration: 0,
            title: 'Student Discussion Groups',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          },
          {
            category: 'closing_activity',
            event_duration: 0,
            title: 'Closing Activity',
            description: '',
            teacher_doing: '',
            student_doing: '',
            formative_assessment: '',
            material_list: '',
            lesson_prep_notes: '',
            is_draft: true
          }
        ]
      };
      Promise.allSettled([addLesson(params)])
        .then(() => {
          const { AddLesson } = store.getState();
          if (AddLesson?.data && Object.keys(AddLesson?.data).length > 0) {
            getDetails();
            setLesson(AddLesson?.data);
            // setIsLessonConfirmeModalVisible(true);
            setIsLessonEdit(true);
            setVisibleLessonDrawer(false);
            cancelConfirmCreateEvent();
            setVisibleEventDrawer(true);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  return (
    <>
      {loader && <Loading />}
      <div>
        <div className={'title-cap'}>
          <h2>{state?.lesson_title || 'Lesson Plan Title'}</h2>
          <p>{`Instruction Day ${page_number} of ${CourseDetails?.total_course_schedule_count}`}</p>
        </div>
        <div className={'lesson-detail'}>
          <h5>Lesson Plan</h5>
          <p>{lesson?.lesson_objective || 'You haven’t planned this lesson yet.'}.</p>
          <Button
            className={'btn-brand'}
            onClick={() => {
              if (lesson?.id > 0) {
                setVisibleEventDrawer(true);
              } else {
                if (state?.is_display_create_lesson_plan_suggestion_popup) {
                  setIsLessonConfirmeModalVisible(true);
                } else {
                  createNewLesson();
                }
              }
            }}>
            Create a New Lesson
          </Button>
        </div>
      </div>

      {/* Lets Get Started Modal  */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isLessonConfirmeModalVisible}
        onCancel={() => {
          setState((s) => ({
            ...s,
            is_display_create_lesson_plan_suggestion_popup: true
          }));
          cancelConfirmCreateEvent();
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="event_delete_back"
            onClick={() => {
              setState((s) => ({
                ...s,
                is_display_create_lesson_plan_suggestion_popup: true
              }));
              cancelConfirmCreateEvent();
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="event_delete_delete"
            onClick={() => {
              confirmLesson();
              // setIsEventModalVisible(true);
            }}
            className="btn btn-brand">
            Let&apos;s Get Started
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Create a Lesson Plan</h1>
            <p className="lg-txt">
              Depending on your experience with the course content, set aside &nbsp;
              <i className="icon text-danger fa-solid fa-clock"></i>
              &nbsp; 15 - 60 minutes to plan each lesson.
            </p>
            <label>To speed up the process, have the following information nearby:</label>
          </div>
          <ul className="list-item warn-list">
            <li>
              <i data-icon="k" className="icon"></i>
              Course curriculum or link to publisher site
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              Physical or digital resources you plan to use
            </li>
            <li>
              <i data-icon="k" className="icon"></i>
              School calendar to reference days you are/are not teaching
            </li>
          </ul>
          <div className="fitem-check">
            <input
              className="fitem-ck-input"
              id="showmsg"
              type="checkbox"
              checked={!state?.is_display_create_lesson_plan_suggestion_popup}
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  is_display_create_lesson_plan_suggestion_popup: !e.target.checked
                }));
              }}
            />
            <label className="fitem-ck-txt" htmlFor="showmsg">
              Do not show this message again.
            </label>
          </div>
        </div>
      </Modal>
      {/* Lets Get Started Modal ends  */}

      {/* Instructonal Event Drawer  */}
      <Drawer
        className={'full-drawer ' + (!isLessonEdit ? 'fl-lesson-drawer' : '')}
        title={''}
        visible={visibleEventDrawer}
        width={'100%'}
        closable={false}
        // placement="left"
        onClose={() => {
          setVisibleEventDrawer(false);
          SetDisplayDate(null);
        }}>
        <DisplayInstrucionalEvent
          getDetails={getDetails}
          setVisibleEventDrawer={setVisibleEventDrawer}
          value={value}
          lesson={lesson}
          setLesson={setLesson}
          totalEventDuration={totalEventDuration}
          setTotalEventDuration={setTotalEventDuration}
          isLessonEdit={isLessonEdit}
          setIsLessonEdit={setIsLessonEdit}
          page_number={page_number}
          setPage={setPage}
          lessonCount={lessonCount}
          SetLessonCount={SetLessonCount}
          id={id}
          dayChoice={dayChoice}
          setDayChoice={setDayChoice}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          setValue={setValue}
          displayDate={displayDate}
          SetDisplayDate={SetDisplayDate}
          setVisibleLessonDrawer={setVisibleLessonDrawer}
          indexNumber={indexNumber}
          setIndexNumber={setIndexNumber}
        />
      </Drawer>
    </>
  );
}

function DisplayInstrucionalEvent(props) {
  const {
    state,
    setState,
    loader,
    setLoader,
    addInstructionalEvent,
    updateInstructionalEvent,
    deleteInstructionalEvent,
    getInstructionalEventCategories,
    // Lesson,
    getInstructionalEvents,
    getInstructionalEventById,
    // confirmInstructionalEvent,
    updateLesson,
    getCourseLessonById,
    getCourseLessons
  } = useContext(PageContext);
  const {
    setVisibleEventDrawer,
    value,
    lesson,
    setLesson,
    totalEventDuration,
    setTotalEventDuration,
    isLessonEdit,
    setIsLessonEdit,
    getDetails,
    page_number,
    setPage,
    // lessonCount,
    SetLessonCount,
    id,
    dayChoice,
    displayDate,
    SetDisplayDate,
    setVisibleLessonDrawer,
    indexNumber,
    setIndexNumber,
    setValue,
    selectedValue,
    setSelectedValue
  } = props;
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isEventDeleteModalVisible, setIsEventDeleteModalVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState(0);
  const [eventHeading, setEventHeading] = useState(0);
  // const [totalEventDuration, setTotalEventDuration] = useState(0);
  const [isOvertimeModalVisible, setIsOvertimeModalVisible] = useState(false);
  const [isAutoSaving, SetIsAutoSaving] = useState(false);
  const [isLessonSaved, SetIsLessonSaved] = useState(false);
  // const [confirmEventId, setConfirmEventId] = useState(0);
  // const [indexNumber, setIndexNumber] = useState(1);
  // const editorRef = useRef(null);

  const {
    category = '',
    event_duration = '',
    eventTitle = '',
    description = '',
    teacher_doing = '',
    student_doing = '',
    formative_assessment = '',
    material_list = '',
    lesson_prep_notes = '',
    formValid = false,
    errors
  } = state;

  /**
   * AUTHOR: RITESH SOLANKI
   */
  useEffect(() => {
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getInstructionalEventCategories()]).then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { InstructionalEventCategories } = store.getState();
          if (InstructionalEventCategories?.data && Object.keys(InstructionalEventCategories?.data).length > 0) {
            setEvents(InstructionalEventCategories?.data?.categories);
          }
        }
      });
    }
  }, []);

  /**
   * AUTHOR: RITESH SOLANKI
   */
  useEffect(() => {
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getAllEvents()]).then(() => {});
    }
  }, [lesson]);

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  useEffect(() => {
    getTotalTime();
  }, [dayChoice]);

  useEffect(() => {
    getTotalTime();
  }, [dayChoice]);

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const getAllEvents = (isDisplayedCongratsOrOverTime = false) => {
    Promise.allSettled([getInstructionalEvents({ lesson_fk: lesson?.id }), getCourseLessonById(lesson?.id)]).then(
      (result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { GetEvents, Lesson } = store.getState();
          // let most_recent = GetEvents?.data?.results.reduce((mostRecent, item) =>
          //   item.updated_timestamp > mostRecent.updated_timestamp ? item : mostRecent
          // );
          let dataIdx = [];
          dataIdx = GetEvents?.data?.results.filter((d) => d.is_draft === false);
          let totalEventDuration = 0;
          if (GetEvents?.data && Object.keys(GetEvents?.data).length > 0) {
            let allEvent = [];
            GetEvents?.data?.results.map((item) => {
              totalEventDuration += +item?.event_duration;
              item['is_expand'] = dataIdx?.length > 0 && item?.id === dataIdx?.[0]?.id ? true : false;
              item['expand_menu'] = false;
              allEvent.push(item);
            });
            setTotalEventDuration(totalEventDuration);
            if (totalEventDuration === state.hours && isDisplayedCongratsOrOverTime) {
              SetIsLessonSaved(true);
              const displayTimeOut = setTimeout(() => {
                SetIsLessonSaved(false);
                clearTimeout(displayTimeOut);
              }, 2000);
            } else {
              if (totalEventDuration > state.hours && isDisplayedCongratsOrOverTime) {
                if (Lesson?.data?.is_display_lesson_over_class_time_popup) {
                  setIsOvertimeModalVisible(true);
                }
              }
            }

            //Set All Event in State
            setState((s) => ({
              ...s,
              events: allEvent
            }));
          }
        }
      }
    );
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const confirmEvent = () => {
    const { is_display_over_class_time_popup } = state;
    if (!is_display_over_class_time_popup) {
      setLoader(true);
      const params = {
        id: lesson?.id,
        is_display_lesson_over_class_time_popup: is_display_over_class_time_popup
      };
      Promise.allSettled([updateLesson(params)])
        .then(() => {
          setIsOvertimeModalVisible(false);
          setLoader(false);
        })
        .catch(() => {
          setIsOvertimeModalVisible(false);
          setLoader(false);
        });
    } else {
      setIsOvertimeModalVisible(false);
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * @param {*} id Instructional Event Id
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const getEventById = (id) => {
    setEventId(id);
    // setConfirmEventId(id);
    setLoader(true);
    Promise.allSettled([getInstructionalEventById(id)]).then((result) => {
      const isFulfilled = result.filter((x) => x.status === 'fulfilled');
      if (isFulfilled?.length > 0) {
        const { GetEvent } = store.getState();
        if (GetEvent?.data && Object.keys(GetEvent?.data).length > 0) {
          setState((s) => ({
            ...s,
            category: GetEvent?.data?.category,
            event_duration: GetEvent?.data?.event_duration,
            eventTitle: GetEvent?.data?.title,
            description: GetEvent?.data?.description,
            teacher_doing: GetEvent?.data?.teacher_doing,
            student_doing: GetEvent?.data?.student_doing,
            formative_assessment: GetEvent?.data?.formative_assessment,
            material_list: GetEvent?.data?.material_list,
            lesson_prep_notes: GetEvent?.data?.lesson_prep_notes,
            formValid:
              GetEvent?.data?.event_duration !== '' &&
              GetEvent?.data?.teacher_doing !== '' &&
              GetEvent?.data?.student_doing !== ''
                ? true
                : false,
            is_display_over_class_time_popup: GetEvent?.data?.is_display_over_class_time_popup,
            errors: {}
          }));
        }
        setLoader(false);
        setIsEventModalVisible(true);
      }
    });
  };

  const isValidInteger = (value) => {
    if (+value === 0 || value === '') {
      return 'Duration is mandatory';
    } else if (
      value.toString().indexOf('-') !== -1 ||
      value.toString().indexOf('+') !== -1 ||
      value.toString().indexOf('.') !== -1 ||
      value >= 1 === false
    ) {
      return 'A valid integer is required';
    } else {
      return '';
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const validate = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case 'category':
        errors.category = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
      case 'event_duration':
        errors.event_duration = isValidInteger(value);
        break;
      case 'teacher_doing':
        errors.teacher_doing = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
      case 'student_doing':
        errors.student_doing = value === '' || value.length === 0 ? 'This field is required!' : '';
        break;
    }
    setState((s) => ({
      ...s,
      formValid:
        category && event_duration && isValidInteger(event_duration) === '' && teacher_doing && student_doing
          ? true
          : false
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const clear = () => {
    setState((s) => ({
      ...s,
      category: '',
      event_duration: '',
      eventTitle: '',
      description: '',
      teacher_doing: '',
      student_doing: '',
      formative_assessment: '',
      material_list: '',
      lesson_prep_notes: '',
      errors: {},
      formValid: false
    }));
    setEventId(0);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const createEvent = () => {
    if (isValidInteger(event_duration) !== '') {
      setState((s) => ({
        ...s,
        errors: isValidInteger(event_duration)
      }));
    } else {
      setState((s) => ({
        ...s,
        errors: {}
      }));
      setLoader(true);
      const params = {
        category: category,
        event_duration: event_duration,
        title: eventTitle,
        description: description,
        teacher_doing: teacher_doing,
        student_doing: student_doing,
        formative_assessment: formative_assessment,
        material_list: material_list,
        lesson_prep_notes: lesson_prep_notes,
        lesson_fk: lesson?.id,
        is_draft: false
      };
      Promise.allSettled([addInstructionalEvent(params)])
        .then(() => {
          const { AddInstructionalEvent } = store.getState();
          if (AddInstructionalEvent?.data && Object.keys(AddInstructionalEvent?.data).length > 0) {
            clear();
            getAllEvents(true);
            setIsEventModalVisible(false);
          } else {
            clear();
            getAllEvents(true);
            setIsEventModalVisible(false);
          }
          setEventId(0);
          setLoader(false);
        })
        .catch(() => {
          setIsEventModalVisible(false);
          setEventId(0);
          setLoader(false);
        });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-113>AAbility for teachers to add/remove instructional event cards</TARA-113>
   */
  const updateEvent = () => {
    if (isValidInteger(event_duration) !== '') {
      setState((s) => ({
        ...s,
        errors: isValidInteger(event_duration)
      }));
    } else {
      setState((s) => ({
        ...s,
        errors: {}
      }));
      setIsEventModalVisible(false);
      SetIsAutoSaving(true);
      const params = {
        id: eventId,
        category: category,
        event_duration: event_duration,
        title: eventTitle,
        description: description,
        teacher_doing: teacher_doing,
        student_doing: student_doing,
        formative_assessment: formative_assessment,
        material_list: material_list,
        lesson_prep_notes: lesson_prep_notes,
        is_draft: false
        // lesson_fk: Lesson?.data?.id
      };
      Promise.allSettled([updateInstructionalEvent(params)])
        .then(() => {
          const { UpdateInstructionalEvent } = store.getState();
          if (UpdateInstructionalEvent?.data && Object.keys(UpdateInstructionalEvent?.data).length > 0) {
            clear();
            setIsEventModalVisible(false);
            const autoSavedTimeOut = setTimeout(() => {
              SetIsAutoSaving(false);
              getAllEvents(true);
              clearTimeout(autoSavedTimeOut);
            }, 1500);
          } else {
            clear();
            setIsEventModalVisible(false);
            const autoSavedTimeOut = setTimeout(() => {
              SetIsAutoSaving(false);
              getAllEvents(true);
              clearTimeout(autoSavedTimeOut);
            }, 1500);
          }
          setEventId(0);
          setLoader(false);
        })
        .catch(() => {
          setIsEventModalVisible(false);
          setEventId(0);
          setLoader(false);
          const autoSavedTimeOut = setTimeout(() => {
            SetIsAutoSaving(false);
            getAllEvents(true);
            clearTimeout(autoSavedTimeOut);
          }, 1500);
        });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-113>AAbility for teachers to add/remove instructional event cards</TARA-113>
   */
  const deleteEvent = () => {
    setLoader(true);
    Promise.allSettled([deleteInstructionalEvent(eventId)])
      .then(() => {
        const { DeleteInstructionalEvent } = store.getState();
        if (DeleteInstructionalEvent?.data && Object.keys(DeleteInstructionalEvent?.data).length > 0) {
          getAllEvents();
          setIsEventDeleteModalVisible(false);
        }
        setEventId(0);
        setLoader(false);
      })
      .catch(() => {
        setIsEventDeleteModalVisible(false);
        setEventId(0);
        setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} item Item Object
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const expandCollapseEvent = (item, isExpand) => {
    const { events } = state;
    events.map((obj) => {
      if (obj.id === item.id) {
        obj['is_expand'] = isExpand;
      }
    });

    //Set All Event in State
    setState((s) => ({
      ...s,
      events: events
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} item Item Object
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  const expandMenu = (item, isExpand) => {
    const { events } = state;
    events.map((obj) => {
      if (obj.id === item.id) {
        obj['expand_menu'] = !isExpand;
      }
    });

    //Set All Event in State
    setState((s) => ({
      ...s,
      events: events
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   */
  // const onChange = (e) => {
  //   setState((s) => ({ ...s, description: e.target.getContent() }));
  // };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const getTotalTime = () => {
    const { CourseDetails } = state;
    if (
      CourseDetails &&
      Object.keys(CourseDetails).length > 0 &&
      CourseDetails.course_schedule &&
      CourseDetails.course_schedule.length > 0
    ) {
      map(CourseDetails.course_schedule, (obj) => {
        /**
         * AUTHOR: Ritesh Solanki
         * Added If block to Fix <TARA-644></TARA-644>
         */
        if (dayChoice === obj.day_choice) {
          const startTime = moment(obj.start_time, 'HH:mm:ss a');
          const endTime = moment(obj.end_time, 'HH:mm:ss a');
          const duration = moment.duration(endTime.diff(startTime));
          const hours = parseInt(duration.asMinutes());
          setState((s) => ({
            ...s,
            hours: hours
          }));
        }
      });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @returns
   * <TARA-114>Ability for teachers to see allocated class time vs total class time available</TARA-114>
   */
  const showProgressbar = () => {
    const percentage = (totalEventDuration * 100) / state?.hours;
    return percentage;
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-619>New Instruction Event | When user create one instructional event and try to
   * create another then user has not added any value but still "Add to my lesson" button enabled
   * </TARA-619>
   */
  const handleCategoryChange = (event) => {
    const title = events.filter((x) => x.key === event?.target?.value);
    setState((s) => ({
      ...s,
      category: event?.target?.value,
      eventTitle: title && title.length > 0 ? title[0]?.value : '',
      formValid:
        category && event_duration && +event_duration >= 1 && Number.isInteger(+value) && teacher_doing && student_doing
          ? true
          : false
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   * Functoin used to load last lesson details
   */
  const lastLesson = () => {
    const { CourseSchedule } = store.getState();
    let idx = +indexNumber - 1;
    if (idx >= 0) {
      let lesson = CourseSchedule?.data?.results[idx];
      /**
       * AUTHOR: Ritesh Solanki
       * <TARA-745>Individual course page | View course plan | User is not able to view instructional day which is
       * in another month using the "Next lesson" and "last lesson" button</TARA-745>
       */
      let nextMonth = moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMYYYY');
      let currentMonth = moment(selectedValue).format('MMYYYY');
      if (nextMonth < currentMonth) {
        setSelectedValue(moment(selectedValue).add(-1, 'M'));
        setValue(moment(selectedValue).add(-1, 'M'));
      }
      if (lesson?.course_lessons?.some((item) => item.is_draft === false)) {
        const LessonItemChanged = lesson?.course_lessons?.find((item) => item.is_draft === false);
        const params = {
          course_id: id,
          limit: 1,
          page_size: 1,
          page: LessonItemChanged?.lesson_page
          // month: moment(selectedValue).format('MM'),
          // year: moment(selectedValue).format('YYYY')
        };
        setPage(lesson?.page_number);
        setIndexNumber(idx);
        Promise.allSettled([getCourseLessons(params)]).then((result) => {
          const isFulfilled = result.filter((x) => x.status === 'fulfilled');
          if (isFulfilled?.length > 0) {
            const { CourseLessons } = store.getState();
            setLesson(CourseLessons?.data?.results[0]);
            SetLessonCount(CourseLessons?.data?.count);
            /**
             * AUTHOR: Ritesh Solanki
             * <TARA-668>View Lesson Plan | When user click on "Last session" and "Next session"
             * it is showing update data but on the title is not changing the date</TARA-668>
             * Modify code to display date
             */
            SetDisplayDate(moment(LessonItemChanged?.date || value));
          }
        });
      } else {
        setPage(lesson?.page_number);
        setValue(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson({});
        /**
         * AUTHOR: Ritesh SOlanki
         * <TARA-691>Individual course page | View course plan | After 3-4 course user is not able to go to "next lesson"</TARA-691>
         * Added block of code to clear lesson object.
         * CHANGED: 05\10\2022 03:00 PM
         */
        setTimeout(() => {}, 500);
        setState((s) => ({
          ...s,
          title: '',
          lesson_objective: '',
          lesson_title: ''
        }));
        setVisibleEventDrawer(false);
        setVisibleLessonDrawer(true);
      }
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson plan</TARA-111>
   * Function used to load next lesson details
   */
  const nextLesson = () => {
    const { CourseSchedule } = store.getState();
    let idx = indexNumber + 1;
    // let page = +page_number + 1;
    // let page = 1;
    if (parseInt(page_number) < parseInt(state?.total_course_schedule_count)) {
      let lesson = CourseSchedule?.data?.results[idx];

      /**
       * AUTHOR: Ritesh Solanki
       * <TARA-745>Individual course page | View course plan | User is not able to view instructional day which is
       * in another month using the "Next lesson" and "last lesson" button</TARA-745>
       */
      let nextMonth = moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone).format('MMYYYY');
      let currentMonth = moment(selectedValue).format('MMYYYY');
      if (nextMonth > currentMonth) {
        setSelectedValue(moment(selectedValue).add(1, 'M'));
        setValue(moment(selectedValue).add(1, 'M'));
      }
      // console.log('lesson', lesson);
      if (lesson?.course_lessons?.some((item) => item.is_draft === false)) {
        const LessonItemChanged = lesson?.course_lessons?.find((item) => item.is_draft === false);
        const params = {
          course_id: id,
          limit: 1,
          page_size: 1,
          page: LessonItemChanged?.lesson_page
          // month: moment(selectedValue).format('MM'),
          // year: moment(selectedValue).format('YYYY')
        };
        setPage(lesson?.page_number);
        setIndexNumber(idx);

        Promise.allSettled([getCourseLessons(params)]).then((result) => {
          const isFulfilled = result.filter((x) => x.status === 'fulfilled');
          if (isFulfilled?.length > 0) {
            const { CourseLessons } = store.getState();
            setLesson(CourseLessons?.data?.results[0]);
            SetLessonCount(CourseLessons?.data?.count);
            /**
             * AUTHOR: Ritesh Solanki
             * <TARA-668>View Lesson Plan | When user click on "Last session" and "Next session"
             * it is showing update data but on the title is not changing the date</TARA-668>
             * Modify code to display date
             */
            SetDisplayDate(moment(LessonItemChanged?.date || value));
          }
        });
      } else {
        // console.log('Else...');
        setPage(lesson?.page_number);
        setValue(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        SetDisplayDate(moment.tz(lesson?.schedule_date, 'YYYY-MM-DD', state?.selectedTimeZone));
        setLesson({});
        /**
         * AUTHOR: Ritesh SOlanki
         * <TARA-691>Individual course page | View course plan | After 3-4 course user is not able to go to "next lesson"</TARA-691>
         * Added block of code to clear lesson object.
         * CHANGED: 05\10\2022 03:00 PM
         */
        setTimeout(() => {
          setState((s) => ({
            ...s,
            title: '',
            lesson_objective: '',
            lesson_title: ''
          }));
        }, 500);

        setVisibleEventDrawer(false);
        setVisibleLessonDrawer(true);
      }
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-701>Add/Edit instructional event | When user has added any link in fields
   * then after saving event it should work and behave like a link</TARA-701>
   */
  // const isValidUrl = (url) => {
  //   const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  //   return regex.test(url);
  // };
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      return '<a href="' + url + '" target="_blank" rel="noreferrer">' + url + '</a>';
    });
  };

  return (
    <>
      {loader && <Loading />}
      <div className="fd-header flex">
        {isLessonEdit && (
          <React.Fragment>
            <div className="left-caption">
              <Link
                to={'#'}
                as={ReactRouterLink}
                className="btn-txt back-btn"
                onClick={() => {
                  getDetails();
                  setVisibleEventDrawer(false);
                  setIsLessonEdit(false);
                  setLesson({});
                }}>
                <i className="icon icon-prev"></i>
                Back to Course View
              </Link>
            </div>
            <h1>Create Lesson Plan</h1>
            <div className="right-caption">
              <p className="date-block">
                {displayDate ? displayDate.format('dddd, MMM DD, YYYY') : value?.format('dddd, MMM DD, YYYY')}
              </p>
            </div>
          </React.Fragment>
        )}
        {!isLessonEdit && (
          <React.Fragment>
            <div className="left-caption">
              <p className="date-title">
                {displayDate ? displayDate.format('dddd, MMM DD, YYYY') : value?.format('dddd, MMM DD, YYYY')}
              </p>
            </div>
            <div className="right-caption">
              <div className="btn-group">
                <a
                  href="javascript:void(0)"
                  className={'btn btn-bdr ' + (indexNumber === 0 ? 'disabled' : '')}
                  onClick={() => {
                    lastLesson();
                  }}>
                  <i className="icon icon-prev"></i>
                  <span>Last Lesson</span>
                </a>
                <a
                  href="javascript:void(0)"
                  className={
                    'btn btn-bdr ' +
                    (parseInt(indexNumber + 1) === parseInt(state?.CourseDetails?.course_schedule?.length)
                      ? 'disabled'
                      : '')
                  }
                  onClick={() => {
                    nextLesson();
                  }}>
                  <span>Next Lesson</span>
                  <i className="icon icon-next"></i>
                </a>
              </div>
              <span className="ant-closedr">
                <i
                  className="icon icon-add"
                  aria-hidden="true"
                  onClick={() => {
                    setState((s) => ({ ...s, page: 1 }));
                    setVisibleEventDrawer(false);
                  }}></i>
              </span>
              {/* <div className="icon icon-add"></div> */}
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="fd-body">
        <LessonDetails
          lesson={lesson}
          totalEventDuration={totalEventDuration}
          isLessonEdit={isLessonEdit}
          page_number={page_number}
          setPage={setPage}
        />
        <div className="fd-right-wrap">
          <div className="title-row flex">
            <div className="left-cap">
              <h4> {!isLessonEdit ? 'Lesson Plan' : 'Instructional Events'}</h4>
              {isLessonEdit && (
                <button
                  type="button"
                  className="btn btn-brand"
                  disabled={totalEventDuration >= state?.hours}
                  onClick={() => {
                    clear();
                    setIsEventModalVisible(true);
                  }}>
                  <i className={'icon fa-solid fa-circle-plus'}></i>
                  Add Instructional Event
                </button>
              )}
            </div>
            {!isLessonEdit && (
              <div className="right-btn">
                <Link
                  to={'#'}
                  as={ReactRouterLink}
                  className="btn btn-bdr"
                  onClick={() => {
                    setIsLessonEdit(true);
                  }}>
                  <i className="icon icon-edits font-size-20 mr-8"></i>
                  Edit Lesson Details
                </Link>
              </div>
            )}
            {isLessonEdit && (
              <React.Fragment>
                {totalEventDuration <= state?.hours && (
                  <div className="right-cap">
                    <div className="progress-wrap">
                      <p className="progress-plan">
                        <b>{totalEventDuration}</b> minutes planned
                      </p>
                      <div className="progress-bar">
                        <span style={{ width: `${showProgressbar()}%` }}></span>
                      </div>
                      <p className="progress-time">
                        <span>Total Time</span>
                        <strong>{state?.hours} min</strong>
                      </p>
                    </div>
                  </div>
                )}
                {totalEventDuration > state?.hours && (
                  <div className="right-cap warn-card">
                    <div className="progress-wrap">
                      <p className="progress-plan">
                        <img src={overtime} />
                        Over Time Limit
                      </p>
                      <div className="progress-bar">
                        <span style={{ width: `${showProgressbar()}%` }}></span>
                      </div>
                      <p className="progress-time">
                        <span>Time Exceeded by </span>
                        <strong>{totalEventDuration - state?.hours}min</strong>
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <p className="suggetion-out">
            <strong>Suggested outline for standard courses.</strong> Edit these instructional event placeholders or add
            your own.
          </p>
          <div className="event-list">
            {state?.events &&
              state?.events.map((item) => {
                if (!item.is_draft || isLessonEdit) {
                  return (
                    <div
                      key={item?.id}
                      className={!item.is_draft ? 'event-card event-word-card' : 'event-card light-card'}>
                      <div className="event-title">
                        <h6>{item?.title}</h6>
                        <div className="right-cap flex align-items-center">
                          <label className={'time ' + (isLessonEdit && item.event_duration > 0 ? 'right-bar' : '')}>
                            <i
                              className={
                                'icon fa-solid fa-clock ' + (item?.is_expand ? 'text-danger' : 'text-secondary')
                              }></i>
                            {item?.event_duration} min
                          </label>
                          {!item.is_draft && isLessonEdit && (
                            <div className="event-optn custom-dropdown">
                              {isLessonEdit && (
                                <Link
                                  to={'#'}
                                  as={ReactRouterLink}
                                  className={item.expand_menu ? 'ellipsis-btn show' : 'ellipsis-btn'}>
                                  <i
                                    className="fa-solid fa-ellipsis"
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                    }}></i>
                                </Link>
                              )}
                              <ul
                                className="cdropdown-menu custom-dropdown"
                                style={{ display: item.expand_menu ? 'block' : 'none' }}>
                                <li>
                                  <Link
                                    to={'#'}
                                    as={ReactRouterLink}
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                      getEventById(item.id);
                                    }}>
                                    <i className="icon icon-edits icons-lg"></i>
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={'#'}
                                    as={ReactRouterLink}
                                    onClick={() => {
                                      expandMenu(item, item.expand_menu);
                                      setEventId(item.id);
                                      setEventHeading(item.title);
                                      setIsEventDeleteModalVisible(true);
                                    }}>
                                    <i className="icon fa-solid fa-trash"></i>
                                    Delete
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="event-body">
                        {item.is_draft && (
                          <React.Fragment>
                            <div className="caption-card">
                              <p>
                                This is a placeholder instructional activity. Edit this card or add details to
                                customize.
                                <Link
                                  to={'#'}
                                  as={ReactRouterLink}
                                  className=" btn-txt"
                                  onClick={() => {
                                    expandCollapseEvent(item, true);
                                  }}>
                                  Links you add will show in the lesson outline
                                </Link>
                              </p>
                            </div>

                            <div className="btn-group">
                              <button
                                type="button"
                                className=" btn-txt"
                                onClick={() => {
                                  setEventId(item.id);
                                  setEventHeading(item.title);
                                  setIsEventDeleteModalVisible(true);
                                }}>
                                <i className="icon fa-solid fa-trash"></i>
                                Delete Placeholder Event
                              </button>
                              <button
                                type="button"
                                className=" btn-txt"
                                onClick={() => {
                                  getEventById(item.id);
                                }}>
                                <i className="icon icon-edits icons-lg"></i>
                                Edit Event Details
                              </button>
                            </div>
                          </React.Fragment>
                        )}

                        {!item.is_draft && (
                          <React.Fragment>
                            <div className="yellow-block">
                              <p>
                                <strong>Teacher</strong>
                                <span>{item?.teacher_doing}</span>
                              </p>
                              <p>
                                <strong>Students</strong>
                                <span>{item?.student_doing}</span>
                              </p>
                            </div>
                            <div className="work-detail">
                              <div className="work-d-list flex justify-content-start">
                                <label className="mx-0">Details:</label>
                                <div
                                  className="mx-0 flex-fill"
                                  dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                              </div>
                              {item?.is_expand && (
                                <React.Fragment>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Formative Assessment:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.formative_assessment) }}
                                    />
                                    {/* {isValidUrl(item?.formative_assessment) && (
                                      <a target="_blank" rel="noreferrer" href={item?.formative_assessment}>
                                        {item?.formative_assessment}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.formative_assessment) && (
                                      <div className="mx-0 flex-fill">{item?.formative_assessment}</div>
                                    )} */}
                                  </div>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Materials:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.material_list) }}
                                    />
                                    {/* {isValidUrl(item?.material_list) && (
                                      <a target="_blank" rel="noreferrer" href={item?.material_list}>
                                        {item?.material_list}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.material_list) && (
                                      <div className="mx-0 flex-fill">{item?.material_list}</div>
                                    )} */}
                                  </div>
                                  <div className="work-d-list flex justify-content-start">
                                    <label className="mx-0">Prep Notes:</label>
                                    <div
                                      className="mx-0 flex-fill"
                                      dangerouslySetInnerHTML={{ __html: urlify(item?.lesson_prep_notes) }}
                                    />
                                    {/* {isValidUrl(item?.lesson_prep_notes) && (
                                      <a target="_blank" rel="noreferrer" href={item?.lesson_prep_notes}>
                                        {item?.lesson_prep_notes}
                                      </a>
                                    )}
                                    {!isValidUrl(item?.lesson_prep_notes) && (
                                      <div className="mx-0 flex-fill">{item?.lesson_prep_notes}</div>
                                    )} */}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      {item?.is_expand && !item.is_draft && (
                        <div className="event-footer">
                          <span
                            className="showmore-less open"
                            onClick={() => {
                              expandCollapseEvent(item, false);
                            }}>
                            View Less
                            <i className="fa-solid fa-angle-down"></i>
                          </span>
                        </div>
                      )}
                      {!item?.is_expand && !item.is_draft && (
                        <div className="event-footer">
                          <span
                            className="showmore-less open"
                            onClick={() => {
                              expandCollapseEvent(item, true);
                            }}>
                            View More
                            <i className="fa-solid fa-angle-up"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        className="instructional-popup"
        visible={isEventModalVisible}
        onCancel={() => {
          clear();
          setIsEventModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="event_back"
            onClick={() => {
              clear();
              setIsEventModalVisible(false);
            }}
            className="btn btn-gray btn-bdr">
            Cancel
          </Button>,
          <Button
            key="event_delete"
            className="btn btn-brand"
            onClick={eventId > 0 ? updateEvent : createEvent}
            disabled={!formValid}>
            {eventId > 0 ? 'Save Changes' : 'Add To My Lesson'}
          </Button>
        ]}>
        <div className="modal-head">
          <h1>{eventId > 0 ? 'Edit ' : 'New '}Instructional Event</h1>
          <p className="h4">Add details for this instructional event. You can edit this later in your lesson plan.</p>
          <div className="title-row">
            <form action="" className="form-group">
              <div className="half-fitem fitem mb-8">
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    Category
                  </label>
                  <select
                    name="category"
                    value={category}
                    onBlur={validate}
                    onChange={(event) => {
                      handleCategoryChange(event);
                    }}>
                    <option value="" disabled>
                      Select Category
                    </option>
                    {events.map((item, index) => (
                      <option key={index} value={item.key}>
                        {item?.value}
                      </option>
                    ))}
                  </select>
                  {errors['category'] ? <label className="error">{errors['category']}</label> : ''}
                </div>
                <div className={errors['event_duration'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label className="fw-normal required" htmlFor="">
                    Event Duration
                  </label>
                  <div className="flex minute-dura">
                    <input
                      name="event_duration"
                      type="text"
                      placeholder="00"
                      value={event_duration}
                      onBlur={validate}
                      onChange={(event) => {
                        if (event?.target?.value !== '') {
                          validate(event);
                        }
                        setState((s) => ({ ...s, event_duration: event?.target?.value }));
                      }}
                    />
                    <span>minutes</span>
                  </div>
                  {errors['event_duration'] ? <label className="error">{errors['event_duration']}</label> : ''}
                </div>
              </div>
              <i className="mb-24 d-block">Selecting custom will allow you to add a custom title below.</i>

              <div className="fitem">
                <label htmlFor="">Title (optional - default to category name)</label>
                <input
                  type="text"
                  placeholder="Enter a custom title"
                  value={eventTitle}
                  onKeyUp={() => {
                    setState((s) => ({ ...s, category: 'other' }));
                  }}
                  onChange={(event) => {
                    setState((s) => ({ ...s, eventTitle: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem">
                <label className="fw-normal" htmlFor="">
                  Description (optional)
                </label>
                <Editor
                  // onInit={(evt, editor) => {
                  //   editorRef.current = editor;
                  //   setState((s) => ({ ...s, description: editor }));
                  // }}
                  // content={props.section.contents || props.placeholder}
                  // onChange={onChange}
                  // initialValue={description}
                  apiKey="n1kconw5gil983ockkdvjt9a2bjubkcxgeurngipvvge021j"
                  onEditorChange={(newValue) => {
                    setState((s) => ({ ...s, description: newValue }));
                  }}
                  value={description}
                  init={{
                    height: 215,
                    menubar: false,
                    placeholder:
                      'Information about the instructional event. Includes links to youtube videos, documentation, your notes, etc.',
                    plugins: [
                      'advlist',
                      'autolink',
                      'lists',
                      'link',
                      'image',
                      'charmap',
                      'preview',
                      'anchor',
                      'searchreplace',
                      'visualblocks',
                      'fullscreen',
                      'insertdatetime',
                      'media',
                      'table',
                      'help',
                      'wordcount'
                    ],
                    toolbar:
                      'superscript subscript text bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | '
                  }}
                />
                {/* <textarea
                  placeholder="Information about the instructional event. Includes links to youtube videos, documentation, your notes, etc."
                  value={description}
                  onChange={(event) => {
                    setState((s) => ({ ...s, description: event?.target?.value }));
                  }}></textarea> */}
              </div>

              <div className="half-fitem fitem">
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    What is the teacher doing during?
                  </label>
                  <input
                    name="teacher_doing"
                    type="text"
                    value={teacher_doing}
                    onBlur={validate}
                    onChange={(event) => {
                      if (event?.target?.value !== '') {
                        validate(event);
                      }
                      setState((s) => ({ ...s, teacher_doing: event?.target?.value }));
                    }}
                  />
                  {errors['teacher_doing'] ? <label className="error">{errors['teacher_doing']}</label> : ''}
                </div>
                <div className="fitem half-fitem">
                  <label htmlFor="" className="required">
                    What are the students doing?
                  </label>
                  <input
                    name="student_doing"
                    type="text"
                    value={student_doing}
                    onBlur={validate}
                    onChange={(event) => {
                      if (event?.target?.value !== '') {
                        validate(event);
                      }
                      setState((s) => ({ ...s, student_doing: event?.target?.value }));
                    }}
                  />
                  {errors['student_doing'] ? <label className="error">{errors['student_doing']}</label> : ''}
                </div>
              </div>
              <div className="fitem">
                <label htmlFor="">Formative Assessment (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="Add description of formative assessment that is part of this instructional event."
                  value={formative_assessment}
                  onChange={(event) => {
                    setState((s) => ({ ...s, formative_assessment: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem">
                <label htmlFor="">Materials List (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="List physical or digital materials used in the lesson"
                  value={material_list}
                  onChange={(event) => {
                    setState((s) => ({ ...s, material_list: event?.target?.value }));
                  }}
                />
              </div>
              <div className="fitem mb-0">
                <label htmlFor="">Lesson Prep Notes (optional)</label>
                <input
                  type="text"
                  className="italic-text"
                  placeholder="Things to prepare before the lesson and time needed."
                  value={lesson_prep_notes}
                  onChange={(event) => {
                    setState((s) => ({ ...s, lesson_prep_notes: event?.target?.value }));
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Overtime Modal Popup */}

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isOvertimeModalVisible}
        onOk={() => {
          setIsOvertimeModalVisible(false);
        }}
        onCancel={() => {
          setIsOvertimeModalVisible(false);
        }}
        width={705}
        maskClosable={false}
        footer={[
          <Button
            key="course_delete_back"
            onClick={() => {
              confirmEvent(false);
            }}
            className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Over class time</h1>
            <h6 className="subhead pt-19 mb-12">
              <b>The total time for this lesson’s instructional events exceeds the total scheduled class time.</b>
            </h6>
            <h6 className="subhead">
              Your lesson will be saved, but you will not be able to add more instructional events. Reduce the time for
              one or more instructional events or edit your class schedule.
            </h6>
          </div>
          <div className="fitem-check">
            <input
              id="dshowmsg"
              className="fitem-ck-input"
              type="checkbox"
              checked={!state?.is_display_over_class_time_popup}
              onChange={(e) => {
                setState((s) => ({
                  ...s,
                  is_display_over_class_time_popup: !e.target.checked
                }));
              }}
            />
            <label className="fitem-ck-txt" htmlFor="dshowmsg">
              Do not show this message again
            </label>
          </div>
        </div>
      </Modal>

      {/* Overtime Modal Popup Ends */}

      {/* Delete Modal Popup */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isEventDeleteModalVisible}
        onCancel={() => {
          setIsEventDeleteModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="lesson_plan_back"
            onClick={() => {
              setIsEventDeleteModalVisible(false);
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="lesson_plan_delete"
            onClick={() => {
              deleteEvent();
            }}
            className="btn btn-brand">
            Confirm Deletion
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Delete Instructional Event</h1>
            <h4 className="h4">Are you sure you want to delete {eventHeading}? This action cannot be undone.</h4>
          </div>
        </div>
      </Modal>
      {/* Delete Modal Popup Ends */}

      {/* Loading Popup Starts */}
      <Modal closable={false} keyboard={false} centered={true} width={705} visible={isAutoSaving} footer={false}>
        <div className="popup-loader">
          <figure>
            <img src={loaderImg} alt="" />
          </figure>
          <p>Autosaving your progress</p>
        </div>
      </Modal>
      {/* Loading Popup Ends */}

      {/* <TARA-629> Congrats popup </TARA-629> */}
      <Modal
        closable={false}
        keyboard={false}
        centered={true}
        width={705}
        visible={isLessonSaved}
        footer={false}
        className="cource-created-wrapper">
        <div className="modal-caption cource-added-wrap min-caption-modal">
          <div className="modal-head">
            <figure>
              <img src={addedicon} alt="" />
            </figure>
            <h1>Congrats! You planned your lesson! </h1>
          </div>
        </div>
      </Modal>
      {/* <TARA-629> Congrats popup </TARA-629> */}
    </>
  );
}

function LessonDetails(props) {
  const { lesson, isLessonEdit, page_number } = props;
  const { state, setState, loader, setLoader, updateLesson, getCourseLessonById, CourseDetails } =
    useContext(PageContext);
  const [isLessonModalVisible, setIsLessonModalVisible] = useState(false);
  const { title, lesson_objective } = state;

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  useEffect(() => {
    getLessonDetails();
  }, [lesson]);

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const getLessonDetails = () => {
    if (lesson && Object.keys(lesson).length > 0) {
      Promise.allSettled([getCourseLessonById(lesson?.id)]).then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { Lesson } = store.getState();
          if (Lesson?.data && Object.keys(Lesson?.data).length > 0) {
            setState((s) => ({
              ...s,
              // id: Lesson?.data?.id,
              title: Lesson?.data?.title,
              lesson_objective: Lesson?.data?.lesson_objective,
              lesson_title: Lesson?.data?.title
            }));
          }
        }
      });
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-111>Ability for teachers to create a lesson</TARA-111>
   */
  const uodateLessonDetails = () => {
    setLoader(true);
    const params = {
      id: lesson?.id,
      title: title,
      lesson_objective: lesson_objective
    };
    Promise.allSettled([updateLesson(params)])
      .then(() => {
        const { UpdateLesson } = store.getState();
        if (UpdateLesson?.data && Object.keys(UpdateLesson?.data).length > 0) {
          getLessonDetails();
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <>
      {loader && <Loading />}
      <div className="fd-left-wrap">
        <div className="title-row flex mb-48">
          <div className="title-cap">
            {isLessonEdit && <h2>{state?.lesson_title || 'Lesson Plan Title'}</h2>}
            {isLessonEdit && (
              <p>{`Instruction Day ${page_number} of ${CourseDetails?.data?.total_course_schedule_count || 0}`}</p>
            )}
            {!isLessonEdit && <h2>{state?.lesson_title || 'Lesson Plan Title'}</h2>}
            {!isLessonEdit && (
              <p>{`Instruction Day ${page_number} of ${CourseDetails?.data?.total_course_schedule_count || 0}`}</p>
            )}
          </div>
          {isLessonEdit && (
            <div className="right-btn">
              <Link
                to={'#'}
                as={ReactRouterLink}
                className="btn-txt"
                onClick={() => {
                  setIsLessonModalVisible(true);
                }}>
                <i className="icon icon-edits icons-lg"></i>
                Edit Lesson Details
              </Link>
            </div>
          )}
        </div>
        <label htmlFor="">Lesson Objective </label>
        <label className="lbl-txt">{state?.lesson_objective || 'Add a lesson objective'}</label>
      </div>

      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isLessonModalVisible}
        onCancel={() => {
          setIsLessonModalVisible(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key="lesson_plan_back"
            onClick={() => {
              setIsLessonModalVisible(false);
            }}
            className="btn btn-primary btn-bdr">
            Cancel
          </Button>,
          <Button
            key="lesson_plan_delete"
            onClick={() => {
              setIsLessonModalVisible(false);
              uodateLessonDetails();
            }}
            className="btn btn-brand">
            Save Lesson Details
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Lesson Plan Details</h1>
            <h4 className="h4">
              Add or edit the title and objective for this lesson. Writing an objective can help you build your teaching
              strategy from one lesson to another.
            </h4>
            <div className="title-row">
              <form action="" className="form-group">
                <div className="fitem">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    placeholder="Give your lesson a title  (i.e. What is science?)"
                    value={title}
                    onChange={(event) => {
                      setState((s) => ({ ...s, title: event?.target?.value }));
                    }}
                  />
                </div>
                <div className="fitem">
                  <label htmlFor="">Objective (Recommended) </label>
                  <textarea
                    placeholder="What is the goal of the lesson? What should students know by the end of the lesson? "
                    value={lesson_objective}
                    onChange={(event) => {
                      setState((s) => ({ ...s, lesson_objective: event?.target?.value }));
                    }}></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

import CheckMyPlan from '../Component/CheckMyPlan';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { CheckMyPlan } = state;
  return { CheckMyPlan };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(CheckMyPlan);

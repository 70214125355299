import { DISMISS_COMPLETE_PROFILE, DISMISS_COURSES_SET_CTA } from '../Action/types';

const DISMISS_COMPLETE_PROFILE_INITIAL_STATE = { dismiss: false };
const DISMISS_COURSES_INITIAL_STATE = { dismiss: [] };

export const dismissCompleteProfile = (state = DISMISS_COMPLETE_PROFILE_INITIAL_STATE, action) => {
  switch (action.type) {
    case DISMISS_COMPLETE_PROFILE:
      return { ...state, dismiss: action.data };
    default:
      return state;
  }
};

export const dismissCoursesCTA = (state = DISMISS_COURSES_INITIAL_STATE, action) => {
  switch (action.type) {
    case DISMISS_COURSES_SET_CTA:
      return { ...state, dismiss: action.data };
    default:
      return state;
  }
};

import config from '../Lib/config';
import axios from 'axios';
const instance = axios.create({
  baseURL: config.getHost(),
  headers: {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_SUBSCRIPTIONS_API_KEY
  }
});
export default instance;

import PasswordChangedSuccess from '../Component/PasswordChangedSuccess';
import { connect } from 'react-redux';
import { setNewPassword } from '../../../../Action';

const mapStateToProps = (state) => {
  const { PasswordChangedSuccess } = state;
  return { PasswordChangedSuccess };
};

const actionCreators = {
  setNewPassword
};

export default connect(mapStateToProps, actionCreators)(PasswordChangedSuccess);

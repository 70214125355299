const INITIAL_STATE = { data: {}, errors: {} };

const getCourseLessonById = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_COURSE_LESSONS_BY_ID_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'GET_COURSE_LESSONS_BY_ID_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default getCourseLessonById;

import React from 'react';
import { TableRow, TableHeaderRow, Pagination } from '../../molecules';
import styles from './reusable-table.module.scss';

export const ReusableTable = ({
  columns,
  currentPage,
  dataSource,
  endIndex,
  startIndex,
  totalItems,
  totalPages,
  changeToPage
}) => {
  const paginate = (pageNumber) => changeToPage(pageNumber);

  return (
    <>
      <table className={styles.table}>
        <thead>
          <TableHeaderRow columns={columns} />
        </thead>
        <tbody>
          {dataSource.map((data, index) => (
            <TableRow key={index} rowData={data} columns={columns} />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columns.length}>
              <Pagination
                currentPage={currentPage}
                endIndex={endIndex}
                startIndex={startIndex}
                totalItems={totalItems}
                totalPages={totalPages}
                paginate={paginate}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import Loading from '../../../../Layout/loading/Container';
import adminLogo from '../../../../assets/images/admin-logo.svg';
const PageContext = React.createContext();

export default function Login(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({ passwordView: false });

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, history, ...props }}>
      <LOGIN />
    </PageContext.Provider>
  );
}

function LOGIN() {
  const { loader, setLoader, state, setState, history, teacherAdminLogin } = useContext(PageContext);
  const { passwordView } = state;

  const schema = yup.object().shape({
    email: yup.string().required('Email field cannot be left blank').email('Incorrect email'),
    password: yup.string().required('Password field cannot be left blank.')
  });

  const { register, handleSubmit, setError, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const { errors } = formState;

  const onSubmit = (data) => {
    setLoader(true);
    const body = {
      ...data,
      user_type: 'admin'
    };
    Promise.all([teacherAdminLogin(body)])
      .then(() => {
        history.push('/teacher-admin/dashboard');
        setLoader(false);
      })
      .catch((err) => {
        if (_.isArray(err?.detail)) {
          if (err?.detail[0] === 'Incorrect email/password') {
            setError('email', { type: 'error', message: '  ' });
            setError('password', { type: 'error', message: 'Incorrect email or password' });
          } else if (err?.detail[0] === 'Incorrect Password') {
            setError('email', { type: 'error', message: '  ' });
            setError('password', { type: 'error', message: 'Incorrect email or password' });
          }
        }
        setLoader(false);
      });
  };

  return (
    <div id="content-area" className="admin-login-theme">
      <div className="admin-linner-outer">
        <div className="admin-linner">
          {loader && <Loading />}
          <div className="title-row">
            <img className="adminlogo" src={adminLogo} alt="" />
            <h1 className="text-center">Tappy Admin Sign In</h1>
          </div>
          <form className="form-group">
            <div className={errors['email'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                Email Address
              </label>
              <input
                type="text"
                {...register('email')}
                name="email"
                placeholder="teacher@schooldistrict.com"
                autoComplete="new-password"
              />
              {errors['email'] ? <label className="error">{errors['email']?.message}</label> : ''}
            </div>
            <div className={errors['password'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                Password
              </label>
              <div className="ficon">
                <input
                  type={passwordView ? 'text' : 'password'}
                  {...register('password')}
                  name="password"
                  placeholder="********"
                  autoComplete="new-password"
                />
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      passwordView: !passwordView
                    }))
                  }
                  className={passwordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
              </div>
              {errors['password'] ? <label className="error">{errors['password']?.message}</label> : ''}
            </div>
            <div className="btn-group pt-20 text-center">
              <Link className="btn-txt btn mb-20 text-center" to={'reset-password'}>
                Reset Password
              </Link>
              <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

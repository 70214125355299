import { Button, Tooltip } from 'antd';
import { VerticalCard } from '../../atom';
import styles from './individual-subscription-card.module.scss';

export const IndividualSubscriptionCard = ({ product, isActive, onClick, handleSponsor, cancelAt }) => {
  return (
    <VerticalCard key={product.name} isActive={isActive} className={styles.planCard}>
      <div className={styles.heading}>
        <h4 className="heading4">{product.name}</h4>
        <label className="body-large">{product.subtitle}</label>
        <p className="body">{product.description}</p>
      </div>
      <div className={`${styles.costContainer} ${styles.freePlanCardCost}`}>
        <h1>{`$${product.price}`}</h1>
        <div className={styles.priceText}>
          <label className="body-large">{product.priceText}</label>
        </div>
      </div>
      {!isActive && (
        <div className={styles.sponsorSection}>
          <p className={`body ${styles.question}`}>
            <Tooltip
              title="Generate a payment link for your sponsor to pay for your subscription directly."
              placement="topLeft"
              destroyTooltipOnHide
              autoAdjustOverflow
              overlayClassName={styles.customTooltip}>
              <i className="fa-solid fa-circle-info"></i>
            </Tooltip>
            Do you have a sponsor?
          </p>
          <p className={`body ${styles.clickHereButton}`} onClick={handleSponsor}>
            Click here
          </p>
        </div>
      )}
      <Button
        className={`btn btn-full ${isActive ? (cancelAt ? 'btn-disabled' : 'btn-tertiary') : 'btn-secondary'}`}
        onClick={() => (!isActive || (isActive && !cancelAt)) && onClick()}>
        {isActive ? 'Cancel Plan' : 'Sign Up'}
      </Button>
    </VerticalCard>
  );
};

import React from 'react';
import styles from './pill.module.scss';
import classNames from 'classnames';

export const Pill = ({ value }) => {
  return (
    <span
      className={classNames(styles.pill, {
        [styles.signedUp]: value === 'Signed Up',
        [styles.inviteSent]: value === 'Invite Sent'
      })}>
      {value}
    </span>
  );
};

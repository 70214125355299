import { roundNumber } from '../../../Utils';
import styles from './summary-user-content.module.scss';
import mainLogo from '../../../assets/images/mainlogo.svg';

export const SummaryUserContent = ({
  subscriptionPlanType,
  user,
  email,
  price,
  description,
  individual,
  showIndividualPrice,
  eachIndividualSubscription,
  isMonthly
}) => {
  return (
    <div>
      <img src={mainLogo} alt="logo" />
      <h4 className={`heading4 ${styles.subscriptionPlanType}`}>{subscriptionPlanType}</h4>
      <label className={`body-large ${styles.subscriptionUser}`} style={{ marginBottom: email ? 4 : 32 }}>
        {user}
      </label>
      {description && <label className={`body ${styles.subscriptionUserEmail}`}>{description}</label>}
      {email && <label className={`body ${styles.subscriptionUserEmail}`}>{email}</label>}
      {price && (
        <label className={`body ${styles.subscriptionPaymentLabel}`}>
          {isMonthly ? 'Monthly payment' : 'Yearly payment'}
        </label>
      )}
      {price && <h3 className={`heading3 ${styles.subscriptionPaymentPrice}`}>${roundNumber(price)}</h3>}
      {showIndividualPrice && (
        <>
          <label className={`body ${styles.subscriptionPaymentLabel}`}>Each individual subscription</label>
          <label className={`body-large ${styles.subscriptionUser}`} style={{ marginBottom: email ? 4 : 32 }}>
            $ {roundNumber(eachIndividualSubscription)}
          </label>
        </>
      )}
      {individual && (
        <label className={`body ${styles.subscriptionIndividualPaymentLabel}`}>Each individual subscription</label>
      )}
      {individual && (
        <h3 className={`heading3 ${styles.subscriptionIndividualPaymentPrice}`}>${roundNumber(individual)}</h3>
      )}
    </div>
  );
};

import {
  TEACHER_ADMIN_LOGIN_REQUEST,
  TEACHER_ADMIN_LOGIN_SUCCESS,
  TEACHER_ADMIN_LOGIN_FAILURE,
  TEACHER_ADMIN_LOGOUT
} from '../../Action/types';
import { tokenService } from '../../Services';

const TEACHER_ADMIN_LOGIN_INITIAL_STATE = {
  loading: false,
  user: tokenService.getLocalAccessTokenAdmin() ? tokenService.getLocalAccessTokenAdmin() : {},
  error: {}
};

export function teacherAdminLogin(state = TEACHER_ADMIN_LOGIN_INITIAL_STATE, action) {
  switch (action.type) {
    case TEACHER_ADMIN_LOGIN_REQUEST:
      return {
        loading: true,
        user: {},
        error: {}
      };
    case TEACHER_ADMIN_LOGIN_SUCCESS:
      return {
        loading: false,
        user: action.data,
        error: {}
      };
    case TEACHER_ADMIN_LOGIN_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.error
      };
    case TEACHER_ADMIN_LOGOUT:
      return {
        loading: false,
        user: {},
        error: {}
      };
    default:
      return state;
  }
}

import api from './api';
import { tokenService } from './token.service';

const getCustomerSubscription = () => {
  const { email } = tokenService.getUser();
  return api.get('/subscriptions/subscription-by-email/', { params: { email } });
};

const getSubscriptionMembers = (page, email) => {
  return api.get('/subscriptions/subscription-members', { params: { page, email } });
};

const createSubscriptionMembers = (members) => {
  const { email } = tokenService.getUser();
  return api.post('/subscriptions/subscription-members/create', {
    users: members,
    email
  });
};

const resendSubscriptionEmail = (email) => {
  return api.get('/subscriptions/subscription-members/re-send-email', { params: { email } });
};

const createSubscription = (priceId, isSponsored) => {
  const { email } = tokenService.getUser();
  return api.get('/subscriptions/payment', { params: { priceId, email, isSponsored } });
};

const cancelSubscription = () => {
  const { email } = tokenService.getUser();
  return api.put(`/subscriptions/cancel/${email}`);
};

const getProducts = (subscriptionInterval) => {
  return api.get('/subscriptions/products', { params: { subscriptionInterval } });
};

const sendPaymentConfirmedEmail = () => {
  const { email } = tokenService.getUser();
  return api.get('/subscriptions/send-individual-payment-email', { params: { email } });
};

const sendSponsorEmail = (email, name, url) => {
  const { email: fromEmail } = tokenService.getUser();
  return api.get('/subscriptions/send-sponsor-email', {
    params: { toEmail: email, fromEmail, sponsorName: name, url }
  });
};

const sendPaymentSponsorEmail = (email) => {
  return api.get('/subscriptions/send-payment-sponsor-email', {
    params: { email }
  });
};

const removeMemberFromEnterpriseSubscription = (email) => {
  return api.delete('/subscriptions/remove-member-from-enterprise-subscription', {
    params: { email }
  });
};

export const subscriptionService = {
  createSubscription,
  cancelSubscription,
  createSubscriptionMembers,
  getCustomerSubscription,
  getProducts,
  getSubscriptionMembers,
  resendSubscriptionEmail,
  sendPaymentConfirmedEmail,
  sendPaymentSponsorEmail,
  sendSponsorEmail,
  removeMemberFromEnterpriseSubscription
};

import { SummaryPriceContent } from '../../molecules/summary-price-content';
import { SummaryUserContent } from '../../molecules/summary-user-content';
import styles from './enterprisePaymentSummary.module.scss';

export const EnterprisePaymentSummary = () => {
  return (
    <div className={styles.summaryContainer}>
      <div className={styles.superiorContent}>
        <SummaryUserContent
          showIndividualPrice={true}
          eachIndividualSubscription={20}
          subscriptionPlanType="Enterprise Subscription"
          user="2+ users subscription"
          price={958.5}
        />
      </div>
      <div className={styles.inferiorContent}>
        <SummaryPriceContent
          showIndividualPrice={true}
          products={[
            {
              name: 'Enterprise Subscription',
              price: 900
            }
          ]}
          tax={6.5}
        />
      </div>
    </div>
  );
};

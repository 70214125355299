import Typography from '../Component/typography';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Typography } = state;
  return { Typography };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Typography);

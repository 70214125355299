import React from 'react';

export default function AssignSeatsConfirmationModal({ totalNewMembers }) {
  return (
    <div>
      <h1>Assign seats?</h1>
      <p>
        Once confirmed, {totalNewMembers} new members will be added to your enterprise subscription plan. Each member
        will receive an email invite to join TeachTappy as part of your subscription plan.
      </p>
    </div>
  );
}

import Courses from '../Component/Courses';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Courses } = state;
  return { Courses };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Courses);

import React from 'react';
import { Link } from 'react-router-dom';
import logoLg from '../../../../assets/images/logo-lg.svg';
const PageContext = React.createContext();

export default function PasswordChanged(props) {
  return (
    <PageContext.Provider value={{ ...props }}>
      <PASSWORDCHANGED />
    </PageContext.Provider>
  );
}

function PASSWORDCHANGED() {
  return (
    <div className="auth-block">
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            <div className="title-row">
              <h1 className="mb-0">Password changed</h1>
            </div>
            <div className="block-row">
              <div className="info-card acard acard-sm">
                <div className="caption">
                  <i className="icon icon-circle-check"></i>
                  <p>Your password has been successfully updated</p>
                </div>
              </div>
            </div>
            <p className="pt-20 clr-black">
              Go back to the sign in page and use you new password to log in.
              <br />
              <strong>
                <Link className="btn-txt btn" style={{ margin: 0 }} to={'/auth/sign-in'}>
                  Back to sign in page
                </Link>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

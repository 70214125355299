import {
  TEACHER_ADMIN_RESEND_CODE_REQUEST,
  TEACHER_ADMIN_RESEND_CODE_SUCCESS,
  TEACHER_ADMIN_RESEND_CODE_FAILURE,
  TEACHER_ADMIN_RESET_PASSWORD_REQUEST,
  TEACHER_ADMIN_RESET_PASSWORD_SUCCESS,
  TEACHER_ADMIN_RESET_PASSWORD_FAILURE,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS,
  TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE,
  TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST,
  TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS,
  TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE,
  TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST,
  TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS,
  TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE
} from '../../Action/types';

const TEACHER_ADMIN_RESET_PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };
const TEACHER_ADMIN_EMAIL_VERIFICATION_INITIAL_STATE = { loading: false, data: {}, error: {} };
const TEACHER_ADMIN_RESEND_CODE_INITIAL_STATE = { loading: false, data: {}, error: {} };
const TEACHER_ADMIN_SET_NEW_PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };
const TEACHER_ADMIN_CHANGE_PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };

export const teacherAdminResetPassword = (state = TEACHER_ADMIN_RESET_PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case TEACHER_ADMIN_RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TEACHER_ADMIN_RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TEACHER_ADMIN_RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const teacherAdminEmailVerification = (state = TEACHER_ADMIN_EMAIL_VERIFICATION_INITIAL_STATE, action) => {
  switch (action.type) {
    case TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const teacherAdminResendCode = (state = TEACHER_ADMIN_RESEND_CODE_INITIAL_STATE, action) => {
  switch (action.type) {
    case TEACHER_ADMIN_RESEND_CODE_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TEACHER_ADMIN_RESEND_CODE_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TEACHER_ADMIN_RESEND_CODE_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const teacherAdminSetNewPassword = (state = TEACHER_ADMIN_SET_NEW_PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const teacherAdminChangePassword = (state = TEACHER_ADMIN_CHANGE_PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

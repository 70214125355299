export const organizationSize = [
  { value: '1-10', name: '1-10 employees' },
  { value: '11-50', name: '11-50 employees' },
  { value: '51-200', name: '51-200 employees' },
  { value: '201-500', name: '201-500 employees' },
  { value: '501-1000', name: '501-1000 employees' },
  { value: '1001-5000', name: '1001-5000 employees' },
  { value: '5001-10000', name: '5001-10000 employees' },
  { value: '10000+', name: '10000+ employees' }
];

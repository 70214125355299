import { React, useState } from 'react';
import { Navigate, Views } from 'react-big-calendar';
import { Select } from 'antd';
import AddNewCourse from './AddNewCourse';
import ExportModal from './ExportModal';

const { Option } = Select;

const ToolbarWithLegend = ({ label, onNavigate, onView, setSelectedCourse, courses, showHolidayModal, data }) => {
  const goToToday = () => {
    const now = new Date();
    setCurrentYear(now.getFullYear());
    onNavigate(Navigate.TODAY, now);
    onView(Views.MONTH);
  };

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [selectedCourse, updateSelectedCourse] = useState('All Courses');
  const [isNewCourseModalVisible, setIsNewCourseModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const navigate = (action) => {
    onNavigate(action);
  };

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
    updateSelectedCourse(value);
  };

  const handleCloseExportCourse = () => {
    setIsExportModalVisible(false);
  };

  const handleExportCourse = () => {
    setIsExportModalVisible(true);
  };

  const handleYearChange = (newYear) => {
    setCurrentYear(newYear);
    const newDate = new Date();
    newDate.setFullYear(newYear);
    onNavigate(Navigate.DATE, newDate);
  };

  const handleSelectOption = (option) => {
    const { value } = option;

    if (value === 'Manage Holidays') {
      handleManageHolidays();
    } else if (value === 'Create New Course') {
      handleCreateNewCourse();
    } else if (value === 'Export') {
      handleExportCourse();
    }
  };

  const handleManageHolidays = () => {
    showHolidayModal();
  };

  const handleCreateNewCourse = () => {
    setIsNewCourseModalVisible(true);
  };

  const handleCloseCreateNewCourse = () => {
    setIsNewCourseModalVisible(false);
  };

  // Generate an array of years for the dropdown. Adjust as needed.
  const years = [];
  for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear() + 5; i++) {
    years.push(i);
  }

  const getFilteredEvents = () => {
    const displayedCourses = new Set();

    return courses.filter((course) => {
      const display =
        !displayedCourses.has(course.id) && (selectedCourse === 'All Courses' || course.id === selectedCourse);
      if (display) displayedCourses.add(course.title);

      return display;
    });
  };

  const options = [
    {
      value: 'Manage Holidays',
      label: (
        <div>
          <i className="fa-regular fa-calendar" style={{ paddingRight: '14px' }}></i>
          Manage Holidays
        </div>
      )
    },
    {
      value: 'Create New Course',
      label: (
        <div>
          <i className="fa-regular fa-plus" style={{ paddingRight: '14px' }}></i>
          Create New Course
        </div>
      )
    },
    {
      value: 'Export',
      label: (
        <div>
          <i className="fa-solid fa-cloud-arrow-down" style={{ paddingRight: '14px' }}></i>
          Export
        </div>
      )
    }
  ];

  const filteredOptions =
    selectedCourse === 'All Courses' ? options.filter((opt) => opt.value !== 'Manage Holidays') : options;

  return (
    <div className="cust-bg-toolbar">
      <div className="title-bar">
        <h1 className="calendar-title">Calendar</h1>
        <div className="course-option-wrap">
          <Select
            className="course-select"
            onChange={handleCourseChange}
            defaultValue="All Courses"
            dropdownMatchSelectWidth={false}
            dropdownClassName="course-select-dropdown"
            placement={'bottomRight'}
            style={{ width: 165 }}>
            <Option value="All Courses">All Courses</Option>
            {courses.map((course, index) => (
              <Option key={index} value={course.id}>
                {course.title}
              </Option>
            ))}
          </Select>
          <Select
            labelInValue
            className="option-select"
            dropdownClassName="option-select-dropdown"
            style={{ width: 139 }}
            dropdownMatchSelectWidth={false}
            placement={'bottomRight'}
            value={{ value: 'Options', label: 'Options' }}
            onChange={handleSelectOption}
            options={filteredOptions}
          />
        </div>
      </div>
      <div className="nav-section">
        <div className="nav-section-left">
          <button className="today-btn" onClick={goToToday}>
            Today
          </button>
          <Select className="year-select" value={currentYear} onChange={handleYearChange}>
            {years.map((year, index) => (
              <Option style={{ color: '257A8E' }} key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>
        <div className="nav-section-right">
          <span className="right-label">{label}</span>
          <button className="nav-btn" onClick={() => navigate(Navigate.PREVIOUS)}>
            <i className="fa-regular fa-chevron-left"></i>
          </button>
          <button className="nav-btn" onClick={() => navigate(Navigate.NEXT)}>
            <i className="fa-regular fa-chevron-right"></i>
          </button>
        </div>
      </div>
      {/* Legend */}
      <div className="legend-container">
        {getFilteredEvents().map((event) => {
          return (
            <div key={event.title + event.id} className="legend-item">
              <div style={{ backgroundColor: event.backgroundColor }} className="legend-swatch"></div>
              <div className="legend-label">{event.title}</div>
            </div>
          );
        })}
      </div>
      <AddNewCourse visible={isNewCourseModalVisible} onClose={handleCloseCreateNewCourse} />
      <ExportModal isVisible={isExportModalVisible} onClose={handleCloseExportCourse} data={data} />
    </div>
  );
};

export default ToolbarWithLegend;

import React, { useContext, useState } from 'react';
import { Link, Link as ReactRouterLink, useHistory } from 'react-router-dom';
import { Modal, DatePicker, Carousel, Button } from 'antd';
import { ListManager } from 'react-beautiful-dnd-grid';
import * as yup from 'yup';
import { map } from 'lodash';
import * as moment from 'moment';
import momentTimeZone from 'moment-timezone';
import plusicon from '../../../assets/images/plus-icon.svg';
import _ from 'lodash';
import config from '../../../Lib/config';
import store from '../../../store';
import Loading from '../../../Layout/loading/Container';
import FreshChat from 'react-freshchat';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import { usePostHog } from 'posthog-js/react';

const PageContext = React.createContext();

export default function Dashboard(props) {
  const history = useHistory();
  const [state, setState] = useState({
    errors: {},
    courseList: null,
    data: {
      days: [{ day_choice: '', start_time: '', startAMPM: '', end_time: '', endAMPM: '', index: 0 }],
      isSame: true
    },
    displayName: props?.user?.display_name,
    email: props?.user?.email,
    firstName: props?.user?.first_name,
    lastName: props?.user?.last_name,
    avatarProfileImageUrl: props?.user?.avatar_profile_image_url,
    thumbnailProfileImage: props?.user?.thumbnail_profile_image
  });
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [step, setStep] = useState('COURSE_DETAILS');

  return (
    <PageContext.Provider
      value={{
        state,
        setState,
        loader,
        setLoader,
        isModalVisible,
        setIsModalVisible,
        step,
        setStep,
        resetState: () => {
          setState((s) => ({
            ...s,
            data: {
              ...s.data,
              days: [
                {
                  day_choice: '',
                  start_time: '',
                  start_time_t: '',
                  startAMPM: '',
                  end_time: '',
                  end_time_t: '',
                  endAMPM: '',
                  index: 0
                }
              ],
              isSame: true
            }
          }));
        },
        history,
        ...props
      }}>
      <DASHBOARD />
    </PageContext.Provider>
  );
}

function DASHBOARD() {
  const {
    state,
    setState,
    resetState,
    isModalVisible,
    setIsModalVisible,
    step,
    setStep,
    dismissProfileCTA,
    dismissCompleteProfile,
    isFullProfileCompleted,
    allCourseLoading,
    allCourse,
    allCourseResponse,
    addSchoolCourse,
    loader,
    setLoader,
    dismissCoursesCTA,
    dismissCoursesCTAResponse,
    updateCourseOrderIndexLoading,
    updateCourseOrderIndex,
    getUser,
    history
  } = useContext(PageContext);
  const { courseName, startDate, endDate, errors, displayName, avatarProfileImageUrl, thumbnailProfileImage } = state;
  const [noDaysFall, setNoDaysFall] = useState('');
  const [isCourseDeleted, setIsCourseDeleted] = useState(false);
  const [daysError, setDaysError] = useState([
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    }
  ]);
  let arrDaysError = [];
  const posthog = usePostHog();

  React.useEffect(() => {
    if (allCourseLoading) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [allCourseLoading]);

  /* GET ALL COURSE */
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    posthog.identify(user.email, { email: user.email });
    const params = {
      page: 1,
      page_size: 20
    };
    allCourse(params);
    setState((s) => ({
      ...s,
      displayName: user?.display_name || '',
      avatarProfileImageUrl: user?.avatar_profile_image_url || '',
      thumbnailProfileImage: user?.thumbnail_profile_image || ''
    }));
  }, []);

  /* All course */
  React.useEffect(() => {
    const mapAllCourse = {
      ...allCourseResponse,
      results: _.orderBy(allCourseResponse?.results, 'order_index', 'desc')
    };
    /* Create New Modal START */
    mapAllCourse.results.push({ id: null, order_index: -1 });
    /* Create New Modal END */
    setState((s) => ({
      ...s,
      courseList: mapAllCourse
    }));
  }, [allCourseResponse?.count]);

  /* Display Deleted Message */
  React.useEffect(() => {
    const deletedMessage = sessionStorage.getItem('DeletedCourse');
    if (deletedMessage) {
      setIsCourseDeleted(true);
    }
  }, []);

  const showModal = () => {
    setState((s) => ({
      ...s,
      courseName: '',
      name: '',
      grade: '',
      leval: '',
      run_time: '',
      startDate: '',
      endDate: '',
      schoolYear: '',
      errors: {}
    }));
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setStep('COURSE_DETAILS');
    setIsModalVisible(false);
    resetState();
  };

  const setDay = (day_choice, idx) => {
    const { data } = state;
    let { days, isSame } = data;
    // if (isSame) {
    //   days = [{ day_choice: day_choice, start_time: '09:00', startAMPM: 'AM', end_time: '09:00', endAMPM: 'PM' }];
    // } else {
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      days.splice(index, 1);
      if (days && days.length === 0) {
        days.push({
          day_choice: '',
          start_time: '',
          start_time_t: '',
          startAMPM: '',
          end_time: '',
          end_time_t: '',
          endAMPM: '',
          index: 0
        });
      }
    } else {
      if (days && days.length === 1 && days[0]['day_choice'] === '') {
        days[0]['day_choice'] = day_choice;
        days[0]['index'] = 0;
      } else {
        if (isSame) {
          days.push({
            day_choice: day_choice,
            start_time: days[0]?.start_time,
            start_time_t: days[0]?.start_time_t,
            startAMPM: days[0]?.startAMPM,
            end_time: days[0]?.end_time,
            end_time_t: days[0]?.end_time_t,
            endAMPM: days[0]?.endAMPM,
            index: idx
          });
        } else {
          days.push({
            day_choice: day_choice,
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            end_time_t: '',
            endAMPM: '',
            index: idx
          });
        }
      }
    }
    let temp = [];
    days = days.filter((el) => {
      return el.day_choice !== '';
    });
    // }
    days.map(() => {
      let params = { start_time: '', end_time: '' };
      temp.push(params);
    });
    setDaysError(temp);
    errors['days'] = '';
    days.sort(config.sortDay);
    setState((s) => ({
      ...s,
      errors: errors,
      data: { ...s.data, days: days, ...(!isSame ? { isSame: days.length <= 1 } : '') }
    }));
  };

  const isSelected = (day_choice) => {
    const { data } = state;
    const { days } = data;
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  const changedTime = (type, index, value) => {
    const { data } = state;
    const { days, isSame } = data;
    if (isSame) {
      days.map((item) => {
        if (type === 'start_time') {
          item['start_time'] = value;
          item['start_time_t'] = value;
        } else if (type === 'startAMPM') {
          item['startAMPM'] = value;
        } else if (type === 'end_time') {
          item['end_time'] = value;
          item['end_time_t'] = value;
        } else if (type === 'endAMPM') {
          item['endAMPM'] = value;
        }
      });
    } else {
      days.map((item, idx) => {
        if (index === idx) {
          if (type === 'start_time') {
            item['start_time'] = value;
            item['start_time_t'] = value;
          } else if (type === 'startAMPM') {
            item['startAMPM'] = value;
          } else if (type === 'end_time') {
            item['end_time'] = value;
            item['end_time_t'] = value;
          } else if (type === 'endAMPM') {
            item['endAMPM'] = value;
          }
        }
      });

      // if (type === 'start_time') {
      //   days[index].start_time = value;
      //   days[index].start_time_t = value;
      // } else if (type === 'startAMPM') {
      //   days[index].startAMPM = value;
      // } else if (type === 'end_time') {
      //   days[index].end_time = value;
      //   days[index].end_time_t = value;
      // } else if (type === 'endAMPM') {
      //   days[index].endAMPM = value;
      // }
    }

    setState((s) => ({
      ...s,
      data: { ...s.data, days: days }
    }));
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-78>Ability for teachers to add a new course<TARA-78>
   */
  const validateStepOne = () => {
    let start_date = moment(startDate).format('YYYY-MM-DD');
    const schema = yup.object().shape({
      courseName: yup.string().required('Course name is required.'),
      startDate: yup.string().typeError('Start date is required.').required('Start date is required.'),
      endDate: yup
        .date()
        .typeError('End date is required.')
        .required('End date is required.')
        .test('Is date greater', 'End date has to be more than start date', (value) => {
          if (!value) return true;
          return !moment(start_date).isSameOrAfter(moment(value).format('YYYY-MM-DD'));
        })
        .test('Is date greater', 'End date cannot occur in the past', (value) => {
          if (!value) return true;
          return !moment(moment().format('YYYY-MM-DD')).isAfter(moment(value).format('YYYY-MM-DD'));
        })
    });
    const data = { courseName, startDate, endDate };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setStep('COURSE_SCHEDULE');
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-78>Ability for teachers to add a new course<TARA-78>
   */
  const validateStepTwo = () => {
    const { data } = state;
    let { isSame, days } = data;
    const isDaySelected = days.some((item) => item.day_choice !== '');
    if (isDaySelected) {
      if (isSame) {
        map(days, (item, index) => {
          let validationError = {
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            end_time_t: '',
            endAMPM: ''
          };

          if (index === 0) {
            if (item.start_time === '') {
              validationError['start_time'] = 'Select start time.';
            }

            if (item.startAMPM === '') {
              validationError['startAMPM'] = 'Select start am/pm.';
            }

            if (item.end_time === '') {
              validationError['end_time'] = 'Select end time.';
            }

            if (item.endAMPM === '') {
              validationError['endAMPM'] = 'Select end am/pm.';
            }

            if (item.start_time_t && item.startAMPM && item.end_time_t && item.endAMPM) {
              const end = moment(`${item.end_time_t} ${item.endAMPM}`, 'hh:mm A').format('HH:mm:ss');
              const endTimeShouldBe = moment(`${item.start_time_t} ${item.startAMPM}`, 'hh:mm A')
                .add('15', 'minutes')
                .format('HH:mm:ss');
              if (end >= endTimeShouldBe) {
                validationError['end_time'] = '';
              } else {
                validationError['end_time'] = 'End must occur at least 15min after start time.';
              }
            }
            arrDaysError.push(validationError);
          }
        });
      } else {
        map(days, (item) => {
          let validationError = {
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            endAMPM: ''
          };
          if (item.start_time === '') {
            validationError['start_time'] = 'Select start time.';
          }
          if (item.startAMPM === '') {
            validationError['startAMPM'] = 'Select start am/pm.';
          }

          if (item.end_time === '') {
            validationError['end_time'] = 'Select end time.';
          }

          if (item.endAMPM === '') {
            validationError['endAMPM'] = 'Select end am/pm.';
          }

          if (item.start_time && item.startAMPM && item.end_time && item.endAMPM) {
            const end = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HHmmss');
            const endTimeShouldBe = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A')
              .add('15', 'minutes')
              .format('HHmmss');
            if (end >= endTimeShouldBe) {
              validationError['end_time'] = '';
            } else {
              validationError['end_time'] = 'End must occur at least 15min after start time.';
            }
          }
          arrDaysError.push(validationError);
        });
      }
      setDaysError(arrDaysError);
      const isFulfilled = arrDaysError.filter(
        (x) => x.start_time !== '' || x.startAMPM !== '' || x.end_time !== '' || x.endAMPM !== ''
      );
      if (isFulfilled?.length === 0) {
        const params = {
          name: state?.courseName ? state?.courseName : '',
          grade: state?.grade ? state?.grade : '',
          level: state?.leval ? state?.leval : '',
          run_time: state?.schoolYear ? state?.schoolYear : '',
          start_date: moment
            .tz(moment(state?.startDate).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .startOf('day')
            .format(),
          end_date: moment
            .tz(moment(state?.endDate).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .endOf('day')
            .format(),
          time_choice: isSame ? 'same_time' : 'custom_time',
          course_schedule: days
        };
        createCourse(params);
      }
    } else {
      errors['days'] = 'Please select at least one day.';
      setState((s) => ({ ...s, errors: errors }));
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-78>Ability for teachers to add a new course<TARA-78>
   */
  const createCourse = (params) => {
    setLoader(true);
    if (state?.data?.isSame) {
      /**
       * AUTHOR: Ritesh Solanki
       * <TARA-438>Create course | Add course schedule | When user has selected multiple days in "Is your class schedule the same every day you teach? "
       * selected "Custom" and then selects "its same for all day" then it is showing multiple "Start/End time" row</TARA-438>
       */
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('HH:mm');
        item['start_time_t'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        item['end_time'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('HH:mm');
        item['end_time_t'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        delete item.start_time_t;
        delete item.end_time_t;
        delete item.startAMPM;
        delete item.endAMPM;
      });
    } else {
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('HH:mm');
        item['start_time_t'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('hh:mm');
        item['end_time'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm');
        item['end_time_t'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('hh:mm');
        delete item.start_time_t;
        delete item.end_time_t;
        delete item.startAMPM;
        delete item.endAMPM;
      });
    }
    params['icon_name'] = config.getRandomCourseCardIcon(); /* set icon while crating course */
    params['color_code'] = config.getRandomCourseCardColorClass(); /* set color while creating course */
    params['time_zone'] = JSON.parse(localStorage.getItem('user'))?.time_zone || momentTimeZone.tz.guess(true);
    // console.log('body', params);
    Promise.allSettled([addSchoolCourse(params)])
      .then(() => {
        const { SchoolCourse, allCourse } = store.getState();
        if (SchoolCourse?.data && Object.keys(SchoolCourse?.data).length > 0) {
          if (allCourse?.data?.count === 0) {
            sessionStorage.setItem('addedCourse', 'your first course!');
          } else {
            sessionStorage.setItem('addedCourse', 'a course!');
          }
          finilzeCourse();
          history.push(`/teacher/course-view/${SchoolCourse?.data?.id}`);
        } else {
          setNoDaysFall(SchoolCourse?.errors);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-78>Ability for teachers to add a new course<TARA-78>
   */
  const finilzeCourse = () => {
    setStep('COURSE_DETAILS');
    setIsModalVisible(false);
    allCourse();
    setState((s) => ({
      ...s,
      data: {
        ...s.data,
        days: [
          {
            day_choice: '',
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            end_time_t: '',
            endAMPM: '',
            index: 0
          }
        ],
        isSame: true
      }
    }));
  };

  const dismissCourseHandler = (courseId) => {
    const data = [...dismissCoursesCTAResponse.dismiss, ...[courseId]];
    dismissCoursesCTA(data);
  };

  const isHideHeaderBottom = () => {
    let slide = 0;
    if (!dismissProfileCTA && !isFullProfileCompleted) {
      slide = slide + 1;
    } else {
      slide = slide - 0;
    }
    if (state?.courseList?.results?.length > 1) {
      const data = state?.courseList?.results.filter(
        (item) =>
          item?.id && !dismissCoursesCTAResponse.dismiss.includes(item.id) && item?.lessons_planned_percentage < 100
      );
      slide = slide + data.length;
    }
    if (slide === 0) {
      return false;
    } else {
      return true;
    }
  };

  const reorderList = (sourceIndex, destinationIndex) => {
    if (updateCourseOrderIndexLoading) {
      return;
    }
    if (destinationIndex === sourceIndex) {
      return;
    }
    const destination = _.cloneDeep(state?.courseList?.results)[destinationIndex];
    const source = _.cloneDeep(state?.courseList?.results)[sourceIndex];
    if (!destination?.id || !source?.id) {
      /* new record should not drag */
      return;
    }

    /* Replace Index Value */
    let couseUpdate = [];
    const clonedCourseList = _.cloneDeep(state?.courseList?.results).map((item, index) => {
      if (index === destinationIndex) {
        item.order_index = source.order_index;
        couseUpdate.push({ id: item.id, order_index: item.order_index });
        return item;
      } else if (index === sourceIndex) {
        item.order_index = destination.order_index;
        couseUpdate.push({ id: item.id, order_index: item.order_index });
        return item;
      } else {
        return item;
      }
    });

    const mapAllCourse = {
      ...allCourseResponse,
      results: _.orderBy(clonedCourseList, 'order_index', 'desc')
    };
    setState((s) => ({
      ...s,
      courseList: mapAllCourse
    }));
    updateCourseOrderIndex(couseUpdate);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * Object to display week days in short while while schedule a class (Create Course Step - 2)
   */
  const objDays = {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun'
  };

  const onError = () => {
    setLoader(true);
    Promise.all([getUser()])
      .then((res) => {
        setState((s) => ({
          ...s,
          avatarProfileImageUrl: res[0]?.avatar_profile_image_url,
          thumbnailProfileImage: res[0]?.thumbnail_profile_image
        }));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-75>Ability for teachers to deleted a course</TARA-75>
   */
  const handleOkay = () => {
    setIsCourseDeleted(false);
    sessionStorage.removeItem('DeletedCourse');
  };

  const handleOk = () => {
    if (step === 'COURSE_DETAILS') {
      setStep('COURSE_SCHEDULE');
    } else if (step === 'COURSE_SCHEDULE') {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
    }
  };

  return (
    <div>
      {loader && <Loading />}
      <div className="main-head">
        <div className="container">
          <div className="header-top">
            <div className="profile-wrap">
              <figure>
                <img
                  src={avatarProfileImageUrl ? avatarProfileImageUrl : thumbnailProfileImage}
                  alt="profile-image"
                  onError={onError}
                />
              </figure>
              <h2>
                Welcome,
                <strong> {displayName}</strong>
              </h2>
              <div className="social-share">
                <EmailShareButton
                  url="https://www.teachtappy.com"
                  subject="Teach Tappy is a great resource for teachers!"
                  body="">
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <FacebookShareButton
                  url="https://www.teachtappy.com"
                  quote="Check out this great resource for teachers!">
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <TwitterShareButton url="https://www.teachtappy.com" title="Great teacher resource!">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>

                <LinkedinShareButton url="https://www.teachtappy.com">
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          {isHideHeaderBottom() && (
            <div className="header-bottom">
              <Carousel draggable={true}>
                {!dismissProfileCTA && !isFullProfileCompleted && (
                  <div className="card">
                    <div className="card-body">
                      <div className="left-cap">
                        <h4>
                          Complete Your Profile
                          <label
                            className="lbl"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              dismissCompleteProfile(true);
                            }}>
                            Dismiss
                          </label>
                        </h4>
                        <p>What you fill out will help us personalize content and connect you with other teachers. </p>
                        <div className="btn-group">
                          <Link className="btn-txt" to={'/teacher/profile-and-settings'}>
                            <u>Complete Now</u> <i className="icon icon-next"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state?.courseList?.count > 0 &&
                  state?.courseList?.results
                    .filter((item) => item?.id)
                    .map((item) => {
                      return (
                        !dismissCoursesCTAResponse.dismiss.includes(item.id) &&
                        item?.lessons_planned_percentage < 100 && (
                          <div className="card" key={item.id}>
                            <div className="card-body">
                              <div className="left-cap">
                                <h4 className="h4">
                                  Stay ahead by completing the rest of your lesson plans.
                                  <label
                                    className="lbl"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      dismissCourseHandler(item.id);
                                    }}>
                                    Dismiss
                                  </label>
                                </h4>
                                <p>
                                  You currently have the first {item?.lessons_planned_percentage}% of lessons planned
                                  for your
                                  <strong> {item.name}.</strong>
                                </p>
                                <div className="btn-group">
                                  <Link className="btn-txt" to={`/teacher/course-view/${item.id}`}>
                                    <u>Plan More Lessons</u> <i className="icon icon-next"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })}
              </Carousel>
            </div>
          )}
        </div>
      </div>
      <div className="block-row">
        <div className="container">
          <div className="page-heading">
            <h1>Courses</h1>
          </div>
          {state?.courseList?.count < 1 && (
            <div className="course-empty flex">
              <div className="card-lg card bg-cardimg">
                <div className="cource-head">
                  <i className="icon icon-courses"></i>
                </div>
                <div className="cource-caps">
                  <h4>Add Your First Course</h4>
                  <p>Set up your first course in TAPPY and start planning lessons. </p>
                  <Link to={'#'} onClick={showModal} className="btn btn-brand">
                    Get Started
                    <i className="icon icon-next"></i>
                  </Link>
                </div>
              </div>
              <div className="card-info card">
                <div className="cource-head">
                  <i className="icon icon-book"></i>
                </div>
                <div className="cource-caps">
                  <p>Courses you add will show in this space and be accessible from the main menu.</p>
                </div>
              </div>
            </div>
          )}
          <div className="cource-list">
            {state?.courseList?.count > 0 && (
              <ListManager
                items={state?.courseList?.results}
                direction="horizontal"
                maxItems={3}
                render={(item) => (
                  <>
                    {item?.id ? (
                      <div key={item?.id} className={`cource-item ${item.color_code}`}>
                        <div className="cource-head">
                          <i className={item.icon_name}></i>
                          <i className="icon icon-grip-dots-vertical"></i>
                        </div>
                        <div className="cource-caps">
                          <Link as={ReactRouterLink} to={`/teacher/course-view/${item.id}`}>
                            <h5>{item.name}</h5>
                            <i className="icon icon-calendar-default-state"></i>
                            {moment(item.end_date).format('YYYY')} – {moment().add(1, 'years').format('YYYY')}
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="cource-item add-new-item" onClick={showModal}>
                        <div className="cource-head">
                          <i className="cicon-plus">
                            <img src={plusicon} alt="add-new-course" />
                          </i>
                        </div>
                        <div className="cource-caps">
                          <h5>Add New Course</h5>
                          <p>
                            <i className="icon icon-note"></i>
                            Plan lessons for another course.
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
                onDragEnd={reorderList}
              />
            )}
          </div>
        </div>
      </div>
      <FreshChat
        token="bb202993-f270-40ac-89ef-3deeb589e69d"
        email={state.email}
        first_name={state.first_name}
        last_name={state.last_name}
        onInit={(widget) => {
          widget.user.setProperties({
            email: state.email,
            first_name: state.first_name,
            last_name: state.last_name
          });
        }}
      />
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isModalVisible}
        onOk={handleOk}
        okText={`${
          step === 'COURSE_DETAILS'
            ? 'Next: Course Schedule'
            : step === 'COURSE_SCHEDULE'
            ? 'Create this Course'
            : 'Start Planning'
        }`}
        onCancel={handleCancel}
        width={770}
        closable={true}
        maskClosable={false}
        footer={[
          <React.Fragment key="back1">
            {(step === 'COURSE_DETAILS' || step === 'COURSE_SCHEDULE') && (
              <Button key="back" onClick={handleCancel} className="btn btn-primary btn-bdr">
                Cancel
              </Button>
            )}
          </React.Fragment>,

          <Button
            className="btn btn-brand"
            key="link"
            type="button"
            onClick={
              step === 'COURSE_DETAILS' ? validateStepOne : step === 'COURSE_SCHEDULE' ? validateStepTwo : finilzeCourse
            }>
            {step === 'COURSE_DETAILS'
              ? 'Next: Course Schedule'
              : step === 'COURSE_SCHEDULE'
              ? 'Create This Course'
              : 'Start Planning'}
          </Button>
        ]}>
        <div className="modal-head">
          {step === 'COURSE_DETAILS' && (
            <React.Fragment>
              <h1>Create Course </h1>
              <div className="title-row">
                <h4 className="h4">Add Course Details</h4>
                <p>
                  Name your course in a way that is easily identifiable to you. This is how your course will appear in
                  the TAPPY menu.
                  <label className="required lbl-lg">Required fields are marked with an</label>
                </p>
                <form action="" className="form-group">
                  <div className={errors['courseName'] ? 'fitem has-error' : 'fitem'}>
                    <label htmlFor="" className="required">
                      Course Name
                    </label>
                    <input
                      type="text"
                      placeholder="Freshman Biology - Period 1"
                      value={state?.courseName}
                      onChange={(event) => {
                        setState((s) => ({ ...s, courseName: event?.target?.value }));
                      }}
                    />
                    {errors['courseName'] ? <label className="error">{errors['courseName']}</label> : ''}
                  </div>
                  <div className="half-fitem fitem">
                    <div className="fitem half-fitem">
                      <label htmlFor="">Grade </label>
                      <input
                        type="text"
                        placeholder="9th"
                        value={state?.grade}
                        onChange={(event) => {
                          setState((s) => ({ ...s, grade: event?.target?.value }));
                        }}
                      />
                    </div>
                    <div className="fitem half-fitem">
                      <label htmlFor="">Level</label>
                      <input
                        type="text"
                        placeholder="Intermediate"
                        value={state?.leval}
                        onChange={(event) => {
                          setState((s) => ({ ...s, leval: event?.target?.value }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="fitem"></div>
                  <div className="fitem">
                    <label htmlFor="">Enter the dates your course begins and ends</label>
                  </div>
                  <div className="half-fitem fitem">
                    <div className={errors['startDate'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                      <label htmlFor="" className="required">
                        Start Date
                      </label>
                      <DatePicker
                        suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                        value={state?.startDate}
                        placeholder={'MM/DD/YYYY'}
                        format={'MM/DD/YYYY'}
                        onChange={(event) => {
                          setState((s) => ({ ...s, startDate: event }));
                        }}
                        onBlur={(event) => {
                          if (moment(event.target.value).isValid()) {
                            setState((s) => ({ ...s, startDate: moment(event.target.value) }));
                          }
                        }}
                      />
                      {errors['startDate'] ? <label className="error">{errors['startDate']}</label> : ''}
                      {moment(state?.startDate).isBefore(moment().format('YYYY-MM-DD')) && (
                        <label className="lbl-txt">This start date occurs in the past</label>
                      )}
                    </div>
                    <div className={errors['endDate'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                      <label htmlFor="" className="required">
                        End Date
                      </label>
                      <DatePicker
                        suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                        value={state?.endDate}
                        placeholder={'MM/DD/YYYY'}
                        format={'MM/DD/YYYY'}
                        onChange={(event) => {
                          setState((s) => ({ ...s, endDate: event }));
                        }}
                        onBlur={(event) => {
                          if (moment(event.target.value).isValid()) {
                            setState((s) => ({ ...s, endDate: moment(event.target.value) }));
                          }
                        }}
                      />
                      {errors['endDate'] ? <label className="error">{errors['endDate']}</label> : ''}
                    </div>
                  </div>
                  <div className="fitem mb-0">
                    <label htmlFor="">Label this School Year, Semester, or Quarter</label>
                    <input
                      type="text"
                      placeholder="Spring Semester 2023"
                      value={state?.schoolYear}
                      onChange={(event) => {
                        setState((s) => ({ ...s, schoolYear: event?.target?.value }));
                      }}
                    />
                  </div>
                </form>
              </div>
            </React.Fragment>
          )}
          {step === 'COURSE_SCHEDULE' && (
            <React.Fragment>
              <h1>Create Course </h1>
              <div className="modal-caption">
                <div className="title-row">
                  <h4 className="h4">Add Course Schedule</h4>
                  <p>
                    Select the days you teach your course and add the time. This will help us set up your calendar.
                    <label className="required lbl-lg">Required fields are marked with an</label>
                  </p>
                </div>
                <form className="form-group hide weekly-wrap">
                  <div className="fitem">
                    <label className="required lbl-lg">Select all that apply:</label>
                    <ul className="weekday-wrap flex">
                      <li onClick={() => setDay('monday', 0)} className={isSelected('monday') ? 'active' : ''}>
                        Monday
                      </li>
                      <li onClick={() => setDay('tuesday', 1)} className={isSelected('tuesday') ? 'active' : ''}>
                        Tuesday
                      </li>
                      <li onClick={() => setDay('wednesday', 2)} className={isSelected('wednesday') ? 'active' : ''}>
                        Wednesday
                      </li>
                      <li onClick={() => setDay('thursday', 3)} className={isSelected('thursday') ? 'active' : ''}>
                        Thursday
                      </li>
                      <li onClick={() => setDay('friday', 4)} className={isSelected('friday') ? 'active' : ''}>
                        Friday
                      </li>
                      <li onClick={() => setDay('saturday', 5)} className={isSelected('saturday') ? 'active' : ''}>
                        Saturday
                      </li>
                      <li onClick={() => setDay('sunday', 6)} className={isSelected('sunday') ? 'active' : ''}>
                        Sunday
                      </li>
                    </ul>
                    {errors['days'] ? <label className="error">{errors['days']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <i style={{ color: '#B91C1C' }}>{noDaysFall}</i>
                  </div>
                  <div className="fitem mb-24">
                    <label className="lg-txt">Is your class schedule the same every day you teach?</label>
                  </div>
                  <div className="fitem half-fitem mb-24">
                    <div className="fitem half-fitem">
                      <div className="fitem-check">
                        <input
                          className="fitem-ck-input"
                          id="sameEveryDay"
                          name="itsSame"
                          type="radio"
                          defaultChecked
                          checked={state?.data?.isSame}
                          onChange={() => {
                            setState((s) => ({
                              ...s,
                              data: { ...s.data, isSame: true }
                            }));
                          }}
                        />
                        <label className="fitem-ck-txt" htmlFor="sameEveryDay">
                          Same for all the days above
                        </label>
                      </div>
                    </div>
                    <div className={state?.data?.isSame ? 'fitem half-fitem maxw-328' : 'fitem half-fitem maxw-276'}>
                      <div className="fitem-check">
                        <input
                          className="fitem-ck-input"
                          id="custom"
                          name="itsSame"
                          type="radio"
                          disabled={state?.data?.days.length <= 1 ? true : false}
                          checked={!state?.data?.isSame}
                          onChange={() => {
                            setState((s) => ({
                              ...s,
                              data: { ...s.data, isSame: false }
                            }));
                          }}
                        />
                        <label
                          className="fitem-ck-txt"
                          htmlFor="custom"
                          style={{ color: state?.data?.days.length <= 1 ? '#767979' : '#193e47' }}>
                          Custom (different every day)
                        </label>
                      </div>
                    </div>
                  </div>
                  {!state?.data?.isSame && state?.data?.days.length > 0 && (
                    <>
                      <div className="fitem half-fitem fitem-mb-0">
                        {!state?.data?.isSame && (
                          <div className="days-item">
                            <p>Day</p>
                          </div>
                        )}
                        <div className="fitem half-fitem maxw-276">
                          <label className="required">Select Start Time</label>
                        </div>
                        <div className="fitem half-fitem maxw-276">
                          <label className="required">Select End Time</label>
                        </div>
                      </div>
                      {state?.data?.days.map((item, index) => (
                        <div className="fitem half-fitem" key={item.day_choice}>
                          {!state?.data?.isSame && (
                            <div className="days-item">
                              <label>{objDays[item.day_choice]}</label>
                            </div>
                          )}
                          <div
                            className={
                              daysError[index]?.start_time
                                ? 'fitem half-fitem has-error maxw-276 d-block'
                                : 'fitem half-fitem maxw-276 d-block'
                            }>
                            <div className="flex">
                              <div className="half-half-fitem half-fitem">
                                <input
                                  type="text"
                                  placeholder={'Add Time'}
                                  value={item?.start_time_t}
                                  onKeyUp={(event) => {
                                    if (event.code === 'Backspace') {
                                      changedTime('start_time', index, '');
                                      changedTime('start_time_t', index, '');
                                      // setState((s) => ({ ...s, testStartDate: '' }));
                                    }
                                  }}
                                  onBlur={() => {
                                    // const { testStartDate } = state;
                                    const testStartDate = item?.start_time_t;
                                    // var hh = testStartDate.substring(0, 2);
                                    // var mm = testStartDate.toString().slice(-2);
                                    // let maintime = hh + ':' + mm;
                                    // let maintime = moment(testStartDate, ['hh:mm']).format('hh:mm');
                                    let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                    let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                    if (!mainTimeIsValid) {
                                      // const errorArr = {
                                      //   start_time: 'Please Enter a valid time'
                                      // };
                                      // if (daysError && daysError.length === 0) {
                                      //   daysError.push(errorArr);
                                      // } else {
                                      //   daysError[index]['start_time'] = 'Please Enter a valid time';
                                      // }
                                      const error = [...daysError];
                                      error[index] = { start_time: 'Please Enter a valid time' };
                                      setDaysError(error);

                                      // maintime = hh && mm ? `${hh}:${mm}` : '';
                                      let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                      changedTime('start_time', index, tempDate);
                                      changedTime('start_time_t', index, tempDate);
                                    } else {
                                      // const errorArr = {
                                      //   start_time: ''
                                      // };
                                      // if (daysError && daysError.length === 0) {
                                      //   daysError.push(errorArr);
                                      // } else {
                                      //   daysError[index]['start_time'] = '';
                                      // }
                                      const error = [...daysError];
                                      error[index] = { start_time: '' };
                                      setDaysError(error);

                                      changedTime('start_time', index, maintime);
                                      changedTime('start_time_t', index, maintime);
                                    }
                                  }}
                                  onChange={(event) => {
                                    // setDaysError([]);
                                    // const re = /^[0-9\b]+$/;
                                    // if (re.test(event.target.value)) {
                                    //   setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                    //   changedTime('start_time', index, event?.target?.value);
                                    //   changedTime('start_time_t', index, event?.target?.value);
                                    // }
                                    if (event.target.value !== '') {
                                      setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                      changedTime('start_time', index, event?.target?.value);
                                      changedTime('start_time_t', index, event?.target?.value);
                                    }
                                  }}
                                />
                              </div>
                              <div className="half-half-fitem half-fitem last">
                                <select
                                  value={item?.startAMPM || ''}
                                  onChange={(e) => changedTime('startAMPM', index, e.target.value)}>
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value={'AM'}>AM</option>
                                  <option value={'PM'}>PM</option>
                                </select>
                              </div>
                            </div>
                            {daysError[index]?.start_time ? (
                              <label className="error">{daysError[index]?.start_time}</label>
                            ) : (
                              ''
                            )}
                            {daysError[index]?.startAMPM ? (
                              <label className="error">{daysError[index]?.startAMPM}</label>
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className={
                              daysError[index]?.end_time
                                ? 'fitem half-fitem has-error maxw-276 d-block'
                                : 'fitem half-fitem maxw-276 d-block'
                            }>
                            <div className="flex">
                              <div className="half-half-fitem half-fitem">
                                <input
                                  type="text"
                                  placeholder={'Add Time'}
                                  value={item?.end_time_t}
                                  onKeyUp={(event) => {
                                    if (event.code === 'Backspace') {
                                      changedTime('end_time', index, '');
                                      changedTime('end_time_t', index, '');
                                      // setState((s) => ({ ...s, testStartDate: '' }));
                                    }
                                  }}
                                  onBlur={() => {
                                    const testStartDate = item?.end_time_t;
                                    if (testStartDate) {
                                      // var hh = testStartDate.substring(0, 2);
                                      // var mm = testStartDate.toString().slice(-2);
                                      // let maintime = hh + ':' + mm;
                                      // let maintime = moment(testStartDate, ['hh:mm']).format('hh:mm');
                                      let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                      let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                      if (!mainTimeIsValid) {
                                        // const errorArr = {
                                        //   end_time: 'Please Enter a valid time'
                                        // };
                                        // if (daysError && daysError.length === 0) {
                                        //   daysError.push(errorArr);
                                        // } else {
                                        //   daysError[index]['end_time'] = 'Please Enter a valid time';
                                        // }
                                        const error = [...daysError];
                                        error[index] = { end_time: 'Please Enter a valid time' };
                                        setDaysError(error);
                                        // maintime = hh && mm ? `${hh}:${mm}` : '';
                                        let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                        changedTime('end_time', index, tempDate);
                                        changedTime('end_time_t', index, tempDate);
                                      } else {
                                        // const errorArr = {
                                        //   end_time: ''
                                        // };
                                        // if (daysError && daysError.length === 0) {
                                        //   daysError.push(errorArr);
                                        // } else {
                                        //   daysError[index]['end_time'] = '';
                                        // }
                                        const error = [...daysError];
                                        error[index] = { end_time: '' };
                                        setDaysError(error);
                                        changedTime('end_time', index, maintime);
                                        changedTime('end_time_t', index, maintime);
                                      }
                                    }
                                  }}
                                  onChange={(event) => {
                                    // setDaysError([]);
                                    // const re = /^[0-9\b]+$/;
                                    // if (re.test(event.target.value)) {
                                    //   setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                    //   changedTime('end_time', index, event?.target?.value);
                                    //   changedTime('end_time_t', index, event?.target?.value);
                                    // }
                                    if (event.target.value !== '') {
                                      setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                      changedTime('end_time', index, event?.target?.value);
                                      changedTime('end_time_t', index, event?.target?.value);
                                    }
                                  }}
                                />
                              </div>
                              <div className="half-half-fitem half-fitem has-error last">
                                <select
                                  value={item?.endAMPM || ''}
                                  onChange={(e) => changedTime('endAMPM', index, e.target.value)}>
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value={'AM'}>AM</option>
                                  <option value={'PM'}>PM</option>
                                </select>
                              </div>
                            </div>
                            {daysError[index]?.end_time ? (
                              <label className="error">{daysError[index]?.end_time}</label>
                            ) : (
                              ''
                            )}
                            {daysError[index]?.endAMPM ? (
                              <label className="error">{daysError[index]?.endAMPM}</label>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {state?.data?.isSame && (
                    <>
                      <div className="fitem half-fitem fitem-mb-0">
                        <div className="fitem half-fitem">
                          <label className="required">Select Start Time</label>
                        </div>
                        <div className="fitem half-fitem maxw-328">
                          <label className="required">Select End Time</label>
                        </div>
                      </div>
                      <div className="fitem half-fitem">
                        <div
                          className={
                            daysError[0]?.start_time
                              ? 'fitem half-fitem has-error maxw-328 ml-0 d-block'
                              : 'fitem half-fitem maxw-328 ml-0 d-block'
                          }>
                          <div className="flex">
                            <div className="half-half-fitem half-fitem">
                              <input
                                type="text"
                                placeholder={'Add Time'}
                                value={state?.data?.days[0]?.start_time_t || ''}
                                onKeyUp={(event) => {
                                  if (event.code === 'Backspace') {
                                    changedTime('start_time', 0, '');
                                    changedTime('start_time_t', 0, '');
                                    setState((s) => ({ ...s, testStartDate: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  const { testStartDate } = state;
                                  // var hh = testStartDate.substring(0, 2);
                                  // var mm = testStartDate.toString().slice(-2);
                                  // let maintime = hh + ':' + mm;
                                  // let maintime = moment(testStartDate, ['hh:mm']).format('hh:mm');
                                  // maintime = maintime === 'Invalid date' ? '' : maintime;
                                  let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                  let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                  if (!mainTimeIsValid) {
                                    // const errorArr = {
                                    //   start_time: 'Please Enter a valid time'
                                    // };
                                    // if (daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[0]['start_time'] = 'Please Enter a valid time';
                                    // }
                                    // setDaysError(daysError);
                                    // maintime = hh && mm ? `${hh}:${mm}` : '';
                                    const error = [...daysError];
                                    error[0] = { start_time: 'Please Enter a valid time' };
                                    setDaysError(error);
                                    let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                    changedTime('start_time', 0, tempDate);
                                    changedTime('start_time_t', 0, tempDate);
                                  } else {
                                    // const errorArr = {
                                    //   start_time: ''
                                    // };
                                    // if (daysError && daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[0]['start_time'] = '';
                                    // }
                                    // setDaysError([]);
                                    const error = [...daysError];
                                    error[0] = { start_time: '' };
                                    setDaysError(error);
                                    changedTime('start_time', 0, maintime);
                                    changedTime('start_time_t', 0, maintime);
                                  }
                                }}
                                onChange={(event) => {
                                  // const re = /^[0-9\b]+$/;
                                  // if (re.test(event.target.value)) {
                                  //   setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                  //   changedTime('start_time', 0, event?.target?.value);
                                  //   changedTime('start_time_t', 0, event?.target?.value);
                                  // }
                                  if (event.target.value !== '') {
                                    setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                    changedTime('start_time', 0, event?.target?.value);
                                    changedTime('start_time_t', 0, event?.target?.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="half-half-fitem half-fitem ml-10">
                              <select
                                value={state?.data?.days[0]?.startAMPM || ''}
                                onChange={(e) => changedTime('startAMPM', 0, e.target.value)}>
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value={'AM'}>AM</option>
                                <option value={'PM'}>PM</option>
                              </select>
                            </div>
                          </div>
                          {daysError[0]?.start_time ? <label className="error">{daysError[0]?.start_time}</label> : ''}
                          {daysError[0]?.startAMPM ? <label className="error">{daysError[0]?.startAMPM}</label> : ''}
                        </div>
                        <div
                          className={
                            daysError[0]?.end_time
                              ? 'fitem half-fitem has-error maxw-328 ml-auto d-block'
                              : 'fitem half-fitem maxw-328 ml-auto d-block'
                          }>
                          <div className="flex">
                            <div className="half-half-fitem half-fitem">
                              <input
                                type="text"
                                placeholder={'Add Time'}
                                value={state?.data?.days[0]?.end_time_t || ''}
                                onKeyUp={(event) => {
                                  if (event.code === 'Backspace') {
                                    changedTime('end_time', 0, '');
                                    changedTime('end_time_t', 0, '');
                                    setState((s) => ({ ...s, testEndDate: '' }));
                                  }
                                }}
                                onBlur={() => {
                                  const { testEndDate } = state;
                                  // var hh = testEndDate.substring(0, 2);
                                  // var mm = testEndDate.toString().slice(-2);
                                  // let maintime = hh + ':' + mm;
                                  // let maintime = moment(testEndDate, ['HH:mm']).format('hh:mm');
                                  let maintime = moment(testEndDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                  let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                  if (!mainTimeIsValid) {
                                    // const errorArr = {
                                    //   end_time: 'Please Enter a valid time'
                                    // };
                                    // if (daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[0]['end_time'] = 'Please Enter a valid time';
                                    // }
                                    // setDaysError(daysError);
                                    // maintime = hh && mm ? `${hh}:${mm}` : '';
                                    const error = [...daysError];
                                    error[0] = { end_time: 'Please Enter a valid time' };
                                    setDaysError(error);
                                    let tempDate = !mainTimeIsValid ? testEndDate : maintime;
                                    changedTime('end_time', 0, tempDate);
                                    changedTime('end_time_t', 0, tempDate);
                                  } else {
                                    // const errorArr = {
                                    //   end_time: ''
                                    // };
                                    // if (daysError.length === 0) {
                                    //   daysError.push(errorArr);
                                    // } else {
                                    //   daysError[0]['end_time'] = '';
                                    // }
                                    // setDaysError(daysError);
                                    // setDaysError([]);
                                    const error = [...daysError];
                                    error[0] = { end_time: '' };
                                    setDaysError(error);
                                    changedTime('end_time', 0, maintime);
                                    changedTime('end_time_t', 0, maintime);
                                  }
                                }}
                                onChange={(event) => {
                                  // const re = /^[0-9\b]+$/;
                                  // if (re.test(event.target.value)) {
                                  //   setState((s) => ({ ...s, testEndDate: event?.target?.value }));
                                  //   changedTime('end_time', 0, event?.target?.value);
                                  //   changedTime('end_time_t', 0, event?.target?.value);
                                  // }
                                  if (event.target.value !== '') {
                                    setState((s) => ({ ...s, testEndDate: event?.target?.value }));
                                    changedTime('end_time', 0, event?.target?.value);
                                    changedTime('end_time_t', 0, event?.target?.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="half-half-fitem half-fitem ml-10">
                              <select
                                value={state?.data?.days[0]?.endAMPM || ''}
                                onChange={(e) => changedTime('endAMPM', 0, e.target.value)}>
                                <option value="" disabled>
                                  Select
                                </option>
                                <option value={'AM'}>AM</option>
                                <option value={'PM'}>PM</option>
                              </select>
                            </div>
                          </div>
                          {daysError[0]?.end_time ? <label className="error">{daysError[0]?.end_time}</label> : ''}
                          {daysError[0]?.endAMPM ? <label className="error">{daysError[0]?.endAMPM}</label> : ''}
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </React.Fragment>
          )}
        </div>
      </Modal>
      {/* Delete success Modal  */}
      <Modal
        className="model-footer-center"
        closeIcon={<div className="icon icon-add"></div>}
        visible={isCourseDeleted}
        closable={false}
        keyboard={false}
        centered={true}
        onOk={handleOk}
        width={770}
        maskClosable={false}
        footer={[
          <Button key="delete_success_back" onClick={handleOkay} className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head text-center">
            <h1>{`${sessionStorage.getItem('DeletedCourse')} has been deleted`}</h1>
            <h4 className="subhead font-weight-400 font-sans">
              It will no longer appear in the side menu or be available on your homepage.
            </h4>
          </div>
        </div>
      </Modal>
      {/* Delete success Modal Ends  */}
    </div>
  );
}

import { Alert } from '../alert';

export const AlertCanceled = ({ productName }) => {
  return (
    <Alert
      type="error"
      title="Your subscription was canceled"
      text={`We hope you enjoyed your TeachTappy ${productName}`}
    />
  );
};

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function Typography() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <Typography_ />
    </PageContext.Provider>
  );
}

function Typography_() {
  return (
    <div id="content-area">
      <div className="container ">
        <div className="card">
          <div className="card-title">
            <h1>Heading</h1>
          </div>
          <div className="card-body">
            <h1>
              H1 size 36 title <small>Small title with header</small>
            </h1>
            <h2>
              H2 size 28 title <small>Small title with header</small>
            </h2>
            <h3>
              H3 size 24 title <small>Small title with header</small>
            </h3>
            <h4>
              H4 size 22 title <small>Small title with header</small>
            </h4>
            <h5>
              H5 size 20 title <small>Small title with header</small>
            </h5>
            <h6>
              H6 size 18 title <small>Small title with header</small>
            </h6>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <h1>Buttons</h1>
          </div>
          <div className="card-body">
            <h3>Button filled</h3>
            <div className="btn-group">
              <button className="btn" type="button">
                Default
              </button>
              <button className="btn btn-brand" type="button">
                Primary
              </button>
              <button className="btn btn-success" type="button">
                Success
              </button>
              <button className="btn btn-danger" type="button">
                Danger
              </button>
              <button className="btn btn-gray" type="button">
                Info
              </button>
              <button className="btn btn-warning" type="button">
                Warning
              </button>
            </div>
            <h3>Button outline</h3>
            <div className="btn-group">
              <button className="btn btn-bdr" type="button">
                Default
              </button>
              <button className="btn btn-brand btn-bdr" type="button">
                Primary
              </button>
              <button className="btn btn-success btn-bdr" type="button">
                Success
              </button>
              <button className="btn btn-danger btn-bdr" type="button">
                Danger
              </button>
              <button className="btn btn-gray btn-bdr" type="button">
                Info
              </button>
              <button className="btn btn-warning btn-bdr" type="button">
                Warning
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <h1>listing</h1>
          </div>
          <div className="card-body">
            <ol>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li> Morbi ac justo dictum, vestibulum orci sit amet, euismod neque. </li>
              <li>In consectetur est eget pellentesque lobortis.</li>
              <li>Suspendisse suscipit mauris eu libero varius sollicitudin.</li>
            </ol>
            <p>
              Lorem ipsum dolor sit amet, <strong>consectetur</strong> adipisicing elit. Qui aut, dolore eaque sint
              tenetur fugit ut quas maiores. Veritatis omnis numquam quasi cumque recusandae fugiat expedita, maxime
              aspernatur vero id.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui aut, dolore eaque sint
              tenetur fugit ut quas maiores. Veritatis omnis numquam quasi cumque recusandae fugiat expedita, maxime
              aspernatur vero id.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat omnis optio, sapiente eos quos enim iure
              dolorum nesciunt laboriosam tempore! Incidunt eos, doloribus. Vero esse soluta ad culpa, excepturi minus!
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia deserunt iure debitis modi rem tenetur
              molestias iusto error{' '}
              <em>
                asperiores adipisci enim doloremque quibusdam, labore animi, omnis magnam quisquam ea! Itaque impedit,
                earum laborum. Explicabo adipisci asperiores aliquid inventore, blanditiis eius.
              </em>
            </p>
            <blockquote>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia deserunt iure debitis modi rem tenetur
                molestias iusto error{' '}
                <em>
                  asperiores adipisci enim doloremque quibusdam, labore animi, omnis magnam quisquam ea! Itaque impedit,
                  earum laborum. Explicabo adipisci asperiores aliquid inventore, blanditiis eius.
                </em>
              </p>{' '}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem repellat, impedit eaque doloremque eveniet
              est ratione, animi nemo voluptates nihil perspiciatis minima ducimus! Reprehenderit nam nulla minima eum,
              debitis itaque numquam, quae laudantium suscipit officia inventore non autem, eaque tempora ipsa corrupti.
              Facere velit quae deserunt distinctio delectus vitae consequuntur dolorem beatae architecto nam, porro
              eos, asperiores in quo quod?{' '}
            </blockquote>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <h1>Forms &amp; Controls</h1>
          </div>
          <div className="card-body">
            <div className="form-group">
              <div className="fitem">
                <label className="required">Input Empty</label>
                <input type="text" />
              </div>
              <div className="fitem">
                <label>Input Disabled</label>
                <input disabled={true} type="text" value="test" />
                <label className="validate-message">Success Message will be diplayed here...</label>
              </div>
              <div className="fitem">
                <label>Input test</label>
                <input type="text" value="test" />
                <label className="validate-message">Success Message..</label>
              </div>
              <div className="fitem has-error">
                <label>Input Error</label>
                <input type="text" />
                <label className="error">Error Message will be diplayed here...</label>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
                <div className="fitem  half-fitem">
                  <label>Selected Empty</label>
                  <select>
                    <option>Select Empty</option>
                    <option>Lorem ipsum dolor,</option>
                    <option>Lorem ipsum </option>
                    <option>Lorem doloroption&gt; </option>
                  </select>
                </div>
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
                <div className="fitem half-fitem">
                  <label>Input label</label>
                  <input type="text" />
                </div>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem inline-check-list">
                  <div className="fitem-check">
                    <input className="fitem-ck-input" id="radio1" name="test" type="radio" />
                    <label className="fitem-ck-txt" htmlFor="radio1">
                      This is radio btn
                    </label>
                  </div>
                  <div className="fitem-check">
                    <input className="fitem-ck-input" id="radio2" name="test" type="radio" />
                    <label className="fitem-ck-txt" htmlFor="radio2">
                      This is radio btn
                    </label>
                  </div>
                </div>
                <div className="fitem half-fitem inline-check-list">
                  <div className="fitem-check">
                    <input className="fitem-ck-input" id="checkbox" name="checkboxs" type="checkbox" />
                    <label className="fitem-ck-txt" htmlFor="checkbox">
                      This is checkbox btn
                    </label>
                  </div>
                  <div className="fitem-check">
                    <input className="fitem-ck-input" id="checkbox1" name="checkboxsa" type="checkbox" />
                    <label className="fitem-ck-txt" htmlFor="checkbox1">
                      This is checkbox btn
                    </label>
                  </div>
                </div>
              </div>
              <div className="fitem">
                <label>Selected Empty</label>
                <select>
                  <option>Select Empty</option>
                  <option>Lorem ipsum dolor,</option>
                  <option>Lorem ipsum </option>
                  <option>Lorem doloroption&gt; </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title">
            <h1>Tables</h1>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="container">
              <h1>tara</h1>
              <p className="small">
                This font was created with<a href="http://fontastic.me/">Fontastic</a>
              </p>
              <h2>CSS mapping</h2>
              <ul className="glyphs css-mapping">
                <li>
                  <div className="icon icon-add"></div>
                  <input type="text" readOnly value="add" />
                </li>
                <li>
                  <div className="icon icon-arrow"></div>
                  <input type="text" readOnly value="arrow" />
                </li>
                <li>
                  <div className="icon icon-article"></div>
                  <input type="text" readOnly value="article" />
                </li>
                <li>
                  <div className="icon icon-auto-stories"></div>
                  <input type="text" readOnly value="auto-stories" />
                </li>
                <li>
                  <div className="icon icon-calendar-view-day"></div>
                  <input type="text" readOnly value="calendar-view-day" />
                </li>
                <li>
                  <div className="icon icon-calendar-view-week"></div>
                  <input type="text" readOnly value="calendar-view-week" />
                </li>
                <li>
                  <div className="icon icon-center-focus-weak"></div>
                  <input type="text" readOnly value="center-focus-weak" />
                </li>
                <li>
                  <div className="icon icon-chair-alt"></div>
                  <input type="text" readOnly value="chair-alt" />
                </li>
                <li>
                  <div className="icon icon-class"></div>
                  <input type="text" readOnly value="class" />
                </li>
                <li>
                  <div className="icon icon-dashboard"></div>
                  <input type="text" readOnly value="dashboard" />
                </li>
                <li>
                  <div className="icon icon-done"></div>
                  <input type="text" readOnly value="done" />
                </li>
                <li>
                  <div className="icon icon-edit icons-lg"></div>
                  <input type="text" readOnly value="edit" />
                </li>
                <li>
                  <div className="icon icon-email"></div>
                  <input type="text" readOnly value="email" />
                </li>
                <li>
                  <div className="icon icon-poll"></div>
                  <input type="text" readOnly value="poll" />
                </li>
                <li>
                  <div className="icon icon-play-lesson"></div>
                  <input type="text" readOnly value="play-lesson" />
                </li>
                <li>
                  <div className="icon icon-person"></div>
                  <input type="text" readOnly value="person" />
                </li>
                <li>
                  <div className="icon icon-notifications"></div>
                  <input type="text" readOnly value="notifications" />
                </li>
                <li>
                  <div className="icon icon-looks-one"></div>
                  <input type="text" readOnly value="looks-one" />
                </li>
                <li>
                  <div className="icon icon-lock"></div>
                  <input type="text" readOnly value="lock" />
                </li>
                <li>
                  <div className="icon icon-language"></div>
                  <input type="text" readOnly value="language" />
                </li>
                <li>
                  <div className="icon icon-link"></div>
                  <input type="text" readOnly value="link" />
                </li>
                <li>
                  <div className="icon icon-keyboard-arrow-down"></div>
                  <input type="text" readOnly value="keyboard-arrow-down" />
                </li>
                <li>
                  <div className="icon icon-home"></div>
                  <input type="text" readOnly value="home" />
                </li>
                <li>
                  <div className="icon icon-help"></div>
                  <input type="text" readOnly value="help" />
                </li>
                <li>
                  <div className="icon icon-flag"></div>
                  <input type="text" readOnly value="flag" />
                </li>
                <li>
                  <div className="icon icon-emoji-emotions"></div>
                  <input type="text" readOnly value="emoji-emotions" />
                </li>
                <li>
                  <div className="icon icon-radar"></div>
                  <input type="text" readOnly value="radar" />
                </li>
                <li>
                  <div className="icon icon-remove"></div>
                  <input type="text" readOnly value="remove" />
                </li>
                <li>
                  <div className="icon icon-school"></div>
                  <input type="text" readOnly value="school" />
                </li>
                <li>
                  <div className="icon icon-search"></div>
                  <input type="text" readOnly value="search" />
                </li>
                <li>
                  <div className="icon icon-settings"></div>
                  <input type="text" readOnly value="settings" />
                </li>
                <li>
                  <div className="icon icon-star"></div>
                  <input type="text" readOnly value="star" />
                </li>
                <li>
                  <div className="icon icon-today"></div>
                  <input type="text" readOnly value="today" />
                </li>
                <li>
                  <div className="icon icon-topic"></div>
                  <input type="text" readOnly value="topic" />
                </li>
                <li>
                  <div className="icon icon-watch-later"></div>
                  <input type="text" readOnly value="watch-later" />
                </li>
                <li>
                  <div className="icon icon-web"></div>
                  <input type="text" readOnly value="web" />
                </li>
                <li>
                  <div className="icon icon-courses"></div>
                  <input type="text" readOnly value="courses" />
                </li>
                <li>
                  <div className="icon icon-dashboard-1"></div>
                  <input type="text" readOnly value="dashboard-1" />
                </li>
                <li>
                  <div className="icon icon-logout"></div>
                  <input type="text" readOnly value="logout" />
                </li>
                <li>
                  <div className="icon icon-settings-fill"></div>
                  <input type="text" readOnly value="settings-fill" />
                </li>
                <li>
                  <div className="icon icon-dubble-right"></div>
                  <input type="text" readOnly value="dubble-right" />
                </li>
                <li>
                  <div className="icon icon-backpack"></div>
                  <input type="text" readOnly value="backpack" />
                </li>
                <li>
                  <div className="icon icon-bell"></div>
                  <input type="text" readOnly value="bell" />
                </li>
                <li>
                  <div className="icon icon-books"></div>
                  <input type="text" readOnly value="books" />
                </li>
                <li>
                  <div className="icon icon-calendar-default-state"></div>
                  <input type="text" readOnly value="calendar-default-state" />
                </li>
                <li>
                  <div className="icon icon-caret-down"></div>
                  <input type="text" readOnly value="caret-down" />
                </li>
                <li>
                  <div className="icon icon-clipboard"></div>
                  <input type="text" readOnly value="clipboard" />
                </li>
                <li>
                  <div className="icon icon-grip-dots-vertical"></div>
                  <input type="text" readOnly value="grip-dots-vertical" />
                </li>
                <li>
                  <div className="icon icon-icons-apple"></div>
                  <input type="text" readOnly value="icons-apple" />
                </li>
                <li>
                  <div className="icon icon-chalkboard-user"></div>
                  <input type="text" readOnly value="chalkboard-user" />
                </li>
                <li>
                  <div className="icon icon-clock-fill"></div>
                  <input type="text" readOnly value="clock-fill" />
                </li>
                <li>
                  <div className="icon icon-memo-fill"></div>
                  <input type="text" readOnly value="memo-fill" />
                </li>
                <li>
                  <div className="icon icon-note"></div>
                  <input type="text" readOnly value="note" />
                </li>
                <li>
                  <div className="icon icon-pen-ruler"></div>
                  <input type="text" readOnly value="pen-ruler" />
                </li>
                <li>
                  <div className="icon icon-notebook"></div>
                  <input type="text" readOnly value="notebook" />
                </li>
                <li>
                  <div className="icon icon-clock-desk"></div>
                  <input type="text" readOnly value="clock-desk" />
                </li>
                <li>
                  <div className="icon icon-next"></div>
                  <input type="text" readOnly value="next" />
                </li>
                <li>
                  <div className="icon icon-prev"></div>
                  <input type="text" readOnly value="prev" />
                </li>
                <li>
                  <div className="icon icon-book"></div>
                  <input type="text" readOnly value="book" />
                </li>
                <li>
                  <div className="icon icon-eye-none"></div>
                  <input type="text" readOnly value="eye-none" />
                </li>
                <li>
                  <div className="icon icon-eye"></div>
                  <input type="text" readOnly value="eye" />
                </li>
                <li>
                  <div className="icon icon-book"></div>
                  <input type="text" readOnly value="book" />
                </li>
                <li>
                  <div className="icon icon-circle-check"></div>
                  <input type="text" readOnly value="circle-check" />
                </li>
                <li>
                  <div className="icon icon-delete"></div>
                  <input type="text" readOnly value="delete" />
                </li>
                <li>
                  <div className="icon icon-edits"></div>
                  <input type="text" readOnly value="edits" />
                </li>
                <li>
                  <div className="icon icon-check"></div>
                  <input type="text" readOnly value="check" />
                </li>
                <li>
                  <div className="icon icon-uncheck"></div>
                  <input type="text" readOnly value="uncheck" />
                </li>
                <li>
                  <div className="icon icon-attention"></div>
                  <input type="text" readOnly value="uncheck" />
                </li>
              </ul>
              <h2>Character mapping</h2>
              <ul className="glyphs character-mapping">
                <li>
                  <div data-icon="a" className="icon"></div>
                  <input type="text" readOnly value="a" />
                </li>
                <li>
                  <div data-icon="b" className="icon"></div>
                  <input type="text" readOnly value="b" />
                </li>
                <li>
                  <div data-icon="c" className="icon"></div>
                  <input type="text" readOnly value="c" />
                </li>
                <li>
                  <div data-icon="d" className="icon"></div>
                  <input type="text" readOnly value="d" />
                </li>
                <li>
                  <div data-icon="e" className="icon"></div>
                  <input type="text" readOnly value="e" />
                </li>
                <li>
                  <div data-icon="f" className="icon"></div>
                  <input type="text" readOnly value="f" />
                </li>
                <li>
                  <div data-icon="g" className="icon"></div>
                  <input type="text" readOnly value="g" />
                </li>
                <li>
                  <div data-icon="h" className="icon"></div>
                  <input type="text" readOnly value="h" />
                </li>
                <li>
                  <div data-icon="i" className="icon"></div>
                  <input type="text" readOnly value="i" />
                </li>
                <li>
                  <div data-icon="j" className="icon"></div>
                  <input type="text" readOnly value="j" />
                </li>
                <li>
                  <div data-icon="k" className="icon"></div>
                  <input type="text" readOnly value="k" />
                </li>
                <li>
                  <div data-icon="l" className="icon"></div>
                  <input type="text" readOnly value="l" />
                </li>
                <li>
                  <div data-icon="m" className="icon"></div>
                  <input type="text" readOnly value="m" />
                </li>
                <li>
                  <div data-icon="n" className="icon"></div>
                  <input type="text" readOnly value="n" />
                </li>
                <li>
                  <div data-icon="o" className="icon"></div>
                  <input type="text" readOnly value="o" />
                </li>
                <li>
                  <div data-icon="p" className="icon"></div>
                  <input type="text" readOnly value="p" />
                </li>
                <li>
                  <div data-icon="q" className="icon"></div>
                  <input type="text" readOnly value="q" />
                </li>
                <li>
                  <div data-icon="r" className="icon"></div>
                  <input type="text" readOnly value="r" />
                </li>
                <li>
                  <div data-icon="s" className="icon"></div>
                  <input type="text" readOnly value="s" />
                </li>
                <li>
                  <div data-icon="t" className="icon"></div>
                  <input type="text" readOnly value="t" />
                </li>
                <li>
                  <div data-icon="u" className="icon"></div>
                  <input type="text" readOnly value="u" />
                </li>
                <li>
                  <div data-icon="v" className="icon"></div>
                  <input type="text" readOnly value="v" />
                </li>
                <li>
                  <div data-icon="w" className="icon"></div>
                  <input type="text" readOnly value="w" />
                </li>
                <li>
                  <div data-icon="x" className="icon"></div>
                  <input type="text" readOnly value="x" />
                </li>
                <li>
                  <div data-icon="y" className="icon"></div>
                  <input type="text" readOnly value="y" />
                </li>
                <li>
                  <div data-icon="z" className="icon"></div>
                  <input type="text" readOnly value="z" />
                </li>
                <li>
                  <div data-icon="A" className="icon"></div>
                  <input type="text" readOnly value="A" />
                </li>
                <li>
                  <div data-icon="B" className="icon"></div>
                  <input type="text" readOnly value="B" />
                </li>
                <li>
                  <div data-icon="C" className="icon"></div>
                  <input type="text" readOnly value="C" />
                </li>
                <li>
                  <div data-icon="D" className="icon"></div>
                  <input type="text" readOnly value="D" />
                </li>
                <li>
                  <div data-icon="E" className="icon"></div>
                  <input type="text" readOnly value="E" />
                </li>
                <li>
                  <div data-icon="F" className="icon"></div>
                  <input type="text" readOnly value="F" />
                </li>
                <li>
                  <div data-icon="G" className="icon"></div>
                  <input type="text" readOnly value="G" />
                </li>
                <li>
                  <div data-icon="H" className="icon"></div>
                  <input type="text" readOnly value="H" />
                </li>
                <li>
                  <div data-icon="I" className="icon"></div>
                  <input type="text" readOnly value="I" />
                </li>
                <li>
                  <div data-icon="J" className="icon"></div>
                  <input type="text" readOnly value="J" />
                </li>
                <li>
                  <div data-icon="K" className="icon"></div>
                  <input type="text" readOnly value="K" />
                </li>
                <li>
                  <div data-icon="L" className="icon"></div>
                  <input type="text" readOnly value="L" />
                </li>
                <li>
                  <div data-icon="M" className="icon"></div>
                  <input type="text" readOnly value="M" />
                </li>
                <li>
                  <div data-icon="N" className="icon"></div>
                  <input type="text" readOnly value="N" />
                </li>

                <li>
                  <div data-icon="O" className="icon"></div>
                  <input type="text" readOnly value="O" />
                </li>
                <li>
                  <div data-icon="P" className="icon"></div>
                  <input type="text" readOnly value="P" />
                </li>
                <li>
                  <div data-icon="Q" className="icon"></div>
                  <input type="text" readOnly value="Q" />
                </li>
                <li>
                  <div data-icon="R" className="icon"></div>
                  <input type="text" readOnly value="R" />
                </li>
                <li>
                  <div data-icon="S" className="icon"></div>
                  <input type="text" readOnly value="S" />
                </li>
                <li>
                  <div data-icon="T" className="icon"></div>
                  <input type="text" readOnly value="T" />
                </li>
                <li>
                  <div data-icon="V" className="icon"></div>
                  <input type="text" readOnly value="V" />
                </li>
                <li>
                  <div data-icon="U" className="icon"></div>
                  <input type="text" readOnly value="U" />
                </li>
                <li>
                  <div data-icon="W" className="icon"></div>
                  <input type="text" readOnly value="W" />
                </li>
                <li>
                  <div data-icon="X" className="icon"></div>
                  <input type="text" readOnly value="X" />
                </li>
                <li>
                  <div data-icon="Y" className="icon"></div>
                  <input type="text" readOnly value="Y" />
                </li>
                <li>
                  <div data-icon="Z" className="icon"></div>
                  <input type="text" readOnly value="Z" />
                </li>
                <li>
                  <div data-icon="0" className="icon"></div>
                  <input type="text" readOnly value="0" />
                </li>
                <li>
                  <div data-icon="1" className="icon"></div>
                  <input type="text" readOnly value="1" />
                </li>
                <li>
                  <div data-icon="2" className="icon"></div>
                  <input type="text" readOnly value="2" />
                </li>
                <li>
                  <div data-icon="3" className="icon"></div>
                  <input type="text" readOnly value="3" />
                </li>
                <li>
                  <div data-icon="4" className="icon"></div>
                  <input type="text" readOnly value="4" />
                </li>
                <li>
                  <div data-icon="5" className="icon"></div>
                  <input type="text" readOnly value="5" />
                </li>
                <li>
                  <div data-icon="6" className="icon"></div>
                  <input type="text" readOnly value="6" />
                </li>
                <li>
                  <div data-icon="7" className="icon"></div>
                  <input type="text" readOnly value="7" />
                </li>
                <li>
                  <div data-icon="8" className="icon"></div>
                  <input type="text" readOnly value="8" />
                </li>
                <li>
                  <div data-icon="9" className="icon"></div>
                  <input type="text" readOnly value="9" />
                </li>
                <li>
                  <div data-icon="!" className="icon"></div>
                  <input type="text" readOnly value="!" />
                </li>
                <li>
                  <div data-icon="*" className="icon"></div>
                  <input type="text" readOnly value="*" />
                </li>
                <li>
                  <div data-icon="#" className="icon"></div>
                  <input type="text" readOnly value="#" />
                </li>
                <li>
                  <div data-icon="$" className="icon"></div>
                  <input type="text" readOnly value="$" />
                </li>
                <li>
                  <div data-icon='"' className="icon"></div>
                  <input type="text" readOnly value='"' />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const INITIAL_STATE = { data: {}, errors: {} };

const confirmCourse = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CONFIRM_COURSE_LESSON_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'CONFIRM_COURSE_LESSON_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default confirmCourse;

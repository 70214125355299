import {
  TEACHER_ADMIN_LOGIN_FAILURE,
  TEACHER_ADMIN_LOGIN_REQUEST,
  TEACHER_ADMIN_LOGIN_SUCCESS,
  TEACHER_ADMIN_LOGOUT
} from '../types';
import teacherAdminApi from '../../Services/teacherAdminApi';
import { tokenService } from '../../Services';
import { history } from '../../Lib/history';

export const teacherAdminLogin = (payload) => {
  return (dispatch) => {
    dispatch(request());

    return teacherAdminApi.post('/account/users/login/', payload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.setLocalAccessTokenAdmin(response.data.token);
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TEACHER_ADMIN_LOGIN_REQUEST };
  }

  function success(data) {
    return { type: TEACHER_ADMIN_LOGIN_SUCCESS, data };
  }

  function failure(error) {
    return { type: TEACHER_ADMIN_LOGIN_FAILURE, error };
  }
};

export const teacherAdminLogout = () => {
  return (dispatch) => {
    return teacherAdminApi
      .post('/account/users/logout/')
      .then((res) => {
        if (res) {
          tokenService.removeLocalAccessTokenAdmin();
          dispatch(logout());
          history.push('/teacher-admin/sign-in');
        }
        return res;
      })
      .catch((err) => {
        if (err?.response) {
          throw err?.response?.data;
        }
      });
  };

  function logout() {
    return { type: TEACHER_ADMIN_LOGOUT };
  }
};

import Loading from '../../../Layout/loading/Container';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ErrorModal from '../../Calendar/Component/ErrorModal';
import GanttView from '../../../Components/GanttView';
import { useCalendarAPI } from '../../../Services/calendar.service';
import { colors } from '../../../Services/courseColors';

const ByCourseTimeline = ({ courseIdParam, onChildLessons }) => {
  const { useGetEventsQuery, getMetadataValue, useMutateReschedule, errorModalData, setErrorModalData } =
    useCalendarAPI();

  const eventsQuery = useGetEventsQuery();
  const mutateReschedule = useMutateReschedule();

  /** @type {GetEventsResponse} */
  const calevents = eventsQuery.data;

  /** @type {string[]} */
  const [courseKeys, setCourseKeys] = useState(['All Courses']);
  const [selectedCourse] = useState(courseKeys[0]);
  const [gantTaskData, setGantTaskData] = useState([]);
  const [legend, setLegend] = useState([]);
  const [updateTaskData, setUpdateTaskData] = useState(null);
  const [expandedCourses, setExpandedCourses] = useState(courseKeys);
  const [showErrorModal, setShowErrorModal] = useState(true);

  // FLEE: DISABLE DELETE, SEE ABOUT PLACEHOLDER ROWS?
  const onDateChange = (task) => {
    const originalTask = gantTaskData.find((eachtask) => eachtask.id === task.id);
    if (!originalTask) {
      return;
    }

    const dateChangeData = {
      lessonPlanId: task.lessonPlanId,
      newDate: moment(task.end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    };

    setUpdateTaskData(dateChangeData);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({ visible: false, errorMessage: '', subMessage: '' });
  };

  useEffect(() => {
    if (!updateTaskData) return;
    mutateReschedule.mutate(updateTaskData, {
      onSettled: () => {
        setUpdateTaskData(null);
      }
    });
  }, [updateTaskData]);

  useEffect(() => {
    if (!calevents || eventsQuery.isFetching) return;
    const mapOfCourses = new Map();

    // Assemble map of courses from course metadata
    calevents.courseMetadatas.forEach((courseMetadata, index) => {
      // Only include courses that match the provided courseIdParam
      if (courseIdParam && courseIdParam !== courseMetadata.id) return;
      mapOfCourses.set(courseMetadata.id, {
        id: `${index}`,
        type: 'task',
        name: courseMetadata.course,
        start: moment(courseMetadata.start).startOf('day').toDate(),
        end: moment(courseMetadata.end).endOf('day').toDate(),
        progress: 0,
        styles: {
          backgroundColor: colors[index % colors.length],
          backgroundSelectedColor: colors[index % colors.length]
        },
        lessonPlans: []
      });
    });

    const filteredCourseId = Array.from(mapOfCourses.values()).find((course) => course.name === selectedCourse)?.id;
    const courseKeys = Array.from(mapOfCourses.values()).map((course) => course.name);

    // Assemble lesson plans into courses from event data
    calevents.events.forEach((lessonPlan) => {
      const courseIdOfLessonPlan = getMetadataValue(lessonPlan, 'course:id');
      const course = mapOfCourses.get(courseIdOfLessonPlan);
      if (!course) return;

      // Only include lesson plans that match the provided courseIdParam
      if (courseIdParam && courseIdParam !== courseIdOfLessonPlan) return;

      course.lessonPlans.push({
        id: lessonPlan.id,
        type: 'task',
        name: lessonPlan.title,
        start: moment(lessonPlan.day).startOf('day').toDate(),
        end: moment(lessonPlan.day).endOf('day').toDate(),
        progress: 0,
        styles: {
          backgroundColor: course.styles.backgroundColor,
          backgroundSelectedColor: course.styles.backgroundColor
        }
      });

      mapOfCourses.set(courseIdOfLessonPlan, course);
    });

    // Create gantt data and legend data
    const ganttData = [];
    const legendData = [];

    mapOfCourses.forEach((course) => {
      if (filteredCourseId && course.id !== filteredCourseId) return;

      // Add course to gantt data
      ganttData.push(course);

      const lessonPlans = [];

      course.lessonPlans.forEach((lessonPlan, index) => {
        lessonPlans.push({
          ...lessonPlan,
          id: `${course.id}-${index + 1}`,
          lessonPlanId: lessonPlan.id,
          courseId: course.id
        });
      });

      // Sort lesson plans based on start date
      lessonPlans.sort((a, b) => a.start - b.start);

      // Add sorted lesson plans to gantt data
      ganttData.push(...lessonPlans);

      // Add course and lesson plan names to the legend data
      legendData.push({
        course: course.name,
        courseId: course.id,
        list: lessonPlans.map((lessonPlan) => lessonPlan.name),
        color: course.styles.backgroundColor
      });
    });

    setGantTaskData(ganttData);
    setLegend(legendData);
    if (legendData[0]) {
      onChildLessons(legendData[0].list.length);
    }

    setCourseKeys(['All Courses', ...courseKeys]);
  }, [calevents, selectedCourse, eventsQuery.isFetching, courseIdParam]);

  if (!gantTaskData.length) return <Loading />;
  return (
    <div className="timeline__container">
      <div className="timeline">
        <GanttView
          title={'Courses'}
          legend={legend}
          tasks={gantTaskData}
          onDateChange={onDateChange}
          expandedCourses={expandedCourses}
          setExpandedCourses={setExpandedCourses}
        />
      </div>
      {/* Error Modal Starts */}
      <ErrorModal
        errorModalData={errorModalData}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        handleCloseErrorModal={handleCloseErrorModal}
      />
      {/* Error Modal Ends */}
    </div>
  );
};

export default ByCourseTimeline;

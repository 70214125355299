import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  TELL_US_ABOUT_YOU_REQUEST,
  TELL_US_ABOUT_YOU_SUCCESS,
  TELL_US_ABOUT_YOU_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  GET_TIMEZONES_LIST_REQUEST,
  GET_TIMEZONES_LIST_SUCCESS,
  GET_TIMEZONES_LIST_FAILURE
} from '../Action/types';

const REGISTRATION_INITIAL_STATE = { loading: false, data: {}, error: {} };
const EMAIL_VERIFICATION_INITIAL_STATE = { loading: false, data: {}, error: {} };
const TELL_US_ABOUT_YOU_INITIAL_STATE = { loading: false, data: {}, error: {} };
const RESEND_CODE_INITIAL_STATE = { loading: false, data: {}, error: {} };
const RESET_PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };
const SET_NEW_PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };
const UPDATE_USER__PASSWORD_INITIAL_STATE = { loading: false, data: {}, error: {} };
const GET_TIMEZONES_LIST_INITIAL_STATE = { loading: false, data: {}, error: {} };

export const registration = (state = REGISTRATION_INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case REGISTER_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case REGISTER_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const emailVerification = (state = EMAIL_VERIFICATION_INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_VERIFICATION_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case EMAIL_VERIFICATION_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case EMAIL_VERIFICATION_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const tellUsAboutYou = (state = TELL_US_ABOUT_YOU_INITIAL_STATE, action) => {
  switch (action.type) {
    case TELL_US_ABOUT_YOU_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case TELL_US_ABOUT_YOU_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case TELL_US_ABOUT_YOU_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const resendCode = (state = RESEND_CODE_INITIAL_STATE, action) => {
  switch (action.type) {
    case RESEND_CODE_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case RESEND_CODE_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case RESEND_CODE_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const resetPassword = (state = RESET_PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const setNewPassword = (state = SET_NEW_PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NEW_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case SET_NEW_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case SET_NEW_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const updateUserPassword = (state = UPDATE_USER__PASSWORD_INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_PASSWORD_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case UPDATE_USER_PASSWORD_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

export const getTimeZonesList = (state = GET_TIMEZONES_LIST_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TIMEZONES_LIST_REQUEST:
      return { ...state, loading: true, data: {}, error: {} };
    case GET_TIMEZONES_LIST_SUCCESS:
      return { ...state, loading: false, data: action.data, error: {} };
    case GET_TIMEZONES_LIST_FAILURE:
      return { ...state, loading: false, data: {}, error: action.error };
    default:
      return state;
  }
};

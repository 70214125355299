import { React, useState } from 'react';
import { Navigate, Views } from 'react-big-calendar';
import { Select } from 'antd';

const { Option } = Select;

const ToolbarWoLegend = ({ label, onNavigate, onView }) => {
  const goToToday = () => {
    const now = new Date();
    setCurrentYear(now.getFullYear());
    onNavigate(Navigate.TODAY, now);
    onView(Views.MONTH);
  };

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const navigate = (action) => {
    onNavigate(action);
  };

  const handleYearChange = (newYear) => {
    setCurrentYear(newYear);
    const newDate = new Date();
    newDate.setFullYear(newYear);
    onNavigate(Navigate.DATE, newDate);
  };

  // Generate an array of years for the dropdown. Adjust as needed.
  const years = [];
  for (let i = new Date().getFullYear() - 5; i <= new Date().getFullYear() + 5; i++) {
    years.push(i);
  }

  return (
    <div className="cust-bg-toolbar">
      <div className="nav-section">
        <div className="nav-section-left">
          <button className="today-btn" onClick={goToToday}>
            Today
          </button>
          <Select className="year-select" value={currentYear} onChange={handleYearChange}>
            {years.map((year, index) => (
              <Option style={{ color: '257A8E' }} key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>
        <div className="nav-section-right">
          <span className="right-label">{label}</span>
          <button className="nav-btn" onClick={() => navigate(Navigate.PREVIOUS)}>
            <i className="fa-regular fa-chevron-left"></i>
          </button>
          <button className="nav-btn" onClick={() => navigate(Navigate.NEXT)}>
            <i className="fa-regular fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToolbarWoLegend;

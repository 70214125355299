import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import Loading from '../../../../Layout/loading/Container';
import { tokenService } from '../../../../Services';
import adminLogo from '../../../../assets/images/admin-logo.svg';
const PageContext = React.createContext();

export default function ResetPasswordEmailVerification(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({ errors: {}, code: '' });
  const [display, setDisplay] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <PageContext.Provider
      value={{ loader, setLoader, state, setState, display, setDisplay, disabled, setDisabled, history, ...props }}>
      <RESETEPASSWORDMAILVERIFICATION />
    </PageContext.Provider>
  );
}

function RESETEPASSWORDMAILVERIFICATION() {
  const {
    loader,
    setLoader,
    state,
    setState,
    display,
    setDisplay,
    disabled,
    setDisabled,
    history,
    teacherAdminEmailVerification,
    teacherAdminResendCode
  } = useContext(PageContext);
  const { code, errors } = state;

  React.useEffect(() => {
    delay();
  }, []);

  const validate = () => {
    const schema = yup.object().shape({
      code: yup.string().required('Field cannot be left blank')
    });
    const data = { code };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setLoader(true);
        const body = {
          code
        };
        Promise.all([teacherAdminEmailVerification(body)])
          .then(() => {
            setState((s) => ({ ...s, errors: {} }));
            history.push('/teacher-admin/create-new-password');
            setLoader(false);
          })
          .catch((err) => {
            let err_data = {};
            if (err?.detail) {
              err_data['code'] = err?.detail;
            }
            setState((s) => ({ ...s, errors: err_data }));
            setLoader(false);
          });
      })
      .catch((error) => {
        let err_data = {};
        error?.inner.map((item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  const resendCodeHandler = () => {
    setLoader(true);
    Promise.all([teacherAdminResendCode()])
      .then(() => {
        delay();
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const backToResetPassword = () => {
    tokenService.removeEmail();
    history.push('/teacher-admin/reset-password');
  };

  const delay = () => {
    setDisplay(true);
    setDisabled(true);
    const displayTimeOut = setTimeout(() => {
      setDisplay(false);
      clearTimeout(displayTimeOut);
    }, 3000);
    const disableTimeOut = setTimeout(() => {
      setDisabled(false);
      clearTimeout(disableTimeOut);
    }, 60000);
  };

  return (
    <div id="content-area" className="admin-login-theme">
      <div className="admin-linner-outer">
        <div className="admin-linner">
          {loader && <Loading />}
          <div className="title-row">
            <img className="adminlogo" src={adminLogo} alt="" />
            <h1 className="text-center">Email verification</h1>
            <p className="text-left">
              Enter the 6-character validation code sent to your email (it may take up to 5 <br /> min to be delivered).
              If you don’t see it, check your spam folder.
            </p>
          </div>
          <form action="" className="form-group">
            <div className={errors['code'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                6-Character Verification Code
              </label>
              <input
                type="text"
                placeholder="6-character code"
                onChange={(e) =>
                  setState((s) => ({
                    ...s,
                    code: e.target.value
                  }))
                }
              />
              {errors['code'] ? <label className="error">{errors['code']}</label> : ''}
            </div>
            <div className="btn-group pt-20">
              <button
                type="button"
                className={code ? 'btn btn-brand btn-full' : 'btn btn-brand btn-full disabled'}
                onClick={() => validate()}>
                Submit Code
              </button>
              <p className="pt-20 clr-black" style={{ marginBottom: '8px' }}>
                Didn’t get the code?
                <strong>
                  <Link
                    className={disabled ? 'btn-txt btn disabled ml-5' : 'btn-txt btn ml-5'}
                    to={'#'}
                    onClick={() => resendCodeHandler()}>
                    Resend Code
                  </Link>
                </strong>
              </p>
              <i>The link above will be temporarily disabled for 60 seconds after a request.</i>
              <p className="pt-20 clr-black">
                Or check your email address and try again
                <strong>
                  <Link className="btn-txt btn ml-5" to={'#'} onClick={() => backToResetPassword()}>
                    Back to reset password
                  </Link>
                </strong>
              </p>
            </div>
          </form>
        </div>
        {display && (
          <div className="block-row pt-20">
            <div className="info-card acard">
              <div className="caption">
                <i className="icon icon-circle-check"></i>
                <p>You’ve got mail! Check your email inbox for the code.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import * as moment from 'moment';
import { Alert } from '../alert';

export const AlertCancelAt = ({ date }) => {
  return (
    <Alert
      type="error"
      text={'Your plan will be canceled at the end of the current period (' + moment(date).format('YYYY-DD-MM') + ')'}
    />
  );
};

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const USERS_LOGOUT = 'USERS_LOGOUT';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export const GET_TIMEZONES_LIST_REQUEST = 'GET_TIMEZONES_LIST_REQUEST';
export const GET_TIMEZONES_LIST_SUCCESS = 'GET_TIMEZONES_LIST_SUCCESS';
export const GET_TIMEZONES_LIST_FAILURE = 'GET_TIMEZONES_LIST_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';

export const TELL_US_ABOUT_YOU_REQUEST = 'TELL_US_ABOUT_YOU_REQUEST';
export const TELL_US_ABOUT_YOU_SUCCESS = 'TELL_US_ABOUT_YOU_SUCCESS';
export const TELL_US_ABOUT_YOU_FAILURE = 'TELL_US_ABOUT_YOU_FAILURE';

export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST';
export const RESEND_CODE_SUCCESS = 'RESEND_CODE_SUCCESS';
export const RESEND_CODE_FAILURE = 'RESEND_CODE_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const GET_SCHOOL_COURSE_ASSESSMENTS_SUCCESSFULLY = 'GET_SCHOOL_COURSE_ASSESSMENTS_SUCCESSFULLY';
export const GET_SCHOOL_COURSE_ASSESSMENTS_FAILED = 'GET_SCHOOL_COURSE_ASSESSMENTS_FAILED';

export const ADD_SCHOOL_COURSE_SUCCESSFULLY = 'ADD_SCHOOL_COURSE_SUCCESSFULLY';
export const ADD_SCHOOL_COURSE_FAILED = 'ADD_SCHOOL_COURSE_FAILED';
export const DISMISS_COMPLETE_PROFILE = 'DISMISS_COMPLETE_PROFILE';
export const DISMISS_LESSION_PLANS = 'DISMISS_LESSION_PLANS';

export const COURSE_ALL_REQUEST = 'COURSE_ALL_REQUEST';
export const COURSE_ALL_SUCCESS = 'COURSE_ALL_SUCCESS';
export const COURSE_ALL_FAILURE = 'COURSE_ALL_FAILURE';

export const GET_COURSE_DETAILS_REQUEST = 'GET_COURSE_DETAILS_REQUEST';
export const GET_COURSE_DETAILS_SUCCESSFULLY = 'GET_COURSE_DETAILS_SUCCESSFULLY';
export const GET_COURSE_DETAILS_FAILED = 'GET_COURSE_DETAILS_FAILED';
export const DISMISS_COURSES_SET_CTA = 'DISMISS_COURSES_SET_CTA';

export const DELETE_SCHOOL_COURSE_SUCCESSFULLY = 'DELETE_SCHOOL_COURSE_SUCCESSFULLY';
export const DELETE_SCHOOL_COURSE_FAILED = 'DELETE_SCHOOL_COURSE_FAILED';

export const UPDATE_SCHOOL_COURSE_SUCCESSFULLY = 'UPDATE_SCHOOL_COURSE_SUCCESSFULLY';
export const UPDATE_SCHOOL_COURSE_FAILED = 'UPDATE_SCHOOL_COURSE_FAILED';

export const GET_COURSE_SCHEDULE_SUCCESSFULLY = 'GET_COURSE_SCHEDULE_SUCCESSFULLY';
export const GET_COURSE_SCHEDULE_FAILED = 'GET_COURSE_SCHEDULE_FAILED';

export const GET_COURSE_LESSONS_SUCCESSFULLY = 'GET_COURSE_LESSONS_SUCCESSFULLY';
export const GET_COURSE_LESSONS_FAILED = 'GET_COURSE_LESSONS_FAILED';

export const GET_COURSE_LESSONS_BY_ID_SUCCESSFULLY = 'GET_COURSE_LESSONS_BY_ID_SUCCESSFULLY';
export const GET_COURSE_LESSONS_BY_ID_FAILED = 'GET_COURSE_LESSONS_BY_ID_FAILED';

export const ADD_COURSE_LESSON_SUCCESSFULLY = 'ADD_COURSE_LESSON_SUCCESSFULLY';
export const ADD_COURSE_LESSON_FAILED = 'ADD_COURSE_LESSON_FAILED';

export const UPDATE_COURSE_LESSON_SUCCESSFULLY = 'UPDATE_COURSE_LESSON_SUCCESSFULLY';
export const UPDATE_COURSE_LESSON_FAILED = 'UPDATE_COURSE_LESSON_FAILED';

export const GET_INSTRUCTIONAL_EVENT_CATEGORIES_SUCCESSFULLY = 'GET_INSTRUCTIONAL_EVENT_CATEGORIES_SUCCESSFULLY';
export const GET_INSTRUCTIONAL_EVENT_CATEGORIES_FAILED = 'GET_INSTRUCTIONAL_EVENT_CATEGORIES_FAILED';

export const ADD_INSTRUCTIONAL_EVENT_SUCCESSFULLY = 'ADD_INSTRUCTIONAL_EVENT_SUCCESSFULLY';
export const ADD_INSTRUCTIONAL_EVENT_FAILED = 'ADD_INSTRUCTIONAL_EVENT_FAILED';

export const UPDATE_INSTRUCTIONAL_EVENT_SUCCESSFULLY = 'UPDATE_INSTRUCTIONAL_EVENT_SUCCESSFULLY';
export const UPDATE_INSTRUCTIONAL_EVENT_FAILED = 'UPDATE_INSTRUCTIONAL_EVENT_FAILED';

export const DELETE_INSTRUCTIONAL_EVENT_SUCCESSFULLY = 'DELETE_INSTRUCTIONAL_EVENT_SUCCESSFULLY';
export const DELETE_INSTRUCTIONAL_EVENT_FAILED = 'DELETE_INSTRUCTIONAL_EVENT_FAILED';

export const GET_INSTRUCTIONAL_EVENTS_SUCCESSFULLY = 'GET_INSTRUCTIONAL_EVENTS_SUCCESSFULLY';
export const GET_INSTRUCTIONAL_EVENTS_FAILED = 'GET_INSTRUCTIONAL_EVENTS_FAILED';

export const GET_INSTRUCTIONAL_EVENT_SUCCESSFULLY = 'GET_INSTRUCTIONAL_EVENT_SUCCESSFULLY';
export const GET_INSTRUCTIONAL_EVENT_FAILED = 'GET_INSTRUCTIONAL_EVENT_FAILED';

export const UPDATE_COURSE_ORDER_INDEX_REQUEST = 'UPDATE_COURSE_ORDER_INDEX_REQUEST';
export const UPDATE_COURSE_ORDER_INDEX_SUCCESS = 'UPDATE_COURSE_ORDER_INDEX_SUCCESS';
export const UPDATE_COURSE_ORDER_INDEX_FAILURE = 'UPDATE_COURSE_ORDER_INDEX_FAILURE';

/* TEACHER ADMIN START */

export const TEACHER_ADMIN_LOGIN_REQUEST = 'TEACHER_ADMIN_LOGIN_REQUEST';
export const TEACHER_ADMIN_LOGIN_SUCCESS = 'TEACHER_ADMIN_LOGIN_SUCCESS';
export const TEACHER_ADMIN_LOGIN_FAILURE = 'TEACHER_ADMIN_LOGIN_FAILURE';

export const TEACHER_ADMIN_LOGOUT = 'TEACHER_ADMIN_LOGOUT';

export const TEACHER_ADMIN_RESEND_CODE_REQUEST = 'TEACHER_ADMIN_RESEND_CODE_REQUEST';
export const TEACHER_ADMIN_RESEND_CODE_SUCCESS = 'TEACHER_ADMIN_RESEND_CODE_SUCCESS';
export const TEACHER_ADMIN_RESEND_CODE_FAILURE = 'TEACHER_ADMIN_RESEND_CODE_FAILURE';

export const GET_ALL_USERS_SUCCESSFULLY = 'GET_ALL_USERS_SUCCESSFULLY';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const SUSPENSE_USERS_SUCCESSFULLY = 'SUSPENSE_USERS_SUCCESSFULLY';
export const SUSPENSE_USERS_FAILED = 'SUSPENSE_USERS_FAILED';

export const TEACHER_ADMIN_RESET_PASSWORD_REQUEST = 'TEACHER_ADMIN_RESET_PASSWORD_REQUEST';
export const TEACHER_ADMIN_RESET_PASSWORD_SUCCESS = 'TEACHER_ADMIN_RESET_PASSWORD_SUCCESS';
export const TEACHER_ADMIN_RESET_PASSWORD_FAILURE = 'TEACHER_ADMIN_RESET_PASSWORD_FAILURE';

export const TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST = 'TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_REQUEST';
export const TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS = 'TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_SUCCESS';
export const TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE = 'TEACHER_ADMIN_RESET_EMAIL_VERIFICATION_FAILURE';

export const TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST = 'TEACHER_ADMIN_SET_NEW_PASSWORD_REQUEST';
export const TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS = 'TEACHER_ADMIN_SET_NEW_PASSWORD_SUCCESS';
export const TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE = 'TEACHER_ADMIN_SET_NEW_PASSWORD_FAILURE';

export const TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST = 'TEACHER_ADMIN_CHANGE_PASSWORD_REQUEST';
export const TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS = 'TEACHER_ADMIN_CHANGE_PASSWORD_SUCCESS';
export const TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE = 'TEACHER_ADMIN_CHANGE_PASSWORD_FAILURE';
/* TEACHER ADMIN END */

export const CONFIRM_COURSE_LESSON_SUCCESSFULLY = 'CONFIRM_COURSE_LESSON_SUCCESSFULLY';
export const CONFIRM_COURSE_LESSON_FAILED = 'CONFIRM_COURSE_LESSON_FAILED';

export const CONFIRM_INSTRUCTIONAL_EVENT_SUCCESSFULLY = 'CONFIRM_INSTRUCTIONAL_EVENT_SUCCESSFULLY';
export const CONFIRM_INSTRUCTIONAL_EVENT_FAILED = 'CONFIRM_INSTRUCTIONAL_EVENT_FAILED';

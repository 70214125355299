import styles from './alert.module.scss';

export const Alert = ({ text, type, title }) => {
  const alertType = {
    success: {
      icon: 'fa-solid fa-circle-check',
      color: '#1c8d64',
      background: '#e3f4ee'
    },
    error: {
      icon: 'fa-solid fa-circle-exclamation',
      color: '#cb3b0b',
      background: '#ffedd5',
      borderColor: '#cb3b0b'
    },
    info: {
      icon: 'fa-solid fa-circle-info',
      color: '#257a8e',
      background: '#e1f2f2'
    }
  };

  return (
    <div
      className={styles.alertContainer}
      style={{ backgroundColor: alertType[type].background, borderColor: alertType[type].borderColor }}>
      <i className={alertType[type].icon} style={{ color: alertType[type].color }}></i>
      <div className={styles.textsContainer}>
        {!!title && <span className={styles.alertTitle}>{title}</span>}
        <p className={styles.alertText}>{text}</p>
      </div>
    </div>
  );
};

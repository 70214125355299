import React, { useState } from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';

import styles from './sponsoredSubscriptionForm.module.scss';
import { Alert } from '../../molecules';
import { toast } from 'react-toastify';

export const SponsoredSubscriptionForm = ({ sendEmailToSponsor, isSentEmailToSponsor }) => {
  const location = useLocation();
  const url = location.state.url;
  const [isCopied, setIsCopied] = useState(false);
  const [isSentEmail, setIsSentEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [isNotValidEmail, setIsNotValidEmail] = useState(false);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setIsCopied(true);
    } catch (error) {
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  const handleSendEmail = async () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      setIsNotValidEmail(true);
    } else {
      setIsSentEmail(true);
      setIsNotValidEmail(false);
      sendEmailToSponsor(email, name, url);
    }
  };

  return (
    <div className={styles.sponsoredSubscriptionFormContainer}>
      <h1 className={`heading1 ${styles.header}`}>Sponsored subscription</h1>
      <p className={`body ${styles.subheader}`}>
        Send a direct link or email for your sponsor to pay for your subscription
      </p>
      <Alert
        type="info"
        text="Once sent, your sponsor will be able to see all subscription details and your personal information to complete
          the payment."
      />
      <div className={styles.generateLinkSection}>
        <p className={`body ${styles.generateLinkText}`}>Generate a direct link</p>
        <div className={styles.inputContainer}>
          <input type="text" placeholder={url} disabled />
          <Button
            className={isCopied ? 'btn btn-disabled' : 'btn btn-brand'}
            disabled={isCopied}
            onClick={handleCopyLink}>
            {isCopied ? 'Copied Link' : 'Copy Link'}
            {isCopied && <i className="fa-solid fa-check"></i>}
          </Button>
        </div>
      </div>
      <div className={styles.dividerContainer}>
        <div className={styles.customDivider}></div>
        <span className={styles.orText}>Or</span>
        <div className={styles.customDivider}></div>
      </div>
      <p className={`body ${styles.generateLinkText}`}>Send through email</p>
      <div className={styles.inputContainer}>
        <div className={styles.inputName}>
          <input type="text" placeholder="Name..." onChange={(e) => setName(e.target.value)} />
        </div>
      </div>
      <div className={styles.generateLinkSection}>
        <div className={styles.inputContainer}>
          <div className={styles.inputLink}>
            <input
              type="text"
              placeholder="janeanderson@easthighschool.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            {isNotValidEmail && (
              <span className={`body ${styles.inputError}`}>
                The email format is not valid. Please enter a valid email address.
              </span>
            )}
          </div>
          <Button
            className="btn btn-white-bg"
            style={{ borderColor: isSentEmail ? '#81c0c1' : '#f8c341' }}
            disabled={isSentEmail}
            onClick={handleSendEmail}>
            {isSentEmail ? 'Email Sent' : 'Send Link'}
            {isSentEmail && <i className="fa-solid fa-check"></i>}
          </Button>
        </div>
      </div>
      {isSentEmailToSponsor && (
        <Alert
          type="success"
          text="Email sent successfully. The holder will receive your request, we’ll let you know through email once it’s been accepted."
        />
      )}
    </div>
  );
};

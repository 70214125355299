const INITIAL_STATE = { data: {}, errors: {} };

const getSchoolCourseAssessments = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_SCHOOL_COURSE_ASSESSMENTS_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'GET_SCHOOL_COURSE_ASSESSMENTS_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default getSchoolCourseAssessments;

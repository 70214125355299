import styles from './subscription.module.scss';

export const SubscriptionLayout = ({ asideContent: AsideContent, content: Content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.asideContent}>
        <AsideContent />
      </div>
      <div className={styles.content}>
        <Content />
      </div>
    </div>
  );
};

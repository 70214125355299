import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { Modal, Button } from 'antd';
import { SidebarContext } from '../../../../Contexts/SidebarContext';
import routes from '../../../../routes';
import mainlogo from '../../../../assets/images/mainlogo.svg';
import Loading from '../../../loading/Container';

const PageContext = React.createContext();

export default function Sidebar(props) {
  const location = useLocation();
  const [state, setState] = useState({ errors: {} });
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);
  const [courseList, setCourseList] = useState({});

  return (
    <PageContext.Provider
      value={{
        location,
        state,
        setState,
        loader,
        setLoader,
        isModalVisible,
        setIsModalVisible,
        isSubMenuShow,
        setIsSubMenuShow,
        courseList,
        setCourseList,
        ...props
      }}>
      <SIDEBAR />
    </PageContext.Provider>
  );
}

function SIDEBAR() {
  const { sidebar, toggleSidebar } = useContext(SidebarContext);
  const {
    location,
    isModalVisible,
    setIsModalVisible,
    isSubMenuShow,
    setIsSubMenuShow,
    courseList,
    setCourseList,
    loader,
    setLoader,
    logout,
    allCourse,
    serverResponse
  } = useContext(PageContext);
  const subscriptionUser = JSON.parse(localStorage.getItem('user'));
  const subscription = JSON.parse(localStorage.getItem('subscription'));
  const hasAccess = (subscriptionUser.subscription_status ?? subscription?.status) !== 'canceled';

  React.useEffect(() => {
    setLoader(true);
    const params = {
      page: 1,
      page_size: 20
    };
    Promise.all([allCourse(params)])
      .then((res) => {
        const allCourse = {
          ...res[0],
          results: _.orderBy(res[0].results, 'order_index', 'desc')
        };
        setCourseList(allCourse);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  React.useEffect(() => {
    if (serverResponse) {
      const allCourse = {
        ...serverResponse,
        results: _.orderBy(serverResponse.results, 'order_index', 'desc')
      };
      setCourseList(allCourse);
    }
  }, [serverResponse]);

  const logoutHandler = () => {
    setLoader(true);
    Promise.all([logout()])
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  return (
    <>
      {loader && <Loading />}
      <div className="aside-header">
        <Link className="mainlogo" to={'#'}>
          <img src={mainlogo} alt="" />
        </Link>

        <Link className="btn btn-dark" to={'#'} onClick={() => toggleSidebar(!sidebar)}>
          <i className="icon icon-dubble-right"></i>
        </Link>
      </div>
      <nav className="aside-main-menu">
        {hasAccess ? (
          <ul>
            {routes.map((item, index) => {
              if (item.layout === '/teacher' && item.display) {
                return (
                  <li
                    key={index}
                    className={
                      location?.pathname === `${item.layout}${item.path}`
                        ? 'active'
                        : item.path === '/courses' &&
                          courseList?.count > 0 &&
                          !location?.pathname?.includes('/teacher/course-view')
                        ? 'mydropdown'
                        : item.path === '/courses' && location?.pathname?.includes('/teacher/course-view')
                        ? 'mydropdown active'
                        : ''
                    }>
                    {item.path === '/courses' && courseList?.count > 0 && (
                      <>
                        <Link
                          to="/dashboard"
                          onClick={() => setIsSubMenuShow(!isSubMenuShow)}
                          onMouseEnter={() => toggleSidebar(false)}>
                          <i className={item.icon}></i>
                          <span>{item.name}</span>
                        </Link>
                        {courseList?.count > 0 && (
                          <ul className="mydropdown-menu">
                            {courseList?.results.map((subItem) => (
                              <li
                                key={`${index}-${subItem?.id}`}
                                className={
                                  location?.pathname === `${item.layout}/course-view/${subItem?.id}` ? 'active' : ''
                                }>
                                <Link to={`${item.layout}/course-view/${subItem?.id}`}>
                                  {/* <i className={item.icon}></i> */}
                                  <span>{subItem.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    )}
                    {item.path !== '/courses' && (
                      <Link to={`${item.layout}${item.path}`}>
                        <i className={item.icon}></i>
                        <span>{item.name}</span>
                      </Link>
                    )}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        ) : (
          <Redirect from="/teacher/dashboard" to="/teacher/profile-and-settings" />
        )}
      </nav>
      <div className="aside-footer">
        <ul className="">
          <li className={location?.pathname === '/teacher/profile-and-settings' ? 'active' : ''}>
            <Link to="/teacher/profile-and-settings">
              <i className="icon icon-settings-fill"></i>
              <span>Profile & Settings</span>
            </Link>
          </li>
          <li>
            <Link to="#" onClick={() => setIsModalVisible(true)}>
              <i className="icon icon-logout"></i>
              <span>Log Out</span>
            </Link>
          </li>
          <li style={{ display: 'none' }}>
            <Link to="#">
              <span>{process.env.REACT_APP_TAPPY_VERSION || ''}</span>
            </Link>
          </li>
        </ul>
      </div>
      <Modal
        keyboard={false}
        centered={true}
        visible={isModalVisible}
        width={770}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button className="btn btn-primary btn-bdr" type="button" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>

            <Button className="btn btn-brand" type="button" disabled={loader} onClick={logoutHandler}>
              Yes, log out
            </Button>
          </>
        ]}>
        <>
          <div className="modal-head">
            <h1>Log out of Tappy</h1>
          </div>
          <div className="modal-body">
            <h4 style={{ margin: 0 }}>Are you sure you want to log out of your Tappy account?</h4>
          </div>
        </>
      </Modal>
    </>
  );
}

import CreateAccount from '../Component/CreateAccount';
import { connect } from 'react-redux';
import { registration } from '../../../../Action';

const mapStateToProps = (state) => {
  const { CreateAccount } = state;
  return { CreateAccount };
};

const actionCreators = {
  registration
};

export default connect(mapStateToProps, actionCreators)(CreateAccount);

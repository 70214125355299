import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { ActionItem, ReusableTable, Pill } from '../../../../Components';
import AssignSeatsModalContent from './AssignSeatsModalContent';
import styles from './SubscriptionMembers.module.scss';
import AssignSeatsConfirmationModal from './AssignSeatsConfirmationModal';
import { subscriptionService } from '../../../../Services/subscriptions.service';
import Loading from '../../../../Layout/loading/Container';
import { tokenService } from '../../../../Services/token.service';
import { toast } from 'react-toastify';

const PageContext = React.createContext();

export default function SubscriptionMembers(props) {
  const [state, setState] = useState({ errors: {} });
  const [loader, setLoader] = useState(false);
  return (
    <PageContext.Provider value={{ state, setState, loader, setLoader, ...props }}>
      <SUBSCRIPTIONMEMBERS />
    </PageContext.Provider>
  );
}

function SUBSCRIPTIONMEMBERS() {
  const [isAssignSeatsModalVisible, setIsAssignSeatsModalVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [csvMembersData, setCsvMembersData] = useState([]);
  const [showAssignSeatsConfirmationModal, setShowAssignSeatsConfirmationModal] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [quantityOfSignedUp, setQuantityOfSignedUp] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [openRemoveMemberModal, setOpenRemoveMemberModal] = useState(false);

  const getMembers = useCallback(async () => {
    setLoader(true);
    const { email } = tokenService.getUser();
    try {
      const membersData = await subscriptionService.getSubscriptionMembers(page, email);
      setMembersData(membersData.data.data);
      setQuantityOfSignedUp(membersData.data.quantityOfSignedUp);
      setCurrentPage(membersData.data.pageInfo.currentPage);
      setEndIndex(membersData.data.pageInfo.endIndex);
      setStartIndex(membersData.data.pageInfo.startIndex);
      setTotalItems(membersData.data.pageInfo.totalItems);
      setTotalPages(membersData.data.pageInfo.totalPages);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  }, [page]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  const fileDropped = (data) => {
    setCsvMembersData(data);
    setShowAssignSeatsConfirmationModal(true);
  };

  const confirmAssignmentClicked = async () => {
    setLoader(true);
    const { email } = tokenService.getUser();
    try {
      await subscriptionService.createSubscriptionMembers(csvMembersData, email);
      setIsAssignSeatsModalVisible(false);
      setShowAssignSeatsConfirmationModal(false);
      setLoader(false);
    } catch (error) {
      setIsAssignSeatsModalVisible(false);
      setShowAssignSeatsConfirmationModal(false);
      setLoader(false);
    }
  };

  const closeModal = () => {
    setIsAssignSeatsModalVisible(false);
    setShowAssignSeatsConfirmationModal(false);
  };

  const changePage = (pageNumber) => setPage(pageNumber);

  const resendEmail = async (email) => {
    setLoader(true);
    try {
      await subscriptionService.resendSubscriptionEmail(email);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  const removeMember = async (email) => {
    setLoader(true);
    try {
      const { data } = await subscriptionService.removeMemberFromEnterpriseSubscription(email);
      toast.success(data.success);
      setOpenRemoveMemberModal(false);
      setLoader(false);
      getMembers();
    } catch (error) {
      setOpenRemoveMemberModal(false);
      setLoader(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  const removeFromEnterpriseSubscription = (email) => {
    setOpenRemoveMemberModal(true);
    Modal.confirm({
      open: openRemoveMemberModal,
      title: 'Remove member from Enterprise Subscription',
      content: 'Are you sure you want to remove this member?',
      onOk: () => removeMember(email),
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      )
    });
  };

  const columnsData = [
    { title: 'Member', key: 'member' },
    { title: 'Email', key: 'email' },
    {
      title: 'Account Status',
      key: 'accountStatus',
      render: (rowData) => <Pill value={rowData.accountStatus} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (rowData) => (
        <ActionItem
          resendEmailAction={() => resendEmail(rowData.email)}
          removeSubscription={() => removeFromEnterpriseSubscription(rowData.email)}
        />
      )
    }
  ];

  if (loader) {
    return <Loading />;
  }

  return (
    <>
      <section className={styles.subsMembersHeader}>
        <div className={styles.subsMembersHeaderColumn}>
          <h1>Subscription Members</h1>
          <h2>
            {quantityOfSignedUp}/{totalItems} members invited
          </h2>
        </div>
        <div className={styles.subsMembersHeaderColumn}>
          <button
            onClick={() => {
              setIsAssignSeatsModalVisible(true);
            }}>
            + Assign Seats
          </button>
        </div>
      </section>

      {/* Assign Seats Modal */}
      <Modal
        className="assign-seats-modal"
        closeIcon={<div className="icon icon-add"></div>}
        visible={isAssignSeatsModalVisible}
        closable={false}
        keyboard={false}
        centered={true}
        width={770}
        maskClosable={false}
        footer={[
          <div key="holiday_footer" className="add-holiday-footer">
            <Button key="holiday_back" onClick={closeModal} className="btn btn-focus-color-bg holiday-cancel-btn">
              Cancel
            </Button>
            {showAssignSeatsConfirmationModal ? (
              <Button
                key="holiday_success_back"
                onClick={confirmAssignmentClicked}
                className="btn btn-brand holiday-save-btn">
                Confirm Assignment
              </Button>
            ) : (
              <Button
                key="holiday_success_back"
                onClick={() => setShowAssignSeatsConfirmationModal(true)}
                className="btn btn-brand holiday-save-btn">
                Assign Seats
              </Button>
            )}
          </div>
        ]}>
        {showAssignSeatsConfirmationModal ? (
          <AssignSeatsConfirmationModal totalNewMembers={csvMembersData.length} />
        ) : (
          <AssignSeatsModalContent onDropped={fileDropped} />
        )}
      </Modal>

      {/* Subscription Members table  */}
      <ReusableTable
        columns={columnsData}
        currentPage={currentPage}
        dataSource={membersData}
        endIndex={endIndex}
        startIndex={startIndex}
        totalItems={totalItems}
        totalPages={totalPages}
        changeToPage={changePage}
      />
    </>
  );
}

import React from 'react';
import classNames from 'classnames';
import styles from './pagination.module.scss';

export const Pagination = ({ startIndex, endIndex, currentPage, totalItems, totalPages, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={styles.nav}>
      <div className={styles.left}>
        Showing {startIndex} to {endIndex} of {totalItems} results
      </div>
      <div className={styles.right}>
        <ul className={styles.pagination}>
          <li
            onClick={() => currentPage > 1 && paginate(currentPage - 1)}
            className={classNames(styles.pageItem, {
              [styles.disabled]: currentPage === 1
            })}>
            &lt;
          </li>
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={classNames(styles.pageItem, {
                [styles.active]: currentPage === number
              })}
              onClick={() => paginate(number)}>
              <p className={styles.pageLink}>{number}</p>
            </li>
          ))}
          <li
            onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
            className={classNames(styles.pageItem, {
              [styles.disabled]: currentPage === totalPages
            })}>
            &gt;
          </li>
        </ul>
      </div>
    </nav>
  );
};

import React from 'react';
import moreVertIcon from '../../../assets/images/more-vert.svg';
import { Dropdown, Menu } from 'antd';

export const ActionItem = ({ resendEmailAction, removeSubscription }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={resendEmailAction}>
        <i className="fa fa-envelope" style={{ paddingRight: '14px' }}></i>
        Resend Invite Email
      </Menu.Item>
      <Menu.Item onClick={removeSubscription}>
        <i className="fa fa-minus-circle" style={{ paddingRight: '14px' }}></i>
        Remove from Subscription Plan
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown placement="bottomRight" className="option-select" overlay={menu} trigger={['click']}>
      <img style={{ cursor: 'pointer' }} src={moreVertIcon} alt="Options" />
    </Dropdown>
  );
};

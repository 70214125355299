import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import logoLg from '../../../../assets/images/logo-lg.svg';
import Loading from '../../../../Layout/loading/Container';
const PageContext = React.createContext();

export default function Login(props) {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({});

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, ...props }}>
      <LOGIN />
    </PageContext.Provider>
  );
}

function LOGIN() {
  const { loader, setLoader, login } = useContext(PageContext);

  const schema = yup.object().shape({
    email: yup.string().required('Email field cannot be left blank').email('Incorrect email'),
    password: yup.string().required('Password field cannot be left blank.')
  });

  const { register, handleSubmit, setError, formState, clearErrors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const { errors } = formState;

  React.useEffect(() => {
    if (localStorage?.getItem('removed_by_admin')) {
      setError('removed_by_admin', { type: 'error', message: 'removed_by_admin' });
    } else if (localStorage?.getItem('suspend_by_admin')) {
      setError('suspend_by_admin', { type: 'error', message: 'suspend_by_admin' });
    }
  }, []);

  const clearError = () => {
    clearErrors('removed_by_admin');
    clearErrors('suspend_by_admin');
    localStorage.removeItem('removed_by_admin');
    localStorage.removeItem('suspend_by_admin');
  };

  const onSubmit = (data) => {
    setLoader(true);
    Promise.all([login(data)])
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        if (_.isArray(err?.detail)) {
          if (err?.detail[0] === 'Incorrect email/password') {
            setError('email', { type: 'error', message: '  ' });
            setError('password', { type: 'error', message: 'Incorrect email/password' });
          } else if (err?.detail[0] === 'Incorrect Password') {
            setError('email', { type: 'error', message: '  ' });
            setError('password', { type: 'error', message: 'Incorrect email/password' });
          } else if (
            err?.detail[0] === 'Your account has been suspended. Contact care@teachtappy.com for more information.'
          ) {
            setError('suspend_by_admin', { type: 'error', message: err?.detail });
          }
        }
        setLoader(false);
      });
  };

  return (
    <div className="auth-block">
      {loader && <Loading />}
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            {errors['removed_by_admin'] && (
              <div className="block-row">
                <div className="warning-card acard">
                  <div className="caption">
                    <i className="icon icon-attention"></i>
                    <p>
                      Your account has been permanently removed. Contact
                      <br />
                      <a href="mailto:care@teachtappy.com"> care@teachtappy.com</a>
                      &nbsp;for more information.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {errors['suspend_by_admin'] && (
              <div className="block-row">
                <div className="warning-card acard">
                  <div className="caption">
                    <i className="icon icon-attention"></i>
                    <p>
                      Your account has been suspended. Contact
                      <br />
                      <a href="mailto:care@teachtappy.com"> care@teachtappy.com</a>
                      &nbsp;for more information.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="title-row">
              <h1 className="pb-20 text-center">Sign In to Tappy</h1>
            </div>
            <form className="form-group" autoComplete="new-password">
              <div className={errors['email'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Email Address
                </label>
                <input
                  type="text"
                  {...register('email')}
                  name="email"
                  placeholder="teacher@toocoolforschool.com"
                  autoComplete="new-password"
                  onBlur={() => clearError()}
                />
                {errors['email'] ? <label className="error">{errors['email']?.message}</label> : ''}
              </div>
              <div className={errors['password'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Password
                </label>
                <input
                  type="password"
                  {...register('password')}
                  name="password"
                  placeholder="8 character minimum"
                  autoComplete="new-password"
                  onBlur={() => clearError()}
                />
                {errors['password'] ? <label className="error">{errors['password']?.message}</label> : ''}
              </div>
              <p className="pt-20 clr-black">
                Forgot Your Password?
                <strong>
                  <Link className="btn-txt btn ml-5" to={'reset-password'}>
                    Reset Password
                  </Link>
                </strong>
              </p>
              <div className="btn-group pt-20">
                <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                  Sign In
                </button>
              </div>
              <p className="pt-20 clr-black text-center">
                <strong>
                  <Link className="btn-txt btn ml-5" to={'create-account'}>
                    Create an Account
                  </Link>
                </strong>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

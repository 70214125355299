import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import routes from '../../routes';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';

function TeacherLayout(props) {
  const { token } = props;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/teacher-admin') {
        if (prop.path === '/dashboard') {
          return <PrivateRoute path={prop.layout + prop.path} component={prop.component} key={key} />;
        }
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
    });
  };

  return (
    <>
      <Switch>
        {!_.isEmpty(token) && <Redirect from="/teacher-admin/sign-in" to="/teacher-admin/dashboard" />}
        {getRoutes(routes)}
        <Redirect from="/teacher-admin" to="/teacher-admin/dashboard" />
      </Switch>
    </>
  );
}

const mapStateToProps = (state) => {
  const { teacherAdminLogin } = state;
  return { token: teacherAdminLogin.user };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(TeacherLayout);

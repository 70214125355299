import React from 'react';
import { useForm } from 'react-hook-form';
import styles from './contactUsForm.module.scss';
import { organizationTypes } from '../../../Lib/organizationType';
import { organizationSize } from '../../../Lib/organizationSize';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { paymentsService } from '../../../Services/payments.service';

export const ContactUsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState: profileFormState
  } = useForm({
    mode: 'onSubmit'
  });

  const onSubmit = async (data) => {
    const response = await paymentsService.contactUs(data);
    if (response.status === 201) {
      history.push('/teacher/contact-us-gratitude');
    }
  };

  const history = useHistory();
  const { errors: profileErrors } = profileFormState;

  return (
    <div className={styles.contactUsForm}>
      <h1 className={styles.header}>Contact Us</h1>
      <p className={styles.subheader}>To request an estimate budget for an enterprise subscription</p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <label className={`${styles.label} required`}>Organization Type</label>
            <select
              {...register('organizationType', { required: 'Organization type is required' })}
              className={`${errors.organizationType ? styles.selectError : ''} ${styles.select}`}
              defaultValue="">
              <option value="" disabled hidden>
                Select Organization Type
              </option>
              {organizationTypes.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.organizationType && <p className={styles.error}>{errors.organizationType.message}</p>}
          </div>
          <div className={styles.formColumn}>
            <label className={`${styles.label} required`}>Organization Size</label>
            <select
              {...register('organizationSize', { required: 'Organization size is required' })}
              className={`${errors.organizationSize ? styles.selectError : ''} ${styles.select}`}
              defaultValue="">
              <option value="" disabled hidden>
                Select Organization Size
              </option>
              {organizationSize.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.organizationSize && <p className={styles.error}>{errors.organizationSize.message}</p>}
          </div>
        </div>

        <div className={profileErrors['orgName'] ? 'fitems half-fitems has-error' : 'fitem half-fitem'}>
          <label className={`${styles.label} required`}>Organization Name</label>
          <input
            type="text"
            {...register('orgName', { required: 'Organization Name is required' })}
            placeholder="Enter Organization Name"
            className={profileErrors.orgName ? styles.inputError : ''}
          />
          {profileErrors.orgName && <p className={styles.error}>{profileErrors.orgName.message}</p>}
        </div>

        <div className={profileErrors['repEmail'] ? 'fitems half-fitems has-error' : 'fitem half-fitem'}>
          <label className={`${styles.label} required`}>Representative Email</label>
          <input
            type="text"
            {...register('repEmail', {
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Entered value does not match email format'
              }
            })}
            placeholder="Enter Email Address"
            className={profileErrors.repEmail ? styles.inputError : ''}
          />
          {profileErrors.repEmail && <p className={styles.error}>{profileErrors.repEmail.message}</p>}
        </div>
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <label className={`${styles.label} required`}>First Name</label>
            <input
              type="text"
              {...register('firstName', { required: 'First Name is required' })}
              placeholder="Enter First Name"
            />
            {errors.firstName && <p className={styles.error}>{errors.firstName.message}</p>}
          </div>
          <div className={styles.formColumn}>
            <label className={`${styles.label} required`}>Last Name</label>
            <input
              type="text"
              {...register('lastName', { required: 'Last Name is required' })}
              placeholder="Enter Last Name"
              className={errors.lastName ? styles.inputError : ''}
            />
            {errors.lastName && <p className={styles.error}>{errors.lastName.message}</p>}
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={profileErrors.zipCode ? `${styles.formColumn} ${styles.hasError}` : styles.formColumn}>
            <label className={`${styles.label} required`}>Zip Code</label>
            <input
              type="text"
              {...register('zipCode', {
                required: 'Zip Code is required',
                pattern: {
                  value: /^\d{5}(?:[-\s]\d{4})?$/,
                  message: 'Invalid Zip Code format'
                }
              })}
              placeholder="Enter Zip Code"
              className={profileErrors.zipCode ? styles.inputError : ''}
            />
            {profileErrors.zipCode && <p className={styles.error}>{profileErrors.zipCode.message}</p>}
          </div>
          <div className={profileErrors.phoneNumber ? `${styles.formColumn} ${styles.hasError}` : styles.formColumn}>
            <label className={`${styles.label} required`}>Phone Number</label>
            <input
              type="text"
              {...register('phoneNumber', {
                required: 'Phone Number is required',
                pattern: {
                  value: /^(([2-9]{1}[0-9]{2})|[0-9]{3}-)[0-9]{3}-?[0-9]{4}$/,
                  message: 'Invalid phone number format'
                }
              })}
              placeholder="Enter Phone Number"
              className={profileErrors.phoneNumber ? styles.inputError : ''}
            />
            {profileErrors.phoneNumber && <p className={styles.error}>{profileErrors.phoneNumber.message}</p>}
          </div>
        </div>
        <div className={styles.formActions}>
          <Button
            key="back"
            className={`btn btn-primary btn-bdr ${styles.backButton}`}
            onClick={() => history.goBack()}>
            Back To Plans
          </Button>
          <input type="submit" className={'btn btn-brand '} value="Send Request" />
        </div>
      </form>
    </div>
  );
};

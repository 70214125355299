import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { schema } from './CreateAccount.schema';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const useCreateAccount = ({ setLoader, registration }) => {
  const { register, handleSubmit, formState, setError, watch } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      retypePassword: ''
    }
  });

  const formValues = watch();

  const { errors, isValid } = formState;
  const [token, setToken] = useState('');
  const location = useLocation();

  const getTokenFromURL = useCallback(
    (paramName) => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get(paramName);
    },
    [location]
  );

  const onSubmit = () => {
    if (token) formValues.token = token;
    handleSubmit(validate(formValues));
  };

  const validate = (data) => {
    if (!isValid) return;
    setLoader(true);
    const body = {
      email: data?.email,
      password: data?.password,
      password2: data?.retypePassword,
      token: data?.token
    };
    Promise.all([registration(body)])
      .then(() => {
        setLoader(false);
      })
      .catch((err) => {
        if (err?.detail) {
          if (
            _.isString(err?.detail) &&
            err?.detail === 'Email is already associated with an account. Log in or use another email.'
          ) {
            setError('email', { type: 'error', message: err?.detail });
          } else if (_.isArray(err?.detail)) {
            if (
              err?.detail[0] ===
              'Email is already associated with a suspended account. Use a different email or contact care@teachtappy.com for more information.'
            ) {
              setError('email', { type: 'error', message: 'suspend_by_admin' });
            } else {
              setError('password', { type: 'error', message: err?.detail[0] });
            }
          }
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    const token = getTokenFromURL('token');
    setToken(token);
  }, [getTokenFromURL]);

  return {
    errors,
    handleSubmit,
    onSubmit,
    register,
    validate
  };
};

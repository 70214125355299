import React, { useState } from 'react';
const PageContext = React.createContext();

export default function Notifications(props) {
  const [state, setState] = useState({ errors: {} });
  const [loader, setLoader] = useState(false);
  return (
    <PageContext.Provider value={{ state, setState, loader, setLoader, ...props }}>
      <NOTIFICATIONS />
    </PageContext.Provider>
  );
}

function NOTIFICATIONS() {
  return (
    <div>
      <h3>Notifications Under Construction</h3>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import logoLg from '../../../../assets/images/logo-lg.svg';
import Loading from '../../../../Layout/loading/Container';
const PageContext = React.createContext();

export default function ResetPassword(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({ errors: {}, email: '' });

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, history, ...props }}>
      <RESETPASSWORD />
    </PageContext.Provider>
  );
}

function RESETPASSWORD() {
  const { loader, setLoader, history, resetPassword } = useContext(PageContext);

  const schema = yup.object().shape({
    email: yup.string().required('Field cannot be left blank').email('Email Address is not a valid format')
  });

  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });
  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    if (!isValid) return;
    setLoader(true);
    Promise.all([resetPassword(data)])
      .then(() => {
        history.push('/auth/reset-password-email-verification');
        setLoader(false);
      })
      .catch((err) => {
        if (err?.detail) {
          if (_.isString(err?.detail)) {
            if (err?.detail === 'Your account has been suspended. Contact care@teachtappy.com for more information.') {
              setError('email', { type: 'error', message: 'suspend_by_admin' });
            } else {
              setError('email', { type: 'error', message: err?.detail });
            }
          }
        }
        setLoader(false);
      });
  };

  return (
    <div className="auth-block">
      {loader && <Loading />}
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            <div className="title-row">
              <h1 className="mb-0">Reset password</h1>
              <p>
                Enter your email address below, and we&apos;all email you a 6-character
                <br />
                reset password code. <i>No code will be sent if the email is not</i>
                <br />
                associated with an account.
              </p>
            </div>
            <form className="form-group">
              <div className={errors['email'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Email Address
                </label>
                <input
                  type="text"
                  {...register('email')}
                  name="email"
                  placeholder="teacher@toocoolforschool.com"
                  autoComplete="new-password"
                />
                {errors['email'] ? (
                  errors['email']?.message === 'suspend_by_admin' ? (
                    <label className="error">
                      Email is already associated with a suspended account. Use a different email or contact{' '}
                      <a href="mailto:care@teachtappy.com" style={{ color: '#b91c1c', textDecoration: 'underline' }}>
                        care@teachtappy.com
                      </a>{' '}
                      for more information.
                    </label>
                  ) : (
                    <label className="error">{errors['email']?.message}</label>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className="btn-group pt-20">
                <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                  Send reset code
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import routes from '../../routes';
import { SidebarContext } from '../../Contexts/SidebarContext';
import Sidebar from './Sidebar/Component/Sidebar';
import { logout, allCourse } from '../../Action';
import { PrivateRoute } from './PrivateRoute/PrivateRoute';

function TeacherLayout(props) {
  const [sidebar, toggleSidebar] = useState(true);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/teacher') {
        return <PrivateRoute path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
    });
  };

  return (
    <div className={`aside-area ${sidebar ? 'open' : ''}`}>
      <SidebarContext.Provider value={{ sidebar, toggleSidebar }}>
        <div className="aside-nav">
          <Sidebar {...props} />
        </div>
      </SidebarContext.Provider>
      <div className="right-contentarea">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/teacher" to="/teacher/dashboard" />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { allCourse } = state;
  return { serverResponse: allCourse?.data };
};

const actionCreators = {
  logout,
  allCourse
};

export default connect(mapStateToProps, actionCreators)(TeacherLayout);

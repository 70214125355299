const INITIAL_STATE = { data: {}, errors: {} };

const suspenseUsers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SUSPENSE_USERS_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'SUSPENSE_USERS_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default suspenseUsers;

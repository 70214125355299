import React, { useEffect, useState } from 'react';
import { momentLocalizer } from 'react-big-calendar';

import moment from 'moment';
import { Button, DatePicker, Modal } from 'antd';
import ToolbarWithLegend from './ToolbarWithLegend';
import ReusableCalendar from '../../../Components/ReusableCalendar';
import { useCalendarAPI } from '../../../Services/calendar.service';
import ErrorModal from './ErrorModal';
import AddNewCourse from './AddNewCourse';
import { colors } from '../../../Services/courseColors';
const localizer = momentLocalizer(moment);

const parseMetadataToCalendarEvents = (metadata) => {
  const parsedEvents = [];

  // for each course metadata, create a new parsed event and push it to the parsed events array
  metadata.forEach((apiMetadata, index) => {
    // api metadata has date, start time, end time. Set the start and end time to datetimes so the day and time can be compared
    apiMetadata.scheduleDays.forEach((scheduleDay) => {
      const [year, month, day] = scheduleDay.date.split('T')[0].split('-');
      const startDate = new Date(
        year,
        month - 1,
        day,
        scheduleDay.startTime.split(':')[0],
        scheduleDay.startTime.split(':')[1],
        scheduleDay.startTime.split(':')[2]
      );
      const endDate = new Date(
        year,
        month - 1,
        day,
        scheduleDay.endTime.split(':')[0],
        scheduleDay.endTime.split(':')[1],
        scheduleDay.endTime.split(':')[2]
      );

      const parsedEvent = {
        id: apiMetadata.courseId, // course id
        title: apiMetadata.courseName,
        start: startDate,
        end: endDate,
        backgroundColor: colors[index % colors.length]
      };
      parsedEvents.push(parsedEvent);
    });
  });
  return parsedEvents;
};

const parseEventToCalendarEvent = (apiEventData) =>
  apiEventData.map((event, index) => {
    const courseId = event.metadata.find((metadata) => metadata.key === 'course:id').value;
    return {
      id: event.id,
      courseId: courseId,
      title: event.title,
      start: new Date(event.day),
      end: new Date(event.day),
      backgroundColor: colors[index % colors.length]
    };
  });

const parseEvents = ({ courseDayEvents, courseDayEventsWithLessonPlans, holidays }) => {
  // for each course day. find the corresponding course day with lesson plan id and date and add the lesson plan title to the course day
  courseDayEvents.forEach((courseDayEvent) => {
    const courseDayEventWithLessonPlan = courseDayEventsWithLessonPlans.find(
      (courseDayEventWithLessonPlan) =>
        courseDayEventWithLessonPlan.courseId === courseDayEvent.id &&
        // check if the course day event is on the same day as the course day event with lesson plan. Just the day, not the time
        courseDayEventWithLessonPlan.start.getFullYear() === courseDayEvent.start.getFullYear() &&
        courseDayEventWithLessonPlan.start.getMonth() === courseDayEvent.start.getMonth() &&
        courseDayEventWithLessonPlan.start.getDate() === courseDayEvent.start.getDate()
    );

    // holidays have start_date and end_date
    const courseDayEventHoliday = holidays.find(
      (holiday) =>
        //check if the course day in within the holiday range
        new Date(holiday.start_date) <= courseDayEvent.start && new Date(holiday.end_date) >= courseDayEvent.start
    );

    courseDayEvent.courseId = courseDayEvent.id;
    if (courseDayEventWithLessonPlan) {
      courseDayEvent.id = courseDayEventWithLessonPlan.id;
      courseDayEvent.title = `${courseDayEventWithLessonPlan.title}`;
      courseDayEvent.courseId = courseDayEventWithLessonPlan.courseId;
    } else {
      courseDayEvent.title = 'No Lesson Planned';
      courseDayEvent.disabled = true;
    }

    if (courseDayEventHoliday && courseDayEventHoliday.course_id === courseDayEvent.courseId) {
      courseDayEvent.title = `${courseDayEventHoliday.event_title} - ${courseDayEvent.title}`;
      courseDayEvent.disabled = true;
    }
  });

  return [...courseDayEvents];
};

const Calendar = () => {
  const { useGetEventsQuery, useMutateReschedule, useGetHolidaysByCourseIdQuery, errorModalData, setErrorModalData } =
    useCalendarAPI();
  const { mutate: mutateReschedule } = useMutateReschedule();
  const holidayQuery = useGetHolidaysByCourseIdQuery();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [eventData, setEventData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('All Courses');
  const [courses, setCourses] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(true);

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { allDay: initialAllDay } = event;

    const allDay =
      (!initialAllDay && droppedOnAllDaySlot) || (initialAllDay && !droppedOnAllDaySlot)
        ? !initialAllDay
        : initialAllDay;

    const updatedEvent = { ...event, start, end, allDay };

    // Check if there is already an event on the same day
    const eventOnSameDay = eventData.some(
      (event) =>
        event.start.getFullYear() === updatedEvent.start.getFullYear() &&
        event.start.getMonth() === updatedEvent.start.getMonth() &&
        event.start.getDate() === updatedEvent.start.getDate() &&
        !event.disabled &&
        event.courseId == updatedEvent.courseId
    );

    if (eventOnSameDay) {
      setErrorModalData({
        visible: true,
        errorMessage: 'There is already an event on this day'
      });
      return;
    }

    if (event.disabled) return;

    mutateReschedule({
      lessonPlanId: updatedEvent.id,
      newDate: updatedEvent.start
    });
  };

  const eventsToDisplay =
    selectedCourse === 'All Courses' ? eventData : eventData.filter((event) => event.courseId === selectedCourse);

  const CustomEvent = ({ event }) => (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <i className="fa-regular fa-grip-dots-vertical" style={{ paddingRight: '8px' }} /> {event.title}
    </div>
  );

  const { data: calendarApiData } = useGetEventsQuery();

  useEffect(() => {
    if (calendarApiData && holidayQuery.isFetched) {
      const courseDayEvents = parseMetadataToCalendarEvents(calendarApiData.courseAndTheirScheduleDays);
      const courseDayEventsWithLessonPlans = parseEventToCalendarEvent(calendarApiData.events);
      setEventData(
        parseEvents(
          structuredClone({ courseDayEvents, courseDayEventsWithLessonPlans, holidays: holidayQuery.data.holidays })
        )
      );
      // add a unique courseDayEvent object to courses and determine unique courses by title
      const uniqueCourses = new Map();
      courseDayEvents.forEach((courseDayEvent) => {
        uniqueCourses.set(courseDayEvent.title, courseDayEvent);
      });
      setCourses([...uniqueCourses.values()]);
    }
  }, [calendarApiData, holidayQuery.isFetched, holidayQuery.data, holidayQuery.isFetching]);

  /** Start of Holiday Modal */
  const holidayEventQueryId = selectedCourse === 'All Courses' ? 0 : selectedCourse;
  const { useEditHolidayMutation } = useCalendarAPI();
  const courseHolidayQuery = useGetHolidaysByCourseIdQuery(holidayEventQueryId);
  const { mutate: editHoliday } = useEditHolidayMutation();

  const [isHolidayModalVisible, setIsHolidayModalVisible] = useState(false);
  const [isAddHolidayVisible, setIsAddHolidayVisible] = useState(false);
  const [holidayName, setHolidayName] = useState('');
  const [holidayStartDate, setHolidayStartDate] = useState('');
  const [holidayEndDate, setHolidayEndDate] = useState('');
  const [holidayList, setHolidayList] = useState([]);
  // const [showNewCourseModal, setShowNewCourseModal] = useState(false);

  useEffect(() => {
    if (courseHolidayQuery.isLoading || selectedCourse === 'All Courses') return;
    setHolidayList(
      courseHolidayQuery.data.holidays.map((holiday) => ({
        id: holiday.id || undefined,
        name: holiday.event_title,
        start: moment(holiday.start_date).startOf('day').toDate(),
        end: moment(holiday.end_date).startOf('day').toDate(),
        is_draft: !holiday.is_draft
      }))
    );
    // console.log('Setting holidayList', holidayList);
  }, [courseHolidayQuery.isFetching]);

  const showHolidayModal = () => {
    setIsAddHolidayVisible(false);
    setIsHolidayModalVisible(true);
    // console.log('holidayList', holidayList);
  };

  const handleCloseHolidayModal = () => {
    editHoliday({
      courseId: selectedCourse,
      holidays: holidayList.map((holiday) => ({
        id: holiday.id || undefined,
        event_title: holiday.name,
        start_date: moment(holiday.start).format('YYYY-MM-DD'),
        end_date: moment(holiday.end).format('YYYY-MM-DD'),
        is_draft: !holiday.is_draft
      }))
    });
    setIsHolidayModalVisible(false);
  };

  const handleCancelHolidayModal = () => {
    setIsHolidayModalVisible(false);
  };

  const handleAddHolidayOpen = () => {
    setHolidayName('');
    setHolidayStartDate(null);
    setHolidayEndDate(null);
    setIsAddHolidayVisible(true);
  };

  const handleAddHolidayClose = () => {
    setHolidayName('');
    setHolidayStartDate('');
    setHolidayEndDate('');
    setIsAddHolidayVisible(false);
  };

  const handleHolidayCheckbox = (item, index) => {
    // get holiday out of array
    const holiday = holidayList.splice(index, 1)[0];
    // toggle is_draft
    holiday.is_draft = !holiday.is_draft;
    // put holiday back in array
    holidayList.splice(index, 0, holiday);
    setHolidayList([...holidayList]);
  };

  const handleAddHoliday = () => {
    if (holidayName !== '' && holidayStartDate !== '' && holidayEndDate !== '') {
      const holiday = {
        name: holidayName,
        start: moment(holidayStartDate).startOf('day').toDate(),
        end: moment(holidayEndDate).startOf('day').toDate(),
        is_draft: false
      };
      setHolidayList([...holidayList, holiday]);
      handleAddHolidayClose();
    }
  };
  /** End of Holiday Modal */

  const handleCloseErrorModal = () => {
    setErrorModalData({ visible: false, errorMessage: '', subMessage: '' });
  };

  const handleShowNewCourseModal = () => {
    // setShowNewCourseModal(true);
    setIsModalVisible(true);
  };

  useEffect(() => {
    // setShowNewCourseModal(true);
    // setIsModalVisible(true);
  }, []);

  // delay render till courses are repopulated.
  // ReusableCalendar will not render if courses is empty on first render
  if (!courses.length) return <div>Loading...</div>;

  return (
    <div className="calendar-container">
      <div className="container-lg">
        <ReusableCalendar
          selectable
          events={eventsToDisplay}
          localizer={localizer}
          onEventDrop={moveEvent}
          defaultView="month"
          defaultDate={new Date()}
          isResizable={false}
          components={{
            toolbar: (props) => (
              <ToolbarWithLegend
                {...props}
                setSelectedCourse={setSelectedCourse}
                courses={courses}
                showHolidayModal={showHolidayModal}
                showNewCourseModal={handleShowNewCourseModal}
                data={calendarApiData}
              />
            ),
            event: CustomEvent
          }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.backgroundColor,
              color: '#102328',
              opacity: event.disabled ? 0.5 : 1
            }
          })}
        />
      </div>
      {/* Holiday Modal */}
      <Modal
        className="model-footer-right"
        closeIcon={<div className="icon icon-add"></div>}
        visible={isHolidayModalVisible}
        closable={false}
        keyboard={false}
        centered={true}
        width={770}
        maskClosable={false}
        footer={[
          <div key="holiday_footer" className="add-holiday-footer">
            <Button
              key="holiday_back"
              onClick={handleCancelHolidayModal}
              className="btn btn-focus-color-bg holiday-cancel-btn">
              Cancel
            </Button>
            <Button
              key="holiday_success_back"
              onClick={handleCloseHolidayModal}
              className="btn btn-brand holiday-save-btn">
              Save Changes
            </Button>
          </div>
        ]}>
        <div className="holiday-wrap">
          <div className="modal-head">
            <h1>Manage Holidays</h1>
            <p className="font-weight-400 font-sans">
              Add your holidays dates, breaks or school events below to organize your plan around them.
            </p>
            <div className="holiday-form-wrap">
              <form>
                <div className="holiday-header">
                  <div className="holiday-header-item">
                    <label>Event</label>
                  </div>
                  <div className="holiday-header-item">
                    <label>Dates</label>
                  </div>
                </div>
                <div className="holiday-list">
                  {holidayList?.map((item, index) => (
                    <div className="holiday-item" key={index}>
                      <input
                        type="checkbox"
                        name={'holiday-checkbox-' + index}
                        checked={item?.is_draft}
                        onChange={() => handleHolidayCheckbox(item, index)}
                      />
                      <div className="holiday-item-name">
                        <label>{item?.name}</label>
                      </div>
                      <div className="holiday-item-date">
                        <label>
                          {moment(item?.start).format('dddd')}
                          {', '}
                          {moment(item?.start).format('MMM DD')}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) && ' - '}
                          {!(moment(item?.start).format('DD') === moment(item?.end).format('DD')) &&
                            moment(item?.end).format('MMM DD')}
                          {', '}
                          {moment(item?.start).format('YYYY')}
                          {!(moment(item?.start).format('YYYY') === moment(item?.end).format('YYYY')) &&
                            ' - ' + moment(item?.end).format('YYYY')}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-option display-false' : 'add-holiday-option display-true'
                    }
                    onClick={handleAddHolidayOpen}>
                    <label>+ Add Another Date</label>
                  </div>
                  <div
                    className={
                      isAddHolidayVisible ? 'add-holiday-item display-true' : 'add-holiday-item display-false'
                    }>
                    <div className="add-holiday-header">Add Date</div>
                    <div className="add-holiday-body">
                      <div className="add-holiday-body-items">
                        <div className="inner-items-wrap">
                          <label>Holiday Name</label>
                          <input
                            value={holidayName}
                            type="text"
                            placeholder="Spring Break"
                            onChange={(event) => {
                              setHolidayName(event?.target?.value);
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap start-date">
                          <label className="holiday-start-date">Start Date</label>
                          <DatePicker
                            value={holidayStartDate ? moment(holidayStartDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayStartDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayStartDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap end-date">
                          <label className="holiday-end-date">End Date</label>
                          <DatePicker
                            value={holidayEndDate ? moment(holidayEndDate) : null}
                            suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                            placeholder={'MM/DD/YYYY'}
                            format={'MM/DD/YYYY'}
                            onChange={(event) => {
                              setHolidayEndDate(event);
                            }}
                            onBlur={(event) => {
                              if (moment(event.target.value).isValid()) {
                                setHolidayEndDate(new Date(event.target.value));
                              }
                            }}
                          />
                        </div>
                        <div className="inner-items-wrap add-holiday-btn">
                          <Button key="add-holiday" onClick={handleAddHoliday} className="btn btn-focus-color-bg">
                            + Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      {/* Holiday Modal Ends */}
      {/* Error Modal Starts */}
      <ErrorModal
        errorModalData={errorModalData}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        handleCloseErrorModal={handleCloseErrorModal}
      />
      {/* Error Modal Ends */}
      {/* Add New Course Starts*/}
      <AddNewCourse visible={isModalVisible} />
      {/* Add New Course Ends*/}
    </div>
  );
};

export default Calendar;

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import logoLg from '../../../../assets/images/logo-lg.svg';
import Loading from '../../../../Layout/loading/Container';
const PageContext = React.createContext();

export default function EmailVerification(props) {
  const [state, setState] = useState({ errors: {}, code: '' });
  const [display, setDisplay] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <PageContext.Provider value={{ state, setState, display, setDisplay, disabled, setDisabled, ...props }}>
      <EMAILVERIFICATION />
    </PageContext.Provider>
  );
}

function EMAILVERIFICATION() {
  const { state, setState, display, setDisplay, disabled, setDisabled, emailVerification, resendCode, loading } =
    useContext(PageContext);
  const { code, errors } = state;

  const validate = () => {
    const schema = yup.object().shape({
      code: yup.string().required('Field cannot be left blank')
    });
    const data = { code };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        const body = {
          URL: null,
          code
        };
        Promise.all([emailVerification(body)])
          .then(() => {
            setState((s) => ({ ...s, errors: {} }));
          })
          .catch((err) => {
            let err_data = {};
            if (err?.detail) {
              err_data['code'] = err?.detail;
            }
            setState((s) => ({ ...s, errors: err_data }));
          });
      })
      .catch((error) => {
        let err_data = {};
        error?.inner.map((item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  const resendCodeHandler = () => {
    Promise.all([resendCode()])
      .then(() => {
        delay();
      })
      .catch(() => {});
  };

  const delay = () => {
    setDisplay(true);
    setDisabled(true);
    setTimeout(() => {
      setDisplay(false);
    }, 3000);
    setTimeout(() => {
      setDisabled(false);
    }, 60000);
  };

  return (
    <div className="auth-block">
      {loading && <Loading />}
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            <div className="title-row">
              <h1 className="mb-0">Email verification</h1>
              <p>
                Enter the 6-character validation code sent to your email (it may <br /> take up to 5 min to be
                delivered). If you don’t see it, check your <br />
                spam folder.
              </p>
            </div>
            <form action="" className="form-group">
              <div className={errors['code'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  6-Character Verification Code
                </label>
                <input
                  type="text"
                  className="sm-input"
                  placeholder="6-character code"
                  onChange={(e) =>
                    setState((s) => ({
                      ...s,
                      code: e.target.value
                    }))
                  }
                />
                {errors['code'] ? <label className="error">{errors['code']}</label> : ''}
              </div>
              <div className="btn-group pt-20">
                <button
                  type="button"
                  className={code ? 'btn btn-brand btn-full' : 'btn btn-brand btn-full disabled'}
                  onClick={() => validate()}>
                  Submit Code
                </button>
                <p className="pt-20 clr-black" style={{ marginBottom: '8px' }}>
                  Didn’t get the code?
                  <strong>
                    <Link
                      className={disabled ? 'btn-txt btn disabled ml-5' : 'btn-txt btn ml-5'}
                      to={'#'}
                      onClick={() => resendCodeHandler()}>
                      Resend Code
                    </Link>
                  </strong>
                </p>
                <i>The link above will be temporarily disabled for 60 seconds after a request.</i>
              </div>
            </form>
            {display && (
              <div className="block-row pt-80">
                <div className="info-card acard">
                  <div className="caption">
                    <i className="icon icon-done"></i>
                    <p>You’ve got mail! Check your email inbox for the code.</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import Dashboard from '../Component/Dashboard';
import { connect } from 'react-redux';
import { teacherAdminLogout, getAllUsers, suspenseUser, teacherAdminChangePassword } from '../../../../Action';

const mapStateToProps = (state) => {
  const { Dashboard, AllUsers, SuspenseUser } = state;

  return {
    Dashboard,
    AllUsers,
    SuspenseUser
  };
};

const actionCreators = {
  teacherAdminLogout,
  getAllUsers,
  suspenseUser,
  teacherAdminChangePassword
};

export default connect(mapStateToProps, actionCreators)(Dashboard);

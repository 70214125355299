import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USERS_LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from '../Action/types';
import { tokenService } from '../Services';

const LOGIN_INITIAL_STATE = {
  loading: false,
  user: tokenService.getUser() && tokenService.getLocalAccessToken() ? tokenService.getUser() : {},
  error: {}
};

export function login(state = LOGIN_INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        user: {},
        error: {}
      };
    case LOGIN_SUCCESS:
      return {
        loading: false,
        user: action.data,
        error: {}
      };
    case LOGIN_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.error
      };
    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        user: action.data,
        error: {}
      };
    case UPDATE_USER_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.error
      };
    case GET_USER_SUCCESS:
      return {
        loading: false,
        user: action.data,
        error: {}
      };
    case GET_USER_FAILURE:
      return {
        loading: false,
        user: {},
        error: action.error
      };
    case USERS_LOGOUT:
      return {
        loading: false,
        user: {},
        error: {}
      };
    default:
      return state;
  }
}

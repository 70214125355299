import { roundNumber } from '../../../Utils';

import { useSummaryPriceContent } from './summary-price-content.hook';
import styles from './summary-price-content.module.scss';

export const SummaryPriceContent = ({ products, showIndividualPrice, isMonthly }) => {
  const { subtotal, total } = useSummaryPriceContent(products);

  return products.length > 0 ? (
    <div className={styles.pricesContainer}>
      <div className={styles.productList}>
        {products.map((product, index) => (
          <div key={index} className={styles.productItem}>
            <label className={`body ${styles.productName}`}>{product.name}</label>
            <label role="listitem" className={`body ${styles.productPrice}`}>
              ${roundNumber(product.price)}
            </label>
          </div>
        ))}
      </div>
      {showIndividualPrice && (
        <div className={styles.productItem}>
          <label className={`body-small ${styles.individualSub}`}>{'x45 Members'}</label>
        </div>
      )}

      <div className={styles.subTotalItem}>
        <div className={styles.productItem}>
          <label className={`body ${styles.productName}`}>Subtotal</label>
          <label role="cell" className={`body ${styles.productPrice}`}>
            ${subtotal}
          </label>
        </div>
      </div>
      <div className={styles.totalItem}>
        <div className={styles.productItem}>
          <label className={`body-large ${styles.productName}`}>Total due</label>
          <label className={`body-large ${styles.productPrice}`}>${roundNumber(total)}</label>
        </div>
      </div>
      <label className={`body ${styles.priceClarification}`}>
        {isMonthly ? '(Billed Monthly)' : '(Billed Yearly)'}
      </label>
    </div>
  ) : (
    <h1 className={styles.noProducts}>No products items</h1>
  );
};

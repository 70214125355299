import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import routes from '../../routes';
import { tokenService } from '../../Services';

function AuthLayout(props) {
  const { user } = props;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
    });
  };

  return (
    <Switch>
      {!_.isEmpty(user) && user.is_user_verified && user.is_partially_profile_completed && (
        <>
          <Redirect from="/auth/sign-in" to="/teacher/dashboard" />
        </>
      )}
      {!_.isEmpty(user) && !user.is_user_verified && <Redirect from="/auth/sign-in" to="/auth/email-verification" />}
      {!_.isEmpty(user) && !user.is_partially_profile_completed && (
        <Redirect from="/auth/sign-in" to="/auth/tell-us-about-you" />
      )}
      {_.isEmpty(tokenService.getEmail()) && <Redirect from="/auth/email-verification" to="/auth/create-account" />}
      {_.isEmpty(tokenService.getEmail()) && (
        <Redirect from="/auth/reset-password-email-verification" to="/auth/reset-password" />
      )}
      {getRoutes(routes)}
      <Redirect from="/auth" to="/auth/sign-in" />
    </Switch>
  );
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { user: login.user };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(AuthLayout);

import api from './../../../Services/api';
import { tokenService } from './../../../Services/index';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const PaperGrader = () => {
  const user = tokenService.getUser();

  const toast = (innerHTML) => {
    const el = document.getElementById('toast');
    el.innerHTML = innerHTML;
    el.className = 'show';
    setTimeout(() => {
      el.className = el.className.replace('show', '');
    }, 3000);
  };

  const getUploadParams = async ({ meta: { name } }) => {
    const { fields, url } = await api
      .get('/paper-grader/upload-url/', { params: { name: name, user_id: user.display_name } })
      .then((res) => res.data);
    const fileUrl = url + '/' + fields.key;
    return { fields, meta: { fileUrl }, url };
  };

  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(status);
    if (status === 'headers_received') {
      toast(`${meta.name} uploaded!`);
      remove();
    } else if (status === 'exception_upload') {
      toast(`${meta.name}, upload failed...`);
    }
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  return (
    <div className="paper-grader-container">
      <div className="container-lg">
        <div className="paper-grader__header">
          <h1>Paper Grader</h1>
          <p>Upload your papers and let Tappy grade them using the latest in Artificial Intelligence!</p>
          <p>
            You can upload multiple essays at one-time. It&apos;s also helpful to include the rubric as your cover
            letter. Essay results are emailed to you within 48 - 72 business hours depending on the number of essays be
            be graded. Handwritten essays are acceptable.
          </p>
          <p>
            Include any comments and instructions that you find important to help grade the paper. Please include
            references to rubrics and any scoring models you use.
          </p>
          <p>(Only images and pdfs will be accepted)</p>
          <div>Examples:</div>
          <div className="examples">
            <div>
              <a
                href="https://prodtappystatic.s3.amazonaws.com/paper-grader/essay_rubric.pdf"
                target="_blank"
                rel="noreferrer">
                <i className="fa-solid fa-file-pdf"></i>
                <span className="example-pdf">essay_rubric.pdf</span>
              </a>
            </div>
            <div>
              <a
                href="https://prodtappystatic.s3.amazonaws.com/paper-grader/essay_results.pdf"
                target="_blank"
                rel="noreferrer">
                <i className="fa-solid fa-file-pdf"></i>
                <span className="example-pdf">essay_results.pdf</span>
              </a>
            </div>
          </div>

          <div id="toast"></div>
          <Dropzone
            getUploadParams={getUploadParams}
            onSubmit={handleSubmit}
            onChangeStatus={handleChangeStatus}
            accept="image/*, application/pdf"
          />
        </div>
      </div>
    </div>
  );
};

export default PaperGrader;

import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import reducers from './Reducers';
import thunk from 'redux-thunk';

function configureStore() {
  const store = createStore(reducers, compose(applyMiddleware(thunk)));

  return store;
}

const store = configureStore();

export default store;

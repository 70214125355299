import { Collapse } from 'antd';
import React from 'react';

import ReusableGantt from './ReusableGantt';

const { Panel } = Collapse;

const GanttView = ({ title, legend, tasks, onDateChange, expandedCourses, setExpandedCourses }) => {
  const courseKeyArray = legend.map((item) => item.courseId);

  const createCourseHtml = (item) => (
    <div className="gantt-view__legend__main-box__course__item">
      <div className="gantt-view__legend__main-box__course__item__color-box" style={{ background: item.color }}></div>
      <div className="gantt-view__legend__course-title">{item.course}</div>
    </div>
  );

  const handleCollapse = (activeKeys) => {
    if (activeKeys.includes('All Courses')) {
      setExpandedCourses(expandedCourses);
    } else {
      setExpandedCourses(activeKeys);
    }
  };
  let filteredTasks = expandedCourses.includes('All Courses')
    ? tasks
    : tasks.filter((task) => {
        return !task.courseId || expandedCourses.includes(task.courseId);
      });
  return (
    <div className="gantt-view__container">
      <div className="gantt-view">
        <div className="gantt-view__legend">
          <div className="gantt-view__legend__container">
            <div className="gantt-view__legend__title-box">{title}</div>
            <div className="gantt-view__legend__main-box">
              <Collapse defaultActiveKey={courseKeyArray} ghost onChange={handleCollapse}>
                {legend.map((item) => {
                  return (
                    <Panel header={createCourseHtml(item)} key={item.courseId}>
                      {item.list.map((lesson, lessonIndex) => (
                        <div
                          className="gantt-view__legend__main-box__course__item gantt-view__legend__main-box__course__item__lesson"
                          key={lessonIndex}>
                          <div
                            className="gantt-view__legend__main-box__course__item__color-box"
                            style={{ background: item.color }}></div>
                          <div className="gantt-view__legend__lesson-title">{lesson}</div>
                        </div>
                      ))}
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </div>
        </div>
        <div className="gantt-view__gantt-container">
          <ReusableGantt tasks={filteredTasks} onDateChange={onDateChange} />
        </div>
      </div>
    </div>
  );
};

export default GanttView;

import Assessments from '../Component/Assessments';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Assessments } = state;
  return { Assessments };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Assessments);

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { SummaryPriceContent } from '../../molecules/summary-price-content';
import { SummaryUserContent } from '../../molecules/summary-user-content';
import { subscriptionService } from '../../../Services/subscriptions.service';

import styles from './summary.module.scss';

export const Summary = () => {
  const [price, setPrice] = useState(0);
  const [subscriptionName, setSubscriptionName] = useState('');
  const [isMonthly, setIsMonthly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCustomerSubscription = async () => {
      setIsLoading(true);
      try {
        const { data: sub } = await subscriptionService.getCustomerSubscription();
        setPrice(sub.price / 100);
        setSubscriptionName(sub.name);
        setIsMonthly(sub.interval === 'month');
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    };

    getCustomerSubscription();
  }, []);

  if (isLoading) return 'Loading...';

  return (
    <div className={styles.summaryContainer}>
      <div className={styles.superiorContent}>
        <SummaryUserContent
          subscriptionPlanType={subscriptionName}
          user="Single User"
          price={price}
          isMonthly={isMonthly}
        />
      </div>
      <div className={styles.inferiorContent}>
        <SummaryPriceContent
          products={[
            {
              name: subscriptionName,
              price
            }
          ]}
          isMonthly={isMonthly}
        />
      </div>
    </div>
  );
};

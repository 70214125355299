import Dashboard from '../Component/Dashboard';
import { connect } from 'react-redux';
import { addSchoolCourse, updateCourseOrderIndex } from '../../../Action';
import { dismissCompleteProfile, allCourse, dismissCoursesCTA, getUser } from '../../../Action';

const mapStateToProps = (state) => {
  const {
    dismissCompleteProfile,
    login,
    allCourse,
    Dashboard,
    SchoolCourse,
    dismissCoursesCTA,
    updateCourseOrderIndex
  } = state;

  return {
    user: login.user,
    dismissProfileCTA: dismissCompleteProfile?.dismiss,
    isFullProfileCompleted: login?.user?.is_full_profile_completed,
    allCourseLoading: allCourse.loading,
    allCourseResponse: allCourse.data,
    Dashboard,
    SchoolCourse,
    dismissCoursesCTAResponse: dismissCoursesCTA,
    updateCourseOrderIndexLoading: updateCourseOrderIndex.loading
  };
};

const actionCreators = {
  dismissCompleteProfile,
  allCourse,
  addSchoolCourse,
  dismissCoursesCTA,
  updateCourseOrderIndex,
  getUser
};

export default connect(mapStateToProps, actionCreators)(Dashboard);

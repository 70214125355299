import {
  GET_SCHOOL_COURSE_ASSESSMENTS_SUCCESSFULLY,
  GET_SCHOOL_COURSE_ASSESSMENTS_FAILED,
  ADD_SCHOOL_COURSE_SUCCESSFULLY,
  ADD_SCHOOL_COURSE_FAILED,
  DELETE_SCHOOL_COURSE_SUCCESSFULLY,
  DELETE_SCHOOL_COURSE_FAILED,
  UPDATE_SCHOOL_COURSE_SUCCESSFULLY,
  UPDATE_SCHOOL_COURSE_FAILED,
  GET_COURSE_LESSONS_SUCCESSFULLY,
  GET_COURSE_LESSONS_FAILED,
  GET_COURSE_LESSONS_BY_ID_SUCCESSFULLY,
  GET_COURSE_LESSONS_BY_ID_FAILED,
  ADD_COURSE_LESSON_SUCCESSFULLY,
  ADD_COURSE_LESSON_FAILED,
  ADD_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
  ADD_INSTRUCTIONAL_EVENT_FAILED,
  UPDATE_COURSE_LESSON_SUCCESSFULLY,
  UPDATE_COURSE_LESSON_FAILED,
  GET_INSTRUCTIONAL_EVENT_CATEGORIES_SUCCESSFULLY,
  GET_INSTRUCTIONAL_EVENT_CATEGORIES_FAILED,
  UPDATE_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
  UPDATE_INSTRUCTIONAL_EVENT_FAILED,
  DELETE_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
  DELETE_INSTRUCTIONAL_EVENT_FAILED,
  GET_INSTRUCTIONAL_EVENTS_SUCCESSFULLY,
  GET_INSTRUCTIONAL_EVENTS_FAILED,
  GET_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
  GET_INSTRUCTIONAL_EVENT_FAILED,
  CONFIRM_COURSE_LESSON_SUCCESSFULLY,
  CONFIRM_COURSE_LESSON_FAILED,
  CONFIRM_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
  CONFIRM_INSTRUCTIONAL_EVENT_FAILED
} from '../types';
import instance from '../../Services/api';

export const getSchoolCourseAssessments = (params) => {
  return (dispatch) => {
    return instance
      .get('/school-course/assessments/', params)
      .then((response) => {
        dispatch({
          type: GET_SCHOOL_COURSE_ASSESSMENTS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: GET_SCHOOL_COURSE_ASSESSMENTS_FAILED,
            data: error.response.data
          });
        }
      });
  };
};

export const addSchoolCourse = (params) => {
  return (dispatch) => {
    return instance
      .post('/school-course/course/', params)
      .then((response) => {
        dispatch({
          type: ADD_SCHOOL_COURSE_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: ADD_SCHOOL_COURSE_FAILED,
            data: error.response.data?.detail
          });
        }
      });
  };
};

export const deleteCourseById = (id) => {
  return (dispatch) => {
    return instance
      .delete(`/school-course/course/${id}/`)
      .then((response) => {
        dispatch({
          type: DELETE_SCHOOL_COURSE_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: DELETE_SCHOOL_COURSE_FAILED,
            data: error.response.data
          });
        }
      });
  };
};

export const updateSchoolCourse = (params) => {
  return (dispatch) => {
    return instance
      .patch(`/school-course/course/${params.id}/`, { ...params })
      .then((response) => {
        dispatch({
          type: UPDATE_SCHOOL_COURSE_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch({
            type: UPDATE_SCHOOL_COURSE_FAILED,
            data: error.response.data?.detail
          });
        }
      });
  };
};

export const getCourseLessons = (params) => {
  return (dispatch) => {
    return instance
      .get('/school-course/lessons/', { params })
      .then((response) => {
        dispatch({
          type: GET_COURSE_LESSONS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_COURSE_LESSONS_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const getCourseLessonById = (id) => {
  return (dispatch) => {
    return instance
      .get(`/school-course/lessons/${id}/`)
      .then((response) => {
        dispatch({
          type: GET_COURSE_LESSONS_BY_ID_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_COURSE_LESSONS_BY_ID_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const addLesson = (params) => {
  return (dispatch) => {
    return instance
      .post('/school-course/lessons/', params)
      .then((response) => {
        dispatch({
          type: ADD_COURSE_LESSON_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: ADD_COURSE_LESSON_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const updateLesson = (params) => {
  return (dispatch) => {
    return instance
      .patch(`/school-course/lessons/${params.id}/`, params)
      .then((response) => {
        dispatch({
          type: UPDATE_COURSE_LESSON_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_COURSE_LESSON_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const getInstructionalEventCategories = () => {
  return (dispatch) => {
    return instance
      .get('/school-course/instructional-events/categories/')
      .then((response) => {
        dispatch({
          type: GET_INSTRUCTIONAL_EVENT_CATEGORIES_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_INSTRUCTIONAL_EVENT_CATEGORIES_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const addInstructionalEvent = (params) => {
  return (dispatch) => {
    return instance
      .post('/school-course/instructional-events/', params)
      .then((response) => {
        dispatch({
          type: ADD_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: ADD_INSTRUCTIONAL_EVENT_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const updateInstructionalEvent = (params) => {
  return (dispatch) => {
    return instance
      .patch(`/school-course/instructional-events/${params.id}/`, params)
      .then((response) => {
        dispatch({
          type: UPDATE_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_INSTRUCTIONAL_EVENT_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const deleteInstructionalEvent = (id) => {
  return (dispatch) => {
    return instance
      .delete(`/school-course/instructional-events/${id}/`)
      .then((response) => {
        dispatch({
          type: DELETE_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: DELETE_INSTRUCTIONAL_EVENT_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const getInstructionalEvents = (params) => {
  return (dispatch) => {
    return instance
      .get('/school-course/instructional-events/', { params })
      .then((response) => {
        dispatch({
          type: GET_INSTRUCTIONAL_EVENTS_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_INSTRUCTIONAL_EVENTS_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const getInstructionalEventById = (id) => {
  return (dispatch) => {
    return instance
      .get(`/school-course/instructional-events/${id}/`)
      .then((response) => {
        dispatch({
          type: GET_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_INSTRUCTIONAL_EVENT_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const confirm = (params) => {
  return (dispatch) => {
    return instance
      .patch(`/school-course/course/DateNotPlannedInstructionDayPopup/${params.id}/`, params)
      .then((response) => {
        dispatch({
          type: CONFIRM_COURSE_LESSON_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: CONFIRM_COURSE_LESSON_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

export const confirmInstructionalEvent = (params) => {
  return (dispatch) => {
    return instance
      .patch(`/school-course/instructional-events/OverClassTimePopup/${params.id}/`, params)
      .then((response) => {
        dispatch({
          type: CONFIRM_INSTRUCTIONAL_EVENT_SUCCESSFULLY,
          data: response.data
        });
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: CONFIRM_INSTRUCTIONAL_EVENT_FAILED,
            error: error?.response?.data
          });
        }
      });
  };
};

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';

function ExportModal(props) {
  const { isVisible, onClose, data } = props;
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [checkSection, setCheckSection] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const handleOk = () => {
    setIsExportModalVisible(false);
  };

  const handleClick = (item) => {
    const updatedCheckedItems = [...checkedList];
    const isChecked = updatedCheckedItems.includes(item);
    if (isChecked) {
      updatedCheckedItems.splice(updatedCheckedItems.indexOf(item), 1);
    } else {
      updatedCheckedItems.push(item);
    }
    setCheckedList(updatedCheckedItems);
  };

  const handleExport = () => {
    const exportData = filterData(data);
    const csvData = convertNestedJSONToCSV(exportData);

    startDownload(csvData);
    handleCancel();
  };

  function startDownload(data) {
    const filename = 'courses_export';
    const blob = new Blob([data], { type: 'text/csv' });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  function convertNestedJSONToCSV(data) {
    const csv = [];
    const headers = [];
    extractHeaders(data[0], headers);

    csv.push(headers.join(','));
    data.forEach((item) => {
      const values = [];
      extractValues(item, headers, values);
      csv.push(values.join(','));
    });

    return csv.join('\r\n');
  }

  function extractHeaders(obj, headers, parentKey = '') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let combinedKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          extractHeaders(obj[key], headers, combinedKey);
        } else {
          headers.push(combinedKey);
        }
      }
    }
  }

  function extractValues(obj, headers, values) {
    for (let key of headers) {
      let nestedKeys = key.split('.');
      let nestedObj = obj;

      for (let nestedKey of nestedKeys) {
        if (Object.prototype.hasOwnProperty.call(nestedObj, nestedKey)) {
          nestedObj = nestedObj[nestedKey];
        } else {
          nestedObj = null;
          break;
        }
      }
      values.push(nestedObj || '');
    }
  }

  const uncheckAll = () => {
    const checkboxes = document.getElementsByClassName('export-checkbox');
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  };

  const handleCancel = () => {
    setIsExportModalVisible(false);
    uncheckAll();
    onClose();
  };

  const filterData = (data) => {
    const { courseMetadatas, events, instructionalEvents } = data;
    const filteredData = [];
    checkedList.forEach((course) => {
      const { courseName, courseGrade, courseLevel, courseTimeChoice, courseId } = course;
      const courseMetadata = courseMetadatas.find((x) => x.id === courseId);
      const courseEvents = events.filter((x) => x.metadata[1].value === courseId);
      courseEvents.forEach((event) => {
        const instructionalBlocks = [];
        instructionalEvents.forEach((block) => {
          if (block.lessonId === event.id && block.is_draft === false) {
            instructionalBlocks.push(block);
          }
        });
        event.instructionalEvents = instructionalBlocks;
      });
      const courseData = {
        courseName,
        courseMetadata,
        courseGrade,
        courseLevel,
        courseTimeChoice,
        courseId,
        courseLessons: courseEvents
      };
      filteredData.push(courseData);
    });
    return filteredData;
  };

  useEffect(() => {
    setCheckSection(props?.data?.courseAndTheirScheduleDays);
    setCheckedList([]);
    setIsExportModalVisible(isVisible);
  }, [isVisible]);

  return (
    <div>
      <Modal
        visible={isExportModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={770}
        maskClosable={false}
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        closable={false}
        className="export-modal"
        footer={[
          <div className="export-modal-footer" key="export_footer">
            <Button
              key="export_course_back"
              onClick={handleCancel}
              className="btn btn-primary btn-bdr export-cancel-btn">
              Cancel
            </Button>
            <Button
              key="export_course"
              onClick={handleExport}
              className="btn btn-brand"
              disabled={checkedList.length === 0}>
              Export Courses
            </Button>
          </div>
        ]}>
        <div className="export-wrap">
          <div className="modal-head">
            <h1>Export Course</h1>
            <h4 className="h4">Select the course you&apos;d like to export below</h4>
          </div>
          {checkSection.map((item, index) => (
            <div className="export-item" key={index}>
              <input
                type="checkbox"
                className="export-checkbox"
                name={'export-checkbox-' + index}
                onChange={() => handleClick(item)}
              />
              <div className="export-item-name">
                <label>{item?.courseName}</label>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default ExportModal;

import CreateNewPassword from '../Component/CreateNewPassword';
import { connect } from 'react-redux';
import { setNewPassword } from '../../../../Action';

const mapStateToProps = (state) => {
  const { CreateNewPassword } = state;
  return { CreateNewPassword };
};

const actionCreators = {
  setNewPassword
};

export default connect(mapStateToProps, actionCreators)(CreateNewPassword);

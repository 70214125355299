import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import Loading from '../../../../Layout/loading/Container';
import adminLogo from '../../../../assets/images/admin-logo.svg';
const PageContext = React.createContext();

export default function CreateNewPassword(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    errors: {},
    data: {},
    password: '',
    retypePassword: '',
    passwordView: false,
    retypePasswordView: false
  });

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, history, ...props }}>
      <CREATENEWPASSWORD />
    </PageContext.Provider>
  );
}

function CREATENEWPASSWORD() {
  const { loader, setLoader, state, setState, teacherAdminSetNewPassword, history } = useContext(PageContext);
  const { passwordView, retypePasswordView } = state;

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Field cannot be left blank')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        'Passwords must have 8 characters and contain at least one letter and one number.'
      ),
    retypePassword: yup
      .string()
      .required('Field cannot be left blank')
      .oneOf([yup.ref('password'), null], 'Passwords do not match')
  });

  const { register, handleSubmit, formState, setError } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      retypePassword: ''
    }
  });
  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    if (!isValid) return;
    setLoader(true);
    const body = {
      password: data?.password,
      password2: data?.retypePassword
    };
    Promise.all([teacherAdminSetNewPassword(body)])
      .then(() => {
        history.push('/teacher-admin/password-changed-success');
        setLoader(false);
      })
      .catch((err) => {
        if (_.isArray(err?.detail)) {
          setError('password', { type: 'error', message: err?.detail[0] });
        }
        setLoader(false);
      });
  };

  return (
    <div id="content-area" className="admin-login-theme">
      <div className="admin-linner-outer">
        <div className="admin-linner">
          {loader && <Loading />}
          <div className="title-row">
            <img className="adminlogo" src={adminLogo} alt="" />
            <h1 className="text-center">Create a new password</h1>
            <p className="text-left">
              Create a new password for your account. Passwords must have 8 characters and contain at least one letter
              and one number.
            </p>
          </div>
          <form action="" className="form-group">
            <div className="fitem">
              <label htmlFor="" className="lbl-txt clr-primary">
                Required fields are marked with *
              </label>
            </div>
            <div className={errors['password'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                Password
              </label>
              <div className="ficon">
                <input
                  type={passwordView ? 'text' : 'password'}
                  {...register('password')}
                  name="password"
                  placeholder="8 character minimum"
                  autoComplete="new-password"
                />
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      passwordView: !passwordView
                    }))
                  }
                  className={passwordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
              </div>

              {errors['password'] ? (
                <label className="error">{errors['password']?.message}</label>
              ) : (
                <label htmlFor="" className="lbl-txt">
                  Passwords must have 8 characters and contain at least one letter and one number.
                </label>
              )}
            </div>
            <div className={errors['retypePassword'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                Retype Password
              </label>
              <div className="ficon">
                <input
                  type={retypePasswordView ? 'text' : 'password'}
                  {...register('retypePassword')}
                  name="retypePassword"
                  placeholder="Retype Password"
                  autoComplete="new-password"
                />
                <i
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setState((s) => ({
                      ...s,
                      retypePasswordView: !retypePasswordView
                    }))
                  }
                  className={retypePasswordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
              </div>
              {errors['retypePassword'] ? <label className="error">{errors['retypePassword']?.message}</label> : ''}
            </div>
            <div className="btn-group pt-20">
              <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                Create new password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

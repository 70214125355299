import axiosInstance from './teacherAdminApi';
import { tokenService } from './index';

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessTokenAdmin();
      if (token) {
        config.headers['Authorization'] = `token ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      return Promise.reject(err);
    }
  );
};
export default setup;

class config {
  getHost = () => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    return `${protocol}//${host}/api`;
  };

  timeInterval = () => {
    const arr = [];
    let i = 1;
    let j = 0;
    for (i = 1; i < 13; i++) {
      for (j = 0; j < 4; j++) {
        arr.push((String(i).length === 1 ? `0${i}` : i) + ':' + (j === 0 ? '00' : 15 * j));
      }
    }
    return arr;
  };

  getRandomCourseCardColorClass = () => {
    const cardColorClass = ['clr-blank', 'clr-light', 'clr-dark'];
    return cardColorClass[Math.floor(Math.random() * cardColorClass.length)];
  };

  getRandomCourseCardIcon = () => {
    const cardIcon = [
      'icon icon-books',
      'icon icon-icons-apple',
      'icon icon-bell',
      'icon icon-books',
      'icon icon-notebook',
      'icon icon-backpack',
      'icon icon-clipboard',
      'icon icon-memo-fill',
      'icon icon-pen-ruler',
      'icon icon-chalkboard-user'
    ];
    return cardIcon[Math.floor(Math.random() * cardIcon.length)];
  };

  sortDay = (day1, day2) => {
    var weekdays = ',monday,tuesday,wednesday,thursday,friday,saturday,sunday';
    return (
      weekdays.indexOf(',' + day1?.day_choice?.toLowerCase()) - weekdays.indexOf(',' + day2?.day_choice?.toLowerCase())
    );
  };

  timeStringToFloat = (time) => {
    let hoursMinutes = (time || '').split(/[.:]/);
    let hours = parseInt(hoursMinutes[0], 10);
    let minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  };
}
export default new config();

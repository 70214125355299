const INITIAL_STATE = { data: {}, errors: {} };

const addSchoolCourse = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_SCHOOL_COURSE_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'ADD_SCHOOL_COURSE_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default addSchoolCourse;

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubscriptionLayout } from '../../../Layout/Subscription';
import { InformativeMessage, Summary } from '../../../Components';
import { useLocation } from 'react-router-dom';
import { subscriptionService } from '../../../Services/subscriptions.service';
import Loading from '../../../Layout/loading/Component/Loading';
import { history } from '../../../Lib/history';
import { toast } from 'react-toastify';
import { tokenService } from '../../../Services';

const PageContext = React.createContext();

export default function ConfirmedPage() {
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  return (
    <PageContext.Provider value={{ loader, setLoader, location }}>
      <ConfirmedPage_ />
    </PageContext.Provider>
  );
}

function ConfirmedPage_() {
  const { loader, setLoader } = useContext(PageContext);
  const { email } = useParams();
  const [subtitle, setSubtitle] = useState('');
  const [content, setContent] = useState('');
  const [buttonAlign, setButtonAlign] = useState('');
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    const initPage = async () => {
      let userEmail = email;

      if (!userEmail || userEmail === 'undefined') {
        const user = tokenService.getUser();
        if (user && user.email) {
          userEmail = user.email;
        }
      }
      const subtitle = userEmail
        ? 'Thank you for your TeachTappy sponsorship'
        : 'Thank you for signing up to TeachTappy';
      setSubtitle(subtitle);

      const content = email ? (
        <div>
          <p>
            {`Congratulations! You have successfully sponsored an individual teacher account. You'll receive a confirmation email to ${decodeURIComponent(
              email
            )} with the sponsorship details.`}
          </p>
          <p>{`Thank you for a granting the opportunity to revolutionize a teacher's teaching experience.`}</p>
        </div>
      ) : (
        <div>
          <p>{`Congratulations! You now have full access to the TeachTappy platform under an individual subscription plan. You’ll receive a confirmation email to ${decodeURIComponent(
            userEmail
          )} with the subscription details.`}</p>
          <p>
            {`Don't miss this opportunity to revolutionize your teaching experience. Click the button below to log in and start exploring TeachTappy now:`}
          </p>
        </div>
      );
      setContent(content);

      const buttonAlign = email ? '' : 'left';
      setButtonAlign(buttonAlign);

      const buttonText = email ? '' : 'Start Planning';
      setButtonText(buttonText);

      if (email) {
        setLoader(true);
        const decodeEmail = decodeURIComponent(email);
        try {
          await subscriptionService.sendPaymentSponsorEmail(decodeEmail);
          setLoader(false);
        } catch (error) {
          setLoader(false);
          error?.response?.data?.error && toast.error(error.response.data.error);
        }
      } else {
        setLoader(true);
        try {
          await subscriptionService.sendPaymentConfirmedEmail();
          setLoader(false);
        } catch (error) {
          setLoader(false);
          error?.response?.data?.error && toast.error(error.response.data.error);
        }
      }
    };

    initPage();
  }, []);

  return loader ? (
    <Loading />
  ) : (
    <SubscriptionLayout
      asideContent={() => <Summary />}
      content={() => (
        <InformativeMessage
          title="Payment confirmed"
          subtitle={subtitle}
          buttonAlign={buttonAlign}
          buttonText={buttonText}
          content={content}
          onActionButton={() => history.push('/teacher/profile-and-settings')}
        />
      )}
    />
  );
}

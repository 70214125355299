import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import instance from './api';
import { useState } from 'react';

export const useCalendarAPI = () => {
  const utils = useQueryClient();

  const [mutating, setMutating] = useState(false);

  const [errorModalData, setErrorModalData] = useState({ visible: false, errorMessage: '', subMessage: '' });

  /**
   * Fetch all event data for the calendars
   */
  const useGetEventsQuery = () =>
    useQuery({
      queryKey: ['eventData'],
      queryFn: () => instance.get('/school-course/events-details').then((res) => res.data)
    });

  /**
   * update a lesson plan date
   * @param {string} lessonPlanId - lesson plan id
   * @param {DateTime} newDate - new date for the lesson plan
   */
  const useMutateReschedule = () =>
    useMutation(
      ({ lessonPlanId, newDate }) =>
        instance.post('/school-course/lesson-plan/reschedule', { lessonPlanId, newDate }).then((res) => res.data),
      {
        onError: (error) => {
          // alert(error.response.data.errorMessage);
          console.log(error.response.data);
          setErrorModalData({
            visible: true,
            errorMessage: error.response.data.errorMessage,
            subMessage: error.response.data.subMessage
          });
          console.log(errorModalData);
        },
        onSettled: () => {
          utils.invalidateQueries('eventData');
          setMutating(false);
        },
        onMutate: async (variables) => {
          // Check if there is an ongoing mutation
          if (mutating) {
            // Abort the new mutation request
            throw new Error('Duplicate mutation request');
          }

          setMutating(true);

          // Allow the mutation to proceed
          return variables;
        }
      }
    );

  /**
   * Fetch all event data for the calendars provide courseId in query params
   */
  const useGetHolidaysByCourseIdQuery = (courseId) =>
    useQuery({
      queryKey: ['holiday', courseId],
      queryFn: () =>
        instance.get(`/school-course/course/holidays${courseId ? `?courseId=${courseId}` : ''}`).then((res) => res.data)
    });

  /**
   * update a lesson plan date
   * @param {string} lessonPlanId - lesson plan id
   * @param {DateTime} newDate - new date for the lesson plan
   */
  const useEditHolidayMutation = () =>
    useMutation(
      ({ holidays, courseId }) =>
        instance
          .post('/school-course/course/holidays/', {
            courseId,
            holidays
          })
          .then((res) => res.data),
      {
        onError: (error) => {
          alert(error.response.data.errorMessage);
        },
        onSettled: async () => {
          await utils.invalidateQueries(['holiday']);
          setMutating(false);
        },
        onMutate: async (variables) => {
          // Check if there is an ongoing mutation
          if (mutating) {
            // Abort the new mutation request
            throw new Error('Duplicate mutation request');
          }

          setMutating(true);

          // Allow the mutation to proceed
          return variables;
        }
      }
    );

  const getMetadataValue = (event, key) => event.metadata?.find((metadata) => metadata.key === key)?.value;

  const getInstructionalEvents = async (params) => {
    try {
      const response = await instance.get('/school-course/instructional-events/', { params });
      return response.data;
    } catch (error) {
      if (error?.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  };

  const getCourseLessonById = async (id) => {
    try {
      const response = await instance.get(`/school-course/lessons/${id}/`);
      return response.data;
    } catch (error) {
      if (error?.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  };

  const getInstructionalEventCategories = async () => {
    try {
      const response = await instance.get('/school-course/instructional-events/categories/');
      return response.data;
    } catch (error) {
      if (error?.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  };

  const addLesson = async (params) => {
    try {
      const response = await instance.post('/school-course/lessons/', params);
      return response.data;
    } catch (error) {
      if (error?.response) {
        throw error.response.data;
      } else {
        throw error;
      }
    }
  };

  return {
    useGetEventsQuery,
    getMetadataValue,
    useGetHolidaysByCourseIdQuery,
    errorModalData,
    setErrorModalData,
    useMutateReschedule,
    useEditHolidayMutation,
    getInstructionalEvents,
    getCourseLessonById,
    getInstructionalEventCategories,
    addLesson
  };
};

import CourseStandards from '../Component/CourseStandards';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { CourseStandards } = state;
  return { CourseStandards };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(CourseStandards);

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function CourseStandards() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <CourseStandards_ />
    </PageContext.Provider>
  );
}

function CourseStandards_() {
  return <div></div>;
}

import api from './api';
import { tokenService } from './token.service';

const login = (payload) => {
  return api.post('/account/users/login/', payload);
};

const logout = async () => {
  return api
    .post('/account/users/logout/')
    .then((res) => {
      tokenService.clearTeacherStorage();
      return res;
    })
    .catch((err) => {
      tokenService.clearTeacherStorage();
      throw err;
    });
};

export const authService = {
  login,
  logout
};

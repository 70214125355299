import ResetPasswordEmailVerification from '../Component/ResetPasswordEmailVerification';
import { connect } from 'react-redux';
import { teacherAdminEmailVerification, teacherAdminResendCode } from '../../../../Action';

const mapStateToProps = (state) => {
  const { ResetPasswordEmailVerification } = state;
  return { ResetPasswordEmailVerification };
};

const actionCreators = {
  teacherAdminEmailVerification,
  teacherAdminResendCode
};

export default connect(mapStateToProps, actionCreators)(ResetPasswordEmailVerification);

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function GetStarted() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <GetStarted_ />
    </PageContext.Provider>
  );
}

function GetStarted_() {
  return (
    <div id="content-area">
      <div className="landing-page">
        <div className="container">
          <div className="classplan-section">
            <div className="title-row">
              <h1>Build Your Class Plan</h1>
              <p>This process may take ~30-90min. You can save your work and come back at any time.</p>
            </div>
            <div className="caption-area">
              <p>To expedite this process, we recommend having the following information available:</p>
              <ol>
                <li>Course number (optional)</li>
                <li>Class schedule</li>
                <li>School calendar</li>
                <li>Pacing guide provided by your department head or district</li>
              </ol>
              <div className="btn-group text-right">
                <a href="#" className="btn btn-bdr btn-white">
                  Maybe Later
                </a>
                <a href="/steptwo" className="btn btn-brand">
                  Lets Get Started!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import api from './api';

const register = (payload) => {
  return api.post('/account/users/', payload);
};

const emailVerification = (payload) => {
  return api.post('/account/users/verifyAccount/', payload);
};

const resetPasswordemailVerification = (payload) => {
  return api.post('/account/users/verifyCode/', payload);
};

const tellUsAboutYou = (id, payload) => {
  return api.patch(`/account/users/${id}/`, payload);
};

const resendCode = (payload) => {
  return api.post('/account/users/resendCode/', payload);
};

const resetPassword = (payload) => {
  return api.post('/account/users/resetPassword/', payload);
};

const setNewPassword = (payload) => {
  return api.post('/account/users/changePassword/', payload);
};

const createUsers = (payload) => {
  return api.post('/account/users/createUsers', payload);
};

export const userService = {
  register,
  emailVerification,
  resetPasswordemailVerification,
  tellUsAboutYou,
  resendCode,
  resetPassword,
  setNewPassword,
  createUsers
};

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function SchoolCalendar() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <SchoolCalendar_ />
    </PageContext.Provider>
  );
}

function SchoolCalendar_() {
  return <></>;
}

import Loading from '../Component/Loading';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Loading } = state;
  return { Loading };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Loading);

import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup.string().required('Email Address is required').email('Email Address is not a valid format'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
      'Passwords must have 8 characters and contain at least one letter and one number.'
    ),
  retypePassword: yup
    .string()
    .required('Repeat password is required')
    .oneOf([yup.ref('password'), 'retypePassword'], 'Passwords do not match')
});

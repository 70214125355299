import React, { useContext, useEffect, useState } from 'react';
import { SubscriptionLayout } from '../../../Layout/Subscription/subscription.jsx';
import { InformativeMessage, EnterprisePaymentSummary } from '../../../Components/index.js';
import { useLocation } from 'react-router-dom';
import { paymentsService } from '../../../Services/payments.service.js';
import Loading from '../../../Layout/loading/Component/Loading.jsx';

const PageContext = React.createContext();

export default function EnterprisePaymentPage() {
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  return (
    <PageContext.Provider value={{ loader, setLoader, location }}>
      <EnterprisePaymentPage_ />
    </PageContext.Provider>
  );
}

function EnterprisePaymentPage_() {
  const { loader, setLoader } = useContext(PageContext);
  const paymentIntent = new URLSearchParams(location.search)?.get('payment_intent');

  useEffect(() => {
    if (paymentIntent) {
      setLoader(true);

      paymentsService.getPaymentIntentStatus(paymentIntent).then(() => {
        setLoader(false);
      });
    }
  }, [paymentIntent]);

  return loader ? (
    <Loading />
  ) : (
    <SubscriptionLayout
      asideContent={() => <EnterprisePaymentSummary />}
      content={() => (
        <InformativeMessage
          title="Payment confirmed"
          subtitle="Thank you for signing up to TeachTappy"
          buttonAlign="left"
          buttonText="Start Seat Assignment"
          content={
            <div>
              {/* TODO email should be dynamic */}
              <p>
                Congratulations on your decision to empower your organization with the services that TeachTappy has to
                offer. You’ll receive a confirmation email to <b>johndoe@gmail.com</b> with the subscription details.
              </p>
              <p>
                To make the most of your subscription, make sure to assign seats to your organization members. Seat
                assignments ensure that your team can efficiently manage and access the resources available through our
                platform.
              </p>
            </div>
          }
        />
      )}
    />
  );
}

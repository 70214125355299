import { Button } from 'antd';
import { VerticalCard } from '../../atom';
import styles from './enterprise-subscription-card.module.scss';

export const EnterpriseSubscriptionCard = ({ product, isActive, onClick }) => {
  return (
    <VerticalCard key={product.name} isActive={isActive} className={styles.planCard}>
      <div className={styles.heading}>
        <h4 className="heading4">{product.name}</h4>
        <label className="body-large">{product.subtitle}</label>
        <p className="body">{product.description}</p>
      </div>
      <div className={`${styles.costContainer} ${styles.freePlanCardCost}`}>
        <h1>?</h1>
        <div className={styles.priceText}>
          <label className="body-large">{product.priceText}</label>
        </div>
      </div>
      <Button className={`btn btn-full ${isActive ? 'btn-disabled' : 'btn-secondary'}`} onClick={onClick}>
        {isActive ? 'Cancel Plan' : 'Contact Us'}
      </Button>
    </VerticalCard>
  );
};

import React, { useState, useEffect } from 'react';
import moment from 'moment';

const CourseSchedule = ({ courseData }) => {
  const [scheduleText, setScheduleText] = useState('');
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    if (courseData) {
      const { scheduleDays, courseTimeChoice } = courseData;

      const sameTimeSchedule = () => {
        if (scheduleDays.length > 0) {
          const uniqueDays = [...new Set(scheduleDays.map((item) => moment(item.day, 'dddd').format('ddd')))];
          const orderedDays = uniqueDays.sort((a, b) => weekdays.indexOf(a) - weekdays.indexOf(b));
          const startTime = moment(scheduleDays[0].startTime, 'HH:mm:ss').format('hh:mm a');
          const endTime = moment(scheduleDays[0].endTime, 'HH:mm:ss').format('hh:mm a');
          return `Schedule: ${orderedDays.join(' / ')} ${startTime} - ${endTime} EDT`;
        }
        return '';
      };

      const diffTimeSchedule = () => {
        if (scheduleDays && scheduleDays.length > 0) {
          const daysMap = {};
          scheduleDays.forEach((item) => {
            const day = moment(item.day, 'dddd').format('ddd');
            const startTime = moment(item.startTime, 'HH:mm:ss').format('hh:mm a');
            const endTime = moment(item.endTime, 'HH:mm:ss').format('hh:mm a');
            if (!daysMap[day]) {
              daysMap[day] = `${day.charAt(0).toUpperCase() + day.slice(1)} ${startTime} - ${endTime}`;
            }
          });
          const orderedSchedule = Object.entries(daysMap)
            .sort((a, b) => weekdays.indexOf(a[0]) - weekdays.indexOf(b[0]))
            .map((entry) => entry[1]);

          return `Schedule: ${orderedSchedule.join(' / ')} EDT`;
        }
        return '';
      };

      setScheduleText(courseTimeChoice === 'same_time' ? sameTimeSchedule() : diffTimeSchedule());
    }
  }, [courseData]);

  return (
    <div className="course-schedule">
      <i className="fa-regular fa-clock"></i>
      <span>{scheduleText}</span>
    </div>
  );
};

export default CourseSchedule;

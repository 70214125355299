import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import logoLg from '../../../../assets/images/logo-lg.svg';
import Loading from '../../../../Layout/loading/Container';
import { states } from '../../../../Lib/usState';
import { useHistory } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import config from '../../../../Lib/config';
const PageContext = React.createContext();

export default function TellUsAboutYou(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({ browserTimeZone: '', offset: null });

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, history, ...props }}>
      <TELLUSABOUTYOU />
    </PageContext.Provider>
  );
}

function TELLUSABOUTYOU() {
  const { loader, setLoader, state, setState, updateUser, history } = useContext(PageContext);

  React.useEffect(() => {
    const browserTimeZone = momentTimeZone.tz.guess(true);
    const utcOffset = momentTimeZone.tz(browserTimeZone).utcOffset();
    const stringTime = momentTimeZone.utc().utcOffset(utcOffset).format('Z');
    const offset = config.timeStringToFloat(stringTime);
    setState((s) => ({
      ...s,
      browserTimeZone: browserTimeZone || '',
      offset: offset || null
    }));
  }, []);

  const schema = yup.object().shape({
    firstName: yup.string().required('Field cannot be left blank'),
    lastName: yup.string().required('Field cannot be left blank'),
    displayName: yup.string().required('Field cannot be left blank'),
    subjects: yup.string(),
    gradeLevel: yup.string(),
    school: yup.string(),
    schoolDestrict: yup.string(),
    usState: yup.string(),
    zipCode: yup.string()
  });

  const { register, handleSubmit, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      subjects: '',
      gradeLevel: '',
      school: '',
      schoolDestrict: '',
      usState: '',
      zipCode: ''
    }
  });
  const { errors, isValid } = formState;

  const onSubmit = (data) => {
    if (!isValid) return;
    setLoader(true);
    const body = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      display_name: data?.displayName,
      teaching_subject: data?.subjects,
      teaching_grade: data?.gradeLevel,
      school_name: data?.school,
      school_district: data?.schoolDestrict,
      school_state: data?.usState,
      school_zip_code: data?.zipCode,
      // is_partially_profile_completed with required fields
      is_partially_profile_completed: true,
      // is_full_profile_completed with all the fields
      is_full_profile_completed:
        data?.firstName &&
        data?.lastName &&
        data?.displayName &&
        data?.subjects &&
        data?.gradeLevel &&
        data?.school &&
        data?.schoolDestrict &&
        data?.usState &&
        data?.zipCode
          ? true
          : false,
      /* Profile Default Image */
      avatar_profile_image_url: '/assets/images/img-1.png',
      time_zone: state?.browserTimeZone,
      time_offset: state?.offset
    };
    Promise.all([updateUser(body)])
      .then(() => {
        history.push('/teacher/dashboard');
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <div className="auth-block">
      {loader && <Loading />}
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            <div className="title-row">
              <h1 className="mb-0">Tell us about you</h1>
              <p>
                This information will help us personalize the experience and
                <br />
                connect you with similar teachers.
              </p>
            </div>
            <form className="form-group">
              <div className="fitem">
                <label className="lbl-txt clr-primary">Required fields are marked with *</label>
              </div>
              <div className="fitem half-fitem">
                <div className={errors['firstName'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label className="required">First Name</label>
                  <input type="text" {...register('firstName')} name="firstName" placeholder="First" />
                  {errors['firstName'] ? <label className="error">{errors['firstName']?.message}</label> : ''}
                </div>
                <div className={errors['lastName'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label className="required">Last Name</label>
                  <input type="text" {...register('lastName')} name="lastName" placeholder="Last" />
                  {errors['lastName'] ? <label className="error">{errors['lastName']?.message}</label> : ''}
                </div>
              </div>
              <div className={errors['displayName'] ? 'fitem has-error' : 'fitem'}>
                <label className="required">Display Name</label>
                <input type="text" {...register('displayName')} name="displayName" placeholder="firstname.lastname" />
                {errors['displayName'] ? (
                  <label className="error">{errors['displayName']?.message}</label>
                ) : (
                  <label className="lbl-txt">Different from actual name if you want to remain anonymous</label>
                )}
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>Subject(s) You Teach</label>
                  <input type="text" {...register('subjects')} name="subjects" placeholder="Biology, Physics" />
                  <label className="lbl-txt">Saparate subject with a comma</label>
                </div>
                <div className="fitem half-fitem">
                  <label>Grade / Level</label>
                  <input type="text" {...register('gradeLevel')} name="gradeLevel" placeholder="9 - 12" />
                </div>
              </div>
              <div className="fitem">
                <label>School</label>
                <input type="text" {...register('school')} name="school" placeholder="Where you teach" />
              </div>
              <div className="fitem">
                <label>School District</label>
                <input
                  type="text"
                  {...register('schoolDestrict')}
                  name="schoolDestrict"
                  placeholder="School District"
                />
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>State</label>
                  <select {...register('usState')} name="usState" placeholder="Select State Dropdown">
                    <option value="">Select State Dropdown</option>
                    {states.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="fitem half-fitem">
                  <label>School Zip Code</label>
                  <input type="text" {...register('zipCode')} name="zipCode" placeholder="School Zip Code" />
                </div>
              </div>
              <div className="btn-group pt-20">
                <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                  Finish Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

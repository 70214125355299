import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom/';

import logoLg from '../../../../assets/images/logo-lg.svg';
import Loading from '../../../../Layout/loading/Container';
import { useCreateAccount } from './CreateAccount.hook';
import styles from './CreateAccount.module.scss';

const PageContext = React.createContext();
export default function CreateAccount(props) {
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    passwordView: false,
    retypePasswordView: false
  });
  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, ...props }}>
      <CreateAccountComponent />
    </PageContext.Provider>
  );
}

function CreateAccountComponent() {
  const { loader, setLoader, state, setState, registration } = useContext(PageContext);
  const { passwordView, retypePasswordView } = state;
  const { errors, onSubmit, register } = useCreateAccount({ setLoader, registration });
  const mailTo = 'care@teachtappy.com';

  return (
    <div className="auth-block">
      {loader && <Loading />}
      <div className="logo-aside">
        <figure>
          <img src={logoLg} alt="" />
        </figure>
      </div>
      <div className="right-caption">
        <div className="caption-area">
          <div className="container">
            <div className="title-row">
              <h1 className="mb-0">Create your account</h1>
              <p>Sign up to access TeachTappy and start your 14-day free trial</p>
            </div>
            <form action="" className="form-group">
              <div className="fitem">
                <label htmlFor="" className="lbl-txt clr-primary">
                  Required fields are marked with *
                </label>
              </div>
              <div className={errors['email'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Email Address
                </label>
                <input
                  type="text"
                  {...register('email')}
                  name="email"
                  placeholder="teacher@toocoolforschool.com"
                  autoComplete="new-password"
                />
                {errors['email'] ? (
                  errors['email']?.message === 'suspend_by_admin' ? (
                    <label className="error">
                      <span>
                        Email is already associated with a suspended account. Use a different email or contact
                      </span>
                      <a href={`mailto:${mailTo}`} className={styles.emailError}>
                        {mailTo}
                      </a>
                      <span>for more information.</span>
                    </label>
                  ) : (
                    <label className="error">{errors['email']?.message}</label>
                  )
                ) : (
                  ''
                )}
              </div>
              <div className={errors['password'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Password
                </label>
                <div className="ficon">
                  <input
                    type={passwordView ? 'text' : 'password'}
                    {...register('password')}
                    name="password"
                    placeholder="8 character minimum"
                    autoComplete="new-password"
                  />
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setState((s) => ({
                        ...s,
                        passwordView: !passwordView
                      }))
                    }
                    className={passwordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                </div>

                {errors['password'] ? (
                  <label className="error">{errors['password']?.message}</label>
                ) : (
                  <label htmlFor="" className="lbl-txt">
                    Passwords must have 8 characters and contain at least one letter and one number.
                  </label>
                )}
              </div>
              <div className={errors['retypePassword'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Repeat Password
                </label>
                <div className="ficon">
                  <input
                    type={retypePasswordView ? 'text' : 'password'}
                    {...register('retypePassword')}
                    name="retypePassword"
                    placeholder="Repeat Password"
                    autoComplete="new-password"
                  />
                  <i
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setState((s) => ({
                        ...s,
                        retypePasswordView: !retypePasswordView
                      }))
                    }
                    className={retypePasswordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                </div>
                {errors['retypePassword'] ? <label className="error">{errors['retypePassword']?.message}</label> : ''}
              </div>
              <div className={styles.controlsGroup}>
                <div className={styles.alignToRight}>
                  <Link to="/" className="btn btn-bdr">
                    Back To Home
                  </Link>
                  <button type="button" className="btn btn-brand btn-dbr" onClick={onSubmit}>
                    Create Account
                  </button>
                </div>
                <div className={styles.alignToRight}>
                  <span>Have an account already?</span>
                  <Link to="/" className={'btn-txt'}>
                    Sign in
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loading from '../../../../Layout/loading/Container';
import adminLogo from '../../../../assets/images/admin-logo.svg';
const PageContext = React.createContext();

export default function ResetPassword(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({});

  return (
    <PageContext.Provider value={{ loader, setLoader, state, setState, history, ...props }}>
      <RESETPASSWORD />
    </PageContext.Provider>
  );
}

function RESETPASSWORD() {
  const { loader, setLoader, history, teacherAdminResetPassword } = useContext(PageContext);

  const schema = yup.object().shape({
    email: yup.string().required('Field cannot be left blank').email('Email Address is not a valid format')
  });

  const { register, handleSubmit, setError, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });
  const { errors } = formState;

  const onSubmit = (data) => {
    setLoader(true);
    const body = {
      ...data,
      user_type: 'admin'
    };
    Promise.all([teacherAdminResetPassword(body)])
      .then(() => {
        history.push('/teacher-admin/reset-password-email-verification');
        setLoader(false);
      })
      .catch((err) => {
        if (err?.detail) {
          setError('email', { type: 'error', message: err?.detail });
        }
        setLoader(false);
      });
  };

  return (
    <div id="content-area" className="admin-login-theme">
      <div className="admin-linner-outer">
        <div className="admin-linner">
          {loader && <Loading />}
          <div className="title-row">
            <img className="adminlogo" src={adminLogo} alt="" />
            <h1 className="text-center">Reset password</h1>
            <p className="text-left">
              Enter your email address below, and we’ll email you a 6-character validation <br />
              code. <i>No code will be sent if there is no account associated with this email.</i>
            </p>
          </div>
          <form className="form-group">
            <div className={errors['email'] ? 'fitem has-error' : 'fitem'}>
              <label htmlFor="" className="required">
                Email Address
              </label>
              <input
                type="text"
                {...register('email')}
                name="email"
                placeholder="Email address"
                autoComplete="new-password"
              />
              {errors['email'] ? <label className="error">{errors['email']?.message}</label> : ''}
            </div>
            <div className="btn-group pt-20">
              <button type="button" className="btn btn-brand btn-full" onClick={handleSubmit(onSubmit)}>
                Sent reset code
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

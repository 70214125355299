import Notifications from '../Component/Notifications';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Notifications } = state;
  return { Notifications };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Notifications);

import React, { useState } from 'react';
import { useContext } from 'react';
import { Tabs, Modal, Button, Upload, Select } from 'antd';
const { Option } = Select;
const { Dragger } = Upload;
import Cropper from 'react-easy-crop';
const { TabPane } = Tabs;
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import momentTimeZone from 'moment-timezone';
import store from '../../../store';
import { states } from '../../../Lib/usState';
import Loading from '../../../Layout/loading/Container';
const PageContext = React.createContext();
import getCroppedImg from '../../../Lib/cropImage';
import profileimg1 from '../../../assets/images/img-1.png';
import profileimg2 from '../../../assets/images/img-2.png';
import profileimg3 from '../../../assets/images/img-3.png';
import profileimg4 from '../../../assets/images/img-4.png';
import profileimg5 from '../../../assets/images/img-5.png';
import profileimg6 from '../../../assets/images/img-6.png';
import fileupload from '../../../assets/images/file-upload.svg';
import config from '../../../Lib/config';
import { CurrentPlanCard } from '../../../Components';
import { useHistory } from 'react-router-dom';
import SubscriptionMembers from './SubscriptionMembers/SubscriptionMembers';
import { subscriptionService } from '../../../Services/subscriptions.service';
import { tokenService } from '../../../Services';
import { SubscriptionStatusMessage } from '../../../Components/molecules';
import { toast } from 'react-toastify';

export default function ProfileAndSettings(props) {
  const [state, setState] = useState({
    profileImageUploadErrors: {},
    id: props?.user?.id,
    /* For Edit */
    firstName: props?.user?.first_name,
    lastName: props?.user?.last_name,
    displayName: props?.user?.display_name,
    subjects: props?.user?.teaching_subject,
    gradeLevel: props?.user?.teaching_grade,
    school: props?.user?.school_name,
    schoolDestrict: props?.user?.school_district,
    zipCode: props?.user?.school_zip_code,
    usState: props?.user?.school_state,
    avatarProfileImageUrl: props?.user?.avatar_profile_image_url,
    profileImageUrl: props?.user?.thumbnail_profile_image,
    /* Displaying */
    displayFirstName: props?.user?.first_name,
    displayLastName: props?.user?.last_name,
    displayDisplayName: props?.user?.display_name,
    displaySubjects: props?.user?.teaching_subject,
    displayGradeLevel: props?.user?.teaching_grade,
    displaySchool: props?.user?.school_name,
    displaySchoolDestrict: props?.user?.school_district,
    displayZipCode: props?.user?.school_zip_code,
    displayUsState: props?.user?.school_state,
    displayEmail: props?.user?.email,
    passwordView: false,
    newPasswordView: false,
    retypeNewPasswordView: false,
    timeZonesList: [],
    selectedTimeZone: null
  });
  const [loader, setLoader] = useState(false);
  const [isChangeProfileImage, setIsChangeProfileImage] = useState(false);
  const [changeProfileTab, setChangeProfileTab] = useState('1');
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [updatePasswordStep, setUpdatePasswordStep] = useState('UPDATE');
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [updateTimeZoneStep, setUpdateTimeZoneStep] = useState('UPDATE');
  const [isEditTimeZone, setIsEditTimeZone] = useState(false);

  return (
    <PageContext.Provider
      value={{
        state,
        setState,
        loader,
        setLoader,
        isChangeProfileImage,
        setIsChangeProfileImage,
        changeProfileTab,
        setChangeProfileTab,
        isProfileEdit,
        setIsProfileEdit,
        updatePasswordStep,
        setUpdatePasswordStep,
        isEditPassword,
        setIsEditPassword,
        updateTimeZoneStep,
        setUpdateTimeZoneStep,
        isEditTimeZone,
        setIsEditTimeZone,
        ...props
      }}>
      <PROFILEANDSETTINGS />
    </PageContext.Provider>
  );
}

function PROFILEANDSETTINGS() {
  const {
    state,
    setState,
    loader,
    setLoader,
    changeProfileTab,
    setChangeProfileTab,
    isChangeProfileImage,
    setIsChangeProfileImage,
    isProfileEdit,
    setIsProfileEdit,
    updatePasswordStep,
    setUpdatePasswordStep,
    isEditPassword,
    setIsEditPassword,
    updateUser,
    updateUserPassword,
    updateTimeZoneStep,
    setUpdateTimeZoneStep,
    isEditTimeZone,
    setIsEditTimeZone,
    user,
    getUser,
    getTimeZonesList
  } = useContext(PageContext);

  const {
    profileImageUploadErrors,
    firstName,
    lastName,
    displayName,
    subjects,
    gradeLevel,
    school,
    schoolDestrict,
    zipCode,
    usState,
    avatarProfileImageUrl,
    profileImageUrl,
    displayFirstName,
    displayLastName,
    displayDisplayName,
    displaySubjects,
    displayGradeLevel,
    displaySchool,
    displaySchoolDestrict,
    displayZipCode,
    displayUsState,
    displayEmail,
    passwordView,
    newPasswordView,
    retypeNewPasswordView,
    timeZonesList,
    selectedTimeZone
  } = state;

  const history = useHistory();
  const [changeProfileStep, setChangeProfileStep] = useState('UPLOAD');
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [customerSubscription, setCustomerSubscription] = useState(null);

  React.useEffect(() => {
    getCustomerSubscription();

    setLoader(true);
    Promise.all([getUser(), getTimeZonesList()])
      .then((res) => {
        const { getTimeZonesList } = store.getState();
        const browserTimeZone = momentTimeZone.tz.guess(true);
        const utcOffset = momentTimeZone.tz(browserTimeZone).utcOffset();
        const stringTime = momentTimeZone.utc().utcOffset(utcOffset).format('Z');
        const offset = config.timeStringToFloat(stringTime);
        const timeZone = res?.[0]?.time_zone || browserTimeZone;
        const timeOffset = res?.[0]?.time_offset || offset;
        const filterDayLightTimeZoneList = getTimeZonesList?.data?.filter((item) => {
          return !(!momentTimeZone.tz(item?.utc?.[0]).isDST() === item?.isdst);
        });
        const index = filterDayLightTimeZoneList?.findIndex((item) => {
          const utcOffset = momentTimeZone.tz(item?.utc?.[0]).utcOffset();
          const stringTime = momentTimeZone.utc().utcOffset(utcOffset).format('Z');
          const offset = config.timeStringToFloat(stringTime);
          return (
            item?.utc?.includes(timeZone) &&
            (item?.offset === timeOffset || item?.offset === offset) &&
            momentTimeZone.tz(item?.utc?.[0]).isDST() === item?.isdst
          );
        });
        setState((s) => ({
          ...s,
          timeZonesList: filterDayLightTimeZoneList || [],
          avatarProfileImageUrl: res[0]?.avatar_profile_image_url,
          profileImageUrl: res[0]?.thumbnail_profile_image,
          selectedTimeZone: index > -1 ? index : null
        }));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const getCustomerSubscription = React.useCallback(async () => {
    try {
      const { data } = await subscriptionService.getCustomerSubscription();
      setCustomerSubscription(data);
      tokenService.setSubscription(data);
    } catch (error) {
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  }, []);

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      0,
      { horizontal: false, vertical: false },
      imageFile
    );
    return croppedImage;
  }, [croppedAreaPixels]);

  const validateProfileImageUpload = (file = null) => {
    setLoader(true);
    const schema = yup.object().shape({
      firstName: yup.string().required('Field cannot be left blank'),
      lastName: yup.string().required('Field cannot be left blank'),
      displayName: yup.string().required('Field cannot be left blank')
    });
    const data = { firstName, lastName, displayName };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        let form_data = new FormData();
        form_data.append('first_name', firstName);
        form_data.append('last_name', lastName);
        form_data.append('display_name', displayName);
        if (file) {
          form_data.append('profile_image', file);
          form_data.append('avatar_profile_image_url', '');
        } else {
          form_data.append('profile_image', '');
          form_data.append('avatar_profile_image_url', avatarProfileImageUrl);
        }

        Promise.all([updateUser(form_data)])
          .then((res) => {
            /* Profile Image Modal Close START*/
            setIsChangeProfileImage(false);
            setChangeProfileTab('1');
            setChangeProfileStep('UPLOAD');
            /* Profile Image Modal Close END*/
            setState((s) => ({
              ...s,
              avatarProfileImageUrl: res[0]?.avatar_profile_image_url,
              profileImageUrl: res[0]?.thumbnail_profile_image,
              profileImageUploadErrors: {}
            }));
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      })
      .catch((error) => {
        let err_data = {};
        error?.inner.map((item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, profileImageUploadErrors: err_data }));
        setLoader(false);
      });
  };

  const profileSchema = yup.object().shape({
    firstName: yup.string().required('Field cannot be left blank'),
    lastName: yup.string().required('Field cannot be left blank'),
    displayName: yup.string().required('Field cannot be left blank')
  });

  const {
    register: profileRegister,
    handleSubmit: profileHandleSubmit,
    formState: profileFormState
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      displayName: displayName,
      subjects: subjects,
      gradeLevel: gradeLevel,
      school: school,
      schoolDestrict: schoolDestrict,
      usState: usState,
      zipCode: zipCode
    }
  });
  const { errors: profileErrors, isValid: profileIsValid } = profileFormState;

  const validateProfile = (data) => {
    if (!profileIsValid) return;
    setLoader(true);
    const body = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      display_name: data?.displayName,
      teaching_subject: data?.subjects,
      teaching_grade: data?.gradeLevel,
      school_name: data?.school,
      school_district: data?.schoolDestrict,
      school_state: data?.usState,
      school_zip_code: data?.zipCode,
      // is_partially_profile_completed with required fields
      is_partially_profile_completed: true,
      // is_full_profile_completed with all the fields
      is_full_profile_completed:
        data?.firstName &&
        data?.lastName &&
        data?.displayName &&
        data?.subjects &&
        data?.gradeLevel &&
        data?.school &&
        data?.schoolDestrict &&
        data?.usState &&
        data?.zipCode
          ? true
          : false
    };
    Promise.all([updateUser(body)])
      .then((res) => {
        setIsProfileEdit(false);
        setState((s) => ({
          ...s,
          displayFirstName: res[0]?.first_name,
          displayLastName: res[0]?.last_name,
          displayDisplayName: res[0]?.display_name,
          displaySubjects: res[0]?.teaching_subject,
          displayGradeLevel: res[0]?.teaching_grade,
          displaySchool: res[0]?.school_name,
          displaySchoolDestrict: res[0]?.school_district,
          displayZipCode: res[0]?.school_zip_code,
          displayUsState: res[0]?.school_state,
          displayEmail: res[0]?.email
        }));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const cancelProfileModal = () => {
    setState((s) => ({
      ...s,
      firstName: user?.first_name,
      lastName: user?.last_name,
      displayName: user?.display_name,
      subjects: user?.teaching_subject,
      gradeLevel: user?.teaching_grade,
      school: user?.school_name,
      schoolDestrict: user?.school_district,
      zipCode: user?.school_zip_code,
      usState: user?.school_state
    }));
    setIsProfileEdit(false);
  };

  const updatePasswordSchema = yup.object().shape({
    current_password: yup.string().required('This field cannot be left blank'),
    new_password: yup
      .string()
      .required('This field cannot be left blank')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        'Passwords must have 8 characters and contain at least one letter and one number.'
      ),
    retype_password: yup
      .string()
      .required('This field cannot be left blank')
      .oneOf([yup.ref('new_password'), null], 'Passwords do not match')
  });

  const {
    register: updatePasswordRegister,
    handleSubmit: updatePasswordHandleSubmit,
    formState: updatePasswordFormState,
    setError: updatePasswordSetError,
    clearErrors: updatePasswordClearError,
    reset: updatePasswordReset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(updatePasswordSchema),
    defaultValues: {
      current_password: '',
      new_password: '',
      retype_password: ''
    }
  });
  const { errors: passwordErrors } = updatePasswordFormState;

  const validateUpdatePassword = (data) => {
    setLoader(true);
    Promise.all([updateUserPassword(data)])
      .then(() => {
        setUpdatePasswordStep('DONE');
        updatePasswordReset();
        setState((s) => ({
          ...s,
          passwordView: false,
          newPasswordView: false,
          retypeNewPasswordView: false
        }));
        setLoader(false);
      })
      .catch((err) => {
        if (err?.detail?.length > 0) {
          updatePasswordSetError('current_password', { type: 'error', message: err?.detail[0] });
        }
        setLoader(false);
      });
  };

  const cancelUpdatePasswordModal = () => {
    updatePasswordReset();
    updatePasswordClearError();
    setIsEditPassword(false);
    setState((s) => ({
      ...s,
      passwordView: false,
      newPasswordView: false,
      retypeNewPasswordView: false
    }));
  };

  const PasswordUpdated = () => {
    setUpdatePasswordStep('UPDATE');
    setIsEditPassword(false);
  };

  const onChange = (value) => {
    setState((s) => ({
      ...s,
      selectedTimeZone: value
    }));
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const updateTimeZoneHandleSubmit = () => {
    const index = timeZonesList.findIndex((item, i) => i === selectedTimeZone);
    if (index > -1) {
      setLoader(true);
      const selectedTimeZoneObject = timeZonesList[selectedTimeZone];
      const body = {
        time_zone: selectedTimeZoneObject?.utc?.[0] || '',
        time_offset: +selectedTimeZoneObject?.offset || null
      };
      Promise.all([updateUser(body)])
        .then((res) => {
          console.log(res[0]);
          setState((s) => ({
            ...s,
            selectedTimeZone: index
          }));
          setUpdateTimeZoneStep('DONE');
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const cancelUpdateTimeZoneModal = () => {
    setIsEditTimeZone(false);
    setUpdateTimeZoneStep('UPDATE');
  };

  const cancelChangeProfileImageModal = () => {
    setIsChangeProfileImage(false);
    setChangeProfileTab('1');
    setChangeProfileStep('UPLOAD');
    setState((s) => ({
      ...s,
      avatarProfileImageUrl: user?.avatar_profile_image_url
    }));
  };

  const saveProfileImageModal = () => {
    if (changeProfileStep === 'RESIZE') {
      showCroppedImage()
        .then((res) => {
          if (res) {
            validateProfileImageUpload(res);
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else {
      validateProfileImageUpload();
    }
  };

  const getStateName = (school_state) => {
    const state = states.find((item) => item.value == school_state);
    return state.name;
  };

  const selectedImage = (imageUrl) => {
    setState((s) => ({
      ...s,
      avatarProfileImageUrl: imageUrl
    }));
  };

  const fileUploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      const fileSize = file?.size / 1024 / 1024;
      if (!['image/jpg', 'image/jpeg', 'image/png'].includes(file?.type)) {
        let err_data = { error: 'File type is not accepted. Please upload a png or jpg file.' };
        setState((s) => ({ ...s, profileImageUploadErrors: err_data }));
        return;
      }
      if (fileSize > 2) {
        let err_data = { error: 'Selected file is too large. File size cannot exceed 2MB.' };
        setState((s) => ({ ...s, profileImageUploadErrors: err_data }));
        return;
      }
      setImageFile(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImage(reader.result);
        setChangeProfileStep('RESIZE');
        setState((s) => ({ ...s, profileImageUploadErrors: {} }));
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
      return false;
    }
  };

  const onChangeProfileTabChange = (key) => {
    setChangeProfileTab(key);
  };

  const onError = () => {
    Promise.all([getUser()])
      .then((res) => {
        setState((s) => ({
          ...s,
          avatarProfileImageUrl: res[0]?.avatar_profile_image_url,
          profileImageUrl: res[0]?.thumbnail_profile_image
        }));
      })
      .catch(() => {});
  };

  return (
    <>
      {loader && <Loading />}
      <div className="main-head inner-head page-tabs page-profile">
        <div className="container xl-container">
          <h1>Profile & Settings</h1>
        </div>
      </div>
      <div className="page-content page-profile">
        <div className="container xl-container">
          <Tabs defaultActiveKey="1" size={'large'} className="head-tab">
            <TabPane tab="Profile" key="1">
              <div className="mprofile-wrap">
                <div className="mprofile-inner">
                  <figure>
                    <img
                      src={avatarProfileImageUrl ? avatarProfileImageUrl : profileImageUrl}
                      alt="profile-image"
                      onError={onError}
                    />
                  </figure>
                  <button className="edit-icon" onClick={() => setIsChangeProfileImage(true)}>
                    <i className="icon icon-edits icons-lg"></i>
                  </button>
                </div>
                <div className="edit-button">
                  <Button className="btn btn-white-bg" onClick={() => setIsProfileEdit(true)}>
                    <i className="icon icon-edits icons-lg mr-8 pl-0"></i>
                    Edit Profile
                  </Button>
                </div>
              </div>
              {!!customerSubscription && (
                <>
                  <SubscriptionStatusMessage
                    status={customerSubscription.status}
                    trialStart={customerSubscription.trialStart}
                    name={customerSubscription.name}
                    cancelAt={customerSubscription.cancelAt}
                  />
                  <CurrentPlanCard
                    currentPlanDescription={customerSubscription.name}
                    content={
                      <div className="page-profile__current-plan-children">
                        {customerSubscription.status === 'trialing' ? (
                          <>
                            <h1 className="heading1">{customerSubscription.remainingDays}</h1>
                            <label className="body-large">days remaining</label>
                            <Button
                              className="btn btn-secondary"
                              onClick={() =>
                                history.push({
                                  pathname: '/teacher/upgrade-plan',
                                  state: {
                                    prodId: customerSubscription.prodId,
                                    status: customerSubscription.status,
                                    cancelAt: customerSubscription.cancelAt,
                                    trialStart: customerSubscription.trialStart
                                  }
                                })
                              }>
                              <i className="fa-solid fa-wand-magic-sparkles"></i>
                              Upgrade
                            </Button>
                          </>
                        ) : (
                          <>
                            {!!customerSubscription.price &&
                              customerSubscription.memberType.toLowerCase() === 'owner' && (
                                <>
                                  <h1 className="heading1">
                                    ${((customerSubscription.price / 100) * customerSubscription.quantity).toFixed(2)}
                                  </h1>
                                  <label className="body-large">
                                    billed {customerSubscription.interval === 'year' ? 'yearly' : 'monthly'}
                                  </label>
                                </>
                              )}
                            {customerSubscription.memberType.toLowerCase() === 'owner' ? (
                              <Button
                                className="btn btn-secondary"
                                onClick={() =>
                                  history.push({
                                    pathname: '/teacher/upgrade-plan',
                                    state: {
                                      name: customerSubscription.name,
                                      status: customerSubscription.status,
                                      subscriptionId: customerSubscription.id,
                                      cancelAt: customerSubscription.cancelAt,
                                      trialStart: customerSubscription.trialStart,
                                      interval: customerSubscription.interval
                                    }
                                  })
                                }>
                                Manage Subscription
                              </Button>
                            ) : (
                              <>
                                <label className="body-large">{customerSubscription.name}</label>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    }
                  />
                </>
              )}
              <div className="form-group details-list">
                <div className="fitem half-fitem inline-half-fitem">
                  <div className="fitem half-fitem">
                    <label className="lbl-txt">Name</label>
                    <label>
                      {displayFirstName} {displayLastName}
                    </label>
                  </div>
                  <div className="fitem inline-fitem"></div>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">Display Name</label>
                  <label>{displayDisplayName}</label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">Subject&#40;s&#41;</label>
                  <label className={displaySubjects ? '' : 'disable'}>{displaySubjects || 'Subjects'}</label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">Grades</label>
                  <label className={displayGradeLevel ? '' : 'disable'}>{displayGradeLevel || 'Grade'}</label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">School</label>
                  <label className={displaySchool ? '' : 'disable'}>{displaySchool || 'Where you teach'}</label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">School District</label>
                  <label className={displaySchoolDestrict ? '' : 'disable'}>
                    {displaySchoolDestrict || 'School District'}
                  </label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">State</label>
                  <label className={displayUsState ? '' : 'disable'}>
                    {displayUsState ? getStateName(displayUsState) : 'State'}
                  </label>
                </div>
                <div className="fitem">
                  <label className="lbl-txt">School Zip Code</label>
                  <label className={displayZipCode ? '' : 'disable'}>{displayZipCode || 'School Zip Code'}</label>
                </div>
              </div>
              {/* CHANGE PROFILE IMAGE MODAL START */}
              <Modal
                closeIcon={<div className="icon icon-add"></div>}
                keyboard={false}
                maskClosable={false}
                centered={true}
                className="profile-setting-modal"
                visible={isChangeProfileImage}
                width={704}
                onCancel={cancelChangeProfileImageModal}
                footer={
                  changeProfileTab === '1' || (changeProfileTab === '2' && changeProfileStep === 'RESIZE')
                    ? [
                        <React.Fragment key="buttons">
                          <>
                            <Button
                              key="back"
                              onClick={cancelChangeProfileImageModal}
                              className="btn btn-primary btn-bdr">
                              Cancel
                            </Button>

                            <Button className="btn btn-brand" key="link" type="button" onClick={saveProfileImageModal}>
                              Save Image
                            </Button>
                          </>
                        </React.Fragment>
                      ]
                    : false
                }>
                <>
                  <div className="modal-head">
                    <h1>Change Profile Image</h1>
                  </div>
                  <div className="modal-body" style={changeProfileStep === 'RESIZE' ? { height: '530px' } : null}>
                    {changeProfileStep === 'UPLOAD' && (
                      <Tabs
                        defaultActiveKey={changeProfileTab}
                        activeKey={changeProfileTab}
                        onChange={onChangeProfileTabChange}>
                        <TabPane tab="Select Image" key="1">
                          <ul className="default-profile-list">
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-1.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-1.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-1.png')}>
                                <img src={profileimg1} />
                              </figure>
                            </li>
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-2.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-2.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-2.png')}>
                                <img src={profileimg2} />
                              </figure>
                            </li>
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-3.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-3.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-3.png')}>
                                <img src={profileimg3} />
                              </figure>
                            </li>
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-4.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-4.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-4.png')}>
                                <img src={profileimg4} />
                              </figure>
                            </li>
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-5.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-5.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-5.png')}>
                                <img src={profileimg5} />
                              </figure>
                            </li>
                            <li
                              className={avatarProfileImageUrl === '/assets/images/img-6.png' ? 'selected icon' : ''}
                              data-icon={avatarProfileImageUrl === '/assets/images/img-6.png' ? 'k' : ''}>
                              <figure onClick={() => selectedImage('/assets/images/img-6.png')}>
                                <img src={profileimg6} />
                              </figure>
                            </li>
                          </ul>
                        </TabPane>
                        <TabPane tab="Upload From Computer" key="2">
                          <Dragger {...fileUploadProps}>
                            <figure className="ant-upload-drag-icon">
                              <img src={fileupload} alt="" />
                            </figure>
                            <p className="ant-upload-text">Drag and drop your image here or upload below.</p>
                            {profileImageUploadErrors['error'] ? (
                              <label className="error">{profileImageUploadErrors['error']}</label>
                            ) : (
                              <p className="ant-upload-hint">File size cannot exceed 2MB </p>
                            )}
                            <button className="btn btn-brand">Upload From Computer</button>
                          </Dragger>
                        </TabPane>
                      </Tabs>
                    )}
                    {changeProfileStep === 'RESIZE' && (
                      <div className="profile-adjust">
                        <h4>Adjust your profile picture</h4>
                        <div className="crop-container">
                          <Cropper
                            image={image}
                            crop={crop}
                            zoom={zoom}
                            aspect={4 / 4}
                            cropShape="round"
                            showGrid={false}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropSize={{ width: 300, height: 300 }}
                          />
                        </div>
                        <div className="controls">
                          <i
                            className="icon icon-remove"
                            onClick={() => {
                              if (zoom <= 1) {
                                return;
                              }
                              setZoom((s) => +(+s - 0.1).toFixed(1));
                            }}></i>
                          <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                              setZoom(e.target.value);
                            }}
                            className="zoom-range"
                          />
                          <i
                            className="icon icon-add"
                            onClick={() => {
                              if (zoom >= 3) {
                                return;
                              }
                              setZoom((s) => +(+s + 0.1).toFixed(1));
                            }}></i>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </Modal>
              {/* CHANGE PROFILE IMAGE MODAL END */}
              {/* EDIT PROFILE MODAL START */}
              <Modal
                closeIcon={<div className="icon icon-add"></div>}
                keyboard={false}
                centered={true}
                maskClosable={false}
                visible={isProfileEdit}
                width={704}
                onCancel={cancelProfileModal}
                footer={[
                  <React.Fragment key="buttons">
                    <Button key="back" onClick={cancelProfileModal} className="btn btn-primary btn-bdr">
                      Cancel
                    </Button>

                    <Button
                      className="btn btn-brand"
                      key="link"
                      type="button"
                      onClick={profileHandleSubmit(validateProfile)}>
                      Save Changes
                    </Button>
                  </React.Fragment>
                ]}>
                <>
                  <div className="modal-head">
                    <h1>Edit Profile</h1>
                    <p>
                      Edit your profile information. <i>Required fields are marked with *</i>
                    </p>
                  </div>
                  <div className="modal-body">
                    <form action="" className="form-group">
                      <div className="fitem half-fitem">
                        <div className={profileErrors['firstName'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                          <label className="required">First Name</label>
                          <input type="text" {...profileRegister('firstName')} name="firstName" placeholder="first" />
                          {profileErrors['firstName'] ? (
                            <label className="error">{profileErrors['firstName']?.message}</label>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className={profileErrors['lastName'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                          <label className="required">Last Name</label>
                          <input type="text" {...profileRegister('lastName')} name="lastName" placeholder="last" />
                          {profileErrors['lastName'] ? (
                            <label className="error">{profileErrors['lastName']?.message}</label>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className={profileErrors['displayName'] ? 'fitem has-error' : 'fitem'}>
                        <label className="required">Display Name</label>
                        <input
                          type="text"
                          {...profileRegister('displayName')}
                          name="displayName"
                          placeholder="firstname.lastname"
                        />
                        {profileErrors['displayName'] ? (
                          <label className="error">{profileErrors['displayName']?.message}</label>
                        ) : (
                          <label className="lbl-txt">Different from actual name if you want to remain anonymous</label>
                        )}
                      </div>
                      <div className="fitem half-fitem">
                        <div className="fitem half-fitem">
                          <label>Subject(s) You Teach</label>
                          <input
                            type="text"
                            {...profileRegister('subjects')}
                            name="subjects"
                            placeholder="Biology, Physics"
                          />
                          <label className="lbl-txt">Saparate subject with a comma</label>
                        </div>
                        <div className="fitem half-fitem">
                          <label>Grade / Level</label>
                          <input
                            type="text"
                            placeholder="9 - 12"
                            {...profileRegister('gradeLevel')}
                            name="gradeLevel"
                          />
                        </div>
                      </div>
                      <div className="fitem">
                        <label>School</label>
                        <input type="text" {...profileRegister('school')} name="school" />
                      </div>
                      <div className="fitem">
                        <label>School District</label>
                        <input
                          type="text"
                          {...profileRegister('schoolDestrict')}
                          name="schoolDestrict"
                          placeholder="School District"
                        />
                      </div>
                      <div className="fitem half-fitem">
                        <div className="fitem half-fitem">
                          <label>State</label>
                          <select {...profileRegister('usState')} name="usState" placeholder="Select State">
                            <option value="">Select State</option>
                            {states.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="fitem half-fitem">
                          <label>School Zip Code</label>
                          <input
                            type="text"
                            {...profileRegister('zipCode')}
                            name="zipCode"
                            placeholder="School Zip Code"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              </Modal>
              {/* EDIT PROFILE MODAL END */}
            </TabPane>
            <TabPane tab="Settings" key="2">
              <div className="form-group details-list pb-64">
                <h2 className="setting-heading">Account</h2>
                <div className="fitem">
                  <label className="lbl-txt" htmlFor="">
                    Email
                  </label>
                  <label>{displayEmail || ''}</label>
                </div>
                <div className="fitem half-fitem inline-half-fitem align-items-end">
                  <div className="fitem half-fitem">
                    <label className="lbl-txt" htmlFor="">
                      Paswword
                    </label>
                    <label>**********</label>
                  </div>
                  <div className="fitem inline-fitem">
                    <button className="btn" type="button" onClick={() => setIsEditPassword(true)}>
                      <i className="icon icon-edits icons-lg mr-8 pl-0"></i>
                      Edit Password
                    </button>
                  </div>
                </div>
              </div>

              <div className="form-group details-list">
                <h2 className="setting-heading">Settings</h2>
                <div className="fitem half-fitem inline-half-fitem align-items-end">
                  <div className="fitem half-fitem">
                    <label className="lbl-txt" htmlFor="">
                      Time Zone
                    </label>
                    <label>{timeZonesList[selectedTimeZone]?.text || 'Time Zone'}</label>
                  </div>
                  <div className="fitem inline-fitem">
                    <button className="btn" type="button" onClick={() => setIsEditTimeZone(true)}>
                      <i className="icon icon-edits icons-lg mr-8 pl-0"></i>
                      Edit Time Zone
                    </button>
                  </div>
                </div>
              </div>

              {/* EDIT PASSWORD MODAL START */}
              <Modal
                closeIcon={<div className="icon icon-add"></div>}
                keyboard={false}
                centered={true}
                maskClosable={false}
                visible={isEditPassword}
                width={704}
                closable={updatePasswordStep === 'UPDATE' ? true : false}
                onCancel={cancelUpdatePasswordModal}
                footer={[
                  <React.Fragment key="buttons">
                    {updatePasswordStep === 'UPDATE' && (
                      <>
                        <Button key="back" onClick={cancelUpdatePasswordModal} className="btn btn-primary btn-bdr">
                          Cancel
                        </Button>

                        <Button
                          className="btn btn-brand"
                          key="link"
                          type="button"
                          onClick={updatePasswordHandleSubmit(validateUpdatePassword)}>
                          Save Changes
                        </Button>
                      </>
                    )}
                    {updatePasswordStep === 'DONE' && (
                      <div style={{ width: '75px', margin: '0 auto' }}>
                        <Button className="btn btn-brand" key="link" type="button" onClick={PasswordUpdated}>
                          Done
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                ]}>
                <>
                  {updatePasswordStep === 'UPDATE' && (
                    <>
                      <div className="modal-head">
                        <h1>Update Password</h1>
                        <h6 className="subhead">
                          Passwords must have 8 characters and contain at least one letter and one number.
                        </h6>
                      </div>
                      <div className="modal-body">
                        <form action="" className="form-group">
                          <div className="fitem">
                            <div className={passwordErrors['current_password'] ? 'fitem has-error' : 'fitem'}>
                              <label className="required">Current Password</label>
                              <div className="ficon">
                                <input
                                  type={passwordView ? 'text' : 'password'}
                                  {...updatePasswordRegister('current_password')}
                                  name="current_password"
                                  placeholder="Current Password"
                                  autoComplete="new-password"
                                />
                                <i
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    setState((s) => ({
                                      ...s,
                                      passwordView: !passwordView
                                    }))
                                  }
                                  className={passwordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                              </div>
                              {passwordErrors['current_password'] ? (
                                <label className="error">{passwordErrors['current_password']?.message}</label>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="fitem">
                            <div className={passwordErrors['new_password'] ? 'fitem has-error' : 'fitem'}>
                              <label className="required">New Password</label>
                              <div className="ficon">
                                <input
                                  type={newPasswordView ? 'text' : 'password'}
                                  {...updatePasswordRegister('new_password')}
                                  name="new_password"
                                  placeholder="New Password"
                                  autoComplete="new-password"
                                />
                                <i
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    setState((s) => ({
                                      ...s,
                                      newPasswordView: !newPasswordView
                                    }))
                                  }
                                  className={newPasswordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                              </div>
                              {passwordErrors['new_password'] ? (
                                <label className="error">{passwordErrors['new_password']?.message}</label>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="fitem">
                            <div className={passwordErrors['retype_password'] ? 'fitem has-error' : 'fitem'}>
                              <label className="required">Retype New Password</label>
                              <div className="ficon">
                                <input
                                  type={retypeNewPasswordView ? 'text' : 'password'}
                                  {...updatePasswordRegister('retype_password')}
                                  name="retype_password"
                                  placeholder="Retype New Password"
                                  autoComplete="new-password"
                                />
                                <i
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    setState((s) => ({
                                      ...s,
                                      retypeNewPasswordView: !retypeNewPasswordView
                                    }))
                                  }
                                  className={retypeNewPasswordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                              </div>
                              {passwordErrors['retype_password'] ? (
                                <label className="error">{passwordErrors['retype_password']?.message}</label>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                  {updatePasswordStep === 'DONE' && (
                    <>
                      <div className="modal-head">
                        <h1>Password has been successfully changed!</h1>
                      </div>
                    </>
                  )}
                </>
              </Modal>
              {/* EDIT PASSWORD MODAL END */}

              {/* EDIT TIME ZONE START */}
              <Modal
                closeIcon={<div className="icon icon-add"></div>}
                keyboard={false}
                centered={true}
                maskClosable={false}
                visible={isEditTimeZone}
                width={704}
                closable={updateTimeZoneStep === 'UPDATE' ? true : false}
                onCancel={cancelUpdateTimeZoneModal}
                footer={[
                  <React.Fragment key="buttons">
                    {updateTimeZoneStep === 'UPDATE' && (
                      <>
                        <Button key="back" onClick={cancelUpdateTimeZoneModal} className="btn btn-primary btn-bdr">
                          Cancel
                        </Button>

                        <Button className="btn btn-brand" key="link" type="button" onClick={updateTimeZoneHandleSubmit}>
                          Save Changes
                        </Button>
                      </>
                    )}
                    {updateTimeZoneStep === 'DONE' && (
                      <div style={{ width: '75px', margin: '0 auto' }}>
                        <Button className="btn btn-brand" key="link" type="button" onClick={cancelUpdateTimeZoneModal}>
                          Done
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                ]}>
                <>
                  {updateTimeZoneStep === 'UPDATE' && (
                    <>
                      <div className="modal-head">
                        <h1>Update Time Zone</h1>
                        <h6 className="subhead" style={{ marginTop: '24px' }}>
                          Update the time zone for your calendar & courses using the drop down.
                        </h6>
                      </div>
                      <div className="modal-body">
                        <div className="timezon-select" id="timeZ">
                          <label>Time zone</label>
                          <Select
                            value={selectedTimeZone}
                            showSearch={true}
                            placeholder="Select a time zone"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            // open={true}
                            getPopupContainer={() => document.getElementById('timeZ')}
                            filterOption={(input, option) => {
                              return (option?.children || '').toLowerCase().includes(input.toLowerCase());
                            }}>
                            {timeZonesList.map((item, index) => {
                              return (
                                <Option key={index} value={index}>
                                  {item?.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </>
                  )}
                  {updateTimeZoneStep === 'DONE' && (
                    <>
                      <div className="modal-head">
                        <h1>Your time zone was updated successfully.</h1>
                      </div>
                    </>
                  )}
                </>
              </Modal>
              {/* EDIT TIME ZONE END */}
            </TabPane>
            {customerSubscription?.name === 'Enterprise Subscription' &&
              customerSubscription.memberType === 'Owner' && (
                <TabPane tab="Subscription Members" key="3">
                  <SubscriptionMembers />
                </TabPane>
              )}
          </Tabs>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'antd';
import Loading from '../../../../Layout/loading/Container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import logoLg from '../../../../assets/images/logo-brand.svg';
import store from '../../../../store';
import { states } from '../../../../Lib/usState';
import * as moment from 'moment';

const PageContext = React.createContext();

export default function Dashboard(props) {
  const [state, setState] = useState({
    errors: {},
    isPasswordResetChecked: false,
    passwordView: false,
    retypePasswordView: false
  });
  const [loader, setLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <PageContext.Provider
      value={{
        state,
        setState,
        loader,
        setLoader,
        isModalVisible,
        setIsModalVisible,
        ...props
      }}>
      <DASHBOARD />
    </PageContext.Provider>
  );
}

function DASHBOARD() {
  const {
    state,
    setState,
    isModalVisible,
    setIsModalVisible,
    loader,
    setLoader,
    teacherAdminLogout,
    getAllUsers,
    suspenseUser,
    teacherAdminChangePassword
  } = useContext(PageContext);

  const [isViewProfileModalVisible, setIsViewProfileModalVisible] = useState(false);
  const { searchText, isPasswordResetChecked, passwordView, retypePasswordView } = state;
  const [value, setValue] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [suspendPopup, setSuspendPopup] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [suspendSuccessful, setSuspendSuccessful] = useState(false);
  const [reactivatePopup, setReactivatePopup] = useState(false);
  const [reactivateSuccessful, setReactivateSuccessful] = useState(false);
  const [removePopup, setRemovePopup] = useState(false);
  const [removeSuccessful, setRemoveSuccessful] = useState(false);
  const [isViewPasswordResetModalVisible, setIsViewPasswordResetVisible] = useState(false);
  const [passwordResetModalStep, setPasswordResetModalStep] = useState('CHECKBOX');
  const [ordering, setOrdering] = useState('');
  const [orderKey, setOrderKey] = useState('');
  const keyRef = useRef('');
  // const delayDebounceFn = useRef(null);

  useEffect(() => {
    getAdminUsers({});
  }, []);

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} params
   * comment below block of code because there is not anything mentioned that on textbox change we need to show the search data.
   * api call twice here when we enter anything in the textbox and when we select the search button.
   * <TARA-797></TARA-797>
   */
  // const onChangeSearchUser = (e) => {
  //   setState((s) => ({
  //     ...s,
  //     searchText: e?.target?.value
  //   }));
  //   if (delayDebounceFn.current) {
  //     clearTimeout(delayDebounceFn.current);
  //     delayDebounceFn.current = null;
  //   }
  //   delayDebounceFn.current = setTimeout(() => {
  //     if (searchText) {
  //       const params = {
  //         search: e?.target?.value
  //       };
  //       getAdminUsers(params);
  //     } else {
  //       getAdminUsers({});
  //     }
  //   }, 2000);
  // };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-127><Ability for Tappy Admin to view all users on a list/TARA-127>
   */
  const getAdminUsers = (params = {}) => {
    setLoader(true);
    Promise.allSettled([getAllUsers(params)]).then((result) => {
      const isFulfilled = result.filter((x) => x.status === 'fulfilled');
      if (isFulfilled?.length > 0) {
        const { AllUsers } = store.getState();
        if (AllUsers && Object.keys(AllUsers?.data).length > 0) {
          const activeUsers = AllUsers?.data?.results.filter((x) => x.status === 'Active');
          setState((s) => ({
            ...s,
            AllUsers: AllUsers?.data?.results,
            totalUsers: AllUsers?.data?.count,
            activeUsers: activeUsers?.length
          }));
        }
        setLoader(false);
      }
    });
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-127><Ability for Tappy Admin to view all users on a list/TARA-127>
   */
  const searchUser = () => {
    const params = {
      search: searchText
    };
    getAdminUsers(searchText ? params : {});
  };

  const logoutHandler = () => {
    setLoader(true);
    Promise.all([teacherAdminLogout()])
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-131>Ability for Tappy Admin to suspend a user</TARA-131>
   */
  const suspenseAccount = () => {
    if (isConfirm) {
      setLoader(true);
      const params = {
        is_active: false,
        action_type: 'suspend',
        email: value?.email
      };
      Promise.allSettled([suspenseUser(params)])
        .then(() => {
          const { SuspenseUser } = store.getState();
          if (SuspenseUser?.data && Object.keys(SuspenseUser?.data).length > 0) {
            getAdminUsers();
            setSuspendSuccessful(true);
            setIsConfirm(false);
            setSuspendPopup(false);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
          setIsConfirm(false);
        });
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-131>Ability for Tappy Admin to suspend a user</TARA-131>
   */
  const reActivateAccount = () => {
    if (isConfirm) {
      setLoader(true);
      const params = {
        is_active: true,
        action_type: 'suspend',
        email: value?.email
      };
      Promise.allSettled([suspenseUser(params)])
        .then(() => {
          const { SuspenseUser } = store.getState();
          if (SuspenseUser?.data && Object.keys(SuspenseUser?.data).length > 0) {
            getAdminUsers();
            setReactivateSuccessful(true);
            setReactivatePopup(false);
            setIsConfirm(false);
          }
          setLoader(false);
        })
        .catch(() => {
          setIsConfirm(false);
          setLoader(false);
        });
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * <TARA-130>Ability for Tappy Admin to remove a user</TARA-130>
   */
  const removeAccount = () => {
    if (isConfirm) {
      setLoader(true);
      const params = {
        action_type: 'remove',
        email: value?.email
      };
      Promise.allSettled([suspenseUser(params)])
        .then(() => {
          const { SuspenseUser } = store.getState();
          if (SuspenseUser?.data && Object.keys(SuspenseUser?.data).length > 0) {
            getAdminUsers();
            setRemoveSuccessful(true);
            setIsConfirm(false);
            setRemovePopup(false);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  /**
   * AUTHOR: RITESH SOLANKI
   * @param {*} sortBy sortBy key
   * Function to sort data by key provided
   * <TARA-127><Ability for Tappy Admin to view all users on a list/TARA-127>
   */
  const sorting = (sortBy) => {
    /* Hard coded as per story TARA-127 Requirements: 2 START */
    let order;
    if (sortBy && sortBy.includes('last_name')) {
      order = ordering === '' ? 'ascend' : ordering === 'ascend' ? 'descend' : '';
      order = keyRef.current !== sortBy ? 'ascend' : order;
    } else if (sortBy && sortBy.includes('last_login')) {
      /**
       * AUTHOR: Ritesh Solanki
       * comment below code to complete <TARA-796></TARA-796>
       */
      order = ordering === '' ? 'descend' : ordering === 'descend' ? 'ascend' : '';
      order = keyRef.current !== sortBy ? 'descend' : order;
      // order = ordering === '' ? 'ascend' : ordering === 'ascend' ? 'descend' : '';
      // order = keyRef.current !== sortBy ? 'ascend' : order;
    } else if (sortBy && sortBy.includes('status')) {
      /**
       * AUTHOR: Ritesh Solanki
       * comment below code to complete <TARA-796></TARA-796>
       */
      // order = ordering === '' ? 'descend' : ordering === 'descend' ? 'ascend' : '';
      // order = keyRef.current !== sortBy ? 'descend' : order;
      order = ordering === '' ? 'ascend' : ordering === 'ascend' ? 'descend' : '';
      order = keyRef.current !== sortBy ? 'ascend' : order;
    }
    /* Hard coded as per story TARA-127 Requirements: 2 END */
    setOrdering(order);
    let sortByKey = order === '' ? '' : order === 'ascend' ? `${sortBy}` : `-${sortBy}`;
    setOrderKey(sortByKey);
    const params = filterParams({
      ordering: sortByKey
    });
    keyRef.current = sortBy;
    getAdminUsers(params);
  };

  /**
   * AUTHOR: Ritesh Solanki
   * Function to remove black or null params from object
   * @param {*} params
   */
  const filterParams = (params) => {
    for (var propName in params) {
      if (
        params[propName] === '' ||
        params[propName] === null ||
        params[propName] === 'null' ||
        params[propName] === undefined ||
        params[propName] === 'undefined'
      ) {
        delete params[propName];
      }
    }
    return params;
  };

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Field cannot be left blank')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        'Passwords must have 8 characters and contain at least one letter and one number.'
      ),
    retypePassword: yup
      .string()
      .required('Field cannot be left blank')
      .oneOf([yup.ref('password'), 'retypePassword'], 'Passwords do not match')
  });
  const { register, formState, trigger, getValues, reset, clearErrors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      retypePassword: ''
    }
  });
  const { errors, isValid } = formState;

  const changeResetPasswordStep = async () => {
    if (passwordResetModalStep === 'CHECKBOX') {
      setPasswordResetModalStep('FORM');
    } else if (passwordResetModalStep === 'FORM') {
      trigger('password');
      trigger('retypePassword');
      if (!isValid) {
        return;
      }
      onSubmit(getValues());
    } else {
      setIsViewPasswordResetVisible(false);
      setPasswordResetModalStep('CHECKBOX');
      setState((s) => ({
        ...s,
        isPasswordResetChecked: false
      }));
    }
  };

  const onSubmit = (data) => {
    const body = {
      email: value?.email,
      password: data?.password,
      password2: data?.retypePassword
    };
    setLoader(true);
    Promise.all([teacherAdminChangePassword(body)])
      .then(() => {
        setLoader(false);
        setPasswordResetModalStep('SUCCESS');
      })
      .catch(() => {
        setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} date
   * @returns
   */
  const UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local().format('MMMM DD, YYYY');
  };

  /**
   * AUTHOR: Ritesh Solanki
   * @param {*} value
   * <TARA-781>Admin Panel | Account Profile | It is not showing "State" name as full, it is showing 2 character format</TARA-781>
   */
  const getState = (value) => {
    if (value) {
      const state = states.filter((state) => state.value === value);
      if (state && state.length > 0) {
        return state[0]['name'];
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  return (
    <div className="w-100">
      {loader && <Loading />}
      <div className="dashboard-header">
        <div className="dash-container d-flex align-items-center">
          <div className="d-flex align-items-center flex-fill">
            <a href="" className="dash-logo">
              <img src={logoLg} alt="" />
            </a>
            <h1>Admin Dashboard</h1>
          </div>
          <Link className="sign-out" to="#" onClick={() => setIsModalVisible(true)}>
            Sign Out
          </Link>
        </div>
      </div>

      <div className="dashboard-body">
        <div className="dash-container">
          <div className="dash-toolbar d-flex justify-content-between align-items-end">
            <div className="user-statistic">
              <div className="active-users">
                <strong>{state?.activeUsers || 0}</strong>
                Total Active Users
              </div>
              <div className="total-users">
                <strong>{state?.totalUsers || 0}</strong>
                Total Users
              </div>
            </div>
            <div className="dashboard-search d-flex">
              <div className="search-area flex-fill">
                <i className="fa-regular fa-magnifying-glass"></i>
                <input
                  type="text"
                  placeholder="Search user"
                  onChange={(e) => {
                    if (e?.target?.value === '') {
                      getAdminUsers({});
                    }
                    setState((s) => ({
                      ...s,
                      searchText: e?.target?.value
                    }));
                  }}
                />
              </div>
              <button
                key="search"
                className="btn search-btn btn-primary btn-bdr"
                onClick={() => {
                  searchUser();
                }}>
                Search
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="dash-table">
              <thead>
                <tr>
                  <th
                    className="has-sort"
                    onClick={() => {
                      sorting('last_name');
                    }}>
                    Name
                    <i
                      className={
                        orderKey === 'last_name'
                          ? 'fa-solid fa-sort-down'
                          : orderKey === '-last_name'
                          ? 'fa-solid fa-sort-up'
                          : 'fa-solid fa-sort'
                      }></i>
                  </th>
                  <th>Display Name</th>
                  <th>Email</th>
                  <th
                    className="has-sort"
                    onClick={() => {
                      sorting('last_login');
                    }}>
                    Last Active
                    <i
                      className={
                        orderKey === 'last_login'
                          ? 'fa-solid fa-sort-up'
                          : orderKey === '-last_login'
                          ? 'fa-solid fa-sort-down'
                          : 'fa-solid fa-sort'
                      }></i>
                  </th>
                  <th
                    className="has-sort"
                    onClick={() => {
                      sorting('status');
                    }}>
                    Status
                    <i
                      className={
                        orderKey === 'status'
                          ? 'fa-solid fa-sort-down'
                          : orderKey === '-status'
                          ? 'fa-solid fa-sort-up'
                          : 'fa-solid fa-sort'
                      }></i>
                  </th>
                  <th className="has-sort">Profile</th>
                </tr>
              </thead>
              <tbody>
                {state?.AllUsers &&
                  state?.AllUsers?.length > 0 &&
                  state?.AllUsers.map((item) => (
                    <tr key={item.id}>
                      <td>{item?.last_name && item?.first_name ? `${item.last_name}, ${item?.first_name}` : ''}</td>
                      <td>{item.display_name}</td>
                      <td>{item.email}</td>
                      <td>{UTCToLocal(item.last_active)}</td>
                      <td>
                        <span
                          className={
                            item?.status === 'Active'
                              ? 'stat stat-active'
                              : item?.status === 'Pending'
                              ? 'stat stat-pending'
                              : 'stat stat-suspend'
                          }>
                          {item?.status}
                        </span>
                      </td>
                      <td>
                        <a
                          href="#"
                          className="view-link"
                          onClick={(e) => {
                            e.preventDefault();
                            setValue(item);
                            setOpenPopup(false);
                            setIsViewProfileModalVisible(true);
                            //   setValue(record);
                          }}>
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                {state?.AllUsers && state?.AllUsers?.length === 0 && (
                  <tr key={'nodata'}>
                    <td colSpan={6}> No data found.</td>
                  </tr>
                )}
                <Modal
                  visible={isViewProfileModalVisible}
                  width={770}
                  closeIcon={<div className="icon icon-add"></div>}
                  keyboard={false}
                  centered={true}
                  className="account-prof-modal"
                  maskClosable={false}
                  footer={[]}
                  onCancel={() => {
                    setIsViewProfileModalVisible(false);
                  }}>
                  <div className="modal-head">
                    <React.Fragment>
                      <h2>Account Profile</h2>
                      <div className="personal-info">
                        <div className="row">
                          <div className="col-6">
                            <div className="pi-block">
                              <label className="fw-normal">Name</label>
                              <div className="pi-value">
                                {value?.last_name && value?.first_name
                                  ? `${value.last_name}, ${value?.first_name}`
                                  : ''}
                              </div>
                            </div>
                            <div className="pi-block">
                              <label className="fw-normal">Display Name</label>
                              <div className="pi-value">{value?.display_name || '-'}</div>
                            </div>
                            <div className="pi-block">
                              <label className="fw-normal">Email</label>
                              <div className="pi-value">{value?.email || '-'}</div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div
                              className={
                                value?.status === 'Active'
                                  ? 'status-color-block green-block'
                                  : value?.status === 'Pending'
                                  ? 'status-color-block gray-block'
                                  : 'status-color-block red-block'
                              }>
                              <div className="acc-stat-block">
                                <label>Account Status</label>

                                {value?.status === 'Active' ? (
                                  <div className="acc-stat-active flex align-items-center mx-0">
                                    <span className="stat stat-active mx-0">{value?.status}</span>
                                    <div className={openPopup ? 'acc-dropdown open' : 'acc-dropdown'}>
                                      <span
                                        className="acc-btn"
                                        onClick={() => {
                                          if (openPopup) {
                                            setOpenPopup(false);
                                          } else {
                                            setOpenPopup(true);
                                          }
                                        }}>
                                        <i className="fa-solid fa-ellipsis mx-0"></i>
                                      </span>
                                      <ul className="acc-dropdownmenu">
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setIsViewPasswordResetVisible(true);
                                              setState((s) => ({
                                                ...s,
                                                isPasswordResetChecked: false,
                                                passwordView: false,
                                                retypePasswordView: false
                                              }));
                                              reset();
                                              clearErrors();
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-key"></i>
                                            </span>{' '}
                                            Reset Password
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setSuspendPopup(true);
                                              setIsConfirm(false);
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-ban"></i>
                                            </span>{' '}
                                            Suspend
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setRemovePopup(true);
                                              setIsConfirm(false);
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-trash"></i>
                                            </span>
                                            Remove
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : value?.status === 'Pending' ? (
                                  <div className="acc-stat-active flex align-items-center mx-0">
                                    <span className="stat stat-pending">{value?.status}</span>
                                    <div className={openPopup ? 'acc-dropdown open' : 'acc-dropdown'}>
                                      <span
                                        className="acc-btn"
                                        onClick={() => {
                                          if (openPopup) {
                                            setOpenPopup(false);
                                          } else {
                                            setOpenPopup(true);
                                          }
                                        }}>
                                        <i className="fa-solid fa-ellipsis mx-0"></i>
                                      </span>
                                      <ul className="acc-dropdownmenu">
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setRemovePopup(true);
                                              setIsConfirm(false);
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-trash"></i>
                                            </span>
                                            Remove
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="acc-stat-active flex align-items-center mx-0">
                                    <span className="stat stat-suspend">{value?.status}</span>
                                    <div className={openPopup ? 'acc-dropdown open' : 'acc-dropdown'}>
                                      <span
                                        className="acc-btn"
                                        onClick={() => {
                                          if (openPopup) {
                                            setOpenPopup(false);
                                          } else {
                                            setOpenPopup(true);
                                          }
                                        }}>
                                        <i className="fa-solid fa-ellipsis mx-0"></i>
                                      </span>
                                      <ul className="acc-dropdownmenu">
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setReactivatePopup(true);
                                              setIsConfirm(false);
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-rotate-right"></i>
                                            </span>
                                            Reactivate
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              setIsViewProfileModalVisible(false);
                                              setRemovePopup(true);
                                              setIsConfirm(false);
                                            }}>
                                            <span>
                                              <i className="fa-solid fa-trash"></i>
                                            </span>
                                            Remove
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="last-act">
                                <label>Last Active</label>
                                <div>{UTCToLocal(value?.last_active) || '-'}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="pi-block">
                            <label className="fw-normal">Grade / Level</label>
                            <div className="pi-value">{value?.teaching_grade || '-'}</div>
                          </div>
                          <div className="pi-block">
                            <label className="fw-normal">School</label>
                            <div className="pi-value">{value?.school_name || '-'}</div>
                          </div>
                          <div className="pi-block">
                            <label className="fw-normal">State</label>
                            <div className="pi-value">{getState(value?.school_state) || '-'}</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="pi-block">
                            <label className="fw-normal">Subject(s)</label>
                            <div className="pi-value">{value?.teaching_subject || '-'}</div>
                          </div>
                          <div className="pi-block">
                            <label className="fw-normal">School District</label>
                            <div className="pi-value">{value?.school_district || '-'}</div>
                          </div>
                          <div className="pi-block">
                            <label className="fw-normal">Zip Code</label>
                            <div className="pi-value">{value?.school_zip_code || '-'}</div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </Modal>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* LOGOUT MODAL START */}
      <Modal
        keyboard={false}
        centered={true}
        visible={isModalVisible}
        width={770}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            <Button className="btn btn-primary btn-bdr" type="button" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>

            <Button className="btn btn-brand" type="button" onClick={logoutHandler}>
              Yes, sign out
            </Button>
          </>
        ]}>
        <>
          <div className="modal-head" key="1">
            <h1>Sign out of Tappy Admin</h1>
            <h4 className="subhead"> Are you sure you want to log out of your Tappy admin account?</h4>
          </div>
          <div className="modal-body" key="2"></div>
        </>
      </Modal>
      {/* LOGOUT MODAL END */}

      {/* SUSPENDE MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        className={'admin-model'}
        visible={suspendPopup}
        onOk={() => {
          setSuspendPopup(false);
        }}
        onCancel={() => {
          setSuspendPopup(false);
        }}
        width={770}
        maskClosable={false}
        footer={[
          <Button
            key={'suspense_cancel'}
            className="btn btn-primary btn-bdr"
            type="button"
            onClick={() => setSuspendPopup(false)}>
            Cancel
          </Button>,
          <Button
            key="suspense_confirm"
            disabled={!isConfirm}
            onClick={() => {
              suspenseAccount();
            }}
            className="btn btn-brand">
            Confirm Suspension
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Confirm Account Suspension</h1>
            <h4 className="subhead">
              Are you sure you want to suspend the account for <strong>{value?.email}</strong>? Their account will be
              locked and they will not be able to log in or access their data.
            </h4>
          </div>
          <ul className="list-item danger-list">
            <li>
              <p>
                <strong> Please confirm account suspension by checking the box below. </strong>
              </p>
            </li>
          </ul>
          <div className="fitem-check">
            <input
              id="suspend-account"
              className="fitem-ck-input"
              type="checkbox"
              checked={isConfirm}
              onChange={() => {
                setIsConfirm(!isConfirm);
              }}
            />
            <label className="fitem-ck-txt" htmlFor="suspend-account">
              Yes, suspend account for <strong>{value?.email}</strong>
            </label>
          </div>
        </div>
      </Modal>
      {/* SUSPENDE MODAL END */}

      {/* SUSPENDE SUCCESSFULL MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={suspendSuccessful}
        onOk={() => {
          setSuspendSuccessful(false);
        }}
        onCancel={() => {
          setSuspendSuccessful(false);
        }}
        width={704}
        maskClosable={false}
        footer={[
          <Button
            key="course_delete_back"
            onClick={() => {
              setSuspendSuccessful(false);
            }}
            className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1 className="mb-24">Confirm Account Suspension</h1>
            <h4 className="subhead mb-0">
              The account associated with <strong>{value?.email}</strong> has been suspended. To reinstate their
              account, view their profile and select “reactivate” from the dropdown.
            </h4>
          </div>
        </div>
      </Modal>
      {/* SUSPENDE SUCCESSFULL MODAL END */}

      {/* REACTIVATE MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        className={'admin-model'}
        visible={reactivatePopup}
        onOk={() => {
          setReactivatePopup(false);
        }}
        onCancel={() => {
          setReactivatePopup(false);
        }}
        width={704}
        maskClosable={false}
        footer={[
          <Button
            key={'suspense_cancel'}
            className="btn btn-primary btn-bdr"
            type="button"
            onClick={() => setReactivatePopup(false)}>
            Cancel
          </Button>,
          <Button
            key="suspense_confirm"
            disabled={!isConfirm}
            onClick={() => {
              reActivateAccount();
            }}
            className="btn btn-brand">
            Confirm Reactivation
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Confirm Account Reactivation</h1>
            <h4 className="subhead">
              Are you sure you want to reactivate the account for <strong>{value?.email}</strong>? Their account will be
              unlocked.
            </h4>
          </div>
          <ul className="list-item success-list">
            <li>
              <p>
                <strong>Please confirm account reactivation by checking the box below.</strong>
              </p>
            </li>
          </ul>
          <div className="fitem-check">
            <input
              id="reactivate"
              className="fitem-ck-input"
              type="checkbox"
              checked={isConfirm}
              onChange={() => {
                setIsConfirm(!isConfirm);
              }}
            />
            <label className="fitem-ck-txt" htmlFor="reactivate">
              Yes, reactivate the account for <strong>{value?.email}</strong>
            </label>
          </div>
        </div>
      </Modal>
      {/* REACTIVATE MODAL END */}

      {/* REACTIVATE SUCCESSFULL MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={reactivateSuccessful}
        onOk={() => {
          setReactivateSuccessful(false);
        }}
        onCancel={() => {
          setReactivateSuccessful(false);
        }}
        width={704}
        maskClosable={false}
        footer={[
          <Button
            key="course_delete_back"
            onClick={() => {
              setReactivateSuccessful(false);
            }}
            className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1 className="mb-24">Account Reactivated</h1>
            <h4 className="subhead mb-0">
              The account associated with <strong>{value?.email}</strong> has been reactivated.{' '}
              <strong>{value?.email}</strong> can now log in and access their data.
            </h4>
          </div>
        </div>
      </Modal>
      {/* REACTIVATE SUCCESSFULL MODAL END */}

      {/* REMOVE MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        className={'admin-model'}
        visible={removePopup}
        onOk={() => {
          setRemovePopup(false);
        }}
        onCancel={() => {
          setRemovePopup(false);
        }}
        width={704}
        maskClosable={false}
        footer={[
          <Button
            key={'remove_cancel'}
            className="btn btn-primary btn-bdr"
            type="button"
            onClick={() => setRemovePopup(false)}>
            Cancel
          </Button>,
          <Button
            key="remove_confirm"
            disabled={!isConfirm}
            onClick={() => {
              removeAccount();
            }}
            className="btn btn-brand">
            Confirm Removal
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1>Confirm Account Removal</h1>
            <h4 className="subhead">
              Are you sure you want to remove the account for <strong>{value?.email}</strong>? Their account and all
              data associated with it will be removed from TAPPY systems.
            </h4>
          </div>
          <ul className="list-item danger-list">
            <li>
              <p>
                Please confirm this action by checking the box below.
                <br />
                <strong>This cannot be undone.</strong>
              </p>
            </li>
          </ul>
          <div className="fitem-check">
            <input
              id="removeacc"
              className="fitem-ck-input"
              type="checkbox"
              checked={isConfirm}
              onChange={() => {
                setIsConfirm(!isConfirm);
              }}
            />
            <label className="fitem-ck-txt" htmlFor="removeacc">
              Yes, remove account for <strong>{value?.email}</strong>
            </label>
          </div>
        </div>
      </Modal>
      {/* REMOVE MODAL END */}

      {/* REMOVE SUCCESSFULL MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={removeSuccessful}
        onOk={() => {
          setRemoveSuccessful(false);
        }}
        onCancel={() => {
          setRemoveSuccessful(false);
        }}
        width={704}
        maskClosable={false}
        footer={[
          <Button
            key="course_delete_back"
            onClick={() => {
              setRemoveSuccessful(false);
            }}
            className="btn btn-brand">
            Okay
          </Button>
        ]}>
        <div className="delete-wrap">
          <div className="modal-head">
            <h1 className="mb-24">Account Removed</h1>
            <h4 className="subhead mb-0">
              The account associated with <strong>{value?.email}</strong> has been removed.
            </h4>
          </div>
        </div>
      </Modal>
      {/* REMOVE SUCCESSFULL MODAL END */}

      {/* CONFIRM PASSWORD RESET MODAL START */}
      <Modal
        closeIcon={<div className="icon icon-add"></div>}
        keyboard={false}
        centered={true}
        visible={isViewPasswordResetModalVisible}
        width={704}
        onCancel={() => {
          setPasswordResetModalStep('CHECKBOX');
          setState((s) => ({
            ...s,
            isPasswordResetChecked: false
          }));
        }}
        closable={false}
        maskClosable={false}
        footer={[
          <>
            {passwordResetModalStep !== 'SUCCESS' && (
              <Button
                className="btn btn-primary btn-bdr"
                type="button"
                onClick={() => {
                  setIsViewPasswordResetVisible(false);
                  setPasswordResetModalStep('CHECKBOX');
                  setState((s) => ({
                    ...s,
                    isPasswordResetChecked: false
                  }));
                }}>
                Cancel
              </Button>
            )}

            <Button
              className={!isPasswordResetChecked ? 'btn btn-brand disabled' : 'btn btn-brand'}
              type="button"
              disabled={!isPasswordResetChecked}
              onClick={changeResetPasswordStep}>
              {passwordResetModalStep !== 'SUCCESS' ? 'Confirm Password Reset' : 'Okay'}
            </Button>
          </>
        ]}>
        <>
          <div className="modal-head" key="1">
            <h1>{passwordResetModalStep !== 'SUCCESS' ? 'Confirm Reset Password' : 'Password Reset Successful'}</h1>
          </div>
          <div className="modal-body admin-model" key="2">
            {passwordResetModalStep === 'CHECKBOX' && (
              <>
                <div className="modal-head">
                  <h4 className="subhead">
                    Are you sure you want to reset the password for<strong> {value?.email}</strong>? They will be locked
                    out of their account until the new password is emailed to them.
                  </h4>
                </div>
                <ul className="list-item danger-list">
                  <li>
                    <p>
                      <strong>Please confirm password reset by checking the box below.</strong>
                    </p>
                  </li>
                </ul>
                <div className="fitem-check">
                  <input
                    className="fitem-ck-input"
                    id="checkbox-check"
                    type="checkbox"
                    checked={isPasswordResetChecked}
                    onChange={(e) => {
                      setState((s) => ({
                        ...s,
                        isPasswordResetChecked: e.target.checked
                      }));
                    }}
                  />
                  <label className="fitem-ck-txt" htmlFor="checkbox-check">
                    Yes, reset account password for <strong>{value?.email}</strong>
                  </label>
                </div>
              </>
            )}
            {passwordResetModalStep === 'FORM' && (
              <>
                <div className="modal-head">
                  <h4 className="subhead">
                    Pick a new password for account <strong>{value?.email}. </strong>
                    Passwords must have 8 characters and contain at least one letter and one number.
                  </h4>
                </div>
                <form action="" className="form-group">
                  <div className={errors['password'] ? 'fitem has-error' : 'fitem'}>
                    <label htmlFor="" className="required">
                      Password
                    </label>
                    <div className="ficon">
                      <input
                        type={passwordView ? 'text' : 'password'}
                        {...register('password')}
                        name="password"
                        placeholder="8 character minimum"
                        autoComplete="new-password"
                        onChange={(e) =>
                          setState((s) => ({
                            ...s,
                            password: e.target.value
                          }))
                        }
                      />
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            passwordView: !passwordView
                          }))
                        }
                        className={passwordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                    </div>

                    {errors['password'] ? (
                      <label className="error">{errors['password']?.message}</label>
                    ) : (
                      <label htmlFor="" className="lbl-txt">
                        Passwords must have 8 characters and contain at least one letter and one number.
                      </label>
                    )}
                  </div>
                  <div className={errors['retypePassword'] ? 'fitem has-error' : 'fitem'}>
                    <label htmlFor="" className="required">
                      Retype Password
                    </label>
                    <div className="ficon">
                      <input
                        type={retypePasswordView ? 'text' : 'password'}
                        {...register('retypePassword')}
                        name="retypePassword"
                        placeholder="Retype Password"
                        autoComplete="new-password"
                      />
                      <i
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setState((s) => ({
                            ...s,
                            retypePasswordView: !retypePasswordView
                          }))
                        }
                        className={retypePasswordView ? 'icon icon-eye' : 'icon icon-eye-none'}></i>
                    </div>
                    {errors['retypePassword'] ? (
                      <label className="error">{errors['retypePassword']?.message}</label>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </>
            )}
            {passwordResetModalStep === 'SUCCESS' && (
              <>
                <div className="modal-head">
                  <h4 className="subhead">
                    The password associated with <strong>{value?.email} </strong>has been updated.
                  </h4>
                  <p className="h4">
                    Send an email to <strong>{value?.email} </strong>
                    with their updated password and instruct them to change their password upon logging in.
                  </p>
                </div>
              </>
            )}
          </div>
        </>
      </Modal>
      {/* CONFIRM PASSWORD RESET MODAL END */}
    </div>
  );
}

import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AddNewCourse from '../../Calendar/Component/AddNewCourse';
import ErrorModal from '../../Calendar/Component/ErrorModal';
import ExportModal from '../../Calendar/Component/ExportModal';
import GanttView from '../../../Components/GanttView';
import { useCalendarAPI } from '../../../Services/calendar.service';
import { colors } from '../../../Services/courseColors';

const { Option } = Select;

const Timeline = () => {
  const { useGetEventsQuery, getMetadataValue, useMutateReschedule, errorModalData, setErrorModalData } =
    useCalendarAPI();

  const { data: calendarApiData } = useGetEventsQuery();

  const eventsQuery = useGetEventsQuery();
  const mutateReschedule = useMutateReschedule();

  /** @type {GetEventsResponse} */
  const calevents = eventsQuery.data;

  /** @type {string[]} */
  const [courseKeys, setCourseKeys] = useState(['All Courses']);
  const [selectedCourse, setSelectedCourse] = useState(courseKeys[0]);
  const [gantTaskData, setGantTaskData] = useState([]);
  const [legend, setLegend] = useState([]);
  const [updateTaskData, setUpdateTaskData] = useState(null);
  const [expandedCourses, setExpandedCourses] = useState(courseKeys);
  const [showErrorModal, setShowErrorModal] = useState(true);
  const [isNewCourseModalVisible, setIsNewCourseModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  // FLEE: DISABLE DELETE, SEE ABOUT PLACEHOLDER ROWS?
  const onDateChange = (task) => {
    const originalTask = gantTaskData.find((eachtask) => eachtask.id === task.id);
    if (!originalTask) {
      return;
    }

    const dateChangeData = {
      lessonPlanId: task.lessonPlanId,
      newDate: moment(task.end).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    };

    setUpdateTaskData(dateChangeData);
  };

  useEffect(() => {
    if (!updateTaskData) return;
    mutateReschedule.mutate(updateTaskData, {
      onSettled: () => {
        setUpdateTaskData(null);
      }
    });
  }, [updateTaskData]);

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
  };

  const handleCloseErrorModal = () => {
    setErrorModalData({ visible: false, errorMessage: '', subMessage: '' });
  };

  const handleSelectOption = (option) => {
    const { value } = option;

    if (value === 'Create New Course') {
      handleCreateNewCourse();
    } else if (value === 'Export') {
      handleExportCourse();
    }
  };

  const handleCreateNewCourse = () => {
    setIsNewCourseModalVisible(true);
  };

  const handleCloseCreateNewCourse = () => {
    setIsNewCourseModalVisible(false);
  };

  const handleCloseExportCourse = () => {
    setIsExportModalVisible(false);
  };

  const handleExportCourse = () => {
    setIsExportModalVisible(true);
  };

  const options = [
    {
      value: 'Create New Course',
      label: (
        <div>
          <i className="fa-regular fa-plus" style={{ paddingRight: '14px' }}></i>
          Create New Course
        </div>
      )
    },
    {
      value: 'Export',
      label: (
        <div>
          <i className="fa-solid fa-cloud-arrow-down" style={{ paddingRight: '14px' }}></i>
          Export
        </div>
      )
    }
  ];

  useEffect(() => {
    if (!calevents || eventsQuery.isFetching) return;
    const mapOfCourses = new Map();

    // Assemble map of courses from course metadata
    calevents.courseMetadatas.forEach((courseMetadata, index) => {
      mapOfCourses.set(courseMetadata.id, {
        id: `${index}`,
        type: 'task',
        name: courseMetadata.course,
        start: moment(courseMetadata.start).startOf('day').toDate(),
        end: moment(courseMetadata.end).endOf('day').toDate(),
        progress: 0,
        styles: {
          backgroundColor: colors[index % colors.length],
          backgroundSelectedColor: colors[index % colors.length]
        },
        lessonPlans: []
      });
    });

    const filteredCourseId = Array.from(mapOfCourses.values()).find((course) => course.name === selectedCourse)?.id;
    const courseKeys = Array.from(mapOfCourses.values()).map((course) => course.name);

    // Assemble lesson plans into courses from event data
    calevents.events.forEach((lessonPlan) => {
      const courseId = getMetadataValue(lessonPlan, 'course:id');
      const course = mapOfCourses.get(courseId);
      if (!course) return;

      course.lessonPlans.push({
        id: lessonPlan.id,
        type: 'task',
        name: lessonPlan.title,
        start: moment(lessonPlan.day).startOf('day').toDate(),
        end: moment(lessonPlan.day).endOf('day').toDate(),
        progress: 0,
        styles: {
          backgroundColor: course.styles.backgroundColor,
          backgroundSelectedColor: course.styles.backgroundColor
        }
      });

      mapOfCourses.set(courseId, course);
    });

    // Create gantt data and legend data
    const ganttData = [];
    const legendData = [];

    mapOfCourses.forEach((course) => {
      if (filteredCourseId && course.id !== filteredCourseId) return;

      // Add course to gantt data
      ganttData.push(course);

      const lessonPlans = [];

      course.lessonPlans.forEach((lessonPlan, index) => {
        lessonPlans.push({
          ...lessonPlan,
          id: `${course.id}-${index + 1}`,
          lessonPlanId: lessonPlan.id,
          courseId: course.id
        });
      });

      // Sort lesson plans based on start date
      lessonPlans.sort((a, b) => a.start - b.start);

      // Add sorted lesson plans to gantt data
      ganttData.push(...lessonPlans);

      // Add course and lesson plan names to the legend data
      legendData.push({
        course: course.name,
        courseId: course.id,
        list: lessonPlans.map((lessonPlan) => lessonPlan.name),
        color: course.styles.backgroundColor
      });
    });

    setGantTaskData(ganttData);
    setLegend(legendData);

    setCourseKeys(['All Courses', ...courseKeys]);
  }, [calevents, selectedCourse, eventsQuery.isFetching]);

  if (!gantTaskData.length) return <div>Loading...</div>;
  return (
    <div className="timeline__container">
      <div className="timeline">
        <div className="timeline__header">
          <div>
            <h1>Timeline</h1>
          </div>
          <div>
            <Select onChange={handleCourseChange} defaultValue={courseKeys[0]} style={{ width: 165, marginRight: 25 }}>
              {courseKeys.map((courseKey, index) => (
                <Option key={index} value={courseKey}></Option>
              ))}
            </Select>
            {/* <Select defaultValue="Options" style={{ backgroundColor: '#F8C341', width: 139 }}>
              <Option value="Options">Options</Option>
              <Option value="Add Holidays">Add Holidays</Option>
              <Option value="Create New Course">Create New Course</Option>
              <Option value="Export">Export</Option>
            </Select> */}
            <Select
              labelInValue
              className="option-select"
              dropdownClassName="option-select-dropdown"
              style={{ backgroundColor: '#F8C341', width: 139 }}
              dropdownMatchSelectWidth={false}
              placement={'bottomRight'}
              value={{ value: 'Options', label: 'Options' }}
              onChange={handleSelectOption}
              options={options}
            />
          </div>
        </div>
        <GanttView
          title={'Courses'}
          legend={legend}
          tasks={gantTaskData}
          onDateChange={onDateChange}
          expandedCourses={expandedCourses}
          setExpandedCourses={setExpandedCourses}
        />
      </div>
      <AddNewCourse visible={isNewCourseModalVisible} onClose={handleCloseCreateNewCourse} />
      {/* Error Modal Starts */}
      <ErrorModal
        errorModalData={errorModalData}
        showErrorModal={showErrorModal}
        setShowErrorModal={setShowErrorModal}
        handleCloseErrorModal={handleCloseErrorModal}
      />
      {/* Error Modal Ends */}
      <ExportModal isVisible={isExportModalVisible} onClose={handleCloseExportCourse} data={calendarApiData} />
    </div>
  );
};

export default Timeline;

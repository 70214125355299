import { Button } from 'antd';
import { VerticalCard } from '../../atom';
import styles from './free-trial-card.module.scss';

export const FreeTrialCard = ({ product, isActive }) => {
  return (
    <VerticalCard key={product.name} isActive={isActive} className={styles.planCard}>
      <div className={styles.heading}>
        <h4 className="heading4">{`${product.name} Subscription`}</h4>
        <label className="body-large">{product.subtitle}</label>
        <p className="body">{product.description}</p>
      </div>
      <div className={`${styles.costContainer} ${styles.freePlanCardCost}`}>
        <h1>{`$${product.price}`}</h1>
        <div className={styles.priceText}>
          <label className="body-large">{product.priceText}</label>
        </div>
      </div>
      <Button className="btn btn-full btn-disabled">Already Signed Up</Button>
    </VerticalCard>
  );
};

import Schedule from '../Component/Schedule';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Schedule } = state;
  return { Schedule };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Schedule);

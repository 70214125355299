import TellUsAboutYou from '../Component/TellUsAboutYou';
import { connect } from 'react-redux';
import { updateUser } from '../../../../Action';

const mapStateToProps = (state) => {
  const { TellUsAboutYou } = state;
  return { TellUsAboutYou };
};

const actionCreators = {
  updateUser
};

export default connect(mapStateToProps, actionCreators)(TellUsAboutYou);

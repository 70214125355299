import { history } from '../Lib/history';
import axiosInstance from './api';
import { tokenService } from './index';
import { USERS_LOGOUT } from '../Action/types';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = `token ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if (err?.response?.status === 401 && err?.response?.data?.detail === 'Invalid token.') {
        store?.dispatch({ type: USERS_LOGOUT });
        localStorage.setItem('removed_by_admin', true);
        tokenService.clearTeacherStorage();
        history.push('/auth/sign-in');
      } else if (err?.response?.status === 401 && err?.response?.data?.detail === 'User inactive or deleted.') {
        store?.dispatch({ type: USERS_LOGOUT });
        localStorage.setItem('suspend_by_admin', true);
        tokenService.clearTeacherStorage();
        history.push('/auth/sign-in');
      }
      return Promise.reject(err);
    }
  );
};
export default setup;

import { RadioButton } from '../../atom';
import styles from './radio-group.module.scss';

export const RadioGroup = ({ options, selectValue, selectedValue }) => {
  const group = options.map((option, index) => (
    <RadioButton
      key={index}
      value={option.value}
      label={option.label}
      onClick={(value) => selectValue(value)}
      isActive={selectedValue === option.value}
    />
  ));

  return <div className={styles.radioGroupContainer}>{group}</div>;
};

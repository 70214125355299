import api from '../Services/api';
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  TELL_US_ABOUT_YOU_REQUEST,
  TELL_US_ABOUT_YOU_SUCCESS,
  TELL_US_ABOUT_YOU_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  GET_TIMEZONES_LIST_REQUEST,
  GET_TIMEZONES_LIST_SUCCESS,
  GET_TIMEZONES_LIST_FAILURE
} from './types';
import { userService, tokenService } from '../Services/index';
import { history } from '../Lib/history';

export const registration = (payload) => {
  return (dispatch) => {
    dispatch(request());
    return userService.register(payload).then(
      (response) => {
        tokenService.setEmail(response.data.email);
        dispatch(success(response.data));
        history.push('/auth/email-verification');
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error.response.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: REGISTER_REQUEST };
  }

  function success(data) {
    return { type: REGISTER_SUCCESS, data };
  }

  function failure(error) {
    return { type: REGISTER_FAILURE, error };
  }
};

export const emailVerification = (payload) => {
  return (dispatch) => {
    dispatch(request());
    if (payload?.URL === 'RESET_PASSWORD_EMAIL_VERIFICATION') {
      const clonedPayload = {
        reset_password_code: payload.code,
        email: tokenService.getEmail()
      };
      return userService.resetPasswordemailVerification(clonedPayload).then(
        (response) => {
          dispatch(success(response.data));
          tokenService.setNewPasswordInfo({
            reset_password_code: payload.code,
            email: tokenService.getEmail()
          });
          tokenService.removeEmail();
          return response?.data;
        },
        (error) => {
          if (error?.response) {
            dispatch(failure(error?.response?.data));
            throw error?.response?.data;
          }
        }
      );
    } else {
      const clonedPayload = {
        activation_token: payload.code,
        email: tokenService.getEmail()
      };
      return userService.emailVerification(clonedPayload).then(
        (response) => {
          dispatch(success(response.data));
          tokenService.setLocalAccessToken(response.data.user.token);
          tokenService.setUser(response.data.user);
          tokenService.removeEmail();
          history.push('/auth/tell-us-about-you');
          return response?.data;
        },
        (error) => {
          if (error?.response) {
            dispatch(failure(error?.response?.data));
            throw error?.response?.data;
          }
        }
      );
    }
  };

  function request() {
    return { type: EMAIL_VERIFICATION_REQUEST };
  }

  function success(data) {
    return { type: EMAIL_VERIFICATION_SUCCESS, data };
  }

  function failure(error) {
    return { type: EMAIL_VERIFICATION_FAILURE, error };
  }
};

export const tellUsAboutYou = (payload) => {
  return (dispatch) => {
    dispatch(request());
    const id = tokenService.getUser()?.id;
    return userService.tellUsAboutYou(id, payload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.setUser(response.data);
        history.push('/teacher/dashboard');
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: TELL_US_ABOUT_YOU_REQUEST };
  }

  function success(data) {
    return { type: TELL_US_ABOUT_YOU_SUCCESS, data };
  }

  function failure(error) {
    return { type: TELL_US_ABOUT_YOU_FAILURE, error };
  }
};

export const resendCode = () => {
  return (dispatch) => {
    dispatch(request());
    const payload = {
      email: tokenService.getEmail()
    };
    return userService.resendCode(payload).then(
      (response) => {
        dispatch(success(response.data));
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: RESEND_CODE_REQUEST };
  }

  function success(data) {
    return { type: RESEND_CODE_SUCCESS, data };
  }

  function failure(error) {
    return { type: RESEND_CODE_FAILURE, error };
  }
};

export const resetPassword = (payload) => {
  return (dispatch) => {
    dispatch(request());
    return userService.resetPassword(payload).then(
      (response) => {
        tokenService.setEmail(payload.email);
        dispatch(success(response.data));
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: RESET_PASSWORD_REQUEST };
  }

  function success(data) {
    return { type: RESET_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: RESET_PASSWORD_FAILURE, error };
  }
};

export const setNewPassword = (payload) => {
  return (dispatch) => {
    dispatch(request());
    const clonedPayload = {
      ...payload,
      reset_password_code: tokenService.getNewPasswordInfo().reset_password_code,
      email: tokenService.getNewPasswordInfo().email
    };
    return userService.setNewPassword(clonedPayload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.removeNewPasswordInfo();
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: SET_NEW_PASSWORD_REQUEST };
  }

  function success(data) {
    return { type: SET_NEW_PASSWORD_SUCCESS, data };
  }

  function failure(error) {
    return { type: SET_NEW_PASSWORD_FAILURE, error };
  }
};

export const updateUserPassword = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_PASSWORD_REQUEST });
    return api
      .patch('/account/users/updatePassword/', payload)
      .then((response) => {
        dispatch({
          type: UPDATE_USER_PASSWORD_SUCCESS,
          data: response.data
        });
        return response?.data;
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_USER_PASSWORD_FAILURE,
            error: error?.response.data
          });
          throw error?.response?.data;
        }
      });
  };
};

export const getTimeZonesList = (payload) => {
  return (dispatch) => {
    dispatch({ type: GET_TIMEZONES_LIST_REQUEST });
    return api
      .get('/account/users/getTimeZones/', payload)
      .then((response) => {
        dispatch({
          type: GET_TIMEZONES_LIST_SUCCESS,
          data: response.data
        });
        return response?.data;
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_TIMEZONES_LIST_FAILURE,
            error: error?.response.data
          });
          throw error?.response?.data;
        }
      });
  };
};

import IndividualCourse from '../Component/IndividualCourse';
import { connect } from 'react-redux';
import {
  getSchoolCourseAssessments,
  addSchoolCourse,
  getCourseById,
  getCourseSchedule,
  getCourseLessons,
  getCourseLessonById,
  deleteCourseById,
  updateSchoolCourse,
  addLesson,
  getInstructionalEventCategories,
  addInstructionalEvent,
  updateInstructionalEvent,
  deleteInstructionalEvent,
  getInstructionalEventById,
  getInstructionalEvents,
  updateLesson,
  confirm,
  confirmInstructionalEvent
} from '../../../Action';

const mapStateToProps = (state) => {
  const {
    IndividualCourse,
    SchoolCourseAssessments,
    SchoolCourse,
    CourseDetails,
    CourseSchedule,
    CourseLessons,
    Lesson,
    AddLesson,
    UpdateLesson,
    DeleteCourse,
    UpdateSchoolCourse,
    AddEvent,
    UpdateInstructionalEvent,
    DeleteInstructionalEvent,
    GetEvent,
    GetEvents,
    InstructionalEventCategories,
    ConfirmCourse,
    ConfirmInstructionalEvent
  } = state;
  return {
    IndividualCourse,
    SchoolCourseAssessments,
    SchoolCourse,
    CourseDetails,
    CourseSchedule,
    CourseLessons,
    Lesson,
    AddLesson,
    UpdateLesson,
    DeleteCourse,
    UpdateSchoolCourse,
    AddEvent,
    UpdateInstructionalEvent,
    DeleteInstructionalEvent,
    GetEvent,
    GetEvents,
    InstructionalEventCategories,
    ConfirmCourse,
    ConfirmInstructionalEvent
  };
};

const actionCreators = {
  getSchoolCourseAssessments,
  addSchoolCourse,
  getCourseById,
  getCourseSchedule,
  getCourseLessons,
  getCourseLessonById,
  deleteCourseById,
  updateSchoolCourse,
  addLesson,
  getInstructionalEventCategories,
  addInstructionalEvent,
  updateInstructionalEvent,
  deleteInstructionalEvent,
  getInstructionalEventById,
  getInstructionalEvents,
  updateLesson,
  confirm,
  confirmInstructionalEvent
};

export default connect(mapStateToProps, actionCreators)(IndividualCourse);

import React, { useState } from 'react';
import loaderImg from '../../../assets/images/loader.png';
const PageContext = React.createContext();

export default function Loading() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <Loading_ />
    </PageContext.Provider>
  );
}

function Loading_() {
  return (
    <div className="loading-wrap">
      <div className="loading-inner">
        <figure>
          <img src={loaderImg} alt="" />
        </figure>
      </div>
    </div>
  );
}

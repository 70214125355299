import SchoolCalendar from '../Component/SchoolCalendar';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { SchoolCalendar } = state;
  return { SchoolCalendar };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(SchoolCalendar);

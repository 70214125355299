import PaceStandards from '../Component/PaceStandards';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { PaceStandards } = state;
  return { PaceStandards };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(PaceStandards);

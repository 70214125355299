import ResetPassword from '../Component/ResetPassword';
import { connect } from 'react-redux';
import { teacherAdminResetPassword } from '../../../../Action';

const mapStateToProps = (state) => {
  const { ResetPassword } = state;
  return { ResetPassword };
};

const actionCreators = {
  teacherAdminResetPassword
};

export default connect(mapStateToProps, actionCreators)(ResetPassword);

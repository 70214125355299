import { AlertCancelAt } from '../alert-cancel-at';
import { AlertCanceled } from '../alert-canceled';
import { AlertTrialEnded } from '../alert-trial-ended';

export const SubscriptionStatusMessage = ({ name, status, trialStart, cancelAt }) => {
  if (status === 'canceled' && trialStart) {
    return <AlertTrialEnded />;
  }

  if (status === 'canceled') {
    return <AlertCanceled productName={name} />;
  }

  if (cancelAt) {
    return <AlertCancelAt date={cancelAt} />;
  }
};

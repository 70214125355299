import { DISMISS_COMPLETE_PROFILE, DISMISS_COURSES_SET_CTA } from './types';

export const dismissCompleteProfile = (payload) => {
  return (dispatch) => {
    dispatch(request(payload));
  };

  function request(data) {
    return { type: DISMISS_COMPLETE_PROFILE, data };
  }
};

export const dismissCoursesCTA = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DISMISS_COURSES_SET_CTA,
      data: payload
    });
  };
};

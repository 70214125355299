import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Modal } from 'antd';
// import { useHistory, useParams } from 'react-router-dom'
// import { useParams } from 'react-router-dom';
import store from '../../../store';
import * as yup from 'yup';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { map } from 'lodash';
import _ from 'lodash';
import config from '../../../Lib/config';
import { updateSchoolCourse, getCourseSchedule, getCourseLessons, getCourseById } from '../../../Action';
import { connect } from 'react-redux';

function EditDetails({ onCourseEdit, ...props }) {
  const {
    isVisible,
    onClose,
    courseId,
    courseName,
    updateSchoolCourse,
    getCourseSchedule,
    getCourseLessons,
    getCourseById
  } = props;
  const [step, setStep] = useState('COURSE_DETAILS');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [lessonCount, SetLessonCount] = useState(0);
  // const history = useHistory();

  const [state, setState] = useState({
    errors: {},
    data: { days: [], isSame: true },
    selectedTimeZone: '',
    lessonCount: lessonCount
  });
  const [daysError, setDaysError] = useState([
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    },
    {
      start_time: '',
      startAMPM: '',
      end_time: '',
      endAMPM: ''
    }
  ]);
  const { start_date, end_date, errors } = state;
  let arrDaysError = [];
  const [noDaysFall, setNoDaysFall] = useState('');

  const getDetails = () => {
    // setLoader(true);
    const params = {
      course_id: courseId,
      page_size: 1000
    };
    Promise.allSettled([getCourseById(courseId), getCourseSchedule(params), getCourseLessons(params)])
      .then((result) => {
        const isFulfilled = result.filter((x) => x.status === 'fulfilled');
        if (isFulfilled?.length > 0) {
          const { CourseDetails, CourseSchedule, CourseLessons } = store.getState();
          let objCourseDetails = CourseDetails?.data;
          objCourseDetails['course_schedule'] = CourseSchedule?.data?.results;
          let draft_events = [];
          if (CourseLessons?.data?.results && CourseLessons?.data?.results?.length > 0) {
            draft_events = CourseLessons?.data?.results.filter((x) => x.is_draft === false);
          }
          const timeZoneForCourse =
            objCourseDetails?.time_zone || localStorage.getItem('user')?.time_zone || momentTimeZone.tz.guess(true);
          SetLessonCount(CourseSchedule?.data?.count);
          myAsyncLoopFunction(objCourseDetails?.schedule_time).then((schedule_time) => {
            objCourseDetails['schedule_time'] = schedule_time;
            setState((s) => ({
              ...s,
              CourseDetails: objCourseDetails,
              id: objCourseDetails?.id,
              courseName: objCourseDetails?.name,
              grade: objCourseDetails?.grade,
              level: objCourseDetails?.level,
              is_display_create_lesson_plan_suggestion_popup:
                objCourseDetails?.is_display_create_lesson_plan_suggestion_popup,
              is_display_date_not_planned_instruction_day_popup:
                objCourseDetails?.is_display_date_not_planned_instruction_day_popup,
              selectedTimeZone: timeZoneForCourse,
              start_date: moment.tz(objCourseDetails?.start_date, 'YYYY-MM-DD', timeZoneForCourse),
              end_date: moment.tz(objCourseDetails?.end_date, 'YYYY-MM-DD', timeZoneForCourse),
              run_time: objCourseDetails?.run_time,
              total_course_schedule_count: objCourseDetails?.total_course_schedule_count,
              total_lesson_planned_count: objCourseDetails?.total_lesson_planned_count,
              schedule_time: schedule_time,
              CourseLessons: draft_events,
              data: { days: schedule_time, isSame: objCourseDetails?.time_choice === 'same_time' ? true : false }
            }));
          });
        }
        // setLoader(false);
      })
      .catch(() => {
        // setLoader(false);
      });
  };

  const myAsyncLoopFunction = async (array) => {
    const allAsyncResults = [];
    for (const item of array) {
      const startTime = moment(item.start_time, ['HH:mm']).format('hh:mm');
      const startampm = moment(item.start_time, ['HH:mm']).format('A');
      const endTime = moment(item.end_time, ['HH:mm']).format('hh:mm');
      const endampm = moment(item.end_time, ['HH:mm']).format('A');
      const data = {
        ...item,
        ['start_time']: startTime,
        ['start_time_t']: startTime,
        ['startAMPM']: startampm,
        ['end_time']: endTime,
        ['end_time_t']: endTime,
        ['endAMPM']: endampm
      };
      allAsyncResults.push(data);
    }
    return allAsyncResults;
  };

  const setDay = (day_choice, idx) => {
    const { data } = state;
    let { days, isSame } = data;
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      days.splice(index, 1);
      if (days && days.length === 0) {
        days.push({
          day_choice: '',
          start_time: '',
          start_time_t: '',
          startAMPM: '',
          end_time: '',
          end_time_t: '',
          endAMPM: '',
          index: 0
        });
      }
    } else {
      if (days && days.length === 1 && days[0]['day_choice'] === '') {
        days[0]['day_choice'] = day_choice;
        days[0]['index'] = 0;
      } else {
        if (isSame) {
          days.push({
            day_choice: day_choice,
            start_time: days[0]?.start_time,
            start_time_t: days[0]?.start_time_t,
            startAMPM: days[0]?.startAMPM,
            end_time: days[0]?.end_time,
            end_time_t: days[0]?.end_time_t,
            endAMPM: days[0]?.endAMPM,
            index: idx
          });
        } else {
          days.push({
            day_choice: day_choice,
            start_time: '',
            start_time_t: '',
            startAMPM: '',
            end_time: '',
            end_time_t: '',
            endAMPM: '',
            index: idx
          });
        }
      }
    }
    days = days.filter((el) => {
      return el.day_choice !== '';
    });
    errors['days'] = '';
    days.sort(config.sortDay);
    setState((s) => ({
      ...s,
      errors: errors,
      data: { ...s.data, days: days, ...(!isSame ? { isSame: days.length <= 1 } : '') }
    }));
    setDaysError([]);
  };

  const isSelected = (day_choice) => {
    const { data } = state;
    const { days } = data;
    const index = _.findIndex(days, (item) => item.day_choice === day_choice);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  const objDays = {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun'
  };

  const changedTime = (type, index, value) => {
    const { data } = state;
    const { days, isSame } = data;
    if (isSame) {
      days.map((item) => {
        if (type === 'start_time') {
          item['start_time'] = value;
          item['start_time_t'] = value;
        } else if (type === 'startAMPM') {
          item['startAMPM'] = value;
        } else if (type === 'end_time') {
          item['end_time'] = value;
          item['end_time_t'] = value;
        } else if (type === 'endAMPM') {
          item['endAMPM'] = value;
        }
      });
    } else {
      days.map((item, idx) => {
        if (index === idx) {
          if (type === 'start_time') {
            item['start_time'] = value;
            item['start_time_t'] = value;
          } else if (type === 'startAMPM') {
            item['startAMPM'] = value;
          } else if (type === 'end_time') {
            item['end_time'] = value;
            item['end_time_t'] = value;
          } else if (type === 'endAMPM') {
            item['endAMPM'] = value;
          }
        }
      });
    }

    setState((s) => ({
      ...s,
      data: { ...s.data, days: days }
    }));
  };

  const validateStepOne = () => {
    const schema = yup.object().shape({
      courseName: yup.string().required('Course name is required.'),
      start_date: yup.string().typeError('Start date is required.').required('Start date is required.'),
      end_date: yup
        .date()
        .typeError('End date is required.')
        .required('End date is required.')
        .test('Is date greater', 'End date has to be more than start date', (value) => {
          if (!value) return true;
          return !moment(start_date).isSameOrAfter(moment(value).format('YYYY-MM-DD'));
        })
        .test('Is date greater', 'End date cannot occur in the past', (value) => {
          if (!value) return true;
          return !moment(moment().format('YYYY-MM-DD')).isAfter(moment(value).format('YYYY-MM-DD'));
        })
    });
    const data = { courseName, start_date, end_date };
    // let temp = [];
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setStep('COURSE_SCHEDULE');
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const validateStepTwo = () => {
    const { data } = state;
    let { isSame, days } = data;
    const isDaySelected = days.some((item) => item.day_choice !== '');
    if (isDaySelected) {
      if (isSame) {
        map(days, (item, index) => {
          let validationError = {
            start_time: '',
            startAMPM: '',
            end_time: '',
            endAMPM: ''
          };

          if (index === 0) {
            if (item.start_time === '') {
              validationError['start_time'] = 'Select start time.';
            }

            if (item.startAMPM === '') {
              validationError['startAMPM'] = 'Select start am/pm.';
            }

            if (item.end_time === '') {
              validationError['end_time'] = 'Select end time.';
            }

            if (item.endAMPM === '') {
              validationError['endAMPM'] = 'Select end am/pm.';
            }

            if (item.start_time && item.startAMPM && item.end_time && item.endAMPM) {
              const end = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm:ss');
              const endTimeShouldBe = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A')
                .add('15', 'minutes')
                .format('HH:mm:ss');
              if (end >= endTimeShouldBe) {
                validationError['end_time'] = '';
              } else {
                validationError['end_time'] = 'End must occur at least 15min after start time.';
              }
            }
            arrDaysError.push(validationError);
          }
        });
      } else {
        map(days, (item) => {
          let validationError = {
            start_time: '',
            startAMPM: '',
            end_time: '',
            endAMPM: ''
          };
          if (item.start_time === '') {
            validationError['start_time'] = 'Select start time.';
          }
          if (item.startAMPM === '') {
            validationError['startAMPM'] = 'Select start am/pm.';
          }

          if (item.end_time === '') {
            validationError['end_time'] = 'Select end time.';
          }

          if (item.endAMPM === '') {
            validationError['endAMPM'] = 'Select end am/pm.';
          }

          if (item.start_time && item.startAMPM && item.end_time && item.endAMPM) {
            const end = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HHmmss');
            const endTimeShouldBe = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A')
              .add('15', 'minutes')
              .format('HHmmss');
            if (end >= endTimeShouldBe) {
              validationError['end_time'] = '';
            } else {
              validationError['end_time'] = 'End must occur at least 15min after start time.';
            }
          }
          arrDaysError.push(validationError);
        });
      }
      setDaysError(arrDaysError);
      const isFulfilled = arrDaysError.filter(
        (x) => x.start_time !== '' || x.startAMPM !== '' || x.end_time !== '' || x.endAMPM !== ''
      );
      if (isFulfilled?.length === 0) {
        const params = {
          id: courseId,
          name: state?.courseName ? state?.courseName : '',
          grade: state?.grade ? state?.grade : '',
          level: state?.level ? state?.level : '',
          run_time: state?.run_time ? state?.run_time : '',
          start_date: moment
            .tz(moment(state?.start_date).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .startOf('day')
            .format(),
          end_date: moment
            .tz(moment(state?.end_date).format('YYYY-MM-DD'), 'YYYY-MM-DD', 'Etc/GMT')
            .endOf('day')
            .format(),
          time_choice: isSame ? 'same_time' : 'custom_time',
          course_schedule: days
        };
        updateCourse(params);
      }
    } else {
      errors['days'] = 'Please select at least one day.';
      setState((s) => ({ ...s, errors: errors }));
    }
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const updateCourse = (params) => {
    // setLoader(true);
    if (state?.data?.isSame) {
      /**
       * AUTHOR: Ritesh Solanki
       * <TARA-438>Create course | Add course schedule | When user has selected multiple days in "Is your class schedule the same every day you teach? "
       * selected "Custom" and then selects "its same for all day" then it is showing multiple "Start/End time" row</TARA-438>
       */
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('HH:mm:ss');
        item['start_time_t'] = moment(
          `${params?.course_schedule[0].start_time} ${params?.course_schedule[0].startAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        item['end_time'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('HH:mm:ss');
        item['end_time_t'] = moment(
          `${params?.course_schedule[0].end_time} ${params?.course_schedule[0].endAMPM}`,
          'hh:mm A'
        ).format('hh:mm');
        delete item.start_time_t;
        delete item.startAMPM;
        delete item.end_time_t;
        delete item.endAMPM;
      });
    } else {
      params?.course_schedule.map((item) => {
        item['start_time'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('HH:mm');
        item['start_time_t'] = moment(`${item.start_time} ${item.startAMPM}`, 'hh:mm A').format('hh:mm');
        item['end_time'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm');
        item['end_time_t'] = moment(`${item.end_time} ${item.endAMPM}`, 'hh:mm A').format('HH:mm');
        delete item.start_time_t;
        delete item.startAMPM;
        delete item.end_time_t;
        delete item.endAMPM;
      });
    }

    Promise.allSettled([updateSchoolCourse(params)])
      .then(() => {
        const { UpdateSchoolCourse } = store.getState();
        if (UpdateSchoolCourse?.data && Object.keys(UpdateSchoolCourse?.data).length > 0) {
          setState((s) => ({
            ...s,
            selectedOption: ''
          }));
          getDetails();
          finilzeCourse();
          onCourseEdit();
        } else {
          /**
           * AUTHOR: Ritesh Solanki
           * <TARA-552>Individual course page | Edit course schedule modal | In "Select all that apply"
           * if the user selects days which are not fall under selected dates then it should show a validation message
           * </TARA-552>
           */
          setNoDaysFall(UpdateSchoolCourse?.errors);
        }
        // setLoader(false);
      })
      .catch(() => {
        // setLoader(false);
      });
  };

  /**
   * AUTHOR: Ritesh Solanki
   * <TARA-79>Ability for teachers edit existing course</TARA-79>
   */
  const finilzeCourse = () => {
    setStep('COURSE_DETAILS');
    setIsEditModalVisible(false);
    onClose();
    moment.updateLocale('en', {
      weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    });
  };

  const handleCancel = () => {
    setIsEditModalVisible(false);
    onClose();
    setStep('COURSE_DETAILS');
    setState((s) => ({
      ...s,
      selectedOption: ''
    }));
    moment.updateLocale('en', {
      weekdaysMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    });
  };

  useEffect(() => {
    if (isVisible) {
      getDetails();
    }
    setIsEditModalVisible(isVisible);
  }, [isVisible]);

  return (
    <Modal
      visible={isEditModalVisible}
      width={704}
      closeIcon={<div className="icon icon-add"></div>}
      keyboard={false}
      centered={true}
      closable={true}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <Button key="edit_back" onClick={handleCancel} className="btn btn-primary btn-bdr">
          Cancel
        </Button>,
        <Button
          className="btn btn-brand"
          key="edit_link"
          type="button"
          onClick={
            step === 'COURSE_DETAILS' ? validateStepOne : step === 'COURSE_SCHEDULE' ? validateStepTwo : finilzeCourse
          }>
          {step === 'COURSE_DETAILS' ? 'Next Step' : step === 'COURSE_SCHEDULE' ? 'Save Changes' : 'Start Planning'}
        </Button>
      ]}>
      <div className="modal-head">
        {step === 'COURSE_DETAILS' && (
          <React.Fragment>
            <h1>Edit Course Details</h1>
            <form action="" className="form-group">
              <div className={errors['courseName'] ? 'fitem has-error' : 'fitem'}>
                <label htmlFor="" className="required">
                  Course Name
                </label>
                <input
                  type="text"
                  placeholder="Freshman Biology - Period 1"
                  value={state?.courseName}
                  onChange={(event) => {
                    setState((s) => ({ ...s, courseName: event?.target?.value }));
                  }}
                />
                {errors['courseName'] ? <label className="error">{errors['courseName']}</label> : ''}
              </div>
              <div className="half-fitem fitem">
                <div className="fitem half-fitem">
                  <label htmlFor="">Grade </label>
                  <input
                    type="text"
                    placeholder="9th"
                    value={state?.grade}
                    onChange={(event) => {
                      setState((s) => ({ ...s, grade: event?.target?.value }));
                    }}
                  />
                </div>
                <div className="fitem half-fitem">
                  <label htmlFor="">Level</label>
                  <input
                    type="text"
                    placeholder="Intermediate"
                    value={state?.level}
                    onChange={(event) => {
                      setState((s) => ({ ...s, level: event?.target?.value }));
                    }}
                  />
                </div>
              </div>
              <div className="fitem"></div>
              <div className="fitem">
                <label htmlFor="">Enter the dates your course begins and ends</label>
              </div>
              <div className="half-fitem fitem">
                <div className={errors['start_date'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label htmlFor="" className="required">
                    Start Date
                  </label>
                  <DatePicker
                    suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                    value={state?.start_date ? moment(state?.start_date) : ''}
                    placeholder={'MM/DD/YYYY'}
                    format={'MM/DD/YYYY'}
                    onChange={(event) => {
                      setState((s) => ({ ...s, start_date: event }));
                    }}
                    onBlur={(event) => {
                      if (moment(event.target.value).isValid()) {
                        setState((s) => ({ ...s, start_date: moment(event.target.value) }));
                      }
                    }}
                  />
                  {errors['start_date'] ? <label className="error">{errors['start_date']}</label> : ''}
                  {moment(state?.start_date).isBefore(moment().format('YYYY-MM-DD')) && (
                    <label className="lbl-txt">This start date occurs in the past</label>
                  )}
                </div>
                <div className={errors['end_date'] ? 'fitem half-fitem has-error' : 'fitem half-fitem'}>
                  <label htmlFor="" className="required">
                    End Date
                  </label>
                  <DatePicker
                    suffixIcon={<i data-icon="S" className="ficon icon"></i>}
                    value={state?.end_date ? moment(state?.end_date) : ''}
                    placeholder={'MM/DD/YYYY'}
                    format={'MM/DD/YYYY'}
                    onChange={(event) => {
                      setState((s) => ({ ...s, end_date: event }));
                    }}
                    onBlur={(event) => {
                      if (moment(event.target.value).isValid()) {
                        setState((s) => ({ ...s, end_date: moment(event.target.value) }));
                      }
                    }}
                  />
                  {errors['end_date'] ? <label className="error">{errors['end_date']}</label> : ''}
                </div>
              </div>
              <div className="fitem mb-0">
                <label htmlFor="">Label this School Year, Semester, or Quarter</label>
                <input
                  type="text"
                  placeholder="Spring Semester 2023"
                  value={state?.run_time}
                  onChange={(event) => {
                    setState((s) => ({ ...s, run_time: event?.target?.value }));
                  }}
                />
              </div>
            </form>
          </React.Fragment>
        )}
        {step === 'COURSE_SCHEDULE' && (
          <React.Fragment>
            <h1 className="mb-24">Edit Course Schedule</h1>
            <form className="form-group hide weekly-wrap">
              <div className="fitem">
                <label className="required lbl-lg mt-0">
                  <i>Select all that apply:</i>
                </label>
                <ul className="weekday-wrap flex">
                  <li onClick={() => setDay('monday', 0)} className={isSelected('monday') ? 'active' : ''}>
                    Monday
                  </li>
                  <li onClick={() => setDay('tuesday', 1)} className={isSelected('tuesday') ? 'active' : ''}>
                    Tuesday
                  </li>
                  <li onClick={() => setDay('wednesday', 2)} className={isSelected('wednesday') ? 'active' : ''}>
                    Wednesday
                  </li>
                  <li onClick={() => setDay('thursday', 3)} className={isSelected('thursday') ? 'active' : ''}>
                    Thursday
                  </li>
                  <li onClick={() => setDay('friday', 4)} className={isSelected('friday') ? 'active' : ''}>
                    Friday
                  </li>
                  <li onClick={() => setDay('saturday', 5)} className={isSelected('saturday') ? 'active' : ''}>
                    Saturday
                  </li>
                  <li onClick={() => setDay('sunday', 6)} className={isSelected('sunday') ? 'active' : ''}>
                    Sunday
                  </li>
                </ul>
                {errors['days'] ? <label className="error">{errors['days']}</label> : ''}
              </div>
              <div className="fitem">
                <i style={{ color: '#B91C1C' }}>{noDaysFall}</i>
              </div>
              <div className="fitem mb-24">
                <label className="lg-txt">Is your class schedule the same every day you teach?</label>
              </div>
              <div className="fitem half-fitem mb-24">
                <div className="fitem half-fitem">
                  <div className="fitem-check">
                    <input
                      className="fitem-ck-input"
                      id="sameEveryDay"
                      name="isSame"
                      type="radio"
                      checked={state?.data?.isSame}
                      onChange={() => {
                        setState((s) => ({
                          ...s,
                          data: { ...s.data, isSame: true }
                        }));
                      }}
                    />
                    <label className="fitem-ck-txt fw-semibold" htmlFor="sameEveryDay">
                      Same for all the days above
                    </label>
                  </div>
                </div>
                <div className={state?.data?.isSame ? 'fitem half-fitem maxw-328' : 'fitem half-fitem maxw-276'}>
                  <div className="fitem-check">
                    <input
                      className="fitem-ck-input"
                      id="custom"
                      name="isSame"
                      type="radio"
                      disabled={state?.data?.days.length === 1 ? true : false}
                      checked={!state?.data?.isSame}
                      onChange={() => {
                        setState((s) => ({
                          ...s,
                          data: { ...s.data, isSame: false }
                        }));
                      }}
                    />
                    <label className="fitem-ck-txt fw-semibold" htmlFor="custom">
                      Custom (different every day)
                    </label>
                  </div>
                </div>
              </div>
              {!state?.data?.isSame && state?.data?.days.length > 0 && (
                <>
                  <div className="fitem half-fitem fitem-mb-0">
                    {!state?.data?.isSame && (
                      <div className="days-item">
                        <p>Day</p>
                      </div>
                    )}
                    <div className="fitem half-fitem maxw-276">
                      <label className="required">Select Start Time</label>
                    </div>
                    <div className="fitem half-fitem maxw-276">
                      <label className="required">Select End Time</label>
                    </div>
                  </div>
                  {state?.data?.days.map((item, index) => (
                    <div className="fitem half-fitem" key={item.day_choice}>
                      {!state?.data?.isSame && (
                        <div className="days-item">
                          <label>{objDays[item.day_choice]}</label>
                        </div>
                      )}
                      <div
                        className={
                          daysError[index]?.start_time
                            ? 'fitem half-fitem has-error maxw-276 d-block'
                            : 'fitem half-fitem maxw-276 d-block'
                        }>
                        <div className="flex">
                          <div className="half-half-fitem half-fitem">
                            <input
                              type="text"
                              placeholder={'Add Time'}
                              value={item?.start_time_t}
                              onKeyUp={(event) => {
                                if (event.code === 'Backspace') {
                                  changedTime('start_time', index, '');
                                  changedTime('start_time_t', index, '');
                                }
                              }}
                              onBlur={() => {
                                const testStartDate = item?.start_time_t;
                                let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                if (!mainTimeIsValid) {
                                  const error = [...daysError];
                                  error[index] = { start_time: 'Please Enter a valid time' };
                                  setDaysError(error);
                                  let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                  changedTime('start_time', index, tempDate);
                                  changedTime('start_time_t', index, tempDate);
                                } else {
                                  const error = [...daysError];
                                  error[index] = { start_time: '' };
                                  setDaysError(error);
                                  changedTime('start_time', index, maintime);
                                  changedTime('start_time_t', index, maintime);
                                }
                              }}
                              onChange={(event) => {
                                if (event.target.value !== '') {
                                  setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                  changedTime('start_time', index, event?.target?.value);
                                  changedTime('start_time_t', index, event?.target?.value);
                                }
                              }}
                            />
                          </div>
                          <div className="half-half-fitem half-fitem last">
                            <select
                              value={item?.startAMPM}
                              onChange={(e) => changedTime('startAMPM', index, e.target.value)}>
                              <option value="" disabled>
                                Select
                              </option>
                              <option value={'AM'}>AM</option>
                              <option value={'PM'}>PM</option>
                            </select>
                          </div>
                        </div>
                        {daysError[index]?.start_time ? (
                          <label className="error">{daysError[index]?.start_time}</label>
                        ) : (
                          ''
                        )}
                        {daysError[index]?.startAMPM ? (
                          <label className="error">{daysError[index]?.startAMPM}</label>
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className={
                          daysError[index]?.end_time
                            ? 'fitem half-fitem has-error maxw-276 d-block'
                            : 'fitem half-fitem maxw-276 d-block'
                        }>
                        <div className="flex">
                          <div className="half-half-fitem half-fitem">
                            <input
                              type="text"
                              placeholder={'Add Time'}
                              value={item?.end_time_t}
                              onKeyUp={(event) => {
                                if (event.code === 'Backspace') {
                                  changedTime('end_time', index, '');
                                  changedTime('end_time_t', index, '');
                                }
                              }}
                              onBlur={() => {
                                const testStartDate = item?.end_time_t;
                                if (testStartDate) {
                                  let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                                  let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                                  if (!mainTimeIsValid) {
                                    const error = [...daysError];
                                    error[index] = { end_time: 'Please Enter a valid time' };
                                    setDaysError(error);

                                    let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                    changedTime('end_time', index, tempDate);
                                    changedTime('end_time_t', index, tempDate);
                                  } else {
                                    const error = [...daysError];
                                    error[index] = { end_time: '' };
                                    setDaysError(error);
                                    changedTime('end_time', index, maintime);
                                    changedTime('end_time_t', index, maintime);
                                  }
                                }
                              }}
                              onChange={(event) => {
                                const re = /^[0-9\b]+$/;
                                if (re.test(event.target.value)) {
                                  setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                  changedTime('end_time', index, event?.target?.value);
                                  changedTime('end_time_t', index, event?.target?.value);
                                }
                              }}
                            />
                          </div>
                          <div className="half-half-fitem half-fitem last">
                            <select
                              value={item?.endAMPM}
                              onChange={(e) => changedTime('endAMPM', index, e.target.value)}>
                              <option value="" disabled>
                                Select
                              </option>
                              <option value={'AM'}>AM</option>
                              <option value={'PM'}>PM</option>
                            </select>
                          </div>
                        </div>
                        {daysError[index]?.end_time ? (
                          <label className="error">{daysError[index]?.end_time}</label>
                        ) : (
                          ''
                        )}
                        {daysError[index]?.endAMPM ? <label className="error">{daysError[index]?.endAMPM}</label> : ''}
                      </div>
                    </div>
                  ))}
                </>
              )}
              {state?.data?.isSame && (
                <>
                  <div className="fitem half-fitem fitem-mb-0">
                    <div className="fitem half-fitem">
                      <label className="required">Select Start Time</label>
                    </div>
                    <div className="fitem half-fitem maxw-328">
                      <label className="required">Select End Time</label>
                    </div>
                  </div>
                  <div className="fitem half-fitem ">
                    <div
                      className={
                        daysError[0]?.start_time
                          ? 'fitem half-fitem has-error maxw-328 ml-0 d-block'
                          : 'fitem half-fitem maxw-328 ml-0 d-block'
                      }>
                      <div className="flex">
                        <div className="half-half-fitem half-fitem">
                          <input
                            type="text"
                            placeholder={'Add Time'}
                            value={state?.data?.days[0]?.start_time_t || ''}
                            onKeyUp={(event) => {
                              if (event.code === 'Backspace') {
                                changedTime('start_time', 0, '');
                                changedTime('start_time_t', 0, '');
                                setState((s) => ({ ...s, testStartDate: '' }));
                              }
                            }}
                            onBlur={() => {
                              const testStartDate = state?.data?.days[0]?.start_time_t || '';
                              let maintime = moment(testStartDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                              let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                              if (!mainTimeIsValid) {
                                const error = [...daysError];
                                error[0] = { start_time: 'Please Enter a valid time' };
                                setDaysError(error);
                                let tempDate = !mainTimeIsValid ? testStartDate : maintime;
                                changedTime('start_time', 0, tempDate);
                                changedTime('start_time_t', 0, tempDate);
                              } else {
                                const error = [...daysError];
                                error[0] = { start_time: '' };
                                setDaysError(error);
                                changedTime('start_time', 0, maintime);
                                changedTime('start_time_t', 0, maintime);
                              }
                            }}
                            onChange={(event) => {
                              if (event.target.value !== '') {
                                setState((s) => ({ ...s, testStartDate: event?.target?.value }));
                                changedTime('start_time', 0, event?.target?.value);
                                changedTime('start_time_t', 0, event?.target?.value);
                              }
                            }}
                          />
                        </div>
                        <div className="half-half-fitem half-fitem ml-10">
                          <select
                            value={state?.data?.days[0]?.startAMPM}
                            onChange={(e) => changedTime('startAMPM', 0, e.target.value)}>
                            <option value="" disabled>
                              Select
                            </option>
                            <option value={'AM'}>AM</option>
                            <option value={'PM'}>PM</option>
                          </select>
                        </div>
                      </div>
                      {daysError[0]?.start_time ? <label className="error">{daysError[0]?.start_time}</label> : ''}
                      {daysError[0]?.startAMPM ? <label className="error">{daysError[0]?.startAMPM}</label> : ''}
                    </div>
                    <div
                      className={
                        daysError[0]?.end_time
                          ? 'fitem half-fitem has-error maxw-328 ml-auto d-block'
                          : 'fitem half-fitem maxw-328 ml-auto d-block'
                      }>
                      <div className="flex">
                        <div className="half-half-fitem half-fitem">
                          <input
                            type="text"
                            placeholder={'Add Time'}
                            value={state?.data?.days[0]?.end_time_t || ''}
                            onKeyUp={(event) => {
                              if (event.code === 'Backspace') {
                                changedTime('end_time', 0, '');
                                changedTime('end_time_t', 0, '');
                                setState((s) => ({ ...s, testEndDate: '' }));
                              }
                            }}
                            onBlur={() => {
                              const testEndDate = state?.data?.days[0]?.end_time_t || '';
                              let maintime = moment(testEndDate, ['HH:mm', 'hh:mm']).format('hh:mm');
                              let mainTimeIsValid = moment(maintime, ['HH:mm', 'hh:mm'], true).isValid();
                              if (!mainTimeIsValid) {
                                const error = [...daysError];
                                error[0] = { end_time: 'Please Enter a valid time' };
                                setDaysError(error);
                                let tempDate = !mainTimeIsValid ? testEndDate : maintime;
                                changedTime('end_time', 0, tempDate);
                                changedTime('end_time_t', 0, tempDate);
                              } else {
                                const error = [...daysError];
                                error[0] = { end_time: '' };
                                setDaysError(error);
                                changedTime('end_time', 0, maintime);
                                changedTime('end_time_t', 0, maintime);
                              }
                            }}
                            onChange={(event) => {
                              if (event.target.value !== '') {
                                setState((s) => ({ ...s, testEndDate: event?.target?.value }));
                                changedTime('end_time', 0, event?.target?.value);
                                changedTime('end_time_t', 0, event?.target?.value);
                              }
                            }}
                          />
                        </div>
                        <div className="half-half-fitem half-fitem ml-10">
                          <select
                            value={state?.data?.days[0]?.endAMPM}
                            onChange={(e) => changedTime('endAMPM', 0, e.target.value)}>
                            <option value="" disabled>
                              Select
                            </option>
                            <option value={'AM'}>AM</option>
                            <option value={'PM'}>PM</option>
                          </select>
                        </div>
                      </div>
                      {daysError[0]?.end_time ? <label className="error">{daysError[0]?.end_time}</label> : ''}
                      {daysError[0]?.endAMPM ? <label className="error">{daysError[0]?.endAMPM}</label> : ''}
                    </div>
                  </div>
                </>
              )}
            </form>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { CourseDetails, CourseSchedule, CourseLessons, UpdateLesson } = state;
  return {
    CourseDetails,
    CourseSchedule,
    CourseLessons,
    UpdateLesson
  };
};

const actionCreators = {
  getCourseById,
  getCourseSchedule,
  getCourseLessons,
  updateSchoolCourse
};

export default connect(mapStateToProps, actionCreators)(EditDetails);

import React, { useState } from 'react';
const PageContext = React.createContext();

export default function Assessments() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <Assessments_ />
    </PageContext.Provider>
  );
}

function Assessments_() {
  return <div></div>;
}

import GetStarted from '../Component/GetStarted';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { GetStarted } = state;
  return { GetStarted };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(GetStarted);

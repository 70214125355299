import Login from '../Component/Login';
import { connect } from 'react-redux';
import { login } from '../../../../Action';
const mapStateToProps = (state) => {
  const { Login } = state;
  return { Login };
};

const actionCreators = {
  login
};

export default connect(mapStateToProps, actionCreators)(Login);

import Done from '../Component/Done';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { Done } = state;
  return { Done };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Done);

import React, { useContext, useState } from 'react';
import { SubscriptionLayout } from '../../../Layout/Subscription/subscription.jsx';
import { Summary } from '../../../Components';
import { useLocation } from 'react-router-dom';
import { subscriptionService } from '../../../Services/subscriptions.service.js';
import Loading from '../../../Layout/loading/Component/Loading.jsx';
import { SponsoredSubscriptionForm } from '../../../Components/organisms/sponsoredSubscriptionForm/sponsoredSubscriptionForm.jsx';
import { toast } from 'react-toastify';

const PageContext = React.createContext();

export default function SponsoredSubscriptionPage() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader, location }}>
      <SponsoredSubscriptionPage_ />
    </PageContext.Provider>
  );
}

function SponsoredSubscriptionPage_() {
  const { loader, setLoader } = useContext(PageContext);
  const [isSentEmail, setIsSentEmail] = useState(false);

  const sendEmail = async (email, name, url) => {
    setLoader(true);
    try {
      await subscriptionService.sendSponsorEmail(email, name, url);
      setIsSentEmail(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setIsSentEmail(false);
      error?.response?.data?.error && toast.error(error.response.data.error);
    }
  };

  return loader ? (
    <Loading />
  ) : (
    <SubscriptionLayout
      asideContent={() => <Summary />}
      content={() => (
        <SponsoredSubscriptionForm
          sendEmailToSponsor={(email, name, url) => sendEmail(email, name, url)}
          isSentEmailToSponsor={isSentEmail}
        />
      )}
    />
  );
}

import api from './api';

const createPaymentIntent = (payload) => {
  return api.post('/subscriptions/intents/create', payload);
};

const getPaymentIntent = (id) => {
  return api.get(`/subscriptions/intents/${id}`);
};

const getPaymentIntentStatus = (id) => {
  return api.get(`/subscriptions/intents/status/${id}`).then(({ data }) => ({ status: data.status }));
};

const createSubscription = (payload) => {
  return api.post('/subscriptions/subscriptions-members/create', payload);
};

const getProducts = () => {
  return api.get('/subscriptions/products');
};

const getProductsBySubscriptionType = (subscriptionType, email) => {
  return api.get('/subscriptions/products', { params: { subscriptionType, email } });
};

const getProductsByIds = (ids) => {
  const customerId = localStorage.getItem('customerId') || process.env.REACT_APP_STRIPE_CUSTOMER_ID;
  // @TODO Delete all customer id references when Django and Nest are integrated
  return api.get('/subscriptions/products/by-id', { params: { ids, customerId } });
};

const contactUs = (data) => {
  return api.post('/subscriptions/contact-us/', data);
};

export const paymentsService = {
  createPaymentIntent,
  getPaymentIntent,
  getPaymentIntentStatus,
  createSubscription,
  getProducts,
  getProductsByIds,
  getProductsBySubscriptionType,
  contactUs
};

import { SummaryUserContent } from '../../molecules/summary-user-content';
import styles from './contactUsSummary.module.scss';
import contactUsLogo from '../../../assets/images/contact-us-summary.svg';

export const ContactUsSummary = () => {
  return (
    <div className={styles.summaryContainer}>
      <div className={styles.superiorContent}>
        <SummaryUserContent
          subscriptionPlanType="Enterprise Subscription"
          user="2+ users subscription"
          description={
            'Provide exclusive access to multiple users with the enterprise subscription. Contact us to request a custom budget.'
          }
        />
      </div>
      <div className={styles.logoContainer}>
        <img src={contactUsLogo} alt="logo" />
      </div>
    </div>
  );
};

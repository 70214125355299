import EmailVerification from '../Component/EmailVerification';
import { connect } from 'react-redux';
import { emailVerification, resendCode } from '../../../../Action';

const mapStateToProps = (state) => {
  const { emailVerification } = state;
  return {
    loading: emailVerification.loading
  };
};

const actionCreators = {
  emailVerification,
  resendCode
};

export default connect(mapStateToProps, actionCreators)(EmailVerification);

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USERS_LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_FAILURE
} from './types.js';
import api from './../Services/api';
import { authService, tokenService } from '../Services/index';
import { history } from '../Lib/history';

export const login = (payload) => {
  return (dispatch) => {
    dispatch(request());

    return authService.login(payload).then(
      (response) => {
        dispatch(success(response.data));
        tokenService.setUser(response.data);
        if (response.data.token) {
          tokenService.setLocalAccessToken(response.data.token);
        }
        if (response?.data?.is_user_verified && response?.data?.is_partially_profile_completed) {
          history.push('/teacher/dashboard');
        } else if (!response?.data?.is_user_verified) {
          tokenService.setEmail(response.data.email);
          /* RESEND CODE START */
          api
            .post('/account/users/resendCode/', { email: response.data.email })
            .then(() => {
              history.push('/auth/email-verification');
            })
            .catch(() => {});
          /* RESEND CODE END */
        } else if (!response?.data?.is_partially_profile_completed) {
          history.push('/auth/tell-us-about-you');
        }
        return response?.data;
      },
      (error) => {
        if (error?.response) {
          dispatch(failure(error?.response?.data));
          throw error?.response?.data;
        }
      }
    );
  };

  function request() {
    return { type: LOGIN_REQUEST };
  }

  function success(data) {
    return { type: LOGIN_SUCCESS, data };
  }

  function failure(error) {
    return { type: LOGIN_FAILURE, error };
  }
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logout());
    history.push('/auth/sign-in');
    return authService.logout();
  };

  function logout() {
    return { type: USERS_LOGOUT };
  }
};

export const updateUser = (payload) => {
  return (dispatch) => {
    const id = tokenService.getUser()?.id;
    return api
      .patch(`/account/users/${id}/`, payload, { headers: { 'Content-type': 'multipart/form-data' } })
      .then((response) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          data: response.data
        });
        tokenService.setUser(response.data);
        return response?.data;
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: UPDATE_USER_FAILURE,
            error: error?.response.data
          });
          throw error?.response?.data;
        }
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    const id = tokenService.getUser()?.id;
    return api
      .get(`/account/users/${id}/`)
      .then((response) => {
        dispatch({
          type: GET_USER_SUCCESS,
          data: response.data
        });
        tokenService.setUser(response.data);
        return response?.data;
      })
      .catch((error) => {
        if (error?.response) {
          dispatch({
            type: GET_USER_FAILURE,
            error: error?.response.data
          });
          throw error?.response?.data;
        }
      });
  };
};

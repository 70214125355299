import React from 'react';
import { Link } from 'react-router-dom';
import adminLogo from '../../../../assets/images/admin-logo.svg';
const PageContext = React.createContext();

export default function PasswordChanged(props) {
  return (
    <PageContext.Provider value={{ ...props }}>
      <PASSWORDCHANGED />
    </PageContext.Provider>
  );
}

function PASSWORDCHANGED() {
  return (
    <div id="content-area" className="admin-login-theme">
      <div className="admin-linner-outer">
        <div className="admin-linner">
          <div className="title-row">
            <img className="adminlogo" src={adminLogo} alt="" />
            <h1 className="text-center">Password changed</h1>
            <div className="info-card acard acard-sm">
              <div className="caption">
                <i className="icon icon-circle-check"></i>
                <p>Your password has been successfully updated</p>
              </div>
            </div>
            <p className="pt-20 clr-black">Go back to the sign in page and use your new password to log in.</p>
          </div>
          <div className="btn-group">
            <Link className="btn-txt btn" style={{ margin: 0 }} to={'/teacher-admin/sign-in'}>
              Back to sign in page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

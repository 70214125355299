import { combineReducers } from 'redux';

/* AUTH */
import { login } from './auth.reducer';

/* USER */
import {
  registration,
  emailVerification,
  tellUsAboutYou,
  resendCode,
  resetPassword,
  setNewPassword,
  updateUserPassword,
  getTimeZonesList
} from './users.reducer';

// INDIVIDUAL COURSE
import SchoolCourseAssessments from './IndividualCourse/getSchoolCourseAssessments';
import SchoolCourse from './IndividualCourse/addSchoolCourse';
import CourseDetails from './IndividualCourse/getCourseById';
import CourseSchedule from './IndividualCourse/getCourseSchedule';
import CourseLessons from './IndividualCourse/getCourseLessons';
import Lesson from './IndividualCourse/getCourseLessonsById';
import AddLesson from './IndividualCourse/addCourseLesson';
import UpdateLesson from './IndividualCourse/updateCourseLesson';
import InstructionalEventCategories from './IndividualCourse/getInstructionalEventCategories';
import AddInstructionalEvent from './IndividualCourse/addInstructionalEvent';
import UpdateInstructionalEvent from './IndividualCourse/updateInstructionalEvent';
import DeleteInstructionalEvent from './IndividualCourse/deleteInstructionalEvent';
import GetEvent from './IndividualCourse/getInstructionalEventById';
import GetEvents from './IndividualCourse/getInstructionalEvents';
import DeleteCourse from './IndividualCourse/deleteCourse';
import UpdateSchoolCourse from './IndividualCourse/updateSchoolCourse';
import ConfirmCourse from './IndividualCourse/confirmCourse';
import ConfirmInstructionalEvent from './IndividualCourse/confirmInstructionalEvent';

/* DIMISS CTA */
import { dismissCompleteProfile, dismissCoursesCTA } from './dismissCTA.reducer';

/* COURSE */
import { allCourse, updateCourseOrderIndex } from './course.reducer';
import { USERS_LOGOUT } from '../Action/types';

/* TEACHER ADMIN START */
import { teacherAdminLogin } from './TeacherAdmin/teacherAdmin.auth.reducers';
import {
  teacherAdminResetPassword,
  teacherAdminEmailVerification,
  teacherAdminResendCode,
  teacherAdminSetNewPassword,
  teacherAdminChangePassword
} from './TeacherAdmin/teacherAdmin.user.reducers';

import AllUsers from './TeacherAdmin/getAllUsers';
import SuspenseUser from './TeacherAdmin/suspenseUser';

const appReducer = combineReducers({
  login,
  registration,
  emailVerification,
  tellUsAboutYou,
  resendCode,
  resetPassword,
  setNewPassword,
  updateUserPassword,
  getTimeZonesList,
  SchoolCourseAssessments,
  SchoolCourse,
  dismissCompleteProfile,
  allCourse,
  CourseDetails,
  CourseSchedule,
  CourseLessons,
  Lesson,
  AddLesson,
  UpdateLesson,
  InstructionalEventCategories,
  AddInstructionalEvent,
  UpdateInstructionalEvent,
  DeleteInstructionalEvent,
  GetEvent,
  GetEvents,
  dismissCoursesCTA,
  DeleteCourse,
  UpdateSchoolCourse,
  updateCourseOrderIndex,
  teacherAdminLogin,
  AllUsers,
  teacherAdminResetPassword,
  teacherAdminEmailVerification,
  teacherAdminResendCode,
  teacherAdminSetNewPassword,
  SuspenseUser,
  teacherAdminChangePassword,
  ConfirmCourse,
  ConfirmInstructionalEvent
});

const rootReducer = (state, action) => {
  if (action.type === USERS_LOGOUT) {
    /* RESET STORE ITEMS ON LOGOUT */
    const copyState = { ...state, dismissCoursesCTA: { dismiss: [] }, dismissCompleteProfile: { dismiss: false } };
    return appReducer(copyState, action);
  }
  return appReducer(state, action);
};
export default rootReducer;

import { Gantt, ViewMode } from 'gantt-task-react';
import React from 'react';

const ReusableGantt = ({ tasks, onDateChange }) => (
  <Gantt
    tasks={tasks}
    viewMode={ViewMode.DayWeek}
    timeStep={24 * 60 * 60 * 1000}
    listCellWidth={''}
    columnWidth={45}
    disableRangeChange={true}
    disableTooltip={true}
    onDateChange={onDateChange}
  />
);

export default ReusableGantt;

import React, { useContext, useEffect, useState } from 'react';
import { SubscriptionLayout } from '../../../Layout/Subscription';
import { InformativeMessage, ContactUsSummary } from '../../../Components';
import { useLocation } from 'react-router-dom';
import { paymentsService } from '../../../Services/payments.service';
import Loading from '../../../Layout/loading/Component/Loading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const PageContext = React.createContext();

export default function ContactUsGratitude() {
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  return (
    <PageContext.Provider value={{ loader, setLoader, location }}>
      <ContactUsGratitude_ />
    </PageContext.Provider>
  );
}

function ContactUsGratitude_() {
  const { loader, setLoader } = useContext(PageContext);
  const paymentIntent = new URLSearchParams(location.search)?.get('payment_intent');
  const history = useHistory();
  const handleGoHome = () => {
    history.push('/');
  };

  useEffect(() => {
    if (paymentIntent) {
      setLoader(true);

      paymentsService.getPaymentIntentStatus(paymentIntent).then(() => {
        setLoader(false);
      });
    }
  }, [paymentIntent]);

  return loader ? (
    <Loading />
  ) : (
    <SubscriptionLayout
      asideContent={() => <ContactUsSummary />}
      content={() => (
        <InformativeMessage
          title="Thank you!"
          subtitle="Your request has been received"
          buttonAlign="right"
          buttonText="Back To Home"
          content={
            //TODO email should be dynamic
            <span>
              {`Thank you for providing your organization's information. We're processing your request and will send you an estimate for the Enterprise Subscription Plan to `}
              <b>nicolas.alarcon@halopowered.com</b>
              {` within the next 72 hours. If you have any urgent inquiries, please feel free to contact our support team.`}
            </span>
          }
          onActionButton={handleGoHome}
        />
      )}
    />
  );
}

import ClassDetail from '../Component/ClassDetail';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { ClassDetail } = state;
  return { ClassDetail };
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(ClassDetail);

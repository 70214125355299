import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const ReusableCalendar = ({
  events,
  onEventDrop,
  onEventResize,
  eventPropGetter,
  components,
  defaultView = 'month',
  isResizable = false,
  defaultDate = new Date(),
  onSelectEvent
}) => (
  <DnDCalendar
    selectable
    events={events}
    localizer={localizer}
    onEventDrop={onEventDrop}
    defaultView={defaultView}
    defaultDate={defaultDate}
    resizable={isResizable}
    onEventResize={onEventResize}
    components={components}
    eventPropGetter={eventPropGetter}
    onSelectEvent={onSelectEvent}
  />
);

export default ReusableCalendar;

import { Button } from 'antd';
import styles from './informative-message.module.scss';

export const InformativeMessage = ({ title, subtitle, content, buttonText, buttonAlign, onActionButton }) => {
  return (
    <div className={styles.finalMessageContainer}>
      <h1 className={`heading1 ${styles.title}`}>{title}</h1>
      <h3 className={`heading3 ${styles.subtitle}`}>{subtitle}</h3>
      <label className={`body ${styles.content}`}>{content}</label>
      {buttonAlign !== '' && buttonText !== '' && (
        <div className={styles.buttonContainer} style={{ textAlign: buttonAlign }}>
          <Button className={`btn btn-brand ${styles.button}`} onClick={onActionButton}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

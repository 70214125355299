import ProfileAndSettings from '../Component/ProfileAndSettings';
import { connect } from 'react-redux';
import { updateUser, updateUserPassword, getUser, getTimeZonesList } from '../../../Action';

const mapStateToProps = (state) => {
  const { login } = state;
  return {
    user: login.user
  };
};

const actionCreators = {
  updateUser,
  updateUserPassword,
  getUser,
  getTimeZonesList
};

export default connect(mapStateToProps, actionCreators)(ProfileAndSettings);

import React, { useState } from 'react';
import { SubscriptionLayout } from '../../../Layout/Subscription';
import { ContactUsSummary } from '../../../Components';
import { ContactUsForm } from '../../../Components/organisms/contactUsForm/contactUsForm';
const PageContext = React.createContext();

export default function ContactUsPage() {
  const [loader, setLoader] = useState(false);

  return (
    <PageContext.Provider value={{ loader, setLoader }}>
      <ContactUsPage_ />
    </PageContext.Provider>
  );
}

function ContactUsPage_() {
  return (
    <SubscriptionLayout
      asideContent={() => <ContactUsSummary />}
      content={() => <ContactUsForm onSubmit={(data) => console.log(data)} />}
    />
  );
}

const INITIAL_STATE = { data: {}, errors: {} };

const getCourseSchedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_COURSE_SCHEDULE_SUCCESSFULLY':
      return { ...INITIAL_STATE, data: action.data };
    case 'GET_COURSE_SCHEDULE_FAILED':
      return { ...INITIAL_STATE, errors: action.data };
    default:
      return { ...state };
  }
};
export default getCourseSchedule;
